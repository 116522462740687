import React from 'react';
import './MainButton.scss';
import { useHistory } from 'react-router-dom';

function MainButton(props) {
    const history = useHistory();

    function navigate() {
        history.push(props.link);
    }

    let classes = 'main-button';
    let callback;

    if (props.ghost) classes += ' ghost';
    if (props.svgAbsolute) classes += ' svg-absolute';
    
    if (props.link) callback = () => navigate;
    else if (props.onclick) callback = () => props.onclick();
    else callback = () => {};

    if(props.download || props.external) {
        return (
            <a
                className={classes}
                style={props.style}
                role="button"
                href={props.link}
                download={props.download}
                target={props.blank ? "_blank" : ""}
            >
                {props.svg}
                {props.text}
            </a>   
        )
    }
    else {
        return (
            <div
                className={classes}
                style={props.style}
                onClick={callback()}
                role="button"
            >
                {props.svg}
                {props.text}
            </div>   
        );
    }
}

export default MainButton;
