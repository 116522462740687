import React, { useEffect, useRef, useState } from 'react';
import './FormSelect.scss';
import DownArrowIcon from '../DownArrowIcon/DownArrowIcon';

const FormSelect = (props) => {
    const formSelectNode = useRef();
    const [selectedOption, setSelectedOption] = useState(null);
    const [optionsListHeight, setOptionsListHeight] = useState(0);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    });

    const handleOutsideClick = (e) => {
        if (
            !formSelectNode.current.contains(e.target) &&
            !e.target.closest('.select')
        ) {
            props.setOpen(-1);
        }
    };

    const setHeight = () => {
        const options = formSelectNode.current.querySelectorAll('.option');
        const optionHeight = options[0].offsetHeight;
        const numberOfOptions = options.length;
        const optionsListHeight = numberOfOptions * optionHeight + 40;
        setOptionsListHeight(optionsListHeight);
    };

    const toggleIsOpen = (e) => {
        setHeight();
        if(isOpen())
            props.setOpen(-1);   
        else
            props.setOpen(props.index);
    };

    const selectOption = (e) => {
        setSelectedOption(e.target.textContent);
        props.setFormValue({
            ...props.formValue,
            [e.target.closest('.select').getAttribute('data-name')]: e.target
                .textContent
        });
    };

    const isOpen = () => {
        return props.open === props.index
    }

    return (
        <div
            className={`form-select${props.twoColumn ? ' two-column' : ''}${
                props.top ? ' top' : ''
            }`}
            ref={formSelectNode}
        >
            <div className="select" data-name={props.name}>
                <div
                    className={`label${isOpen() ? ' active' : ''}`}
                    onClick={toggleIsOpen}
                    role="button"
                >
                    <span className="label-text">
                        {selectedOption ? selectedOption : props.label}
                    </span>
                    <DownArrowIcon />
                </div>
                <ul
                    className="options"
                    style={
                        !isOpen()
                            ? { height: '0' }
                            : { height: `${optionsListHeight}px` }
                    }
                    onClick={toggleIsOpen} // This is for the spacer underneath the dropdown
                >
                    {props.options.map((option, key) => (
                        <li
                            className="option"
                            onClick={selectOption}
                            role="button"
                            key={key}
                        >
                            {option.text}
                        </li>
                    ))}
                </ul>
                <select name={props.name} value={selectedOption}>
                    <option value>{props.label}</option>
                    {props.options.map((option, key) => (
                        <option value={option.value} key={key}>
                            {option.text}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default FormSelect;
