import React, { useEffect } from 'react';
import './Contact.scss';
import ContactForm from '../ContactForm/ContactForm';
import Cover from '../Cover/Cover';
import MainButton from '../MainButton/MainButton';
import LinkedInIcon from '../LinkedInIcon/LinkedInIcon';
import InstagramIcon from '../InstagramIcon/InstagramIcon';
import FacebookIcon from '../FacbookIcon/FacebookIcon';
import TwitterIcon from '../TwitterIcon/TwitterIcon';
import { useSelector } from 'react-redux';
import MobileFooter from '../MobileFooter/MobileFooter';

const Contact = () => {
    const content = useSelector((state) => state.content.pages.contact);
    const form = useSelector((state) => state.content.options.form);
    const submitted =
        useSelector((state) => state.content.options.form_submitted) || false;
    const submitting =
        useSelector((state) => state.content.options.form_submitting) || false;
    const consent_error =
        useSelector((state) => state.content.options.consent_error) || false;

    useEffect(() => {
        document.querySelector('#root').scrollTo(0, 0);
    }, []);

    if (typeof content !== 'undefined') {
        return (
            <div className="contact">
                <Cover />
                <div className="contact-bg"></div>
                <div className="contact-info-container">
                    <div className="contact-info">
                        <div className="flex-section">
                            <h2>{content.form_contact_info.heading}</h2>
                            {/* 
                            <MainButton
                                text={
                                    content.form_contact_info.meeting_button
                                        .text
                                }
                                link={
                                    content.form_contact_info.meeting_button
                                        .link
                                }
                                external
                                blank={
                                    content.form_contact_info.meeting_button
                                        .blank
                                }
                                style={{
                                    width: '344px',
                                    marginBottom: '8px'
                                }}
                            />
                            <MainButton
                                text={
                                    content.form_contact_info.appointment_button
                                        .text
                                }
                                link={
                                    content.form_contact_info.appointment_button
                                        .link
                                }
                                external
                                blank={
                                    content.form_contact_info.appointment_button
                                        .blank
                                }
                                style={{
                                    width: '344px',
                                    marginBottom: '24px'
                                }}
                            />
                            */}
                            <a
                                href={
                                    content.form_contact_info.email
                                        .mailto_address
                                }
                                className="contact-link"
                            >
                                {content.form_contact_info.email.label}
                            </a>
                            <a
                                className="contact-link"
                                href={
                                    content.form_contact_info.phone_number
                                        .tel_number
                                }
                            >
                                {content.form_contact_info.phone_number.label}
                            </a>
                            {/* <h3 className="sales-office-title">
                                {content.form_contact_info.sales_office.heading}
                            </h3>
                            <ul className="address">
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            content.form_contact_info
                                                .sales_office.label
                                    }}
                                />
                            </ul>
                            <a
                                href={
                                    content.form_contact_info.sales_office.link
                                }
                                className="contact-link"
                                target="_blank"
                            >
                                Get Directions
                            </a> */}
                            {/*
                            <img src="/img-temp/contact-map.jpg" alt="Map" />
                            */}
                            <ul className="contact-social contact-social-desktop">
                                <li>
                                    <a
                                        href="https://www.linkedin.com/company/marlinspringinvestments/"
                                        target="_blank"
                                    >
                                        <LinkedInIcon />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.instagram.com/marlinspring/"
                                        target="_blank"
                                    >
                                        <InstagramIcon />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://www.facebook.com/MarlinSpring"
                                        target="_blank"
                                    >
                                        <FacebookIcon />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://twitter.com/marlinspring"
                                        target="_blank"
                                    >
                                        <TwitterIcon />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="corporate-logos corporate-logos-desktop">
                            <a href="https://marlinspring.com/" target="_blank">
                                <img
                                    src={
                                        content.form_contact_info.marlin_logo
                                            .url
                                    }
                                    alt={
                                        content.form_contact_info.marlin_logo
                                            .alt
                                    }
                                />
                            </a>
                            <span className="separator"></span>
                            <a
                                href="https://realty.greybrook.com/"
                                target="_blank"
                            >
                                <img
                                    src={
                                        content.form_contact_info.greybrook_logo
                                            .url
                                    }
                                    alt={
                                        content.form_contact_info.greybrook_logo
                                            .alt
                                    }
                                />
                            </a>
                        </div>
                    </div>
                    <img
                        src="/img-temp/flowers.jpg"
                        className="contact-flowers-left"
                        alt="Flowers"
                    />
                    <img
                        src="/img-temp/flowers-2.jpg"
                        className="contact-flowers-right"
                        alt="Flowers"
                    />
                </div>
                <div className="contact-form-container">
                    {!submitted && (
                        <h2>
                            Register for exclusive updates about this community.
                        </h2>
                    )}
                    <div className="flex-container">
                        <ContactForm
                            form={form}
                            submitted={submitted}
                            submitting={submitting}
                            consent_error={consent_error}
                        />
                        <div className="contact-bottom">
                            <div className="copyright">
                                &copy; {new Date().getFullYear()} Marlin Spring.
                                All Rights Reserved. The developer reserves the
                                right to make changes and modifications to the
                                information contained herein without prior
                                notice. Renderings, maps and photographs are
                                representational only and may not be accurate.
                                Price and promotions are subject to change
                                without notice. E.&O.E.
                            </div>
                            <div className="links">
                                <span>
                                    <a href="/privacy-policy" target="_blank">
                                        Privacy Policy
                                    </a>
                                </span>
                                <span>
                                    By{' '}
                                    <a
                                        href="https://www.52pick-up.com/"
                                        target="_blank"
                                    >
                                        52 Pick-up Inc.
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <MobileFooter showBelow={1301} />
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default Contact;
