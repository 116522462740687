import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './About.scss';
import Cover from '../Cover/Cover';
import MobileFooter from '../MobileFooter/MobileFooter';

const About = () => {
    const content = useSelector((state) => state.content.pages.about);

    useEffect(() => {
        document.querySelector('#root').scrollTo(0, 0);
    }, []);

    if (typeof content !== 'undefined') {
        return (
            <div className="about">
                <Cover />
                <div className="about-section">
                    <div className="about-left">
                        <img
                            src={content.left_side.logo.url}
                            alt="Marlin Spring Logo"
                            className="about-logos"
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: content.left_side.copy
                            }}
                        />
                        <img
                            src="/img-temp/flowers.jpg"
                            alt="Flowers"
                            className="about-flowers-left"
                        />
                    </div>
                </div>
                <div className="about-section">
                    <div className="about-right">
                        <img
                            src={content.right_side.logo.url}
                            alt="Greybrook Logo"
                            className="about-logos"
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: content.right_side.copy
                            }}
                        />
                        <img
                            src="/img-temp/flowers-2.jpg"
                            alt="Flowers"
                            className="about-flowers-right"
                        />
                    </div>
                </div>
                <MobileFooter showBelow={1301} />
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default About;
