import React from 'react';
import './Hamburger.scss';

const Hamburger = (props) => {
    return (
        <button
            className={`hamburger hamburger--slider${
                props.active ? ' is-active' : ''
            }`}
            type="button"
        >
            <span className="hamburger-box">
                <span className="hamburger-inner"></span>
            </span>
        </button>
    );
};

export default Hamburger;
