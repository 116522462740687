import {
    CONTENT_QUERY,
    CONTENT_UPDATE,
    CONTENT_UPDATE_FORM,
    CONTENT_FORM_SUBMIT_QUERY,
    CONTENT_REMOVE_FORM_FIELD_ERROR,
    CONTENT_REMOVE_CONSENT_ERROR
} from './actionTypes';
import apiService from '../../services/apiService';

function content_notify() {
    return {
        type: CONTENT_QUERY
    }
}

function updateContent(content) {
    return {
        type: CONTENT_UPDATE,
        content
    }
}

function form_notify() {
    return {
        type: CONTENT_FORM_SUBMIT_QUERY
    }
}

function updateFormResults(results) {
    return {
        type: CONTENT_UPDATE_FORM,
        results
    }
}

export function removeFormFieldError(index) {
    return {
        type: CONTENT_REMOVE_FORM_FIELD_ERROR,
        index
    }
}

export function removeConsentError() {
    return {
        type: CONTENT_REMOVE_CONSENT_ERROR
    }
}

export function formSubmission(data) {
    return function(dispatch) {
        dispatch(form_notify());
        return apiService.submitForm(data)
        .then(response => {
            //we need to update the form response now
            dispatch(updateFormResults(response.data));
        }).catch(err => {
            console.error(err);
        });
    }
}

export function queryContent() {
    return function(dispatch) {
        dispatch(content_notify);
        return apiService.getContent()
        .then(response => {
            if(response.data.success === true)
            dispatch(updateContent(response.data.data));
        }).catch(err => {
            console.error(err);
        });
    }
}