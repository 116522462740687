import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    compareFloorplanToActive,
    updateActiveCompareDisplayedFloorplan
} from '../../redux/actions/floorplanActions';
import FloorplanDetailsStats from '../FloorplanDetailsStats/FloorplanDetailsStats';
import './FloorplanDetailsInfoPanel.scss';
import MainButton from '../MainButton/MainButton';
import EmailIcon from '../EmailIcon/EmailIcon';
import DownloadIcon from '../DownloadIcon/DownloadIcon';
import BackIcon from '../BackIcon/BackIcon';
import CompareFloorplansIcon from '../CompareFloorplansIcon/CompareFloorplansIcon';
import TabbedLayout from '../TabbedLayout/TabbedLayout';
import floorplan from '../../redux/reducers/floorplanReducer';

function FloorplanDetailsInfoPanel(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);

    const tabbedTitles = [];
    const tabbedContent = [];

    function viewFloorplan() {
        //switch to the selected floorplan
        dispatch(compareFloorplanToActive(props.floorplan, props.side));
        history.push('/floorplans/details');
    }

    function elevHasUnits(floorplan, key) {
        var eKeys = Object.keys(floorplan.elevation);
        var elev = floorplan.elevation[eKeys[key]];

        var show = true;

        //check all of the units to make sure at least one is there and is active
        floorplan.units.map((unit) => {
            if (elev.label_elevation.value === unit.unit_elevation.value) {
                //check if its availible
                if (!unit.avaliblity) show = false;
            }
        });

        return show;
    }

    const callback = () => viewFloorplan();

    if (typeof props.floorplan !== 'undefined' &&
    typeof props.floorplan.elevation !== 'undefined') {
        const eKeys = Object.keys(props.floorplan.elevation);

        tabbedTitles[eKeys[props.floorplan.ele]] = {
            label:
                props.floorplan.elevation[eKeys[props.floorplan.ele]].label,
            active: parseInt(eKeys[props.floorplan.ele])
        };

        tabbedContent[eKeys[props.floorplan.ele]] = (
            <React.Fragment>
                <FloorplanDetailsStats
                    name={`${
                        props.compare
                            ? props.floorplan.realName
                            : props.floorplan.realName
                    } ${
                        props.floorplan.elevation[eKeys[props.floorplan.ele]]
                            .label_elevation.letter_label
                    }`}
                    description={props.floorplan.description}
                    productName={props.floorplan.product.name}
                    squarefeet={
                        props.floorplan.elevation[
                            eKeys[props.floorplan.ele]
                        ].info.square_feet
                    }
                    bedrooms={
                        props.floorplan.elevation[
                            eKeys[props.floorplan.ele]
                        ].info.bedrooms
                    }
                    bathrooms={
                        props.floorplan.elevation[
                            eKeys[props.floorplan.ele]
                        ].info.bathrooms
                    }
                    half_bathrooms={
                        props.floorplan.elevation[
                            eKeys[props.floorplan.ele]
                        ].info.half_bathrooms
                    }
                />
                {/* 
                    <div className="floorplan-details-caption">
                        {
                            props.floorplan.elevation[
                                eKeys[props.floorplan.ele]
                            ].starting_from
                        }
                    </div>
                */}
                {props.compare && !isTouchScreen && (
                    <MainButton
                        text={`Download Floorplan ${
                            props.floorplan.elevation[eKeys[props.floorplan.ele]]
                                .label_elevation.letter_label
                        }`}
                        style={{
                            width: '320px',
                            alignSelf: 'center',
                            padding: '16px 56px',
                            marginBottom: '8px'
                        }}
                        link={props.floorplan.elevation[eKeys[props.floorplan.ele]]
                            .downloadable.url
                        }
                        external
                        blank
                        ghost={props.compare}
                        svg={<DownloadIcon />}
                        svgAbsolute={true}
                    />
                )}
                {props.sitePlan && !isTouchScreen && (
                    <MainButton
                        text={`Download Floorplan ${
                            props.floorplan.elevation[eKeys[props.floorplan.ele]]
                                .label_elevation.letter_label
                        }`}
                        style={{
                            width: '320px',
                            alignSelf: 'center',
                            padding: '16px 56px',
                            marginBottom: '8px'
                        }}
                        link={props.floorplan.elevation[eKeys[props.floorplan.ele]]
                            .downloadable.url
                        }
                        external
                        blank
                        ghost={props.sitePlan}
                        svg={<DownloadIcon />}
                        svgAbsolute={true}
                    />
                )}
                <MainButton
                    text={`Email Floorplan ${
                        props.floorplan.elevation[eKeys[props.floorplan.ele]]
                            .label_elevation.letter_label
                    }`}
                    style={{
                        width: '320px',
                        alignSelf: 'center',
                        padding: '16px 56px',
                        marginBottom: '8px'
                    }}
                    onclick={() => props.toggleModal}
                    ghost={true}
                    svg={<EmailIcon />}
                    svgAbsolute={true}
                />
                {(props.compare || props.sitePlan) && (
                    <MainButton
                        text="View Floorplan"
                        style={{
                            width: '320px',
                            alignSelf: 'center',
                            padding: '16px 56px',
                            marginBottom: '8px'
                        }}
                        onclick={() => callback}
                        svg={<CompareFloorplansIcon />}
                        svgAbsolute={true}
                    />
                )}
                {!props.compare && !props.sitePlan && (
                    <a
                        href={
                            props.grid
                                ? 'floorplans-grid'
                                : '/floorplans/list'
                        }
                        className="back-to-floorplans"
                    >
                        <BackIcon />
                        Back To Floorplans
                    </a>
                )}
            </React.Fragment>
        );

        return (
            <div
                className={`floorplan-details-info-panel${
                    props.compare ? ' compare-info-panel' : ''
                }${props.sitePlan ? ' site-plan-info-panel' : ''}`}
            >
                <TabbedLayout
                    titles={tabbedTitles}
                    content={tabbedContent}
                    index={parseInt(eKeys[props.floorplan.ele])}
                    colCont
                />
                <img
                    src="/img-temp/flowers.jpg"
                    className="flowers-left"
                    alt="Flowers"
                />
                <img
                    src="/img-temp/flowers-2.jpg"
                    className="flowers-right"
                    alt="Flowers"
                />
            </div>
        );
    }
    else {
        return (
            <div/>
        )
    }
}

export default FloorplanDetailsInfoPanel;
