import React from 'react';
import './Accordion.scss';
import AccordionItem from '../AccordionItem/AccordionItem';

const Accordion = (props) => {
    const isActive = (key) => {
        return key === props.active;
    }

    return (
        <ul className="accordion">
            {props.items.map((item, key) =>
                <AccordionItem
                    key={key}
                    item={item}
                    colour={item.colour}
                    active={isActive(key)}
                    id={key}
                    update={props.update}
                />
            )}
        </ul>
    );
};

export default Accordion;
