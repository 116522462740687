import React from 'react';

function RadioIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            className="radio-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-664 -106)">
                    <g transform="translate(0 80)">
                        <g transform="translate(616)">
                            <g transform="translate(48 24)">
                                <g transform="translate(0 2)">
                                    <circle
                                        cx="6"
                                        cy="6"
                                        r="5.5"
                                        fill="#2D3D38"
                                        stroke="#B8C9BB"
                                    ></circle>
                                    <circle
                                        cx="6"
                                        cy="6"
                                        r="3"
                                        className="radio-fill"
                                    ></circle>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default RadioIcon;
