import React from 'react';
import './CompareFloorplans.scss';
import FloorplanFullscreen from '../FloorplanFullscreen/FloorplanFullscreen';
import CompareFloorplansSide from '../CompareFloorplansSide/CompareFloorplansSide';
import Cover from '../Cover/Cover';

function CompareFloorplans() {
    return (
        <div className="compare-floorplans">
            <Cover />
            <div className="compare-floorplans-title">
                <span className="compare-floorplans-title-text">
                    Compare Floorplans
                </span>
            </div>
            <div className="compare-floorplans-container">
                <CompareFloorplansSide side="left" />
                <CompareFloorplansSide side="right" />
            </div>
            <FloorplanFullscreen />
        </div>
    );
}

export default CompareFloorplans;
