import React, { useRef, useState, useEffect } from 'react';
import './FloorplanDetailsSendEmailModal.scss';
import CloseIcon from '../CloseIcon/CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
    emailFloorplans,
    clearEmailSubmission,
    updateEmailError
} from '../../redux/actions/floorplanActions';
import VirtualKeyboard from '../VirtualKeyboard/VirtualKeyboard';

function FloorplanDetailsSendEmailModal(props) {
    const dispatch = useDispatch();
    const submitting = useSelector((state) => state.floorplan.emailSubmitting);
    const submitted = useSelector((state) => state.floorplan.emailSubmitted);
    const error = useSelector((state) => state.floorplan.emailError);
    const isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);
    const [input, setInput] = useState('');
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const keyboard = useRef();
    const emailInput = useRef();
    const thankYou = useSelector((state) => state.content.options.email_sent_message); 

    const styles = {
        backgroundColor: error ? 'red' : ''
    };

    const buttonStyles = {
        opacity: submitting ? '0.4' : '1'
    }

    useEffect(() => {
        document.addEventListener('click', close);
        return () => {
            document.removeEventListener('click', close);
        };
    });

    function closeModal() {
        props.close();
        dispatch(clearEmailSubmission());
    }

    function close(e) {
        if (
            !e.target.closest('.email_email') &&
            !e.target.closest('.virtual-keyboard')
        ) {
            setKeyboardOpen(false);
        }
    }

    function openKeyboard() {
        setKeyboardOpen(true);
    }

    function onChange(input) {
        setInput(input);
    }

    function onInputChange(e) {
        const i = e.target.value;
        setInput(i);
        keyboard.current.setInput(i);
        resetErrors();
    }

    function isEmail(email) {
        const reg = new RegExp(/^\S+@\S+\.\S+$/);
        var is = reg.test(email);
        if(is === false) dispatch(updateEmailError(true));

        return is;
    }

    function sendEmail() {
        if (!submitting) {
            var email = emailInput.current.value;

            if (isEmail(email)) {
                dispatch(emailFloorplans(email, props.floorplans));
            }
        }
    }

    function resetErrors() {
        dispatch(updateEmailError(false));
    }

    let classes = 'floorplan-details-send-email';
    if (props.open) classes += ' open';

    return (
        <div className={classes}>
            <div
                className="floorplan-details-send-email-overlay"
                onClick={closeModal}
            />
            <div className="floorplan-details-send-email-wrapper">
                {!submitted &&
                    <React.Fragment>
                        <label>{props.label}</label>
                        <div className="email-input">
                            <label htmlFor="email_email">
                                Email<span className="req-star">*</span>
                            </label>
                            {isTouchScreen && (
                                <input
                                    type="text"
                                    name="email_email"
                                    className="email_email"
                                    id="email_email"
                                    onChange={onInputChange}
                                    value={input}
                                    ref={emailInput}
                                    onClick={openKeyboard}
                                    autoComplete="off"
                                    style={styles}
                                />
                            )}
                            {!isTouchScreen && (
                                <input
                                    type="text"
                                    name="email_email"
                                    ref={emailInput}
                                    style={styles}
                                    onChange={resetErrors}
                                />
                            )}
                            {isTouchScreen && (
                                <VirtualKeyboard
                                    keyboard={keyboard}
                                    onChange={onChange}
                                    open={keyboardOpen}
                                />
                            )}
                        </div>
                        <button styles={buttonStyles} onClick={sendEmail}>
                            Send Floorplan
                        </button>
                    </React.Fragment>
                }
                {submitted &&
                    <React.Fragment>
                        <label className="thank-you">
                            {thankYou}
                        </label>
                    </React.Fragment>
                }
                <div className="email-close-button" onClick={closeModal}>
                    <CloseIcon />
                </div>
            </div>
        </div>
    );
}

export default FloorplanDetailsSendEmailModal;
