import React, { useState } from 'react';
import './MainHeader.scss';
import RequestWorkSheetIcon from '../RequestWorkSheetIcon/RequestWorkSheetIcon';
import StarIcon from '../StarIcon/StarIcon';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Hamburger from '../Hamburger/Hamburger';
import MainButton from '../MainButton/MainButton';
import { useSelector } from 'react-redux';

function MainHeader() {
    const location = useLocation();
    const isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);
    const [menuStatus, setMenuStatus] = useState(false);
    const history = useHistory();

    let browserHeight = window.innerHeight;

    const toggleMenu = () => {
        browserHeight = window.innerHeight;
        setMenuStatus(!menuStatus);
    };

    const closeMenu = () => {
        setMenuStatus(false);
    };

    // Remove once redux global is set

    // const menuButtonLink = () => {
    //     closeMenu();
    //     history.push('/contact-us');
    // };

    return (
        <header className="main-header">
            <div className="main-header-logos">
                {!isTouchScreen && (
                    <NavLink to="/">
                        <img
                            src="/img-temp/archetto-logo.svg"
                            className="main-header-archetto-logo"
                            alt="Archetto Logo"
                            onClick={closeMenu}
                        />
                    </NavLink>
                )}
                {isTouchScreen && (
                    <a href="/?isTouchScreen">
                        <img
                            src="/img-temp/archetto-logo.svg"
                            className="main-header-archetto-logo"
                            alt="Archetto Logo"
                            onClick={closeMenu}
                        />
                    </a>
                )}
                <img
                    src="/img-temp/marlin-spring-logo.svg"
                    className="main-header-marlin-spring-logo"
                    alt="Marlin Spring Logo"
                />
            </div>
            <div
                className="mobile-menu-container"
                style={
                    menuStatus
                        ? { height: browserHeight - 56 + 'px' }
                        : { height: '0' }
                }
            >
                <div
                    className="mobile-menu-sizer"
                    style={{ height: browserHeight - 56 + 'px' }}
                >
                    <div className="centerer">
                        <ul className="main-header-links">
                            <li>
                                <NavLink
                                    activeClassName="main-header-link-active"
                                    to="/"
                                    exact
                                    onClick={toggleMenu}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    activeClassName="main-header-link-active"
                                    to="/area"
                                    onClick={toggleMenu}
                                >
                                    Area
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    activeClassName="main-header-link-active"
                                    to="/site-plan"
                                    onClick={toggleMenu}
                                >
                                    Site Plan
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    activeClassName="main-header-link-active"
                                    to="/floorplans"
                                    onClick={toggleMenu}
                                >
                                    Floorplans
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    activeClassName="main-header-link-active"
                                    to="/features-and-finishes"
                                    onClick={toggleMenu}
                                >
                                    Features And Finishes
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    activeClassName="main-header-link-active"
                                    to="/gallery"
                                    onClick={toggleMenu}
                                >
                                    Gallery
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    activeClassName="main-header-link-active"
                                    to="/about"
                                    onClick={toggleMenu}
                                >
                                    About
                                </NavLink>
                            </li>
                            {!isTouchScreen && (
                                <li>
                                    <NavLink
                                        activeClassName="main-header-link-active"
                                        to="/contact-us"
                                        onClick={toggleMenu}
                                    >
                                        Contact Us
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                        {/* <MainButton
                            text="Request Worksheet"
                            ghost
                            style={{
                                border: 'none',
                                width: '300px',
                                margin: '0 auto 40px'
                            }}
                            onclick={() => menuButtonLink}
                        /> */}
                    </div>
                </div>
            </div>
            {/* {(location.pathname.startsWith('/floorplans') ||
                location.pathname.startsWith('/site-plan')) && (
                <div className="main-header-right-links main-header-star-section">
                    <button>
                        <RequestWorkSheetIcon />
                        Request Worksheet
                    </button>
                    <button>
                        <StarIcon saved={true} />
                        Saved
                    </button>
                </div>
            )} */}
            <div
                className={`main-header-right-links header-register${
                    location.pathname.startsWith('/floorplans') ||
                    location.pathname.startsWith('/site-plan')
                        ? ' header-register-floorplans'
                        : ''
                }`}
            >
                <div className="hamburger-container" onClick={toggleMenu}>
                    <Hamburger active={menuStatus} />
                </div>
                {/*!isTouchScreen && (
                    <NavLink to="/contact-us" onClick={closeMenu}>
                        Register
                    </NavLink>
                )*/}
                {!isTouchScreen && (
                    <a href="https://marlinspring.com/find-your-home/our-communities/" target="_blank">
                        DISCOVER MORE PROJECTS
                    </a> 
                )}
            </div>
        </header>
    );
}

export default MainHeader;
