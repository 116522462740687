import React, { useState, useEffect } from 'react';
import './GallerySlider.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y, Thumbs } from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/swiper.scss';
import PrevIcon from '../PrevIcon/PrevIcon';
import NextIcon from '../NextIcon/NextIcon';

SwiperCore.use([Navigation, A11y, Thumbs]);

function GallerySlider(props) {
    const [swiperInitStatus, setSwiperInitStatus] = useState(
        window.innerWidth > 1300
    );

    // the resize stuff isn't working yet

    const initSwiper = () => {
        setSwiperInitStatus(window.innerWidth > 1300);
    };

    useEffect(() => {
        window.addEventListener('resize', initSwiper);

        return () => {
            window.removeEventListener('resize', initSwiper);
        };
    });

    if(swiperInitStatus) {
        return (
            <div className="gallery-slider">
                <Swiper
                    spaceBetween={48}
                    slidesPerView={'auto'}
                    // freeMode={true}
                    // rebuildOnUpdate={true}
                    // shouldSwiperUpdate={true}
                    //init={swiperInitStatus}
                    thumbs={{
                        swiper: {
                            el: '.thumb-swiper',
                            slidesPerView: 'auto',
                            spaceBetween: 8
                        }
                    }}
                    navigation={{
                        nextEl: '.next-button',
                        prevEl: '.prev-button'
                    }}
                    className="main-swiper"
                >
                    {props.renderings &&
                        props.renderings.map((rendering, key) => (
                            <SwiperSlide key={key}>
                                <div className="slide-inner-container">
                                    <div className="caption">{rendering.label}</div>
                                    <img
                                        src={rendering.image.url}
                                        alt={rendering.image.alt}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
                <div className="buttons">
                    <button className="prev-button">
                        <PrevIcon />
                    </button>
                    <button className="next-button">
                        <NextIcon />
                    </button>
                </div>
                <div className="thumb-swiper-container">
                    <Swiper
                        spaceBetween={8}
                        slidesPerView={'auto'}
                        watchSlidesVisibility
                        watchSlidesProgress
                        // touchRatio={0}
                        className="thumb-swiper"
                    >
                        {props.renderings.map((rendering, key) => (
                            <SwiperSlide key={key}>
                                <img
                                    src={rendering.image.url}
                                    alt={rendering.image.alt}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="gallery-slider">
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {props.renderings && props.renderings.map((rendering, key) => 
                            <div className="swiper-slide">
                                <div className="slide-inner-container">
                                    <div className="caption">{rendering.label}</div>
                                    <img
                                        src={rendering.image.url}
                                        alt={rendering.image.alt}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default GallerySlider;
