import React from 'react';

function ZoomIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-1855 -951)">
                    <g transform="translate(1840 936)">
                        <g transform="translate(16 16)">
                            <path
                                stroke="#FFF"
                                className="magnifier"
                                d="M9.6 19.2A9.6 9.6 0 109.6 0a9.6 9.6 0 000 19.2zm7.2-2.4L24 24"
                            ></path>
                            <g
                                fill="#FFF"
                                transform="translate(4 4)"
                                className="sign"
                            >
                                <path d="M5 0H6V11H5z"></path>
                                <path
                                    d="M5 0H6V11H5z"
                                    transform="rotate(90 5.5 5.5)"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ZoomIcon;
