import React from 'react';
import NextIcon from '../NextIcon/NextIcon';
import './FeaturesAndFinishesListButton.scss';

function FeaturesAndFinishesListButton(props) {
    return (
        <li className="features-and-finishes-list-button">
            <button
                onClick={props.callback}
                className={`button${props.active ? ' active' : ''}`}
            >
                {props.text}
                <NextIcon />
            </button>
        </li>
    );
}

export default FeaturesAndFinishesListButton;
