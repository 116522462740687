import React, { useEffect } from 'react';
import './SelectProducts.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cover from '../Cover/Cover';
import Product from '../Product/Product';

function SelectProducts() {
    const content = useSelector((state) => state.content.pages.floorplans);
    const filterID = useSelector((state) => state.floorplan.productFilterID);
    const filters = useSelector((state) => state.floorplan.filters);

    if (typeof content !== 'undefined' && filters.length > 0 && filterID !== -1) {
        return (
            <div className="select-products">
                <Cover
                    background={{ background: '#fff' }}
                    svgColor={{ stroke: '#9b3465' }}
                />
                {content.products.map((prod, key) => (
                    <Product
                        key={key}
                        title={prod.label}
                        copy={prod.copy}
                        rendering={prod.rendering.url}
                        flowers={prod.flowers.url}
                        productid={key}
                        filterid={filterID}
                        left
                    />
                ))}
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default SelectProducts;
