import React from 'react';
import './CompareFloorplansSelectFloorplan.scss';
import PlusIcon from '../PlusIcon/PlusIcon';

function openFloorplanList(event) {
    var parent = event.target.closest('.compare-floorplans-side');
    parent.querySelector(
        '.compare-floorplans-select-floorplan-container'
    ).style.display = 'none';
    parent.querySelector('.compare-floorplans-list-container').style.display =
        'block';
}

function CompareFloorplansSelectFloorplan(props) {
    return (
        <div className="compare-floorplans-select-floorplan-container">
            {props.side === 'left' && (
                <img
                    src="/img-temp/flowers.jpg"
                    className="compare-floorplans-left-flowers"
                    alt="Flowers"
                />
            )}
            {props.side === 'right' && (
                <img
                    src="/img-temp/flowers-2.jpg"
                    className="compare-floorplans-right-flowers"
                    alt="Flowers"
                />
            )}
            <button
                className="compare-floorplans-select-floorplan"
                onClick={openFloorplanList}
            >
                <PlusIcon />
                Select Floorplan To Compare
            </button>
        </div>
    );
}

export default CompareFloorplansSelectFloorplan;
