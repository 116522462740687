import React from 'react';
import './ListGridHeader.scss';
import GridIcon from '../GridIcon/GridIcon';
import ListIcon from '../ListIcon/ListIcon';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ListGridHeader(props) {
    const allPlans = useSelector((state) => state.floorplan.floorplans);
    const dispPlans = useSelector((state) => state.floorplan.displayFloorplans);

    let planCount = 0;
    let add;

    allPlans.map((plan) => {
        add = false;
        var keys = Object.keys(plan.elevation);
        keys.map((key) => {
            var elev = plan.elevation[key];

            plan.units.map((unit) => {
                if(elev.label_elevation.value === unit.unit_elevation.value) {
                    if(unit.avaliblity === true) add = true;
                }
            });

            if(add === true) {
                planCount++;
            }
        });
    });

    let count = 0;
    let total = 0;
    if (typeof dispPlans.length !== 'undefined') count = dispPlans.length;
    if (typeof allPlans.length !== 'undefined') total = planCount;

    return (
        <div
            className={`list-grid-header${
                props.listGridHeaderBorder ? ' list-grid-header-border' : ''
            }`}
        >
            Floorplans ({count} of {total})
            <div className="list-grid-header-links">
                <Link
                    to="/floorplans/list"
                    className={`list-grid-link${
                        props.isListPage ? ' active-list-grid-header' : ''
                    }`}
                >
                    <ListIcon />
                    List
                </Link>
                <Link
                    to="/floorplans/grid"
                    className={`list-grid-link${
                        props.isGridPage ? ' active-list-grid-header' : ''
                    }`}
                >
                    <GridIcon />
                    Grid
                </Link>
            </div>
        </div>
    );
}

export default ListGridHeader;
