import React from 'react';

function BedroomsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 20 16"
            version="1.1"
            viewBox="0 0 20 16"
        >
            <g>
                <g transform="translate(-237 -360)">
                    <g transform="translate(0 192)">
                        <g transform="translate(0 144)">
                            <g transform="translate(48 23)">
                                <g transform="translate(189 1)">
                                    <path
                                        d="M16 0c1.1 0 2 .9 2 2v3c0 .4-.1.7-.3 1h.3c1.1 0 2 .9 2 2v3c0 1.1-.9 2-2 2v2.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5V13H3v2.5c0 .3-.2.5-.5.5s-.5-.2-.5-.5V13c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2h.3c-.2-.3-.3-.6-.3-1V2c0-1.1.9-2 2-2h12zm2 7H2c-.5 0-.9.4-1 .9V11c0 .5.4.9.9 1H18.1c.5-.1.8-.4.9-.9V8c0-.5-.4-.9-.9-1H18zM8.5 4H4c-.6 0-1 .4-1 1 0 .5.4.9.9 1h4.6c.6 0 1-.4 1-1 0-.5-.4-.9-.9-1h-.1zM16 4h-4.5c-.6 0-1 .4-1 1 0 .5.4.9.9 1H16c.6 0 1-.4 1-1 0-.5-.4-.9-.9-1H16zm0-3H4c-.5 0-.9.4-1 .9V3.3c.3-.2.6-.3 1-.3h4.5c.6 0 1.1.3 1.5.7.4-.4.9-.7 1.5-.7H16c.4 0 .7.1 1 .3V2c0-.5-.4-.9-.9-1H16z"
                                        className="st0"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default BedroomsIcon;
