import React, { useEffect, useState, useRef } from 'react';
import './FloorplanDetails.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
    updateActivePlan,
    updateActiveElevation,
    activeFloorplanToCompare,
    activeFloorplanFromSlug,
    clearRightComparePlan
} from '../../redux/actions/floorplanActions';
import MainButton from '../MainButton/MainButton';
import EmailIcon from '../EmailIcon/EmailIcon';
import CompareFloorplansIcon from '../CompareFloorplansIcon/CompareFloorplansIcon';
import DownloadIcon from '../DownloadIcon/DownloadIcon';
import FloorplanFullscreen from '../FloorplanFullscreen/FloorplanFullscreen';
import FloorplanDetailsStats from '../FloorplanDetailsStats/FloorplanDetailsStats';
import TabbedLayout from '../TabbedLayout/TabbedLayout';
import BackIcon from '../BackIcon/BackIcon';
import ForwardIcon from '../ForwardIcon/ForwardIcon';
import ZoomIcon from '../ZoomIcon/ZoomIcon';
import Cover from '../Cover/Cover';
import MiniSiteplan from '../MiniSiteplan/MiniSiteplan';
import MobileFooter from '../MobileFooter/MobileFooter';
import FloorplanDetailsSendEmailModal from '../FloorplanDetailsSendEmailModal/FloorplanDetailsSendEmailModal';

function FloorplanDetails(props) {
    const history = useHistory();
    const floorplan = useSelector((state) => state.floorplan.activeFloorplan);
    const isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);
    const dispatch = useDispatch();
    const [ele, setEle] = useState(floorplan.ele || 0);
    const [fullscreen, setFullScreen] = useState(false);
    const [load, setLoad] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [keyboardRef] = useState(false);
    const input = useRef();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    let tabbedTitles = [];
    let tabbedContent = [];

    let elevationTitles = [];
    let elevationContent = [];

    let blocks = {};

    function toggleModal() {
        var open = !modalOpen;

        setModalOpen(open);
    }

    function closeModal() {
        setModalOpen(false);
    }

    function openFullscreen() {
        setFullScreen(true);
    }

    function closeFullscreen() {
        setFullScreen(false);
    }

    function active(key) {
        return key === floorplan.ele;
    }

    function updateEle(ele) {
        dispatch(updateActiveElevation(ele));
        setEle(ele);
    }

    function updatePlan(plan) {
        dispatch(updateActivePlan(plan));
    }

    function compareFloorplan() {
        dispatch(activeFloorplanToCompare(floorplan));
        dispatch(clearRightComparePlan());
        history.push('/floorplans/compare');
    }

    function elevHasUnits(floorplan, key) {
        const eKeys = Object.keys(floorplan.elevation);
        var elev = floorplan.elevation[eKeys[key]];
        var show = true;

        //check all of the units to make sure at least one is there and is active
        floorplan.units.map((unit) => {
            if (elev.label_elevation.value === unit.unit_elevation.value) {
                //check if its availible
                if (!unit.avaliblity) show = false;
            }
        });

        return show;
    }

    function resized() {
        setWindowWidth(window.innerWidth);
    }

    //i need 2 useeffects to pull this off. When they land on this page the very first time
    //we should check to see if they're trying to load something. IF not we shoudl just update the title

    //this one is for the title
    useEffect(() => {
        if (typeof floorplan.elevation !== 'undefined') {
            if (load !== '') {
                //at this point
                //need to update the activeFloorplan at this point
                dispatch(activeFloorplanFromSlug(load));
                setLoad('');
            } else {
                const eKeys = Object.keys(floorplan.elevation);
                const name = floorplan.post_name;
                const letter = floorplan.elevation[
                    eKeys[floorplan.ele]
                ].label_elevation.letter_label
                    .toLowerCase()
                    .replace(new RegExp(' ', 'g'), '-');
                //change teh title
                history.replace(`/floorplans/details/${name}-${letter}`);
            }
        }
    });

    //the first time we load into this page. We need to check two things
    //1 we need to check if there's anything after /floorplans/details
    //if it is we need to make that the floorplan we want to load.

    //but we only want to do this if we find that the redux store does not have a floorplans
    //if it does we want to just load from that and then do waht we're doing.
    useEffect(() => {
        //should only go once :D
        if (typeof floorplan.elevation === 'undefined') {
            var fp = history.location.pathname.split('/');
            fp = fp[fp.length - 1];

            //now that we have the slug name i need to save that as the load fp
            setLoad(fp);
        }

        document.querySelector('#root').scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resized);

        return () => {
            window.removeEventListener('resize', resized);
        };
    });

    if (typeof floorplan.elevation !== 'undefined') {
        //we need the keys its not an array anymore
        const eKeys = Object.keys(floorplan.elevation);

        floorplan.elevation[eKeys[floorplan.ele]].floorplans.map(
            (plan, key) => {
                tabbedTitles.push({
                    label: plan.label,
                    active: active(key)
                });

                tabbedContent.push(
                    <div className="grid-floorplan-image-container">
                        <div className="floorplan-details-floorplan-container">
                            <div className="space-between-hack-sorry"></div>
                            <img
                                src={plan.content.main.image}
                                className="grid-floorplan-image"
                                alt="Floorplan"
                            />
                            <div className="floorplan-details-floorplan-name">
                                {plan.content.main.label}
                            </div>
                        </div>
                        {plan.content.optional.image && (
                            <div className="floorplan-details-floorplan-container">
                                <div className="space-between-hack-sorry"></div>
                                <img
                                    src={plan.content.optional.image}
                                    className="grid-floorplan-image"
                                    alt="Optional Floorplan"
                                />
                                <div className="floorplan-details-floorplan-name">
                                    {plan.content.optional.label}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        );

        /*
            For the floorplan elevations tabbed content
        */

        if (elevHasUnits(floorplan, floorplan.ele)) {
            elevationTitles[floorplan.ele] = {
                label: floorplan.elevation[eKeys[floorplan.ele]].label,
                active: active(floorplan.ele)
            };

            elevationContent[floorplan.ele] = (
                <React.Fragment key={floorplan.ele}>
                    <FloorplanDetailsStats
                        name={`${floorplan.realName} ${
                            floorplan.elevation[eKeys[floorplan.ele]]
                                .label_elevation.letter_label
                        }`}
                        description={floorplan.description}
                        productName={floorplan.product.name}
                        squarefeet={
                            floorplan.elevation[eKeys[floorplan.ele]].info
                                .square_feet
                        }
                        bedrooms={
                            floorplan.elevation[eKeys[floorplan.ele]].info
                                .bedrooms
                        }
                        bathrooms={
                            floorplan.elevation[eKeys[floorplan.ele]].info
                                .bathrooms
                        }
                        half_bathrooms={
                            floorplan.elevation[eKeys[floorplan.ele]].info
                                .half_bathrooms
                        }
                    />
                    {/* 
                        <div className="floorplan-details-caption">
                            {
                                floorplan.elevation[eKeys[floorplan.ele]]
                                    .starting_from
                            }
                        </div>
                    */}
                    {!isTouchScreen && (
                        <MainButton
                            text={`Download Floorplan ${
                                floorplan.elevation[eKeys[floorplan.ele]]
                                    .label_elevation.letter_label
                            }`}
                            style={{
                                width: '320px',
                                alignSelf: 'center',
                                padding: '16px 56px',
                                marginBottom: '8px'
                            }}
                            link={
                                floorplan.elevation[eKeys[floorplan.ele]]
                                    .downloadable['url'] || ''
                            }
                            download
                            blank
                            svg={<DownloadIcon />}
                            svgAbsolute={true}
                        />
                    )}
                    <MainButton
                        text={`Email Floorplan ${
                            floorplan.elevation[eKeys[floorplan.ele]]
                                .label_elevation.letter_label
                        }`}
                        style={{
                            width: '320px',
                            alignSelf: 'center',
                            padding: '16px 56px'
                        }}
                        onclick={() => toggleModal}
                        ghost={true}
                        svg={<EmailIcon />}
                        svgAbsolute={true}
                    />
                </React.Fragment>
            );
        }

        /*
            For the blocks content 
        */

        floorplan.units.map((unit) => {
            //get the key for the block :)
            if (
                unit.unit_elevation.value ===
                floorplan.elevation[eKeys[floorplan.ele]].label_elevation.value
            ) {
                var key = unit.block.post_name;
                key = key.split('-')[1];

                //now that we have a key for each block lets just throw all of the units into the right block
                //but we only need the number

                if (typeof blocks[key] === 'undefined') blocks[key] = [];
                blocks[key].push(unit.unit_number);
            }
        });

        var bkeys = Object.keys(blocks);
        for (var i = 0; i < bkeys.length; i++) {
            blocks[bkeys[i]].sort();

            var string = '';
            string = blocks[bkeys[i]][0];
            for (var j = 1; j < blocks[bkeys[i]].length; j++) {
                string += ', ' + blocks[bkeys[i]][j];
            }

            blocks[bkeys[i]] = string;
        }

        return (
            <div className="floorplan-details">
                <Cover
                    background={{ background: '#fff' }}
                    svgColor={{ stroke: '#9b3465' }}
                />
                <FloorplanDetailsSendEmailModal
                    open={modalOpen}
                    close={closeModal}
                    label={`${floorplan.realName} ${
                        floorplan.elevation[eKeys[floorplan.ele]]
                            .label_elevation.letter_label
                    }`}
                    floorplans={[
                        {
                            ID: floorplan.ID,
                            ele: floorplan.ele
                        }
                    ]}
                />
                <div className="floorplan-details-left-panel">
                    <TabbedLayout
                        titles={elevationTitles}
                        content={elevationContent}
                        callback={updateEle}
                        index={floorplan.ele}
                        setIndex={updatePlan}
                        colCont
                    />
                    <Link to="/floorplans/list" className="back-to-floorplans">
                        <BackIcon />
                        Back To Floorplans
                    </Link>
                    <img
                        src="/img-temp/flowers.jpg"
                        className="flowers-left"
                        alt="Flowers"
                    />
                    <img
                        src="/img-temp/flowers-2.jpg"
                        className="flowers-right"
                        alt="Flowers"
                    />
                </div>
                <div className="floorplan-details-center-panel">
                    <TabbedLayout
                        titles={tabbedTitles}
                        content={tabbedContent}
                        callback={updatePlan}
                        index={floorplan.disp}
                        details
                    />
                    {windowWidth > 768 && (
                        <button
                            className="zoom-container"
                            onClick={openFullscreen}
                        >
                            <ZoomIcon />
                        </button>
                    )}
                </div>
                <div className="floorplan-details-right-panel">
                    <MainButton
                        text="Compare Floorplan"
                        style={{
                            width: '100%',
                            padding: '16px 48px',
                            marginBottom: '48px'
                        }}
                        onclick={() => compareFloorplan}
                        svg={<CompareFloorplansIcon />}
                        svgAbsolute={true}
                    />
                    <div className="floorplan-details-block-container">
                        <div className="floorplan-details-block-container-heading">
                            Availability
                        </div>
                        {Object.keys(blocks).map((key, id) => (
                            <div
                                className="floorplan-details-block-container-block"
                                key={id}
                            >
                                <span className="label">BLK {key}</span>
                                <span className="units">{blocks[key]}</span>
                            </div>
                        ))}
                    </div>
                    <Link
                        className="floorplan-siteplan-container"
                        to={{
                            pathname: '/site-plan',
                            state: { mini: floorplan.ID }
                        }}
                    >
                        <MiniSiteplan
                            floorplanID={floorplan.ID}
                            floorplanELE={eKeys[floorplan.ele]}
                        />
                    </Link>
                    <img
                        className="floorplan-details-compass"
                        src="/img-temp/Archetto-Compass.png"
                        alt="Compass"
                    />
                    <Link
                        className="go-to-siteplan"
                        to={{
                            pathname: '/site-plan',
                            state: { mini: floorplan.ID }
                        }}
                    >
                        View Interactive Site Plan
                        <ForwardIcon />
                    </Link>
                </div>
                <FloorplanFullscreen
                    open={fullscreen}
                    callback={closeFullscreen}
                    selected={floorplan.disp}
                    setSelected={updatePlan}
                    floorplans={
                        floorplan.elevation[eKeys[floorplan.ele]].floorplans
                    }
                />
                <MobileFooter showBelow={1300} />
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default FloorplanDetails;
