import {
    UTILITIES_GATHER_COOKIES,
    UTILITIES_UPDATE_COOKIES,
    UTILITIES_CHECK_IF_TOUCHSCREEN
} from './actionTypes';

import apiService from '../../services/apiService';
import cookieService from '../../services/cookieService';

export function getCookie() {
    var cookie = cookieService.getCookie();

    return {
        type: UTILITIES_GATHER_COOKIES,
        cookie
    }
}

export function updateCookie(cookie) {
    return {
        type: UTILITIES_UPDATE_COOKIES,
        cookie
    }
}

export function checkIfTouchscreen() {
    //check if the url is the touchscreen.
    var location = window.location.href;
    //this might need to thunk cause i need to add the cookie
    //if it is the touch screen. But i could also just pass it in
    //normally

    var vars = location.split("?");
    var isTouch = false;

    if(vars.length > 1)
        isTouch = vars.includes('isTouchScreen');

    return {
        type: UTILITIES_CHECK_IF_TOUCHSCREEN,
        isTouch
    }
}