import { combineReducers } from 'redux';
import floorplan from './floorplanReducer';
import content from './contentReducer';
import siteplan from './siteplanReducer';
import utilities from './utilitiesReducer';

export default combineReducers({
    floorplan,
    content,
    siteplan,
    utilities
});