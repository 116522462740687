import React from 'react';
import './CompareFloorplanListButton.scss';

function CompareFloorplanListContainerButton(props) {
    let classes = "compare-floorplans-list-container-select-product";
    if(props.active) classes += " compare-floorplans-list-container-select-product-active";

    return (
        <button className={classes} onClick={() => props.callback(props.term)}>
            {props.text}
        </button>
    );
}

export default CompareFloorplanListContainerButton;