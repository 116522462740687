//Floorplan Actions
export const FLOORPLAN_QUERY = "FLOORPLANS:FLOORPLAN_QUERY";
export const FLOORPLAN_UPDATE = "FLOORPLANS:FLOORPLAN_UPDATE";

export const FLOORPLAN_UPDATE_FILTER = "FLOORPLANS:FLOORPLAN_FILTER_UPDATE";
export const FLOORPLAN_UPDATE_FILTERS = "FLOORPLANS:FLOORPLAN_UPDATE_FILTERS";
export const FLOORPLAN_UPDATE_FILTER_VALUE = "FLOORPLANS:FLOORPLAN_UPDATE_FILTER_VALUE";
export const FLOORPLAN_UPDATE_FILTER_ACTIVE = "FLOORPLANS:FLOORPLAN_UPDATE_FILTER_ACTIVE";
export const FLOORPLAN_SETUP_COMPARE_FILTERS = "FLOORPLAN:FLOORPLAN_SETUP_COMPARE_FILTERS";
export const FLOORPLAN_TOGGLE_FILTER_DROPDOWN = "FLOORPLANS:FLOORPLAN_TOGGLE_FILTER_DROPDOWN";
export const FLOORPLAN_CLOSE_ALL_FILTER_DROPDOWNS = "FLOORPLANS:FLOORPLAN_CLOSE_ALL_FILTER_DROPDOWNS";
export const FLOORPLAN_CLEAR_SEARCH_FILTER = "FLOORPLANS:FLOORPLAN_CLEAR_SEARCH_FILTER";
export const FLOORPLAN_CLEAR_NORMAL_FILTERS = "FLOORPLANS:FLOORPLAN_CLEAR_NORMAL_FILTERS";

export const FLOORPLAN_UPDATE_ACTIVE = "FLOORPLANS:FLOORPLAN_UPDATE_ACTIVE";
export const FLOORPLAN_UPDATE_ACTIVE_FROM_SLUG = "FLOORPLANS:FLOORPLAN_UPDATE_ACTIVE_FROM_SLUG";
export const FLOORPLAN_ACTIVE_TO_COMPARE = "FLOORPLAN:FLOORPLAN_ACTIVE_TO_COMPARE";
export const FLOORPLAN_COMPARE_TO_ACTIVE = "FLOORPLAN:FLOORPLAN_COMPARE_TO_ACTIVE";
export const FLOORPLAN_UPDATE_ACTIVE_ELEV = "FLOORPLANS:FLOORPLAN_UPDATE_ACTIVE_ELEV";
export const FLOORPLAN_UPDATE_ACTIVE_PLAN = "FLOORPLANS:FLOORPLAN_UPDATE_ACTIVE_PLAN";
export const FLOORPLAN_UPDATE_DISPLAYED_PLAN = "FLOORPLANS:FLOORPLAN_UPDATE_DISPLAYED_PLAN";
export const FLOORPLAN_UPDATE_SITEPLAN_ACTIVE_FLOORPLAN = "FLOORPLAN:FLOORPLAN_UPDATE_SITEPLAN_ACTIVE_FLOORPLAN";
export const FLOORPLAN_CLEAR_COMPARE = "FLOORPLANS:FLOORPLAN_CLEAR_COMPARE";
export const FLOORPLAN_CLEAR_RIGHT_COMPARE = "FLOORPLANS:FLOORPLAN_CLEAR_RIGHT_COMPARE";

export const FLOORPLAN_UPDATE_PRODUCTS = "FLOORPLANS:FLOORPLAN_UPDATE_PRODUCTS";
export const FLOORPLAN_FILTER_FLOORPLANS = "FLOORPLANS:FLOORPLAN_FILTER_FLOORPLANS";
export const FLOORPLAN_SETUP_SITEPLAN_LIST = "FLOORPLANS:FLOORPLAN_SETUP_SITEPLAN_LIST";
export const FLOORPLAN_CLEAR_COMPARE_HIDDEN = "FLOORPLANS:FLOORPLAN_CLEAR_COMPARE_HIDDEN";
export const FLOORPLAN_UPDATE_SEARCH_FILTER_ID = "FLOORPLANS:FLOORPLAN_UPDATE_SEARCH_FILTER_ID";
export const FLOORPLAN_UPDATE_PRODUCT_FILTER_ID = "FLOORPLANS:FLOORPLAN_UPDATE_PRODUCT_FILTER_ID";
export const FLOORPLAN_FILTER_COMPARE_FLOORPLANS = "FLOORPLANS:FLOORPLAN_FILTER_COMPARE_FLOORPLANS";
export const FLOORPLAN_UPDATE_COMPARE_FILTER_VALUE = "FLOORPLANS:FLOORPLAN_UPDATE_COMPARE_FILTER_VALUE";
export const FLOORPLAN_UPDATE_COMPARE_DISPLAYED_PLAN = "FLOORPLANS:FLOORPLAN_UPDATE_COMPARE_DISPLAYED_PLAN";
export const FLOORPLAN_UPDATE_ACTIVE_COMPARE_FLOORPLAN = "FLOORPLANS:FLOORPLAN_UPDATE_ACTIVE_COMPARE_FLOORPLAN";

export const FLOORPLAN_SEND_EMAIL = "FLOORPLANS:FLOORPLAN_SEND_EMAIL";
export const FLOORPLAN_EMAIL_STATUS = "FLOORPLANS:FLOORPLAN_EMAIL_STATUS";
export const FLOORPLAN_EMAIL_CLEAR_SUBMISSION = "FLOORPLANS:FLOORPLAN_EMAIL_CLEAR_SUBMISSION";
export const FLOORPLAN_UPDATE_EMAIL_ERROR = "FLOORPLANS:FLOORPLAN_UPDATE_EMAIL_ERROR";

//Siteplan Actions
export const SITEPLAN_QUERY = "SITEPLAN:SITEPLAN_QUERY";
export const SITEPLAN_UPDATE = "SITEPLAN:SITEPLAN_UPDATE";
export const SITEPLAN_UPDATE_UNIT_OUTLINE = "SITEPLAN:SITEPLAN_UPDATE_UNIT_OUTLINE";

//Content Actions
export const CONTENT_QUERY = "CONTENT:CONTENT_QUERY";
export const CONTENT_UPDATE = "CONTENT:CONTENT_UPDATE";
export const CONTENT_UPDATE_FORM = "CONTENT:CONTENT_UPDATE_FORM";
export const CONTENT_FORM_SUBMIT_QUERY = "CONTENT:CONTENT_FORM_SUBMIT_QUERY";
export const CONTENT_REMOVE_FORM_FIELD_ERROR = "CONTENT:CONTENT_REMOVE_FORM_FIELD_ERROR";
export const CONTENT_REMOVE_CONSENT_ERROR = "CONTENT:CONTENT_REMOVE_CONSENT_ERROR";

export const DROPDOWN_ADD_DROPDOWN = "DROPDOWN:DROPDOWN_ADD_DROPDOWN";
export const DROPDOWN_REMOVE_DROPDOWN = "DROPDOWN:DROPDOWN_REMOVE_DROPDOWN";
export const DROPDOWN_TOGGLE_DROPDOWN = "DROPDOWN:DROPDOWN_TOGGLE_DROPDOWN";

//Utilities Actions
export const UTILITIES_SEND_EMAIL = "UTILITIES:UTILITIES_SEND_EMAIL";
export const UTILITIES_GATHER_COOKIES = "UTILITIES:UTILITIES_GATHER_COOKIES";
export const UTILITIES_UPDATE_COOKIES = "UTILITIES:UTILITIES_UPDATE_COOKIES";
export const UTILITIES_CHECK_IF_TOUCHSCREEN = "UTILITIES:UTILITIES_CHECK_IF_TOUCHSCREEN";