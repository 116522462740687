import React from 'react';

function PlusIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="49"
            viewBox="0 0 49 49"
            className="plus-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#FFF" transform="translate(-457 -567)">
                    <g transform="translate(1 144)">
                        <g transform="translate(352 340)">
                            <g transform="translate(58 83)">
                                <g transform="translate(46)">
                                    <path d="M24 0H25V49H24z"></path>
                                    <path
                                        d="M24 0H25V49H24z"
                                        transform="rotate(90 24.5 24.5)"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PlusIcon;
