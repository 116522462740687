import React from 'react';

function PrevIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="23"
            viewBox="0 0 13 23"
            className="prev-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g stroke="#FFF" transform="translate(-54 -529)">
                    <g transform="translate(0 -1)">
                        <g transform="translate(32 513)">
                            <g transform="translate(23 18)">
                                <path
                                    d="M2.71370482e-10 21L11 10.5 2.73871592e-10 -2.06043516e-13"
                                    transform="rotate(-180 5.5 10.5)"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PrevIcon;
