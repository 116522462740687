import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateUnitOutline } from '../../redux/actions/siteplanActions';
import SitePlanSvg from '../SitePlanSvg/SitePlanSvg';
import "./MiniSiteplan.scss";

function MiniSiteplan(props) {
    const dispatch = useDispatch();
    //need to check for the values i guess

    useEffect(() => {
        if(props.floorplanID) {
            dispatch(updateUnitOutline(props.floorplanID, true, props.floorplanELE));
        }
    });
    
    return (
        <div className="mini-site-plan">
            <img
                src="/img-temp/archetto-site-plan-mini.jpg"
                alt="Site Plan Background"
                className="mini-site-plan-image"
            />
            <SitePlanSvg />
            <div className="no-click-cover" />
        </div>
    );
}

export default MiniSiteplan;