import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import './FloorplansListItem.scss';
import { useHistory } from 'react-router-dom';
import StarIcon from '../StarIcon/StarIcon';
import {
    updateActiveFloorplan,
    updateActiveCompareFloorplan
} from '../../redux/actions/floorplanActions';

function FloorplansListItem(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        //props.grid = document.querySelector('.floorplans-table');
        props.grid.current.addEventListener('mousedown', mousedown);
        props.grid.current.addEventListener('mouseup', mouseup);
    });

    let horizontalDown = 0;
    let verticalDown = 0;
    let horizontalUp = 0;
    let verticalUp = 0;
    let eKeys = Object.keys(props.floorplan.elevation);

    function mousedown(event) {
        horizontalDown = event.clientX;
        verticalDown = event.clientY;
    }

    function mouseup(event) {
        horizontalUp = event.clientX;
        verticalUp = event.clientY;
    }

    function floorplanClicked() {
        if (
            Math.abs(horizontalDown - horizontalUp) < 5 &&
            Math.abs(verticalDown - verticalUp) < 5
        ) {
            dispatch(updateActiveFloorplan(props.id));
            history.push('/floorplans/details');
        }
    }

    function floorplanCompare() {
        //this is where you put the compare floorplan into its
        //variable
        if(
            Math.abs(horizontalDown - horizontalUp) < 5 &&
            Math.abs(verticalDown - verticalUp) < 5
        ) {
            dispatch(updateActiveCompareFloorplan(props.id, props.side));
            props.update(true);
        }
    }

    let callback = () => floorplanClicked();
    if (props.compare) callback = () => floorplanCompare();

    return (
        <React.Fragment>
            {/* {props.compare ? (
                ''
            ) : (
                <div className="floorplans-list-item" onClick={callback}>
                    <img
                        src="/img-temp/list-rendering.jpg"
                        className="list-rendering"
                        alt="Archetto rendering"
                    />
                </div>
            )} */}
            <div className="list-name floorplans-list-item" onClick={callback}>
                {props.floorplan.post_title}
            </div>
            <div className="floorplans-list-item" onClick={callback}>
                {
                    props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                        .square_feet
                }{' '}
                SQ. FT.
            </div>
            {/* <div className="floorplans-list-item" onClick={callback}>
                1A
            </div> */}
            <div className="floorplans-list-item" onClick={callback}>
                {
                    props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                        .bedrooms.first
                }
                {props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                    .bedrooms.second &&
                    `/${
                        props.floorplan.elevation[eKeys[props.floorplan.ele]]
                            .info.bedrooms.second
                    }`}
            </div>
            <div className="floorplans-list-item" onClick={callback}>
                {
                    props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                        .bathrooms.first
                }
                {props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                    .bathrooms.second &&
                    `/${
                        props.floorplan.elevation[eKeys[props.floorplan.ele]]
                            .info.bathrooms.second
                    }`}
            </div>
            {/* <div
                className="star-container floorplans-list-item"
                onClick={callback}
            >
                <StarIcon saved={props.saved} />
            </div> */}
            <div
                className="right-arrow-container floorplans-list-item"
                onClick={callback}
            >
                <img src="/img-temp/right-arrow.svg" alt="Right arrow" />
            </div>
        </React.Fragment>
    );
}

export default FloorplansListItem;
