import React, { useState } from 'react';
import './FloorplanFullscreen.scss';
import CloseIcon from '../CloseIcon/CloseIcon';
import PrevIcon from '../PrevIcon/PrevIcon';
import NextIcon from '../NextIcon/NextIcon';

function FloorplanFullscreen(props) {
    const [open, setOpen] = useState(props.open);
    const [selected, setSelected] = useState(props.selected || 0);

    if (open !== props.open) setOpen(props.open);

    if (props.floorplans)
        if (selected >= props.floorplans.length) setSelected(0);

    const styles = {
        display: open ? 'block' : 'none',
        position: props.absolute && 'absolute'
    };

    function backClasses() {
        let classes = 'floorplan-details-back-container';
        if (selected - 1 < 0) classes += ' disabled';

        return classes;
    }

    function backLabel() {
        if (selected - 1 < 0) return '';
        return props.floorplans[selected - 1].content.main.label;
    }

    function nextClasses() {
        let classes = 'floorplan-details-next-container';
        if (selected + 1 > props.floorplans.length - 1) classes += ' disabled';

        return classes;
    }

    function nextLabel() {
        if (selected + 1 > props.floorplans.length - 1) return '';
        return props.floorplans[selected + 1].content.main.label;
    }

    function goBack() {
        if (selected - 1 >= 0) setSelected(selected - 1);
    }

    function goForward() {
        if (selected + 1 < props.floorplans.length) setSelected(selected + 1);
    }

    if (props.floorplans) {
        return (
            <div className="floorplan-details-fullscreen" style={styles}>
                <button
                    className="floorplan-details-fullscreen-close"
                    onClick={props.callback}
                >
                    <CloseIcon />
                    Close
                </button>
                <div className={backClasses()} onClick={goBack}>
                    <button>
                        <PrevIcon />
                    </button>
                    {backLabel()}
                </div>
                <div className={nextClasses()} onClick={goForward}>
                    {nextLabel()}
                    <button>
                        <NextIcon />
                    </button>
                </div>
                <img
                    src="/img-temp/fullscreen-flowers-top.jpg"
                    className="floorplan-details-fullscreen-flowers-top"
                    alt="Flowers"
                />
                <img
                    src="/img-temp/fullscreen-flowers-bottom.jpg"
                    className="floorplan-details-fullscreen-flowers-bottom"
                    alt="Flowers"
                />
                {props.floorplans[selected].content && (
                    <div className="floorplan-details-floorplans">
                        {props.floorplans[selected].content.main.image && (
                            <div className="floorplan-details-floorplan-container">
                                <div className="space-between-hack-sorry"></div>
                                <img
                                    src={
                                        props.floorplans[selected].content.main
                                            .image
                                    }
                                    alt="Floorplan"
                                />
                                <div className="floorplan-details-floorplan-name">
                                    {
                                        props.floorplans[selected].content.main
                                            .label
                                    }
                                </div>
                            </div>
                        )}
                        {props.floorplans[selected].content.optional.image && (
                            <div className="floorplan-details-floorplan-container">
                                <div className="space-between-hack-sorry"></div>
                                <img
                                    src={
                                        props.floorplans[selected].content
                                            .optional.image
                                    }
                                    alt="Floorplan"
                                />
                                <div className="floorplan-details-floorplan-name">
                                    {
                                        props.floorplans[selected].content
                                            .optional.label
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default FloorplanFullscreen;
