import './ContactForm.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormTextInput from '../FormTextInput/FormTextInput';
import FormRadio from '../FormRadio/FormRadio';
import FormSelect from '../FormSelect/FormSelect';
import Consent from '../Consent/Consent';
import Submit from '../Submit/Submit';
import { formSubmission } from '../../redux/actions/contentActions';

const ContactForm = (props) => {
    const form = props.form;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(-1);

    const [formValue, setFormValue] = useState({
        formName: 'contact',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        postalcode: '',
        realtor: '',
        brokerage: '',
        hearabout: '',
        pricerange: '',
        whymove: '',
        consent: ''
    });    

    const handleSubmit = (e) => {
        e.preventDefault();
        //make an action pass the data do the api get the response update the thing
        //infiltrate the dealer. Find the supplier
        if (!props.submitting) {
            dispatch(formSubmission(formValue));
        }
    };

    const hasErrors = () => {
        if(props.consent_error) return true;

        for(var i = 0; i < form.length; i++) {
            if(typeof form[i].error !== 'undefined')
            if(form[i].error === true) return true;
        }

        return false;
    };

    if (typeof form !== 'undefined') {
        if (!props.submitted) {
            return (
                <form className="contact-form">
                    <div className="input-section">
                        <FormTextInput
                            index={0}
                            name={form[0].name}
                            label={form[0].label}
                            value={formValue.firstName}
                            formValue={formValue}
                            error={form[0].error || false}
                            errorText={form[0].error_text}
                            setFormValue={setFormValue}
                            required
                            inputSectionChild
                        />
                        <FormTextInput
                            index={1}
                            name={form[1].name}
                            label={form[1].label}
                            value={formValue.lastName}
                            error={form[1].error || false}
                            errorText={form[1].error_text}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            required
                            inputSectionChild
                        />
                        <FormTextInput
                            index={2}
                            name={form[2].name}
                            label={form[2].label}
                            value={formValue.email}
                            error={form[2].error || false}
                            errorText={form[2].error_text}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            required
                            email
                            inputSectionChild
                        />
                        <FormTextInput
                            index={3}
                            name={form[3].name}
                            label={form[3].label}
                            value={formValue.phone}
                            error={form[3].error || false}
                            errorText={form[3].error_text}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            required
                            inputSectionChild
                        />
                        <FormTextInput
                            index={4}
                            name={form[4].name}
                            label={form[4].label}
                            value={formValue.address}
                            error={form[4].error || false}
                            errorText={form[4].error_text}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            required
                            inputSectionChild
                        />
                        <FormTextInput
                            index={5}
                            name={form[5].name}
                            label={form[5].label}
                            value={formValue.postalCode}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            error={form[5].error || false}
                            errorText={form[5].error_text}
                            required
                            inputSectionChild
                        />
                    </div>
                    <div className="radio-section">
                        <FormRadio
                            name={form[6].name}
                            label={form[6].label}
                            buttons={form[6].choices}
                            value={formValue.realtorStatus}
                            formValue={formValue}
                            setFormValue={setFormValue}
                        />
                        <FormTextInput
                            name={form[7].name}
                            label={form[7].label}
                            value={formValue.brokerage}
                            formValue={formValue}
                            conditional={true}
                            cond_value={formValue.realtor}
                            setFormValue={setFormValue}
                            radioSectionChild
                        />
                    </div>
                    <div className="select-section-two-columns">
                        <FormSelect
                            name={form[8].name}
                            label={form[8].label}
                            value={form[8].label}
                            options={form[8].choices}
                            formValue={formValue}
                            open={open}
                            setOpen={setOpen}
                            setFormValue={setFormValue}
                            index={0}
                            twoColumn
                            top
                        />
                        <FormSelect
                            name={form[9].name}
                            label={form[9].label}
                            twoColumn
                            options={form[9].choices}
                            formValue={formValue}
                            open={open}
                            setOpen={setOpen}
                            index={1}
                            setFormValue={setFormValue}
                        />
                    </div>
                    <div className="select-section-one-column">
                        <p>
                            We design our homes to reflect your lifestyle, so
                            feel free to tell us more about why you’re looking
                            to move:{' '}
                        </p>
                        <FormSelect
                            name={form[10].name}
                            label={form[10].label}
                            options={form[10].choices}
                            formValue={formValue}
                            open={open}
                            setOpen={setOpen}
                            setFormValue={setFormValue}
                            index={2}
                        />
                    </div>
                    <div className="submit-section">
                        <Consent
                            text="I would like to receive email communication from Marlin Spring."
                            name="consent"
                            formValue={formValue}
                            setFormValue={setFormValue}
                            error={props.consent_error}
                            required
                        />
                        <Submit
                            buttonText="Submit"
                            requiredText="Required"
                            formValue={formValue}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                    <div className="required-fields">
                        <span className="required-star">*</span> Required
                    </div>
                    {hasErrors() &&
                        <div className="form-errors">
                            Please fill out all required fields.
                        </div>
                    }
                </form>
            );
        } else {
            return (
                <div className="thank-you-message">
                    Thank you for registering. Life in Woodbridge at Archetto is
                    a natural choice. We look forward to keeping you inspired.
                </div>
            );
        }
    } else {
        return <div></div>;
    }
};

export default ContactForm;
