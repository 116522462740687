import React from 'react';

function BlockIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 20 20"
            version="1.1"
            viewBox="0 0 20 20"
            xmlSpace="preserve"
            className="block-icon"
        >
            <g>
                <g transform="translate(-1473 -102)">
                    <g transform="translate(-24)">
                        <g transform="translate(24 80)">
                            <g transform="translate(960)">
                                <g transform="translate(466)">
                                    <g transform="translate(47 22)">
                                        <path
                                            d="M7.5.5v19h-7V.5h7zm12 0v19h-9V.5h9z"
                                            className="st0"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default BlockIcon;
