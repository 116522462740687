import React from 'react';

function DragIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="13"
            viewBox="0 0 21 13"
            className="drag-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-1784 -604)">
                    <g transform="translate(0 80)">
                        <g transform="translate(648)">
                            <g transform="translate(1072 424)">
                                <g transform="translate(64 101)">
                                    <path fill="#FFF" d="M0 5H20V6H0z"></path>
                                    <path
                                        stroke="#FFF"
                                        d="M15 11L20 5.5 15 1.62003744e-12"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default DragIcon;
