import React, { useRef } from 'react';
import './FloorplansTable.scss';
import FloorplansTableTopRow from '../FloorplansTableTopRow/FloorplansTableTopRow';
import FloorplansListItem from '../FloorplansListItem/FloorplansListItem';
import { useSelector } from 'react-redux';
import { useScrollBoost } from 'react-scrollbooster';

function FloorplansTable(props) {
    const valid = useSelector((state) => state.floorplan.productFilterID);
    const floorplans = useSelector(
        (state) => state.floorplan.displayFloorplans
    );
    const table = useRef();

    let plans;

    console.log(floorplans);

    if(props.compareplans) {
        //remove hidden from the list somehow
        var comparePs = [];
        props.compareplans.map((p) => {
            if(!p.compareHide) {
                comparePs.push(p);
            }
        });

        plans = comparePs;
    }
    else plans = floorplans;

    const [viewport] = useScrollBoost({
        direction: 'vertical',
        friction: 0.05,
        scrollMode: 'native'
        // onClick: (state, event) => {
        //     if (state.isDragging) {
        //         event.preventDefault();
        //     }
        // }
    });

    if (valid !== -1) {
        return (
            <div
                ref={viewport}
                className={`table-viewport${
                    props.compare ? ' compare-table' : ''
                }`}
            >
                <div className="floorplans-table" ref={table}>
                    <FloorplansTableTopRow compare={props.compare} />
                    {props.side &&
                        plans &&
                        plans.map((floorplan, key) => (
                            <FloorplansListItem
                                key={key}
                                floorplan={floorplan}
                                id={key}
                                compare={props.compare}
                                side={props.side}
                                update={props.update}
                                grid={table}
                            />
                        ))}
                    {plans &&
                        plans.map((floorplan, key) => (
                            <FloorplansListItem
                                id={key}
                                key={key}
                                side={props.side}
                                update={props.update}
                                compare={props.compare}
                                floorplan={floorplan}
                                grid={table}
                            />
                        ))}
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={`table-viewport${
                    props.compare ? ' compare-table' : ''
                }`}
            ></div>
        );
    }
}

export default FloorplansTable;
