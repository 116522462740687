import React, { useState } from 'react';
import './CompareFloorplansSingleFloorplan.scss';
import FloorplanDetailsInfoPanel from '../FloorplanDetailsInfoPanel/FloorplanDetailsInfoPanel';
import FloorplanDetailsFloorplanPanel from '../FloorplanDetailsFloorplanPanel/FloorplanDetailsFloorplanPanel';
import CompareFloorplansIcon from '../CompareFloorplansIcon/CompareFloorplansIcon';
import FloorplanFullscreen from '../FloorplanFullscreen/FloorplanFullscreen';
import FloorplanDetailsSendEmailModal from '../FloorplanDetailsSendEmailModal/FloorplanDetailsSendEmailModal';
import { useDispatch } from 'react-redux';
import { clearCompareHidden } from '../../redux/actions/floorplanActions';

function CompareFloorplansSingleFloorplan(props) {
    const dispatch = useDispatch();

    let styles = {
        display: props.visible ? "flex" : "none"
    };

    const [fullscreen, setFullScreen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    function toggleModal() {
        setModalOpen(!modalOpen);
    }

    function closeModal() {
        setModalOpen(false);
    }

    function openFullScreen() {
        setFullScreen(true);
    }

    function closeFullScreen() {
        setFullScreen(false);
    }

    function changeFloorplan() {
        props.update(false);
        //clear hidden from this side
        dispatch(clearCompareHidden(props.side));
    }

    if(props.floorplan) {
        const eKeys = Object.keys(props.floorplan.elevation);
        return (
            <div
                className="compare-floorplans-single-floorplan"
                style={styles}
            >
                <FloorplanDetailsSendEmailModal
                    open={modalOpen}
                    close={closeModal}
                    label={`${props.floorplan.realName} ${
                        props.floorplan.elevation[eKeys[props.floorplan.ele]]
                            .label_elevation.letter_label
                    }`}
                    floorplans={[
                        {
                            ID: props.floorplan.ID,
                            ele: eKeys[props.floorplan.ele]
                        }
                    ]}
                />
                <div className="compare-floorplans-reselect">
                    <span onClick={changeFloorplan}>
                        <CompareFloorplansIcon />
                        Change Floorplan
                    </span>
                </div>
                <FloorplanDetailsInfoPanel
                    compare={true}
                    floorplan={props.floorplan}
                    toggleModal={toggleModal}
                />
                <FloorplanDetailsFloorplanPanel
                    compare={true}
                    callback={openFullScreen}
                    floorplan={props.floorplan}
                    side={props.side}
                />
                <FloorplanFullscreen
                    open={fullscreen}
                    callback={closeFullScreen}
                    floorplans={
                        props.floorplan.elevation[
                            eKeys[props.floorplan.ele]
                        ].floorplans
                    }
                    absolute
                />
            </div>
        );
    }
    else {
        return (
            <div></div>
        )
    }
}

export default CompareFloorplansSingleFloorplan;
