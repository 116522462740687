import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './GalleryArea.scss';
import { useScrollBoost } from 'react-scrollbooster';
import Cover from '../Cover/Cover';
import DragInfo from '../DragInfo/DragInfo';
import MobileFooter from '../MobileFooter/MobileFooter';

const setContainerWidth = () => {
    // probably a good idea to fire this only when the last image has loaded at some point
    const areaModules = document.querySelectorAll('.area-module');
    let areaModuleWidth = 0;
    for (var i = 0; i < areaModules.length; i++) {
        if (areaModules[i].clientWidth > areaModuleWidth) {
            areaModuleWidth = areaModules[i].clientWidth;
        }
        if (i === areaModules.length - 1) {
            for (var ii = 0; ii < areaModules.length; ii++) {
                if (window.innerWidth > 1300) {
                    // this doesn't work right when resizing from below 1300px to above
                    // but that seems like an edge case with a barely noticable layout issue
                    // i intend to fix it but let's be honest i'll probably never have time
                    areaModules[ii].style.width = areaModuleWidth + 'px';
                } else if (window.innerWidth <= 768) {
                    areaModules[ii].style.width = '100%';
                } else {
                    areaModules[ii].style.width = 'calc(50% - 12px)';
                }
            }
        }
    }
    let rowLength = 0;
    if (areaModules.length % 2) {
        rowLength = (areaModules.length + 1) / 2;
    } else {
        rowLength = areaModules.length / 2;
    }
    let containerWidth = rowLength * areaModuleWidth + rowLength * 24 + 48 + 24;
    if (window.innerWidth > 1300) {
        document.querySelector('.gallery-area').style.width =
            containerWidth + 'px';
    } else {
        document.querySelector('.gallery-area').style.width = 'auto';
    }
};

function GalleryArea() {
    const content = useSelector((state) => state.content.pages);

    const [viewport] = useScrollBoost({
        direction: 'horizontal',
        friction: 0.05,
        scrollMode: 'native'
    });

    function hideDragInfo() {
        if (document.querySelector('.gallery-area-viewport').scrollLeft > 40) {
            document.querySelector('.drag-info').style.opacity = '0';
        } else {
            document.querySelector('.drag-info').style.opacity = '1';
        }
    }

    useEffect(() => {
        var item = document.querySelector('.gallery-area-viewport');

        window.addEventListener('wheel', function (e) {
            if (e.deltaY > 0) {
                if (item) item.scrollLeft += 100;
            } else if (e.deltaY < 0) {
                if (item) item.scrollLeft -= 100;
            }
        });

        window.addEventListener('resize', setContainerWidth);

        return () => {
            window.removeEventListener('resize', setContainerWidth);
        };
    });

    if (typeof content.area !== 'undefined') {
        const renderings = content.gallery.area;

        return (
            <div
                ref={viewport}
                className="gallery-area-viewport"
                onScroll={hideDragInfo}
            >
                <div className="gallery-area">
                    <Cover
                        background={{ background: '#fff' }}
                        svgColor={{ stroke: '#9b3465' }}
                    />
                    <DragInfo />
                    {renderings.map((rendering, key) => (
                        <div className="area-module" key={key}>
                            <div className="area-image-container">
                                <img
                                    src={rendering.image.url}
                                    alt={rendering.image.alt}
                                    onLoad={setContainerWidth}
                                />
                            </div>
                            <div className="area-caption">
                                {rendering.label}
                            </div>
                        </div>
                    ))}
                </div>
                <MobileFooter showBelow={1301} />
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default GalleryArea;
