import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './Consent.scss';
import CheckboxIcon from '../CheckboxIcon/CheckboxIcon';
import RequiredStar from '../RequiredStar/RequiredStar';
import { removeConsentError } from '../../redux/actions/contentActions';

const Consent = (props) => {
    const dispatch = useDispatch();
    const [checkboxStatus, setCheckboxStatus] = useState(false);

    const handleCheckboxClick = (e) => {
        if(props.error) {
            dispatch(removeConsentError());
        }
        setCheckboxStatus(!checkboxStatus);
        props.setFormValue({
            ...props.formValue,
            [e.target
                .closest('.consent')
                .getAttribute('data-name')]: !checkboxStatus ? 'Yes' : 'No'
        });
    };

    return (
        <div
            className={`consent${checkboxStatus ? ' active' : ''}${
                props.error ? ' error' : ''
            }`}
            data-name={props.name}
            onClick={handleCheckboxClick}
        >
            <CheckboxIcon />
            <span className="consent-text">
                {props.text}
                {props.required && <RequiredStar />}
            </span>
        </div>
    );
};

export default Consent;
