import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSiteplanToFloorplan } from '../../redux/actions/floorplanActions';

function SitePlanSvg(props) {
    const blocks = useSelector((state) => state.siteplan);
    const dispatch = useDispatch();

    function sBlock(id) {
        var block;
        if (typeof blocks[id] === 'undefined') {
            block = {
                active: false,
                blockID: -1,
                elevation: {
                    label: '',
                    value: ''
                },
                outline: false
            };
        } else block = blocks[id];
        return block;
    }

    function sBlockUnit(bId, uNumber) {
        var block;
        var unit = {
            colour: '#FFFFFF',
            stroke: '#292728',
            width: '1',
            opacity: '0',
            focused: false
        };

        if (typeof blocks[bId] !== 'undefined') {
            block = blocks[bId];
            if (typeof block.units[uNumber] !== 'undefined') {
                unit = block.units[uNumber];

                if (!unit.avaliblity) unit.fade = true;

                //i need to do the unit highlight thing im just not going to do it right now

                if (props.panelOpen === true) {
                    if (
                        unit.floorplanID === props.floorplan.ID &&
                        parseInt(uNumber) === props.floorplan.unit &&
                        props.floorplan.bID === bId
                    ) {
                        unit.opacity = '1';
                        unit.stroke = '#000000';
                        unit.width = '5';
                    } else {
                        unit.opacity = '0';
                        unit.stroke = '#292728';
                        unit.width = '1';
                    }
                } else {
                    if (unit.outline) {
                        unit.stroke = '#000000';
                        unit.width = '5';
                    } else {
                        unit.stroke = '#292728';
                        unit.width = '1';
                    }

                    if (unit.fade) unit.opacity = '0';
                    else unit.opacity = '1';
                }
            }
        }

        return unit;
    }

    function unit_clicked(ID, ele, unit, bID) {
        if (sBlockUnit(bID, unit).avaliblity !== false) {
            setDisplayedFloorplan(ID, ele, unit, bID);
            props.open();
        }
    }

    function setDisplayedFloorplan(ID, ele, unit, bID) {
        dispatch(updateSiteplanToFloorplan(ID, ele, unit, bID));
    }

    if (Object.keys(blocks).length !== 0) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5000"
                height="3308"
                viewBox="0 0 5000 3308"
                className="site-plan-svg"
            >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g>
                        <g>
                            <path d="M0 0H5000V3308H0z"></path>
                            <text
                                fill="#FFF"
                                fillRule="nonzero"
                                fontFamily="Vaud-Bold, Vaud"
                                fontSize="76"
                                fontWeight="bold"
                                transform="rotate(-16.23 2450.881 468.111)"
                            >
                                {/* <tspan x="1968.381" y="488.111">
                                    MAJOR MACKENZIE DRIVE
                                </tspan> */}
                            </text>
                            <text
                                fill="#FFF"
                                fillRule="nonzero"
                                fontFamily="Vaud-Bold, Vaud"
                                fontSize="76"
                                fontWeight="bold"
                                transform="rotate(80.98 242.075 2188.739)"
                            >
                                {/* <tspan x="-117.425" y="2208.739">
                                    PINE VALLEY DRIVE
                                </tspan> */}
                            </text>
                        </g>
                        <g
                            id="Block-16"
                            fillRule="nonzero"
                            transform="translate(676 2854)"
                            style={{
                                display: sBlock('block_16').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(475)">
                                <path
                                    fill={sBlockUnit('block_16', '1').colour}
                                    stroke={sBlockUnit('block_16', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_16', '1').width
                                    }
                                    opacity={
                                        sBlockUnit('block_16', '1').opacity
                                    }
                                    d="M100.47 207.92L94.15 0.14 46.79 0.14 47.32 39.11 0.06 40.95 6.92 210.7z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_16', '1')
                                                .floorplanID,
                                            
                                            sBlockUnit('block_16', '1')
                                                .unit_elevation.value
                                            ,
                                            1,
                                            'block_16'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M51.07 145l-5.54.17-.45-14.93 5.33-.16c3.46-.1 5.23 1.38 5.3 3.71a3.27 3.27 0 01-2 3.31 3.55 3.55 0 012.56 3.43c.1 2.71-1.76 4.36-5.2 4.47zm-.61-13.18l-3.29.1.13 4.54 3.49-.11c1.86-.06 2.92-.72 2.87-2.4-.05-1.68-1.25-2.19-3.2-2.13zm.49 6.29l-3.59.11.15 5.12 3.49-.11c2.12-.06 3.29-.85 3.23-2.66-.06-1.81-1.23-2.52-3.23-2.46h-.05z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M51.75 114.65L51 93c-2 1.41-4 2.85-6 4.26l-.13-3.55c2.13-1.56 4.26-3.16 6.38-4.72l3-.11.94 25.63-3.44.14z"
                                ></path>
                            </g>
                            <g transform="translate(383 8)">
                                <path
                                    fill={sBlockUnit('block_16', '2').colour}
                                    stroke={sBlockUnit('block_16', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_16', '2').width
                                    }
                                    opacity={
                                        sBlockUnit('block_16', '2').opacity
                                    }
                                    d="M92.06 32.95L45.3 34.78 44.25 0.7 0.42 1.56 8.65 205.39 98.92 202.7z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_16', '2')
                                                .floorplanID,
                                            sBlockUnit('block_16', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_16'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M49.41 139.84l-5.54.17-.45-14.93 5.33-.16c3.46-.1 5.23 1.38 5.3 3.71a3.27 3.27 0 01-2 3.31 3.54 3.54 0 012.56 3.43c.11 2.71-1.76 4.36-5.2 4.47zm-.61-13.17l-3.29.09.14 4.54 3.48-.11c1.87-.05 2.92-.72 2.87-2.4-.05-1.68-1.25-2.18-3.2-2.12zm.49 6.28l-3.59.11.15 5.12 3.49-.1c2.12-.07 3.29-.86 3.24-2.66-.05-1.8-1.26-2.53-3.29-2.42v-.05z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M43.62 107.41c.93-.88 6.92-8.07 8-9.07 2.68-2.65 4.08-4.89 4-7.36a4 4 0 00-4.49-4.1c-3.06.12-4.45 1.8-4.78 4.7l-3.41.12c.3-4.08 2.64-7.72 8.12-7.92 4.8-.18 7.94 2.55 8.1 6.92.12 3.29-1.44 6.16-4.65 9.31-.69.62-5.44 6.35-6.15 7l11.68-.43.12 3.14-16.38.6-.16-2.91z"
                                ></path>
                            </g>
                            <g transform="translate(293 9)">
                                <path
                                    fill={sBlockUnit('block_16', '3').colour}
                                    stroke={sBlockUnit('block_16', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_16', '3').width
                                    }
                                    opacity={
                                        sBlockUnit('block_16', '3').opacity
                                    }
                                    d="M89.86 0.57L90.04 5.29 47.22 6.16 48.27 36.92 0.74 37.63 7.59 207.11 98.65 204.39 90.42 0.56z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_16', '3')
                                                .floorplanID,
                                            sBlockUnit('block_16', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_16'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M49.16 141.58l-5.54.16-.45-14.92 5.33-.16c3.46-.1 5.23 1.38 5.3 3.71a3.28 3.28 0 01-2 3.31 3.55 3.55 0 012.56 3.43c.1 2.71-1.76 4.36-5.2 4.47zm-.61-13.18l-3.29.1.13 4.54 3.49-.11c1.86-.06 2.92-.72 2.87-2.4-.05-1.68-1.25-2.19-3.2-2.13zm.49 6.29l-3.59.11.15 5.12 3.49-.11c2.12-.06 3.29-.85 3.23-2.66-.06-1.81-1.25-2.52-3.32-2.46h.04z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M44 105.23c.38 3.13 2 4.81 5.43 4.68 3.07-.11 5-1.81 4.86-4.84-.12-3.18-2.16-4.51-5.27-4.4l-2.22.08-.11-3.1 2.29-.09c2.81-.1 4.46-1.6 4.35-4.41-.08-2.41-1.88-4-4.66-3.87-3 .11-4.68 1.54-5 4.48l-3.4.12c-.11-4.1 3.06-7.51 8.31-7.71 5-.18 8.09 2.63 8.24 6.62a6 6 0 01-3.56 6 6.31 6.31 0 014.56 6.12c.17 4.74-3 7.92-8.26 8.12-6 .22-8.75-3.49-9-7.67l3.44-.13z"
                                ></path>
                            </g>
                            <g transform="translate(201 15)">
                                <path
                                    fill={sBlockUnit('block_16', '4').colour}
                                    stroke={sBlockUnit('block_16', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_16', '4').width
                                    }
                                    opacity={
                                        sBlockUnit('block_16', '4').opacity
                                    }
                                    d="M46.25 32.32L44.93 4.7 1.16 4.71 0.99 0.34 0.6 0.34 8.83 203.81 99.59 201.11 92.74 31.63z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_16', '4')
                                                .floorplanID,
                                            sBlockUnit('block_16', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_16'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M49.88 138.34l-5.54.17-.45-14.92 5.33-.16c3.46-.1 5.23 1.37 5.3 3.7a3.28 3.28 0 01-2 3.32 3.54 3.54 0 012.56 3.43c.11 2.71-1.75 4.36-5.2 4.46zm-.61-13.17l-3.29.1.14 4.53 3.48-.1c1.87-.06 2.92-.72 2.87-2.4-.05-1.68-1.24-2.19-3.2-2.13zm.49 6.29l-3.59.11.16 5.12 3.48-.11c2.12-.06 3.29-.85 3.24-2.66-.05-1.81-1.26-2.52-3.29-2.46zM40.1 101.27l11.3-17.11 3.51-.13.6 16.34 3.26-.12.1 2.89-3.25.12.23 6.32-3.55.13-.23-6.32-11.87.43-.1-2.55zm11.86-.77L51.54 89l-7.79 11.81 8.21-.31z"
                                ></path>
                            </g>
                            <g transform="translate(111 15)">
                                <path
                                    fill={sBlockUnit('block_16', '5').colour}
                                    stroke={sBlockUnit('block_16', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_16', '5').width
                                    }
                                    opacity={
                                        sBlockUnit('block_16', '5').opacity
                                    }
                                    d="M46.42 0.69L47.12 35.47 0.4 36.16 7.29 206.54 98.83 203.81 90.6 0.34z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_16', '5')
                                                .floorplanID,
                                            sBlockUnit('block_16', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_16'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M47.42 141.15l-5.54.17-.46-14.92 5.33-.16c3.47-.11 5.23 1.37 5.3 3.7a3.26 3.26 0 01-2 3.32 3.55 3.55 0 012.56 3.43c.11 2.7-1.75 4.36-5.19 4.46zm-.61-13.17l-3.3.1.14 4.53 3.48-.11c1.87-.05 2.92-.71 2.87-2.39-.05-1.68-1.24-2.19-3.19-2.11v-.02zm.48 6.28l-3.59.11.16 5.12 3.48-.1c2.12-.07 3.3-.86 3.24-2.66-.06-1.8-1.25-2.53-3.29-2.47z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M42.38 106.28c.41 2.94 2.17 4.55 5.35 4.43 3.18-.12 4.91-2.33 4.78-6-.12-3.22-1.68-5.42-5.12-5.29a5.75 5.75 0 00-4.76 2.43l-3.29.12.33-14.15 14.46-.54.11 3.11-11.46.42-.27 7.75a7.33 7.33 0 015.36-2.16c5.33-.19 8 3.45 8.15 8.14.2 5.48-2.78 9-8.14 9.23-5.36.23-8.46-2.58-8.94-7.38l3.44-.11z"
                                ></path>
                            </g>
                            <g transform="translate(0 15)">
                                <path
                                    fill={sBlockUnit('block_16', '6').colour}
                                    stroke={sBlockUnit('block_16', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_16', '6').width
                                    }
                                    opacity={
                                        sBlockUnit('block_16', '6').opacity
                                    }
                                    d="M63.75 36.86L62.71 0.16 0.68 1.76 2.76 108.34 19.89 108.16 21.99 214.42 118.11 211.59 117.93 206.55 118.29 206.54 111.4 36.16z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_16', '6')
                                                .floorplanID,
                                            sBlockUnit('block_16', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_16'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M63.89 129.06L66 129l6.08 14.77-2.18.06-1.48-3.65-6.34.2-1.25 3.73-2.12.06 5.18-15.11zm3.85 9.32l-2.77-6.87-2.3 7 5.07-.13zM66.84 117.12c-7.06.26-9.07-6.1-9.29-12.09-.32-8.51 2-14.44 8.68-14.69 4.84-.18 7.46 2.65 8 6.33l-3.4.13c-.56-2.24-1.83-3.56-4.61-3.46-4.32.16-5.42 4.57-5.34 9.75a7.07 7.07 0 016-3.26c5.36-.19 8.12 3.22 8.3 8.25.18 5.03-2.87 8.84-8.34 9.04zm-.38-14.38c-2.88.1-5 2.22-4.9 5.66.11 3.1 1.81 5.89 5.17 5.77 3.36-.12 5.13-2.56 5-6-.11-3.07-1.65-5.57-5.27-5.43z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M227.61 209.66l-9 .34-.92-24.15 8.63-.33c5.6-.21 8.48 2.16 8.63 5.93a5.3 5.3 0 01-3.16 5.39 5.76 5.76 0 014.19 5.53c.2 4.38-2.8 7.08-8.37 7.29zm-1.15-21.32l-5.34.2.28 7.34 5.64-.21c3-.12 4.72-1.21 4.62-3.92-.1-2.71-2.04-3.53-5.2-3.41zm.86 10.17l-5.81.23.32 8.28 5.64-.21c3.43-.13 5.32-1.43 5.21-4.35-.11-2.92-2.06-4.07-5.36-3.95z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M239.77 185L243.13 184.87 243.94 206.04 256 205.58 256.12 208.58 240.69 209.17z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M268.94 208.59c-7.3.28-10.68-5.27-10.94-12.17-.26-6.9 2.78-12.76 10-13 7.22-.24 10.61 5.25 10.88 12.21.27 6.96-2.56 12.68-9.94 12.96zm-.84-22.22c-5.51.21-6.85 5.06-6.67 9.92.18 4.86 1.93 9.55 7.4 9.35 5.47-.2 6.79-5 6.6-9.89-.19-4.89-1.93-9.58-7.33-9.38z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M293.07 207.67c-7.2.27-10.44-5.21-10.71-12.18-.27-6.97 2.71-12.76 9.74-13 5.4-.2 8.73 3.24 9.59 7.9l-3.39.13c-.6-3.17-2.58-5.17-6.08-5-5.17.2-6.62 5-6.43 9.91.19 4.91 2 9.55 7.16 9.35 3.71-.14 5.49-2.38 5.74-5.59l3.47-.13c-.29 4.77-3.62 8.4-9.09 8.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M305.82 182.45L309.18 182.32 309.64 194.41 320.29 181.89 324.19 181.75 316.35 191.16 326 205.9 322.13 206.05 314.13 193.63 309.83 198.69 310.13 206.51 306.77 206.63z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M327.77 194.13L338.13 193.73 338.24 196.73 327.88 197.12z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M350.23 205l-.76-19.87c-1.82 1.29-3.64 2.62-5.47 3.92l-.12-3.26c2-1.44 3.9-2.91 5.85-4.34l2.79-.11.9 23.54-3.19.12z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M370.39 204.71c-6.48.25-8.34-5.6-8.55-11.1-.3-7.82 1.8-13.27 8-13.51 4.45-.17 6.87 2.43 7.4 5.81l-3.12.12a3.72 3.72 0 00-4.24-3.17c-4 .15-5 4.2-4.9 9a6.51 6.51 0 015.47-3c4.92-.19 7.46 2.95 7.64 7.57.18 4.62-2.67 8.09-7.7 8.28zm-.37-13.22c-2.64.1-4.61 2.05-4.49 5.21.11 2.85 1.67 5.41 4.76 5.29 3.09-.12 4.71-2.35 4.59-5.48-.11-2.85-1.53-5.15-4.88-5.02h.02zM227.61 209.66l-9 .34-.92-24.15 8.63-.33c5.6-.21 8.48 2.16 8.63 5.93a5.3 5.3 0 01-3.16 5.39 5.76 5.76 0 014.19 5.53c.2 4.38-2.8 7.08-8.37 7.29zm-1.15-21.32l-5.34.2.28 7.34 5.64-.21c3-.12 4.72-1.21 4.62-3.92-.1-2.71-2.04-3.53-5.2-3.41zm.86 10.17l-5.81.23.32 8.28 5.64-.21c3.43-.13 5.32-1.43 5.21-4.35-.11-2.92-2.06-4.07-5.36-3.95z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M239.77 185L243.13 184.87 243.94 206.04 256 205.58 256.12 208.58 240.69 209.17z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M268.94 208.59c-7.3.28-10.68-5.27-10.94-12.17-.26-6.9 2.78-12.76 10-13 7.22-.24 10.61 5.25 10.88 12.21.27 6.96-2.56 12.68-9.94 12.96zm-.84-22.22c-5.51.21-6.85 5.06-6.67 9.92.18 4.86 1.93 9.55 7.4 9.35 5.47-.2 6.79-5 6.6-9.89-.19-4.89-1.93-9.58-7.33-9.38z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M293.07 207.67c-7.2.27-10.44-5.21-10.71-12.18-.27-6.97 2.71-12.76 9.74-13 5.4-.2 8.73 3.24 9.59 7.9l-3.39.13c-.6-3.17-2.58-5.17-6.08-5-5.17.2-6.62 5-6.43 9.91.19 4.91 2 9.55 7.16 9.35 3.71-.14 5.49-2.38 5.74-5.59l3.47-.13c-.29 4.77-3.62 8.4-9.09 8.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M305.82 182.45L309.18 182.32 309.64 194.41 320.29 181.89 324.19 181.75 316.35 191.16 326 205.9 322.13 206.05 314.13 193.63 309.83 198.69 310.13 206.51 306.77 206.63z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M327.77 194.13L338.13 193.73 338.24 196.73 327.88 197.12z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M350.23 205l-.76-19.87c-1.82 1.29-3.64 2.62-5.47 3.92l-.12-3.26c2-1.44 3.9-2.91 5.85-4.34l2.79-.11.9 23.54-3.19.12z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M370.39 204.71c-6.48.25-8.34-5.6-8.55-11.1-.3-7.82 1.8-13.27 8-13.51 4.45-.17 6.87 2.43 7.4 5.81l-3.12.12a3.72 3.72 0 00-4.24-3.17c-4 .15-5 4.2-4.9 9a6.51 6.51 0 015.47-3c4.92-.19 7.46 2.95 7.64 7.57.18 4.62-2.67 8.09-7.7 8.28zm-.37-13.22c-2.64.1-4.61 2.05-4.49 5.21.11 2.85 1.67 5.41 4.76 5.29 3.09-.12 4.71-2.35 4.59-5.48-.11-2.85-1.53-5.15-4.88-5.02h.02z"
                            ></path>
                        </g>
                        <g
                            id="Block-15"
                            fillRule="nonzero"
                            transform="translate(1291 2664)"
                            style={{
                                display: sBlock('block_15').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(153)">
                                <path
                                    fill={sBlockUnit('block_15', '1').colour}
                                    stroke={sBlockUnit('block_15', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_15', '1').width
                                    }
                                    opacity={
                                        sBlockUnit('block_15', '1').opacity
                                    }
                                    d="M160.45 153.09L146.47 139.11 171.64 108.35 51.4 0.7 1.77 55.92 31.83 80.39 0.34 116.23 121.94 222.45 110.12 211.11z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_15', '1')
                                                .floorplanID,
                                            sBlockUnit('block_15', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_15'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M102.29 127.15l1.36-1.63 15.08 5.24-1.4 1.68-3.71-1.32-4.06 4.88 2 3.41-1.36 1.63-7.91-13.89zm9.53 3.29l-7-2.48 3.72 6.38 3.28-3.9z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M91 117.13l-16.3-14.24c-.33 2.41-.64 4.84-1 7.25l-2.67-2.34c.32-2.62.61-5.26.94-7.88l2-2.28 19.31 16.87-2.28 2.62z"
                                ></path>
                            </g>
                            <g transform="translate(67 116)">
                                <path
                                    fill={sBlockUnit('block_15', '2').colour}
                                    stroke={sBlockUnit('block_15', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_15', '2').width
                                    }
                                    opacity={
                                        sBlockUnit('block_15', '2').opacity
                                    }
                                    d="M54.95 35.94L29.45 14.32 0.17 47.21 153.75 181.37 213.6 111.88 207.94 106.45 86.34 0.23z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_15', '2')
                                                .floorplanID,
                                            sBlockUnit('block_15', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_15'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M119 91.85l1.35-1.63 15.09 5.25-1.4 1.68-3.71-1.32-4.06 4.87 2 3.42-1.36 1.63-7.91-13.9zm9.52 3.29l-7-2.48 3.73 6.39 3.27-3.91z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M99.86 85c0-1.29-1.14-10.58-1.15-12-.1-3.77-.77-6.32-2.64-7.95a4 4 0 00-6 .46c-2 2.32-1.75 4.47.14 6.71l-2.24 2.56c-2.78-3-3.82-7.22-.22-11.34 3.17-3.62 7.31-4 10.6-1.16 2.48 2.17 3.5 5.27 3.59 9.78 0 .91.9 8.3.89 9.27l7.69-8.8 2.37 2.06-10.78 12.35L99.86 85z"
                                ></path>
                            </g>
                            <g transform="translate(0 184)">
                                <path
                                    fill={sBlockUnit('block_15', '3').colour}
                                    stroke={sBlockUnit('block_15', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_15', '3').width
                                    }
                                    opacity={
                                        sBlockUnit('block_15', '3').opacity
                                    }
                                    d="M91.16 0.89L63.2 34.8 33.84 9.63 0.99 46.68 158.97 185.09 220.75 113.37z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_15', '3')
                                                .floorplanID,
                                            sBlockUnit('block_15', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_15'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M138.54 103.49l-3.55 4.26-11.47-9.56 3.42-4.1c2.21-2.66 4.49-3 6.29-1.51a3.29 3.29 0 011.17 3.68 3.54 3.54 0 014.26.36c2.08 1.73 2.08 4.22-.12 6.87zm-10.26-8.28l-2.11 2.53 3.48 2.9 2.23-2.67c1.2-1.44 1.4-2.67.11-3.74-1.29-1.07-2.46-.52-3.71.98zm5 3.8l-2.29 2.76 3.93 3.28 2.23-2.68c1.36-1.63 1.55-3 .16-4.18-1.39-1.18-2.7-.74-4.01.81l-.02.01z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M103.39 82.81c2.54 1.87 4.89 1.82 7.13-.75 2-2.31 2.09-4.86-.2-6.85-2.29-1.99-4.77-1.52-6.81.82l-1.46 1.68-2.34-2.05 1.51-1.73c1.85-2.12 1.88-4.35-.24-6.2-1.81-1.58-4.18-1.34-6 .75-2 2.28-2.08 4.47-.15 6.7l-2.24 2.56c-3.07-2.73-3.38-7.38.08-11.33 3.26-3.74 7.46-4.1 10.47-1.47a6 6 0 011.92 6.69 6.29 6.29 0 017.58.88c3.57 3.11 3.74 7.59.26 11.57-4 4.51-8.55 4-11.79 1.35l2.28-2.62z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M166.84 340l-5.85 6.81-18.34-15.74 5.63-6.55c3.65-4.26 7.35-4.76 10.21-2.3a5.29 5.29 0 011.81 6 5.72 5.72 0 016.9.68c3.32 2.82 3.27 6.85-.36 11.1zm-16.42-13.65l-3.48 4.05 5.57 4.79 3.68-4.29c2-2.29 2.33-4.27.26-6-2.07-1.73-3.97-.96-6.03 1.43v.02zm8 6.29l-3.79 4.41 6.29 5.4 3.68-4.28c2.24-2.61 2.57-4.87.35-6.78-2.22-1.91-4.34-1.28-6.48 1.23l-.05.02z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M157 314.28L159.19 311.73 175.26 325.53 183.12 316.37 185.42 318.37 175.37 330.08z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M194.15 309c-4.76 5.54-11.12 4.24-16.36-.25s-7.47-10.72-2.77-16.19c4.7-5.47 11-4.21 16.34.33s7.64 10.46 2.79 16.11zm-16.86-14.49c-3.59 4.18-1 8.46 2.74 11.63s8.32 5.08 11.88.93c3.56-4.15 1-8.37-2.76-11.56-3.76-3.19-8.34-5.15-11.86-1.05v.05z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M209.88 290.63c-4.7 5.47-10.92 4.11-16.21-.43-5.29-4.54-7.51-10.66-2.93-16 3.52-4.1 8.31-4.2 12.31-1.66l-2.21 2.58c-2.74-1.72-5.55-1.63-7.83 1-3.37 3.92-.84 8.23 2.9 11.44 3.74 3.21 8.38 5 11.72 1.11 2.42-2.81 2-5.65-.2-8l2.26-2.63c3.36 3.52 3.75 8.43.19 12.59z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M200.05 264.13L202.24 261.58 211.43 269.46 209.43 253.14 211.98 250.14 213.56 262.29 230.92 265.23 228.4 268.17 213.83 265.62 214.63 272.21 220.56 277.31 218.37 279.86z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M223.54 256L230.29 248.13 232.59 250.13 225.84 258z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M246.76 246.88l-15.09-13c-.29 2.22-.55 4.46-.84 6.67l-2.47-2.12c.27-2.41.52-4.84.79-7.25l1.82-2.11 17.88 15.35-2.09 2.46zM251.8 231c2.24 1.57 4.42 1.38 6.32-.84 1.9-2.22 1.5-4.77-1.06-7-2.24-1.93-4.7-2.24-6.76.15a5.31 5.31 0 00-1.33 4.73l-2 2.3-9.34-9 8.66-10.09 2.16 1.86-6.86 8 5.06 5a6.73 6.73 0 011.89-5c3.18-3.72 7.3-3.23 10.57-.42 3.82 3.28 4.36 7.5 1.15 11.24-3.21 3.74-7 4.1-10.55 1.43l2.09-2.36z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M166.84 340l-5.85 6.81-18.34-15.74 5.63-6.55c3.65-4.26 7.35-4.76 10.21-2.3a5.29 5.29 0 011.81 6 5.72 5.72 0 016.9.68c3.32 2.82 3.27 6.85-.36 11.1zm-16.42-13.65l-3.48 4.05 5.57 4.79 3.68-4.29c2-2.29 2.33-4.27.26-6-2.07-1.73-3.97-.96-6.03 1.43v.02zm8 6.29l-3.79 4.41 6.29 5.4 3.68-4.28c2.24-2.61 2.57-4.87.35-6.78-2.22-1.91-4.34-1.28-6.48 1.23l-.05.02z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M157 314.28L159.19 311.73 175.26 325.53 183.12 316.37 185.42 318.37 175.37 330.08z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M194.15 309c-4.76 5.54-11.12 4.24-16.36-.25s-7.47-10.72-2.77-16.19c4.7-5.47 11-4.21 16.34.33s7.64 10.46 2.79 16.11zm-16.86-14.49c-3.59 4.18-1 8.46 2.74 11.63s8.32 5.08 11.88.93c3.56-4.15 1-8.37-2.76-11.56-3.76-3.19-8.34-5.15-11.86-1.05v.05z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M209.88 290.63c-4.7 5.47-10.92 4.11-16.21-.43-5.29-4.54-7.51-10.66-2.93-16 3.52-4.1 8.31-4.2 12.31-1.66l-2.21 2.58c-2.74-1.72-5.55-1.63-7.83 1-3.37 3.92-.84 8.23 2.9 11.44 3.74 3.21 8.38 5 11.72 1.11 2.42-2.81 2-5.65-.2-8l2.26-2.63c3.36 3.52 3.75 8.43.19 12.59z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M200.05 264.13L202.24 261.58 211.43 269.46 209.43 253.14 211.98 250.14 213.56 262.29 230.92 265.23 228.4 268.17 213.83 265.62 214.63 272.21 220.56 277.31 218.37 279.86z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M223.54 256L230.29 248.13 232.59 250.13 225.84 258z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M246.76 246.88l-15.09-13c-.29 2.22-.55 4.46-.84 6.67l-2.47-2.12c.27-2.41.52-4.84.79-7.25l1.82-2.11 17.88 15.35-2.09 2.46zM251.8 231c2.24 1.57 4.42 1.38 6.32-.84 1.9-2.22 1.5-4.77-1.06-7-2.24-1.93-4.7-2.24-6.76.15a5.31 5.31 0 00-1.33 4.73l-2 2.3-9.34-9 8.66-10.09 2.16 1.86-6.86 8 5.06 5a6.73 6.73 0 011.89-5c3.18-3.72 7.3-3.23 10.57-.42 3.82 3.28 4.36 7.5 1.15 11.24-3.21 3.74-7 4.1-10.55 1.43l2.09-2.36z"
                            ></path>
                        </g>
                        <g
                            id="Block-14"
                            fillRule="nonzero"
                            transform="translate(1804 2140)"
                            style={{
                                display: sBlock('block_14').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(122)">
                                <path
                                    fill={sBlockUnit('block_14', '1').colour}
                                    stroke={sBlockUnit('block_14', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_14', '1').width
                                    }
                                    opacity={
                                        sBlockUnit('block_14', '1').opacity
                                    }
                                    d="M192.24 138.14L34.26 0.43 2.8 38.18 30.76 62.64 0.16 97.86 129.97 208.57z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_14', '1')
                                                .floorplanID,
                                            sBlockUnit('block_14', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_14'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M118.35 135.48l-3.62 4.2-11.31-9.74 3.48-4c2.26-2.63 4.54-2.93 6.31-1.41a3.27 3.27 0 011.11 3.7 3.55 3.55 0 014.26.43c2.05 1.72 2.01 4.21-.23 6.82zm-10.13-8.44l-2.15 2.49 3.43 3 2.28-2.64c1.22-1.41 1.44-2.64.17-3.73-1.27-1.09-2.46-.6-3.73.84v.04zm5 3.88l-2.34 2.72 3.88 3.35 2.28-2.64c1.38-1.61 1.59-3 .22-4.19-1.37-1.19-2.75-.78-4.08.76h.04z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M96.05 117.27L79.74 103c-.33 2.41-.64 4.84-1 7.25l-2.67-2.34c.32-2.62.61-5.26.94-7.88l2-2.28 19.31 16.87-2.27 2.65z"
                                ></path>
                            </g>
                            <g transform="translate(37 97)">
                                <path
                                    fill={sBlockUnit('block_14', '2').colour}
                                    stroke={sBlockUnit('block_14', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_14', '2').width
                                    }
                                    opacity={
                                        sBlockUnit('block_14', '2').opacity
                                    }
                                    d="M85.16 0.86L55.64 34.85 29.78 12.48 0.2 46.59 155.4 178.95 214.97 111.57z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_14', '2')
                                                .floorplanID,
                                            sBlockUnit('block_14', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_14'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M142.88 108.2l-3.61 4.2-11.32-9.74 3.49-4c2.26-2.62 4.54-2.93 6.31-1.41a3.29 3.29 0 011.11 3.7 3.55 3.55 0 014.26.43c2.05 1.73 2.01 4.21-.24 6.82zm-10.12-8.44l-2.16 2.5 3.44 2.95 2.27-2.64c1.22-1.41 1.45-2.63.17-3.73s-2.48-.56-3.72.92zm5 3.89l-2.34 2.72 3.88 3.34 2.27-2.64c1.39-1.61 1.6-3 .23-4.19-1.37-1.19-2.75-.78-4.08.77h.04z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M112.13 94.71c0-1.28-1.14-10.57-1.15-12-.1-3.77-.77-6.32-2.64-7.95a4 4 0 00-6 .46c-2 2.31-1.75 4.47.14 6.7l-2.24 2.56c-2.78-3-3.82-7.21-.22-11.34 3.17-3.62 7.31-4 10.6-1.16 2.48 2.17 3.5 5.28 3.59 9.78 0 .92.9 8.3.89 9.28l7.7-8.81 2.36 2.07-10.78 12.34-2.25-1.93z"
                                ></path>
                            </g>
                            <g transform="translate(0 143)">
                                <path
                                    fill={sBlockUnit('block_14', '3').colour}
                                    stroke={sBlockUnit('block_14', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_14', '3').width
                                    }
                                    opacity={
                                        sBlockUnit('block_14', '3').opacity
                                    }
                                    d="M7.36 34.98L33.23 55.25 0.37 93 127.6 206.24 192.4 132.95 37.2 0.59z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_14', '3')
                                                .floorplanID,
                                            sBlockUnit('block_14', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_14'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M118.83 133.78l-3.62 4.2-11.31-9.74 3.48-4c2.26-2.63 4.55-2.93 6.31-1.41a3.27 3.27 0 011.11 3.7 3.55 3.55 0 014.26.42c2.06 1.73 2.02 4.22-.23 6.83zm-10.13-8.45l-2.15 2.5 3.44 3 2.27-2.64c1.22-1.42 1.44-2.64.17-3.73-1.27-1.09-2.43-.61-3.73.87zm5 3.89l-2.34 2.72 3.88 3.34 2.27-2.64c1.39-1.6 1.59-3 .23-4.18-1.36-1.18-2.74-.78-4.07.76h.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M86 114.13c2.53 1.87 4.89 1.82 7.13-.74 2-2.32 2.09-4.86-.2-6.86s-4.77-1.51-6.81.83l-1.46 1.67-2.35-2.05 1.51-1.72c1.86-2.12 1.89-4.35-.23-6.2-1.81-1.59-4.18-1.35-6 .74-2 2.29-2.08 4.47-.15 6.7l-2.24 2.56c-3.07-2.73-3.38-7.37.08-11.33 3.26-3.73 7.46-4.1 10.47-1.47a6 6 0 011.92 6.69 6.32 6.32 0 017.58.88c3.57 3.12 3.74 7.59.26 11.57-4 4.52-8.55 4-11.79 1.35l2.28-2.62z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M165.2 288.37l-5.85 6.81L141 279.44l5.62-6.55c3.66-4.26 7.36-4.76 10.22-2.3a5.29 5.29 0 011.81 6 5.75 5.75 0 016.89.68c3.34 2.84 3.29 6.87-.34 11.1zm-16.43-13.65l-3.47 4.05 5.57 4.79 3.68-4.29c2-2.29 2.32-4.27.26-6-2.06-1.73-3.98-.95-6.04 1.45zm8 6.29l-3.78 4.41 6.29 5.4 3.68-4.28c2.23-2.61 2.57-4.88.35-6.78-2.22-1.9-4.31-1.26-6.49 1.24l-.05.01z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M155.36 262.67L157.55 260.12 173.62 273.91 181.48 264.76 183.78 266.76 173.72 278.47z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M192.51 257.33c-4.76 5.55-11.13 4.25-16.36-.24-5.23-4.49-7.47-10.72-2.78-16.19 4.69-5.47 11.06-4.21 16.35.32 5.29 4.53 7.6 10.52 2.79 16.11zm-16.87-14.48c-3.59 4.18-.94 8.46 2.74 11.63s8.32 5.08 11.89.93c3.57-4.15 1-8.37-2.76-11.56-3.76-3.19-8.35-5.1-11.87-1z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M208.24 239c-4.7 5.47-10.93 4.11-16.21-.43-5.28-4.54-7.52-10.66-2.93-16 3.52-4.1 8.31-4.2 12.31-1.66l-2.21 2.57c-2.74-1.71-5.55-1.62-7.83 1-3.37 3.92-.85 8.23 2.89 11.44 3.74 3.21 8.39 5 11.73 1.11 2.42-2.81 2-5.65-.2-8l2.26-2.63c3.35 3.6 3.75 8.47.19 12.6z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M198.41 212.52L200.6 209.97 209.78 217.85 207.84 201.53 210.39 198.53 211.97 210.68 229.33 213.62 226.81 216.56 212.24 214.01 213.03 220.6 218.97 225.69 216.77 228.25z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M221.9 204.37L228.65 196.5 230.95 198.5 224.19 206.37z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M245.12 195.27l-15.09-13c-.29 2.22-.55 4.46-.84 6.67l-2.48-2.12c.28-2.41.53-4.84.8-7.25l1.82-2.11 17.87 15.34-2.08 2.47z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M246.35 181.31l-4.5-18.29 2.1-2.45 11.4 9.79 2-2.28 2 1.73-1.95 2.27 4.42 3.79-2.13 2.48-4.41-3.79-7.11 8.28-1.82-1.53zm6.88-8.48l-8-6.88 3.11 12.61 4.89-5.73zM165.2 288.37l-5.85 6.81L141 279.44l5.62-6.55c3.66-4.26 7.36-4.76 10.22-2.3a5.29 5.29 0 011.81 6 5.75 5.75 0 016.89.68c3.34 2.84 3.29 6.87-.34 11.1zm-16.43-13.65l-3.47 4.05 5.57 4.79 3.68-4.29c2-2.29 2.32-4.27.26-6-2.06-1.73-3.98-.95-6.04 1.45zm8 6.29l-3.78 4.41 6.29 5.4 3.68-4.28c2.23-2.61 2.57-4.88.35-6.78-2.22-1.9-4.31-1.26-6.49 1.24l-.05.01z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M155.36 262.67L157.55 260.12 173.62 273.91 181.48 264.76 183.78 266.76 173.72 278.47z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M192.51 257.33c-4.76 5.55-11.13 4.25-16.36-.24-5.23-4.49-7.47-10.72-2.78-16.19 4.69-5.47 11.06-4.21 16.35.32 5.29 4.53 7.6 10.52 2.79 16.11zm-16.87-14.48c-3.59 4.18-.94 8.46 2.74 11.63s8.32 5.08 11.89.93c3.57-4.15 1-8.37-2.76-11.56-3.76-3.19-8.35-5.1-11.87-1z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M208.24 239c-4.7 5.47-10.93 4.11-16.21-.43-5.28-4.54-7.52-10.66-2.93-16 3.52-4.1 8.31-4.2 12.31-1.66l-2.21 2.57c-2.74-1.71-5.55-1.62-7.83 1-3.37 3.92-.85 8.23 2.89 11.44 3.74 3.21 8.39 5 11.73 1.11 2.42-2.81 2-5.65-.2-8l2.26-2.63c3.35 3.6 3.75 8.47.19 12.6z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M198.41 212.52L200.6 209.97 209.78 217.85 207.84 201.53 210.39 198.53 211.97 210.68 229.33 213.62 226.81 216.56 212.24 214.01 213.03 220.6 218.97 225.69 216.77 228.25z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M221.9 204.37L228.65 196.5 230.95 198.5 224.19 206.37z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M245.12 195.27l-15.09-13c-.29 2.22-.55 4.46-.84 6.67l-2.48-2.12c.28-2.41.53-4.84.8-7.25l1.82-2.11 17.87 15.34-2.08 2.47z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M246.35 181.31l-4.5-18.29 2.1-2.45 11.4 9.79 2-2.28 2 1.73-1.95 2.27 4.42 3.79-2.13 2.48-4.41-3.79-7.11 8.28-1.82-1.53zm6.88-8.48l-8-6.88 3.11 12.61 4.89-5.73z"
                            ></path>
                        </g>
                        <g
                            id="Block-13"
                            fillRule="nonzero"
                            transform="translate(1981 1650)"
                            style={{
                                display: sBlock('block_13').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(244 119)">
                                <path
                                    fill={sBlockUnit('block_13', '1').colour}
                                    stroke={sBlockUnit('block_13', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_13', '1').width
                                    }
                                    opacity={
                                        sBlockUnit('block_13', '1').opacity
                                    }
                                    d="M109.27 0.36L79.41 35.2 50.05 9.57 0.21 66.06 116.47 166.89 196.66 76.15z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_13', '1')
                                                .floorplanID,
                                            sBlockUnit('block_13', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_13'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M104.66 76.1l1.39-1.6 15 5.55-1.44 1.65-3.68-1.39-4.16 4.79 1.9 3.45-1.39 1.6-7.62-14.05zm9.46 3.49l-6.93-2.63 3.6 6.46 3.33-3.83z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M86.77 71.52c0-1.29-1.13-10.57-1.15-12-.1-3.78-.77-6.33-2.64-8a4 4 0 00-6.05.46c-2 2.32-1.74 4.47.14 6.71l-2.24 2.56c-2.78-3-3.82-7.22-.22-11.34 3.17-3.62 7.31-4 10.6-1.16 2.48 2.17 3.5 5.27 3.59 9.78 0 .91.91 8.3.89 9.27l7.7-8.8 2.36 2.07L89 73.44l-2.23-1.92z"
                                ></path>
                            </g>
                            <g transform="translate(353)">
                                <path
                                    fill={sBlockUnit('block_13', '2').colour}
                                    stroke={sBlockUnit('block_13', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_13', '2').width
                                    }
                                    opacity={
                                        sBlockUnit('block_13', '2').opacity
                                    }
                                    d="M170.8 101.07L57.56 0.88 5.83 59.13 31.93 82.43 0.27 119.36 87.66 195.15z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_13', '2')
                                                .floorplanID,
                                            sBlockUnit('block_13', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_13'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M76.09 102.47l1.39-1.6 15 5.55-1.44 1.65-3.68-1.39-4.16 4.79 1.9 3.45-1.39 1.6-7.62-14.05zm9.46 3.49l-6.93-2.63 3.59 6.46 3.34-3.83z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M67.07 93.89l-16.3-14.24c-.34 2.41-.65 4.84-1 7.25l-2.68-2.34c.32-2.61.62-5.26.94-7.87l2-2.29 19.31 16.87-2.27 2.62z"
                                ></path>
                            </g>
                            <g transform="translate(168 185)">
                                <path
                                    fill={sBlockUnit('block_13', '3').colour}
                                    stroke={sBlockUnit('block_13', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_13', '3').width
                                    }
                                    opacity={
                                        sBlockUnit('block_13', '3').opacity
                                    }
                                    d="M28.19 54.48L55.68 80.58 27.72 112.73 0.23 89.43 0.02 89.65 114.59 189.01 192.47 100.89 76.21 0.06z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_13', '3')
                                                .floorplanID,
                                            sBlockUnit('block_13', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_13'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M100.23 102.73l1.39-1.6 15 5.55-1.43 1.65-3.69-1.39-4.16 4.79 1.9 3.45-1.39 1.6-7.62-14.05zm9.46 3.49l-6.93-2.63 3.6 6.46 3.33-3.83z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M80.29 93.12c2.54 1.88 4.89 1.82 7.13-.74 2-2.31 2.09-4.86-.2-6.86s-4.77-1.51-6.81.83L79 88l-2.35-2 1.51-1.72c1.85-2.12 1.89-4.35-.23-6.2-1.81-1.59-4.18-1.35-6 .74-2 2.29-2.08 4.47-.15 6.7l-2.24 2.56c-3.07-2.73-3.38-7.37.08-11.33 3.26-3.73 7.46-4.1 10.47-1.47A6 6 0 0182 81.97a6.34 6.34 0 017.59.88c3.57 3.12 3.74 7.59.26 11.58-3.95 4.51-8.55 4-11.79 1.34l2.23-2.65z"
                                ></path>
                            </g>
                            <g transform="translate(112 274)">
                                <path
                                    fill={sBlockUnit('block_13', '4').colour}
                                    stroke={sBlockUnit('block_13', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_13', '4').width
                                    }
                                    opacity={
                                        sBlockUnit('block_13', '4').opacity
                                    }
                                    d="M4.03 56.82L31.99 82.45 0.06 118.28 87.46 194.08 170.59 100.01 56.02 0.65z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_13', '4')
                                                .floorplanID,
                                            sBlockUnit('block_13', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_13'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M75.8 106.36l1.39-1.6 15 5.55-1.43 1.65-3.69-1.39-4.15 4.79 1.89 3.45-1.39 1.6-7.62-14.05zm9.46 3.49l-6.93-2.63 3.6 6.46 3.33-3.83zM51.8 98.57l-4.73-20 2.31-2.65L61.7 86.69l2.14-2.46 2.18 1.9-2.15 2.45 4.77 4.17-2.34 2.67-4.76-4.16-7.82 8.94-1.92-1.63zm7.56-9.16l-8.66-7.57 3.26 13.76 5.4-6.19z"
                                ></path>
                            </g>
                            <g transform="translate(0 392)">
                                <path
                                    fill={sBlockUnit('block_13', '5').colour}
                                    stroke={sBlockUnit('block_13', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_13', '5').width
                                    }
                                    opacity={
                                        sBlockUnit('block_13', '5').opacity
                                    }
                                    d="M82.95 32.95L54.98 10.12 0.46 67.44 116.5 169.96 199.46 76.08 112.06 0.28z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_13', '5')
                                                .floorplanID,
                                            sBlockUnit('block_13', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_13'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M107.37 81l1.39-1.6 15 5.55-1.43 1.65-3.69-1.39-4.15 4.79 1.89 3.45L115 95l-7.63-14zm9.46 3.49l-6.92-2.62 3.59 6.45 3.33-3.83z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M87.1 71.07c2.42 1.72 4.79 1.54 6.89-.86 2.1-2.4 1.67-5.17-1.09-7.58-2.42-2.12-5.1-2.49-7.36.1a5.76 5.76 0 00-1.49 5.14l-2.17 2.47L71.8 60.4l9.52-10.89 2.34 2-7.55 8.64 5.46 5.51a7.36 7.36 0 012.1-5.39c3.51-4 8-3.45 11.51-.35 4.13 3.6 4.67 8.2 1.15 12.24-3.52 4.04-7.68 4.4-11.5 1.46l2.27-2.55z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M268.36 378.05l-5.92 6.74-18.16-16 5.71-6.48c3.7-4.22 7.4-4.67 10.24-2.18a5.3 5.3 0 011.73 6 5.75 5.75 0 016.89.76c3.3 2.94 3.15 6.97-.49 11.16zm-16.26-13.84l-3.52 4 5.51 4.85 3.73-4.24c2-2.27 2.38-4.25.34-6s-3.97-.98-6.06 1.39zm8 6.38l-3.84 4.36 6.23 5.48 3.73-4.24c2.26-2.58 2.62-4.84.43-6.77-2.19-1.93-4.4-1.31-6.57 1.17h.02z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M258.83 352.24L261.05 349.71 276.96 363.71 284.96 354.65 287.24 356.65 277 368.22z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M296 347.34c-4.82 5.49-11.17 4.11-16.36-.44-5.19-4.55-7.34-10.81-2.58-16.22 4.76-5.41 11.1-4.09 16.34.52 5.24 4.61 7.51 10.59 2.6 16.14zm-16.7-14.68c-3.63 4.13-1 8.45 2.61 11.66 3.61 3.21 8.26 5.17 11.87 1.06 3.61-4.11 1-8.35-2.62-11.58-3.62-3.23-8.25-5.2-11.82-1.14h-.04z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M312 329.21c-4.76 5.41-11 4-16.2-.62-5.2-4.62-7.39-10.75-2.75-16 3.57-4.06 8.36-4.1 12.34-1.51l-2.25 2.55c-2.71-1.75-5.53-1.69-7.84.94-3.41 3.88-.94 8.22 2.76 11.48 3.7 3.26 8.33 5.1 11.72 1.24 2.45-2.78 2-5.62-.11-8l2.29-2.61c3.29 3.5 3.63 8.41.04 12.53z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M302.46 302.6L304.69 300.07 313.78 308.07 312.03 291.73 314.61 288.79 316 301 333.33 304.15 330.77 307.06 316.24 304.33 316.95 310.94 322.83 316.1 320.6 318.63z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M326.05 294.72L332.89 286.93 335.17 288.93 328.32 296.71z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M349.38 285.89l-14.94-13.13c-.32 2.21-.61 4.45-.92 6.66l-2.45-2.15c.3-2.41.58-4.83.88-7.24l1.84-2.09 17.7 15.55-2.11 2.4zM354.1 270.22c2.33 1.73 4.49 1.68 6.56-.67 1.86-2.11 1.93-4.46-.16-6.3-2.09-1.84-4.38-1.4-6.27.74l-1.35 1.54-2.14-1.89 1.39-1.58c1.71-1.94 1.74-4-.2-5.7-1.66-1.46-3.84-1.24-5.52.67-1.84 2.09-1.93 4.1-.16 6.15l-2.06 2.35c-2.81-2.51-3.09-6.79.1-10.41 3-3.42 6.87-3.74 9.62-1.32a5.49 5.49 0 011.75 6.15 5.8 5.8 0 017 .83c3.27 2.87 3.42 7 .21 10.63-3.64 4.14-7.86 3.64-10.84 1.21l2.07-2.4z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M268.36 378.05l-5.92 6.74-18.16-16 5.71-6.48c3.7-4.22 7.4-4.67 10.24-2.18a5.3 5.3 0 011.73 6 5.75 5.75 0 016.89.76c3.3 2.94 3.15 6.97-.49 11.16zm-16.26-13.84l-3.52 4 5.51 4.85 3.73-4.24c2-2.27 2.38-4.25.34-6s-3.97-.98-6.06 1.39zm8 6.38l-3.84 4.36 6.23 5.48 3.73-4.24c2.26-2.58 2.62-4.84.43-6.77-2.19-1.93-4.4-1.31-6.57 1.17h.02z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M258.83 352.24L261.05 349.71 276.96 363.71 284.96 354.65 287.24 356.65 277 368.22z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M296 347.34c-4.82 5.49-11.17 4.11-16.36-.44-5.19-4.55-7.34-10.81-2.58-16.22 4.76-5.41 11.1-4.09 16.34.52 5.24 4.61 7.51 10.59 2.6 16.14zm-16.7-14.68c-3.63 4.13-1 8.45 2.61 11.66 3.61 3.21 8.26 5.17 11.87 1.06 3.61-4.11 1-8.35-2.62-11.58-3.62-3.23-8.25-5.2-11.82-1.14h-.04z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M312 329.21c-4.76 5.41-11 4-16.2-.62-5.2-4.62-7.39-10.75-2.75-16 3.57-4.06 8.36-4.1 12.34-1.51l-2.25 2.55c-2.71-1.75-5.53-1.69-7.84.94-3.41 3.88-.94 8.22 2.76 11.48 3.7 3.26 8.33 5.1 11.72 1.24 2.45-2.78 2-5.62-.11-8l2.29-2.61c3.29 3.5 3.63 8.41.04 12.53z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M302.46 302.6L304.69 300.07 313.78 308.07 312.03 291.73 314.61 288.79 316 301 333.33 304.15 330.77 307.06 316.24 304.33 316.95 310.94 322.83 316.1 320.6 318.63z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M326.05 294.72L332.89 286.93 335.17 288.93 328.32 296.71z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M349.38 285.89l-14.94-13.13c-.32 2.21-.61 4.45-.92 6.66l-2.45-2.15c.3-2.41.58-4.83.88-7.24l1.84-2.09 17.7 15.55-2.11 2.4zM354.1 270.22c2.33 1.73 4.49 1.68 6.56-.67 1.86-2.11 1.93-4.46-.16-6.3-2.09-1.84-4.38-1.4-6.27.74l-1.35 1.54-2.14-1.89 1.39-1.58c1.71-1.94 1.74-4-.2-5.7-1.66-1.46-3.84-1.24-5.52.67-1.84 2.09-1.93 4.1-.16 6.15l-2.06 2.35c-2.81-2.51-3.09-6.79.1-10.41 3-3.42 6.87-3.74 9.62-1.32a5.49 5.49 0 011.75 6.15 5.8 5.8 0 017 .83c3.27 2.87 3.42 7 .21 10.63-3.64 4.14-7.86 3.64-10.84 1.21l2.07-2.4z"
                            ></path>
                        </g>
                        <g
                            id="Block-12"
                            fillRule="nonzero"
                            transform="translate(2418 1070)"
                            style={{
                                display: sBlock('block_12').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(452)">
                                <path
                                    fill={sBlockUnit('block_12', '1').colour}
                                    stroke={sBlockUnit('block_12', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_12', '1').width
                                    }
                                    opacity={
                                        sBlockUnit('block_12', '1').opacity
                                    }
                                    d="M192.27 140.72L30.8 0.22 0.39 35.87 30.8 59.98 0.45 94.38 132.89 207.75z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_12', '1')
                                                .floorplanID,
                                            sBlockUnit('block_12', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_12'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M109 126.27l-3.68 4.14-11.15-9.92 3.54-4c2.3-2.59 4.59-2.86 6.33-1.31a3.26 3.26 0 011.05 3.72 3.53 3.53 0 014.25.49c1.98 1.82 1.9 4.31-.34 6.88zm-10-8.6l-2.19 2.46 3.39 3 2.32-2.6c1.24-1.4 1.48-2.62.22-3.73-1.26-1.11-2.48-.59-3.74.87zm4.9 4l-2.38 2.69 3.82 3.4 2.32-2.6c1.41-1.59 1.64-3 .29-4.18-1.35-1.18-2.73-.87-4.09.65l.04.04z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M85.27 101.6L69 87.36c-.33 2.41-.64 4.84-1 7.25l-2.67-2.34c.32-2.62.62-5.26.94-7.88l2-2.28 19.31 16.87-2.31 2.62z"
                                ></path>
                            </g>
                            <g transform="translate(365 94)">
                                <path
                                    fill={sBlockUnit('block_12', '2').colour}
                                    stroke={sBlockUnit('block_12', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_12', '2').width
                                    }
                                    opacity={
                                        sBlockUnit('block_12', '2').opacity
                                    }
                                    d="M87.45 0.38L54.89 37.29 27.62 15.27 0.29 49.3 157.66 184 219.89 113.75z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_12', '2')
                                                .floorplanID,
                                            sBlockUnit('block_12', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_12'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M135.17 101.09c-3 3.32-6.79 2.42-10-.44s-4.52-6.67-1.63-9.92a5.55 5.55 0 017.62-.89l-1.4 1.57a3.43 3.43 0 00-4.84.55c-2.12 2.39-.61 5.08 1.66 7.1 2.27 2.02 5.12 3.18 7.23.81a3.41 3.41 0 000-4.95l1.42-1.6a5.51 5.51 0 01-.06 7.77zM102.6 82.94c0-1.29-1.14-10.57-1.15-12-.1-3.77-.77-6.32-2.64-8a4 4 0 00-6 .46c-2 2.32-1.74 4.47.14 6.71l-2.24 2.56c-2.78-3-3.82-7.22-.22-11.34 3.17-3.62 7.31-4 10.6-1.16 2.48 2.17 3.5 5.27 3.59 9.77 0 .92.91 8.31.89 9.28l7.7-8.8 2.36 2.06-10.78 12.35-2.25-1.89z"
                                ></path>
                            </g>
                            <g transform="translate(331 143)">
                                <path
                                    fill={sBlockUnit('block_12', '3').colour}
                                    stroke={sBlockUnit('block_12', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_12', '3').width
                                    }
                                    opacity={
                                        sBlockUnit('block_12', '3').opacity
                                    }
                                    d="M6.05 35.47L33.31 56.44 0.22 92.85 130.33 204.22 191.66 135 34.29 0.3z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_12', '3')
                                                .floorplanID,
                                            sBlockUnit('block_12', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_12'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M108.13 120.23l-3.68 4.15-11.16-9.93 3.55-4c2.3-2.59 4.59-2.86 6.33-1.31a3.26 3.26 0 011 3.72 3.54 3.54 0 014.25.49c2.08 1.82 2 4.31-.29 6.88zm-10-8.6l-2.19 2.46 3.39 3 2.32-2.6c1.24-1.4 1.48-2.61.22-3.73s-2.43-.59-3.73.87h-.01zm4.9 4l-2.38 2.69 3.82 3.4 2.32-2.6c1.41-1.59 1.64-3 .29-4.18-1.35-1.18-2.68-.87-4.08.65l.03.04z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M73.66 101c2.53 1.88 4.89 1.82 7.13-.74 2-2.31 2.08-4.86-.2-6.86-2.28-2-4.77-1.51-6.82.83l-1.46 1.67-2.34-2 1.51-1.73c1.85-2.12 1.89-4.35-.23-6.2-1.81-1.58-4.18-1.34-6 .74-2 2.29-2.08 4.47-.15 6.7l-2.24 2.57c-3.07-2.73-3.38-7.38.07-11.34 3.27-3.73 7.46-4.09 10.47-1.46a6 6 0 011.92 6.69 6.32 6.32 0 017.59.87c3.57 3.12 3.74 7.59.26 11.58-3.95 4.51-8.55 4-11.79 1.34l2.28-2.66z"
                                ></path>
                            </g>
                            <g transform="translate(248 235)">
                                <path
                                    fill={sBlockUnit('block_12', '4').colour}
                                    stroke={sBlockUnit('block_12', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_12', '4').width
                                    }
                                    opacity={
                                        sBlockUnit('block_12', '4').opacity
                                    }
                                    d="M53.4 33.64L31.38 15.82 0.97 50.57 152.81 180.54 213.33 112.22 83.22 0.85z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_12', '4')
                                                .floorplanID,
                                            sBlockUnit('block_12', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_12'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M130.22 96.71l-3.68 4.15-11.16-9.93 3.55-4c2.3-2.59 4.59-2.86 6.33-1.31a3.26 3.26 0 011 3.72 3.54 3.54 0 014.25.49c2.08 1.82 2 4.31-.29 6.88zm-10-8.6l-2.19 2.46 3.39 3 2.32-2.6c1.24-1.4 1.48-2.61.22-3.73s-2.43-.59-3.73.87h-.01zm4.9 4l-2.38 2.69 3.82 3.4 2.32-2.6c1.41-1.59 1.64-3 .29-4.18-1.35-1.18-2.68-.87-4.04.65l-.01.04zM89.28 82.38l-4.73-20 2.32-2.64L99.18 70.5l2.14-2.46 2.18 1.9-2.15 2.46 4.77 4.16-2.34 2.67-4.76-4.16-7.82 9-1.92-1.69zm7.56-9.17l-8.66-7.57 3.26 13.76 5.4-6.19z"
                                ></path>
                            </g>
                            <g transform="translate(209 285)">
                                <path
                                    fill={sBlockUnit('block_12', '5').colour}
                                    stroke={sBlockUnit('block_12', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_12', '5').width
                                    }
                                    opacity={
                                        sBlockUnit('block_12', '5').opacity
                                    }
                                    d="M11.66 32.93L32.63 51.8 0.83 87.37 131.19 198.96 191.81 130.54 39.97 0.57z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_12', '5')
                                                .floorplanID,
                                            sBlockUnit('block_12', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_12'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M108.31 115.19l-3.68 4.15-11.16-9.93 3.55-4c2.3-2.59 4.59-2.86 6.33-1.31a3.26 3.26 0 011.05 3.72 3.56 3.56 0 014.25.49c2.03 1.82 1.95 4.31-.34 6.88zm-10-8.6l-2.19 2.46 3.39 3 2.31-2.61c1.25-1.4 1.49-2.61.23-3.73s-2.43-.58-3.73.88h-.01zm4.9 4l-2.38 2.68 3.82 3.4 2.32-2.6c1.41-1.59 1.64-3 .29-4.18-1.35-1.18-2.68-.89-4.04.67l-.01.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M73.3 98c2.43 1.73 4.8 1.54 6.89-.86s1.68-5.17-1.08-7.58c-2.43-2.12-5.1-2.49-7.37.1a5.81 5.81 0 00-1.49 5.14l-2.16 2.48L58 87.34l9.52-10.9 2.34 2-7.54 8.64 5.46 5.5a7.3 7.3 0 012.1-5.38c3.5-4 8-3.45 11.51-.36 4.13 3.6 4.67 8.21 1.14 12.25-3.53 4.04-7.67 4.4-11.49 1.45L73.3 98z"
                                ></path>
                            </g>
                            <g transform="translate(121 372)">
                                <path
                                    fill={sBlockUnit('block_12', '6').colour}
                                    stroke={sBlockUnit('block_12', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_12', '6').width
                                    }
                                    opacity={
                                        sBlockUnit('block_12', '6').opacity
                                    }
                                    d="M58.77 34L29.41 11.98 0.73 43.79 159.32 179.54 219.19 111.96 88.83 0.37z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_12', '6')
                                                .floorplanID,
                                            sBlockUnit('block_12', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_12'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M134.79 97.36l-3.68 4.14-11.15-9.92 3.54-4c2.3-2.59 4.59-2.86 6.33-1.31a3.25 3.25 0 011.05 3.71 3.54 3.54 0 014.25.5c2.03 1.82 1.95 4.31-.34 6.88zm-10-8.61l-2.19 2.47 3.39 3 2.32-2.6c1.24-1.4 1.48-2.62.22-3.73-1.26-1.11-2.43-.6-3.73.86h-.01zm4.9 4l-2.38 2.68 3.82 3.41 2.32-2.61c1.41-1.58 1.64-3 .29-4.18-1.35-1.18-2.68-.85-4.04.67l-.01.03zM111.39 79.78c-4.65 5.32-10.67 2.42-15.18-1.52-6.41-5.6-9.16-11.35-4.75-16.39 3.19-3.65 7-3.62 10.12-1.52l-2.24 2.56a4.05 4.05 0 00-5.67 1c-2.85 3.26-.39 7.08 3.44 10.57a7.07 7.07 0 011.7-6.57c3.53-4 7.91-3.7 11.7-.39 3.79 3.31 4.49 8.13.88 12.26zm-10.74-9.59c-1.9 2.18-1.83 5.19.76 7.45 2.34 2 5.53 2.72 7.75.19 2.22-2.53 1.65-5.49-.91-7.73-2.35-2.04-5.25-2.64-7.6.09z"
                                ></path>
                            </g>
                            <g transform="translate(90 415)">
                                <path
                                    fill={sBlockUnit('block_12', '7').colour}
                                    stroke={sBlockUnit('block_12', '7').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_12', '7').width
                                    }
                                    opacity={
                                        sBlockUnit('block_12', '7').opacity
                                    }
                                    d="M2.74 32.94L32.1 59.16 0.08 94.38 129.51 205.18 190.32 136.54 31.73 0.79z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_12', '7')
                                                .floorplanID,
                                            sBlockUnit('block_12', '7')
                                                .unit_elevation.value,
                                            7,
                                            'block_12'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M105 123.18c-3 3.32-6.79 2.42-10-.45s-4.52-6.66-1.63-9.91a5.55 5.55 0 017.62-.89l-1.4 1.57a3.42 3.42 0 00-4.84.55c-2.13 2.38-.61 5.08 1.66 7.1 2.27 2.02 5.12 3.18 7.23.81a3.41 3.41 0 000-5l1.42-1.6a5.51 5.51 0 01-.06 7.82zM79.13 108.34a245.41 245.41 0 01-10.49-22.19l-8.86 10.14-2.37-2.07 11.34-13 2.21 1.92a216.1 216.1 0 0010.68 22.31l-2.51 2.89z"
                                ></path>
                            </g>
                            <g transform="translate(0 509)">
                                <path
                                    fill={sBlockUnit('block_12', '8').colour}
                                    stroke={sBlockUnit('block_12', '8').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_12', '8').width
                                    }
                                    opacity={
                                        sBlockUnit('block_12', '8').opacity
                                    }
                                    d="M59.19 34.36L30.88 10.25 0.47 44.85 155.65 183.26 219.51 111.18 90.08 0.38z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_12', '8')
                                                .floorplanID,
                                            sBlockUnit('block_12', '8')
                                                .unit_elevation.value,
                                            8,
                                            'block_12'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M132.91 98.52l-3.68 4.14-11.16-9.92 3.54-4c2.31-2.59 4.6-2.85 6.34-1.3a3.27 3.27 0 011.05 3.71 3.55 3.55 0 014.25.5c2.02 1.81 1.95 4.29-.34 6.87zm-10-8.61l-2.19 2.46 3.39 3 2.31-2.61c1.24-1.39 1.48-2.61.23-3.73s-2.44-.58-3.73.88h-.01zm4.9 4l-2.39 2.68 3.83 3.4 2.32-2.6c1.41-1.58 1.64-3 .29-4.18-1.35-1.18-2.69-.85-4.04.67l-.01.03zM93.59 71.72c-3.26-2.84-3.26-7.12.12-11s7.57-4.45 10.86-1.57a6.24 6.24 0 012 6.6 6.75 6.75 0 017.56.86c3.59 3.14 3.38 7.71-.18 11.78-3.56 4.07-8.06 4.9-11.65 1.76a6.74 6.74 0 01-1.83-7.35 6.34 6.34 0 01-6.88-1.08zm8.85-3.37c2.11-2.43 2-4.75-.06-6.58-1.86-1.63-4.32-1.57-6.46.88-2.14 2.45-1.85 4.92 0 6.56 2.08 1.79 4.42 1.54 6.52-.86zm9.3 8.08c2.29-2.62 2.21-5.34 0-7.24-2.21-1.9-4.68-1.68-7.09 1.08s-2.21 5.39-.15 7.19c2.25 1.93 4.93 1.61 7.24-1.03z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M345.8 461.18l-5.92 6.74-18.16-16 5.71-6.48c3.7-4.21 7.4-4.67 10.24-2.18a5.3 5.3 0 011.73 6 5.75 5.75 0 016.89.76c3.3 2.98 3.2 6.98-.49 11.16zm-16.26-13.84l-3.52 4 5.52 4.85 3.72-4.24c2-2.27 2.38-4.25.34-6s-3.97-.95-6.06 1.39zm8 6.38l-3.84 4.36 6.23 5.48 3.73-4.24c2.26-2.58 2.63-4.84.43-6.77-2.2-1.93-4.39-1.31-6.57 1.17h.02z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M336.27 435.37L338.49 432.84 354.4 446.84 362.4 437.77 364.67 439.77 354.48 451.36z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M373.48 430.47c-4.82 5.49-11.17 4.11-16.36-.44-5.19-4.55-7.34-10.8-2.58-16.22 4.76-5.42 11.1-4.08 16.34.52s7.48 10.6 2.6 16.14zm-16.69-14.68c-3.64 4.13-1.05 8.45 2.6 11.66 3.65 3.21 8.26 5.18 11.87 1.07 3.61-4.11 1-8.36-2.62-11.59-3.62-3.23-8.29-5.2-11.85-1.14z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M389.42 412.34c-4.76 5.41-11 4-16.2-.62-5.2-4.62-7.39-10.75-2.74-16 3.56-4.06 8.36-4.1 12.33-1.51l-2.25 2.55c-2.71-1.75-5.52-1.69-7.84.94-3.41 3.88-.94 8.22 2.77 11.48s8.32 5.1 11.71 1.24c2.45-2.78 2.05-5.62-.11-8l2.29-2.61c3.32 3.5 3.62 8.42.04 12.53z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M379.91 385.73L382.13 383.2 391.22 391.2 389.47 374.86 392.05 371.92 393.48 384.09 410.81 387.24 408.25 390.15 393.72 387.43 394.44 394.03 400.31 399.19 398.09 401.72z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M403.49 377.85L410.34 370.06 412.61 372.06 405.76 379.85z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M426.82 369l-14.94-13.13c-.31 2.21-.6 4.45-.92 6.66l-2.45-2.15c.3-2.41.58-4.83.88-7.24l1.84-2.09 17.7 15.55-2.11 2.4zM433 357.9c0-1.18-1-9.71-1-11-.07-3.46-.68-5.81-2.39-7.31a3.67 3.67 0 00-5.57.41c-1.86 2.12-1.61 4.1.11 6.16l-2.06 2.35c-2.54-2.78-3.49-6.65-.17-10.42 2.92-3.32 6.72-3.69 9.74-1 2.27 2 3.2 4.85 3.28 9 0 .84.8 7.63.78 8.53l7.1-8.07 2.17 1.9-9.94 11.32-2.05-1.87z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M345.8 461.18l-5.92 6.74-18.16-16 5.71-6.48c3.7-4.21 7.4-4.67 10.24-2.18a5.3 5.3 0 011.73 6 5.75 5.75 0 016.89.76c3.3 2.98 3.2 6.98-.49 11.16zm-16.26-13.84l-3.52 4 5.52 4.85 3.72-4.24c2-2.27 2.38-4.25.34-6s-3.97-.95-6.06 1.39zm8 6.38l-3.84 4.36 6.23 5.48 3.73-4.24c2.26-2.58 2.63-4.84.43-6.77-2.2-1.93-4.39-1.31-6.57 1.17h.02z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M336.27 435.37L338.49 432.84 354.4 446.84 362.4 437.77 364.67 439.77 354.48 451.36z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M373.48 430.47c-4.82 5.49-11.17 4.11-16.36-.44-5.19-4.55-7.34-10.8-2.58-16.22 4.76-5.42 11.1-4.08 16.34.52s7.48 10.6 2.6 16.14zm-16.69-14.68c-3.64 4.13-1.05 8.45 2.6 11.66 3.65 3.21 8.26 5.18 11.87 1.07 3.61-4.11 1-8.36-2.62-11.59-3.62-3.23-8.29-5.2-11.85-1.14z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M389.42 412.34c-4.76 5.41-11 4-16.2-.62-5.2-4.62-7.39-10.75-2.74-16 3.56-4.06 8.36-4.1 12.33-1.51l-2.25 2.55c-2.71-1.75-5.52-1.69-7.84.94-3.41 3.88-.94 8.22 2.77 11.48s8.32 5.1 11.71 1.24c2.45-2.78 2.05-5.62-.11-8l2.29-2.61c3.32 3.5 3.62 8.42.04 12.53z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M379.91 385.73L382.13 383.2 391.22 391.2 389.47 374.86 392.05 371.92 393.48 384.09 410.81 387.24 408.25 390.15 393.72 387.43 394.44 394.03 400.31 399.19 398.09 401.72z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M403.49 377.85L410.34 370.06 412.61 372.06 405.76 379.85z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M426.82 369l-14.94-13.13c-.31 2.21-.6 4.45-.92 6.66l-2.45-2.15c.3-2.41.58-4.83.88-7.24l1.84-2.09 17.7 15.55-2.11 2.4zM433 357.9c0-1.18-1-9.71-1-11-.07-3.46-.68-5.81-2.39-7.31a3.67 3.67 0 00-5.57.41c-1.86 2.12-1.61 4.1.11 6.16l-2.06 2.35c-2.54-2.78-3.49-6.65-.17-10.42 2.92-3.32 6.72-3.69 9.74-1 2.27 2 3.2 4.85 3.28 9 0 .84.8 7.63.78 8.53l7.1-8.07 2.17 1.9-9.94 11.32-2.05-1.87z"
                            ></path>
                        </g>
                        <g
                            id="Block-11"
                            fillRule="nonzero"
                            transform="translate(3003 905)"
                            style={{
                                display: sBlock('block_11').active ? '' : 'none'
                            }}
                        >
                            <path
                                fill={sBlockUnit('block_11', '1').colour}
                                stroke={sBlockUnit('block_11', '1').stroke}
                                strokeWidth={sBlockUnit('block_11', '1').width}
                                opacity={sBlockUnit('block_11', '1').opacity}
                                d="M432.52 63.89L359.98 0.27 338.57 25.24 324.89 13.95 300.52 41.89 307.06 47.84 275.51 82.75 354.41 152.8z"
                                onClick={() =>
                                    unit_clicked(
                                        sBlockUnit('block_11', '1').floorplanID,
                                        sBlockUnit('block_11', '1')
                                            .unit_elevation.value,
                                        1,
                                        'block_11'
                                    )
                                }
                            ></path>
                            <path
                                fill="#FFF"
                                d="M370.45 85.83l1.41-1.59 14.91 5.74-1.45 1.63-3.67-1.44-4.22 4.74 1.86 3.47-1.41 1.59-7.43-14.14zm9.41 3.6l-6.89-2.71 3.52 6.5 3.37-3.79z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M357.69 75.83l-16.3-14.24c-.33 2.41-.64 4.84-1 7.25l-2.67-2.34c.32-2.61.61-5.26.94-7.87l2-2.29L360 73.21l-2.31 2.62z"
                            ></path>
                            <path
                                fill={sBlockUnit('block_11', '2').colour}
                                stroke={sBlockUnit('block_11', '2').stroke}
                                strokeWidth={sBlockUnit('block_11', '2').width}
                                opacity={sBlockUnit('block_11', '2').opacity}
                                d="M258.88 67.98L226.79 104.92 194.68 76.97 169.11 106.7 296.58 218.63 354.41 152.8z"
                                onClick={() =>
                                    unit_clicked(
                                        sBlockUnit('block_11', '2').floorplanID,
                                        sBlockUnit('block_11', '2')
                                            .unit_elevation.value,
                                        2,
                                        'block_11'
                                    )
                                }
                            ></path>
                            <path
                                fill="#FFF"
                                d="M275.66 143l1.41-1.58 14.91 5.73-1.45 1.63-3.67-1.44-4.21 4.74 1.85 3.48-1.41 1.58-7.43-14.14zm9.42 3.6l-6.9-2.7 3.52 6.5 3.38-3.8z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M251.09 132.87c0-1.29-1.13-10.57-1.15-12-.1-3.77-.77-6.32-2.64-7.95a4 4 0 00-6.05.46c-2 2.32-1.75 4.47.14 6.71l-2.24 2.56c-2.78-3-3.82-7.21-.22-11.34 3.17-3.62 7.31-4 10.6-1.16 2.48 2.17 3.5 5.27 3.59 9.78 0 .91.9 8.3.89 9.27l7.7-8.8 2.36 2.07-10.78 12.34-2.2-1.94z"
                            ></path>
                            <path
                                fill={sBlockUnit('block_11', '3').colour}
                                stroke={sBlockUnit('block_11', '3').stroke}
                                strokeWidth={sBlockUnit('block_11', '3').width}
                                opacity={sBlockUnit('block_11', '3').opacity}
                                d="M169.11 106.7L122.73 66.27 94.19 99.57 115.59 117.41 84.88 152.94 236.69 286.79 296.58 218.63z"
                                onClick={() =>
                                    unit_clicked(
                                        sBlockUnit('block_11', '3').floorplanID,
                                        sBlockUnit('block_11', '3')
                                            .unit_elevation.value,
                                        3,
                                        'block_11'
                                    )
                                }
                            ></path>
                            <path
                                fill="#FFF"
                                d="M214.79 210.6l1.41-1.59 14.91 5.73-1.45 1.63-3.67-1.43-4.22 4.74 1.86 3.47-1.41 1.59-7.43-14.14zm9.41 3.59l-6.89-2.7 3.52 6.5 3.37-3.8z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M189.4 196.67c2.54 1.87 4.9 1.82 7.14-.74 2-2.32 2.08-4.87-.2-6.86-2.28-1.99-4.77-1.52-6.82.83l-1.46 1.67-2.34-2 1.51-1.73c1.85-2.11 1.88-4.35-.23-6.2-1.82-1.58-4.19-1.34-6 .75-2 2.29-2.09 4.47-.15 6.7l-2.24 2.56c-3.07-2.73-3.39-7.38.07-11.33 3.26-3.74 7.46-4.1 10.47-1.47a6 6 0 011.92 6.69 6.31 6.31 0 017.59.88c3.56 3.11 3.73 7.59.25 11.57-3.94 4.52-8.54 4-11.79 1.35l2.28-2.67z"
                            ></path>
                            <path
                                fill={sBlockUnit('block_11', '4').colour}
                                stroke={sBlockUnit('block_11', '4').stroke}
                                strokeWidth={sBlockUnit('block_11', '4').width}
                                opacity={sBlockUnit('block_11', '4').opacity}
                                d="M35.32 173.89L31.76 170.92 0.84 207.19 174.46 357.63 236.69 286.79 66.84 137.03z"
                                onClick={() =>
                                    unit_clicked(
                                        sBlockUnit('block_11', '4').floorplanID,
                                        sBlockUnit('block_11', '4')
                                            .unit_elevation.value,
                                        4,
                                        'block_11'
                                    )
                                }
                            ></path>
                            <path
                                fill="#FFF"
                                d="M167.15 287.27l-3.68 4.14-11.15-9.92 3.54-4c2.3-2.58 4.59-2.85 6.33-1.3a3.24 3.24 0 011 3.71 3.56 3.56 0 014.26.5c2.07 1.81 1.99 4.3-.3 6.87zm-10-8.61l-2.19 2.47 3.39 3 2.32-2.6c1.24-1.4 1.48-2.62.22-3.74s-2.43-.59-3.73.87h-.01zm4.9 4l-2.38 2.68 3.83 3.41 2.31-2.61c1.41-1.58 1.64-3 .29-4.18-1.35-1.18-2.68-.85-4.04.67l-.01.03zM125.71 267.32l-4.73-20 2.31-2.64 12.32 10.76 2.14-2.45 2.18 1.89-2.14 2.46 4.76 4.16-2.34 2.68-4.76-4.17-7.82 9-1.92-1.69zm7.57-9.17l-8.67-7.57 3.26 13.76 5.41-6.19zM210.07 302.44l-5.93 6.74-18.15-16 5.7-6.49c3.7-4.21 7.41-4.67 10.24-2.18a5.3 5.3 0 011.74 6 5.75 5.75 0 016.89.76c3.29 2.95 3.19 6.98-.49 11.17zm-16.26-13.84l-3.52 4 5.51 4.85 3.73-4.24c2-2.27 2.37-4.24.33-6-2.04-1.76-3.96-.99-6.05 1.39zm8 6.37l-3.83 4.37 6.23 5.47 3.72-4.23c2.27-2.58 2.63-4.85.43-6.78-2.2-1.93-4.36-1.31-6.58 1.2l.03-.03z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M200.53 276.62L202.75 274.09 218.66 288.09 226.66 279.02 228.93 281.02 218.74 292.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M237.75 271.72c-4.83 5.49-11.18 4.12-16.36-.44-5.18-4.56-7.35-10.8-2.59-16.21s11.11-4.09 16.34.51 7.48 10.6 2.61 16.14zm-16.7-14.68c-3.64 4.14-1.05 8.45 2.61 11.66 3.66 3.21 8.25 5.18 11.87 1.07 3.62-4.11 1-8.36-2.63-11.59-3.63-3.23-8.28-5.18-11.85-1.18v.04z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M253.69 253.59c-4.76 5.41-11 4-16.21-.62-5.21-4.62-7.39-10.75-2.74-16 3.57-4.06 8.36-4.11 12.33-1.52l-2.24 2.55c-2.72-1.75-5.53-1.69-7.84.94-3.41 3.88-.94 8.23 2.76 11.48 3.7 3.25 8.33 5.1 11.72 1.25 2.44-2.79 2-5.63-.11-8l2.29-2.6c3.35 3.49 3.65 8.41.04 12.52z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M244.17 227L246.39 224.47 255.48 232.47 253.73 216.13 256.31 213.2 257.75 225.36 275.08 228.51 272.52 231.42 257.99 228.7 258.7 235.3 264.57 240.46 262.35 242.99z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M267.75 219.1L274.6 211.31 276.87 213.31 270.03 221.1z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M291.08 210.28l-14.93-13.13c-.32 2.21-.61 4.44-.92 6.65l-2.46-2.15c.31-2.4.58-4.83.89-7.23l1.84-2.1 17.69 15.56-2.11 2.4zM304.12 195.44l-14.93-13.13c-.32 2.21-.61 4.45-.92 6.66l-2.45-2.16c.3-2.4.58-4.83.88-7.23l1.84-2.1 17.7 15.56-2.12 2.4z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M210.07 302.44l-5.93 6.74-18.15-16 5.7-6.49c3.7-4.21 7.41-4.67 10.24-2.18a5.3 5.3 0 011.74 6 5.75 5.75 0 016.89.76c3.29 2.95 3.19 6.98-.49 11.17zm-16.26-13.84l-3.52 4 5.51 4.85 3.73-4.24c2-2.27 2.37-4.24.33-6-2.04-1.76-3.96-.99-6.05 1.39zm8 6.37l-3.83 4.37 6.23 5.47 3.72-4.23c2.27-2.58 2.63-4.85.43-6.78-2.2-1.93-4.36-1.31-6.58 1.2l.03-.03z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M200.53 276.62L202.75 274.09 218.66 288.09 226.66 279.02 228.93 281.02 218.74 292.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M237.75 271.72c-4.83 5.49-11.18 4.12-16.36-.44-5.18-4.56-7.35-10.8-2.59-16.21s11.11-4.09 16.34.51 7.48 10.6 2.61 16.14zm-16.7-14.68c-3.64 4.14-1.05 8.45 2.61 11.66 3.66 3.21 8.25 5.18 11.87 1.07 3.62-4.11 1-8.36-2.63-11.59-3.63-3.23-8.28-5.18-11.85-1.18v.04z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M253.69 253.59c-4.76 5.41-11 4-16.21-.62-5.21-4.62-7.39-10.75-2.74-16 3.57-4.06 8.36-4.11 12.33-1.52l-2.24 2.55c-2.72-1.75-5.53-1.69-7.84.94-3.41 3.88-.94 8.23 2.76 11.48 3.7 3.25 8.33 5.1 11.72 1.25 2.44-2.79 2-5.63-.11-8l2.29-2.6c3.35 3.49 3.65 8.41.04 12.52z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M244.17 227L246.39 224.47 255.48 232.47 253.73 216.13 256.31 213.2 257.75 225.36 275.08 228.51 272.52 231.42 257.99 228.7 258.7 235.3 264.57 240.46 262.35 242.99z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M267.75 219.1L274.6 211.31 276.87 213.31 270.03 221.1z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M291.08 210.28l-14.93-13.13c-.32 2.21-.61 4.44-.92 6.65l-2.46-2.15c.31-2.4.58-4.83.89-7.23l1.84-2.1 17.69 15.56-2.11 2.4zM304.12 195.44l-14.93-13.13c-.32 2.21-.61 4.45-.92 6.66l-2.45-2.16c.3-2.4.58-4.83.88-7.23l1.84-2.1 17.7 15.56-2.12 2.4z"
                            ></path>
                        </g>
                        <g
                            id="Block-10"
                            fillRule="nonzero"
                            transform="translate(1803 1165)"
                            style={{
                                display: sBlock('block_10').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(454)">
                                <path
                                    fill={sBlockUnit('block_10', '1').colour}
                                    stroke={sBlockUnit('block_10', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_10', '1').width
                                    }
                                    opacity={
                                        sBlockUnit('block_10', '1').opacity
                                    }
                                    d="M117.43 110.03L155.53 97.45 145.74 63.55 149.59 61.8 131.76 0.98 104.85 9.02 102.75 4.83 30.75 25.8 44.03 72.63 0.34 84.87 29.7 186.58 98.91 167 94.01 147.08 124.77 138.34z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_10', '1')
                                                .floorplanID,
                                            sBlockUnit('block_10', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_10'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M77.78 126.28l2-.58 9.51 12.83-2.1.59-2.32-3.17-6.1 1.74-.3 3.93-2 .58 1.31-15.92zm6 8.08l-4.37-6-.52 7.38 4.89-1.38z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74.28 114.91l-5.94-20.82c-1.59 1.84-3.17 3.71-4.76 5.55l-1-3.41c1.7-2 3.38-4.08 5.07-6.1l2.92-.84 7 24.66-3.29.96z"
                                ></path>
                            </g>
                            <g transform="translate(356 63)">
                                <path
                                    fill={sBlockUnit('block_10', '2').colour}
                                    stroke={sBlockUnit('block_10', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_10', '2').width
                                    }
                                    opacity={
                                        sBlockUnit('block_10', '2').opacity
                                    }
                                    d="M51.16 31.65L41.72 0.9 0.1 13.69 58.99 211.37 59.2 211.3 59.2 209.21 116.87 192.08 111.97 173.21 140.03 165.59 97.23 18.59z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_10', '2')
                                                .floorplanID,
                                            sBlockUnit('block_10', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_10'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M77.27 118.14l2-.58 9.51 12.83-2.09.6-2.33-3.18-6.1 1.74-.3 3.93-2 .58 1.31-15.92zm6 8.09l-4.37-6-.52 7.37 4.89-1.37z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M66.61 105.58c.69-1.08 4.78-9.49 5.55-10.71 2-3.22 2.78-5.73 2.09-8.11a4 4 0 00-5.33-2.9c-3 .85-3.89 2.81-3.52 5.71l-3.27.94c-.7-4 .7-8.13 6-9.65 4.62-1.32 8.32.58 9.53 4.77.91 3.17.08 6.33-2.28 10.17-.51.76-3.74 7.46-4.28 8.27l11.24-3.23.86 3-15.75 4.52-.84-2.78z"
                                ></path>
                            </g>
                            <g transform="translate(276 76)">
                                <path
                                    fill={sBlockUnit('block_10', '3').colour}
                                    stroke={sBlockUnit('block_10', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_10', '3').width
                                    }
                                    opacity={
                                        sBlockUnit('block_10', '3').opacity
                                    }
                                    d="M36.44 14.11L45.18 43.82 0.65 56.62 49.48 220.5 90.97 208.09 92.71 212.29 138.99 198.37 80.1 0.69z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_10', '3')
                                                .floorplanID,
                                            sBlockUnit('block_10', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_10'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74.82 144.25l-5.33 1.52-4.1-14.35 5.13-1.47c3.33-1 5.41 0 6 2.29a3.25 3.25 0 01-1.1 3.7 3.56 3.56 0 013.33 2.7c.78 2.6-.62 4.66-3.93 5.61zM71 131.62l-3.17.91 1.24 4.36 3.35-1c1.8-.51 2.66-1.41 2.2-3-.46-1.59-1.75-1.8-3.62-1.27zm2 6l-3.45 1 1.4 4.92 3.36-1c2-.59 3-1.64 2.48-3.38S75 137 73 137.6v.02z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M60.18 112.76c1.12 3 3.13 4.18 6.4 3.24 3-.85 4.39-3 3.55-5.87-.84-2.87-3.19-3.86-6.18-3l-2.13.62-.86-3 2.21-.64c2.7-.77 3.94-2.63 3.16-5.33-.66-2.31-2.78-3.4-5.45-2.63-2.92.84-4.18 2.62-3.76 5.54l-3.27.94c-1.1-4 1.16-8 6.21-9.48 4.76-1.37 8.48.6 9.59 4.44a6 6 0 01-2 6.66 6.32 6.32 0 015.89 4.85c1.31 4.56-1 8.41-6.06 9.87-5.76 1.65-9.34-1.28-10.63-5.26l3.33-.95z"
                                ></path>
                            </g>
                            <g transform="translate(180 113)">
                                <path
                                    fill={sBlockUnit('block_10', '4').colour}
                                    stroke={sBlockUnit('block_10', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_10', '4').width
                                    }
                                    opacity={
                                        sBlockUnit('block_10', '4').opacity
                                    }
                                    d="M52.4 32.33L43.66 0.53 0.75 12.91 58.18 214.19 105.18 200.45 105.18 195.56 145.48 183.5 96.65 19.62z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_10', '4')
                                                .floorplanID,
                                            sBlockUnit('block_10', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_10'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M84.23 132l-5.33 1.52-4.1-14.35 5.13-1.47c3.33-1 5.41 0 6.05 2.29a3.27 3.27 0 01-1.11 3.7 3.56 3.56 0 013.33 2.7c.74 2.61-.66 4.61-3.97 5.61zm-3.82-12.62l-3.17.9 1.24 4.36 3.35-1c1.8-.51 2.66-1.41 2.2-3-.46-1.59-1.75-1.81-3.62-1.27v.01zm2 6l-3.45 1 1.4 4.92 3.36-1c2-.59 3-1.64 2.48-3.38s-1.82-2.14-3.78-1.58l-.01.04zM64.35 100.6l6.85-19.33 3.38-1 4.51 15.72 3.13-.9.8 2.78-3.13.89 1.75 6.09-3.42 1-1.75-6.08-11.41 3.28-.71-2.45zM75.68 97L72.5 85.94l-4.72 13.33 7.9-2.27z"
                                ></path>
                            </g>
                            <g transform="translate(102 125)">
                                <path
                                    fill={sBlockUnit('block_10', '5').colour}
                                    stroke={sBlockUnit('block_10', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_10', '5').width
                                    }
                                    opacity={
                                        sBlockUnit('block_10', '5').opacity
                                    }
                                    d="M35.68 13.34L45.82 45.85 0.04 59.23 47.94 222.61 90.56 210.07 92.31 215.01 136.18 202.19 78.75 0.91z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_10', '5')
                                                .floorplanID,
                                            sBlockUnit('block_10', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_10'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74 145.21l-5.33 1.52-4.1-14.35 5.13-1.47c3.33-1 5.41 0 6 2.29a3.27 3.27 0 01-1.11 3.7 3.56 3.56 0 013.33 2.7c.75 2.6-.65 4.66-3.92 5.61zm-3.82-12.62l-3.17.9 1.24 4.36 3.36-.95c1.79-.52 2.65-1.42 2.19-3-.46-1.58-1.8-1.85-3.66-1.31h.04zm2 6l-3.45 1 1.41 4.92 3.35-1c2-.59 3-1.64 2.48-3.38s-1.86-2.13-3.82-1.57l.03.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M59.21 114.34c1.1 2.76 3.2 3.89 6.26 3 3.06-.89 4.21-3.44 3.2-7-.89-3.1-2.94-4.86-6.25-3.91a5.8 5.8 0 00-4 3.51l-3.17.91-3.08-13.82 13.9-4 .86 3-11 3.17 1.6 7.59a7.32 7.32 0 014.69-3.39c5.12-1.47 8.57 1.43 9.86 5.94 1.52 5.27-.52 9.43-5.68 10.91-5 1.44-8.83-.46-10.44-5l3.25-.91z"
                                ></path>
                            </g>
                            <g transform="translate(0 160)">
                                <path
                                    fill={sBlockUnit('block_10', '6').colour}
                                    stroke={sBlockUnit('block_10', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_10', '6').width
                                    }
                                    opacity={
                                        sBlockUnit('block_10', '6').opacity
                                    }
                                    d="M56.95 37.41L45.76 0.01 0.67 14.69 60.79 219.16 118.46 201.33 116.36 197.49 149.94 187.61 102.04 24.23z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_10', '6')
                                                .floorplanID,
                                            sBlockUnit('block_10', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_10'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M87.27 135.55l-5.33 1.52-4.1-14.35 5.13-1.47c3.33-1 5.41 0 6 2.29a3.27 3.27 0 01-1.11 3.7 3.56 3.56 0 013.33 2.7c.81 2.6-.61 4.66-3.92 5.61zm-3.82-12.62l-3.17.9 1.25 4.36 3.35-1c1.79-.52 2.65-1.42 2.19-3-.46-1.58-1.74-1.8-3.62-1.26zm2 6l-3.45 1 1.41 4.92 3.35-1c2-.59 3-1.64 2.48-3.38s-1.82-2.13-3.78-1.57l-.01.03zM80.62 110.46c-6.79 2-10.28-3.75-11.93-9.51-2.35-8.18-1.55-14.49 4.89-16.34 4.66-1.34 7.88.78 9.33 4.21l-3.27.94a4.06 4.06 0 00-5.3-2.25c-4.16 1.2-4.17 5.74-2.84 10.75a7 7 0 015-4.59c5.16-1.48 8.65 1.17 10 6 1.35 4.83-.62 9.33-5.88 10.79zm-3.83-13.87c-2.77.79-4.35 3.36-3.4 6.67.86 3 3.18 5.28 6.41 4.36 3.23-.92 4.37-3.72 3.43-7-.86-2.98-2.96-5.04-6.44-4.03z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M254.45 302.16l-8.61 2.53L239 281.5l8.28-2.44c5.38-1.58 8.76 0 9.82 3.63a5.29 5.29 0 01-1.74 6 5.75 5.75 0 015.42 4.32c1.22 4.22-.98 7.57-6.33 9.15zm-6.35-20.39l-5.12 1.51 2.07 7 5.42-1.59c2.9-.85 4.27-2.32 3.51-4.93-.76-2.61-2.85-2.88-5.88-1.99zm3.34 9.65l-5.58 1.64 2.34 8 5.41-1.59c3.3-1 4.81-2.69 4-5.5-.81-2.81-3.01-3.48-6.17-2.55z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M260.18 275.24L263.41 274.24 269.41 294.56 280.98 291.15 281.84 294.05 267.03 298.41z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M294.26 291c-7 2.07-11.65-2.49-13.6-9.11-1.95-6.62-.44-13 6.48-15.09 6.92-2.09 11.57 2.48 13.54 9.17 1.97 6.69.66 12.92-6.42 15.03zm-6.28-21.33c-5.28 1.56-5.4 6.59-4 11.25 1.4 4.66 4.21 8.79 9.46 7.25 5.25-1.54 5.36-6.51 4-11.2-1.36-4.69-4.27-8.85-9.44-7.33l-.02.03z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M317.42 284.16c-6.92 2-11.4-2.49-13.37-9.18-1.97-6.69-.51-13 6.25-15 5.18-1.53 9.25 1 11.23 5.3l-3.26 1c-1.36-2.93-3.77-4.38-7.13-3.39-5 1.46-5.19 6.45-3.8 11.18 1.39 4.73 4.32 8.76 9.24 7.31 3.56-1 4.74-3.66 4.19-6.83l3.33-1c.9 4.73-1.43 9.06-6.68 10.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M323.58 256.58L326.81 255.63 330.23 267.24 337.48 252.49 341.23 251.39 335.94 262.39 348.94 274.31 345.22 275.4 334.39 265.34 331.47 271.34 333.68 278.84 330.45 279.84z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M347.73 262.52L357.68 259.59 358.53 262.49 348.53 265.42z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M372.17 267.51l-5.62-19.07c-1.45 1.7-2.89 3.43-4.33 5.14l-.92-3.14c1.54-1.87 3.07-3.77 4.61-5.64l2.67-.79 6.65 22.6-3.06.9z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M391.36 262.4c-6.13 1.8-9.48-2.81-11.43-9.47-1.95-6.66-1.49-12.39 4.54-14.16 6.03-1.77 9.49 2.84 11.44 9.46 1.95 6.62 1.64 12.35-4.55 14.17zm-6.1-20.85c-4.24 1.25-3.49 6-2.17 10.45 1.32 4.45 3.22 8.91 7.49 7.65 4.27-1.26 3.5-6 2.16-10.49-1.34-4.49-3.31-8.84-7.48-7.61zM254.45 302.16l-8.61 2.53L239 281.5l8.28-2.44c5.38-1.58 8.76 0 9.82 3.63a5.29 5.29 0 01-1.74 6 5.75 5.75 0 015.42 4.32c1.22 4.22-.98 7.57-6.33 9.15zm-6.35-20.39l-5.12 1.51 2.07 7 5.42-1.59c2.9-.85 4.27-2.32 3.51-4.93-.76-2.61-2.85-2.88-5.88-1.99zm3.34 9.65l-5.58 1.64 2.34 8 5.41-1.59c3.3-1 4.81-2.69 4-5.5-.81-2.81-3.01-3.48-6.17-2.55z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M260.18 275.24L263.41 274.24 269.41 294.56 280.98 291.15 281.84 294.05 267.03 298.41z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M294.26 291c-7 2.07-11.65-2.49-13.6-9.11-1.95-6.62-.44-13 6.48-15.09 6.92-2.09 11.57 2.48 13.54 9.17 1.97 6.69.66 12.92-6.42 15.03zm-6.28-21.33c-5.28 1.56-5.4 6.59-4 11.25 1.4 4.66 4.21 8.79 9.46 7.25 5.25-1.54 5.36-6.51 4-11.2-1.36-4.69-4.27-8.85-9.44-7.33l-.02.03z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M317.42 284.16c-6.92 2-11.4-2.49-13.37-9.18-1.97-6.69-.51-13 6.25-15 5.18-1.53 9.25 1 11.23 5.3l-3.26 1c-1.36-2.93-3.77-4.38-7.13-3.39-5 1.46-5.19 6.45-3.8 11.18 1.39 4.73 4.32 8.76 9.24 7.31 3.56-1 4.74-3.66 4.19-6.83l3.33-1c.9 4.73-1.43 9.06-6.68 10.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M323.58 256.58L326.81 255.63 330.23 267.24 337.48 252.49 341.23 251.39 335.94 262.39 348.94 274.31 345.22 275.4 334.39 265.34 331.47 271.34 333.68 278.84 330.45 279.84z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M347.73 262.52L357.68 259.59 358.53 262.49 348.53 265.42z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M372.17 267.51l-5.62-19.07c-1.45 1.7-2.89 3.43-4.33 5.14l-.92-3.14c1.54-1.87 3.07-3.77 4.61-5.64l2.67-.79 6.65 22.6-3.06.9z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M391.36 262.4c-6.13 1.8-9.48-2.81-11.43-9.47-1.95-6.66-1.49-12.39 4.54-14.16 6.03-1.77 9.49 2.84 11.44 9.46 1.95 6.62 1.64 12.35-4.55 14.17zm-6.1-20.85c-4.24 1.25-3.49 6-2.17 10.45 1.32 4.45 3.22 8.91 7.49 7.65 4.27-1.26 3.5-6 2.16-10.49-1.34-4.49-3.31-8.84-7.48-7.61z"
                            ></path>
                        </g>
                        <g
                            id="Block-9"
                            fillRule="nonzero"
                            transform="translate(1099 1349)"
                            style={{
                                display: sBlock('block_9').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(588)">
                                <path
                                    fill={sBlockUnit('block_9', '1').colour}
                                    stroke={sBlockUnit('block_9', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_9', '1').width
                                    }
                                    opacity={sBlockUnit('block_9', '1').opacity}
                                    d="M80.86 0.13L35.04 12.93 45.77 50.08 0.63 63.95 49.26 227.4 82.1 218.07 84.57 222.2 141.54 205.69z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_9', '1')
                                                .floorplanID,
                                            sBlockUnit('block_9', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_9'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M70.71 135.7l2-.58 9.52 12.82-2.1.6-2.33-3.18-6.09 1.75-.3 3.93-2 .58 1.3-15.92zm6 8.08l-4.38-6-.51 7.38 4.89-1.38z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M65.75 122l-6-20.78c-1.59 1.84-3.16 3.73-4.74 5.57l-1-3.41c1.68-2 3.36-4.09 5-6.12l2.91-.85 7.16 24.62-3.33.97z"
                                ></path>
                            </g>
                            <g transform="translate(490 44)">
                                <path
                                    fill={sBlockUnit('block_9', '2').colour}
                                    stroke={sBlockUnit('block_9', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_9', '2').width
                                    }
                                    opacity={sBlockUnit('block_9', '2').opacity}
                                    d="M53.79 33.73L42.65 0.3 0.92 12.78 60.73 213.84 106.63 200.49 104.56 195.54 147.26 183.4 98.63 19.95z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_9', '2')
                                                .floorplanID,
                                            sBlockUnit('block_9', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_9'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M78.85 117.37l2-.58 9.52 12.83-2.1.6L86 127l-6.1 1.74-.3 3.93-2 .58 1.25-15.88zm6 8.09l-4.37-6-.52 7.37 4.89-1.37z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M66.87 103.12c.69-1.09 4.76-9.51 5.52-10.74 1.95-3.22 2.76-5.73 2.07-8.11a4 4 0 00-5.34-2.88c-2.95.86-3.88 2.82-3.51 5.72l-3.26 1c-.71-4 .68-8.14 5.94-9.67 4.62-1.34 8.32.55 9.54 4.75.92 3.16.11 6.32-2.25 10.17-.51.76-3.72 7.47-4.25 8.28l11.22-3.26.88 3-15.74 4.58-.82-2.84z"
                                ></path>
                            </g>
                            <g transform="translate(413 56)">
                                <path
                                    fill={sBlockUnit('block_9', '3').colour}
                                    stroke={sBlockUnit('block_9', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_9', '3').width
                                    }
                                    opacity={sBlockUnit('block_9', '3').opacity}
                                    d="M35.44 13.48L44.94 45.67 0.16 59.42 48.6 222.23 91.17 210.37 92.82 214.91 137.73 201.84 77.92 0.78z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_9', '3')
                                                .floorplanID,
                                            sBlockUnit('block_9', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_9'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M67.75 130.54l2-.58 9.52 12.83-2.1.6-2.33-3.18-6.1 1.74-.29 3.93-2 .58 1.3-15.92zm6 8.09l-4.37-6-.51 7.37 4.88-1.37z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M57.39 111.56c1.13 3 3.14 4.18 6.41 3.23 3-.86 4.38-3 3.53-5.88-.89-3.06-3.2-3.85-6.18-3l-2.13.61-.87-3 2.2-.64c2.7-.78 3.94-2.65 3.15-5.35-.67-2.31-2.79-3.38-5.46-2.61-2.91.85-4.16 2.64-3.74 5.56l-3.27 1c-1.11-4 1.13-8 6.18-9.51 4.76-1.38 8.49.58 9.6 4.42a6 6 0 01-2 6.67 6.31 6.31 0 015.91 4.83c1.33 4.55-1 8.41-6 9.88-5.75 1.67-9.34-1.25-10.65-5.23l3.32-.98z"
                                ></path>
                            </g>
                            <g transform="translate(318 95)">
                                <path
                                    fill={sBlockUnit('block_9', '4').colour}
                                    stroke={sBlockUnit('block_9', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_9', '4').width
                                    }
                                    opacity={sBlockUnit('block_9', '4').opacity}
                                    d="M51.19 33.92L40.46 0.07 0.36 11.92 58.22 212.93 103.2 199.43 103.2 194.48 143.6 183.23 95.16 20.42z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_9', '4')
                                                .floorplanID,
                                            sBlockUnit('block_9', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_9'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M76.16 116.28l2-.58 9.52 12.83-2.1.6-2.33-3.18-6.1 1.74-.29 3.93-2 .58 1.3-15.92zm6 8.09l-4.37-6-.51 7.37 4.88-1.37zM60.79 96.88l6.79-19.35 3.38-1 4.56 15.7 3.13-.9.8 2.77-3.12.91 1.76 6.07-3.41 1-1.77-6.07-11.4 3.31-.72-2.44zm11.32-3.63L68.9 82.19l-4.68 13.35 7.89-2.29z"
                                ></path>
                            </g>
                            <g transform="translate(237 106)">
                                <path
                                    fill={sBlockUnit('block_9', '5').colour}
                                    stroke={sBlockUnit('block_9', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_9', '5').width
                                    }
                                    opacity={sBlockUnit('block_9', '5').opacity}
                                    d="M37.67 13.84L46.34 46.03 0.66 60.91 48.72 223.37 91.33 211.14 93.39 215.68 139.22 201.93 81.36 0.92z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_9', '5')
                                                .floorplanID,
                                            sBlockUnit('block_9', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_9'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M68.89 130.5l2-.58 9.52 12.83-2.1.6-2.33-3.18-6.1 1.74-.29 3.93-2 .58 1.3-15.92zm6 8.09l-4.37-6L70 140l4.89-1.41z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M59.85 112.28c1.11 2.76 3.21 3.88 6.26 3 3.05-.88 4.2-3.45 3.18-7-.9-3.09-3-4.85-6.25-3.89a5.79 5.79 0 00-4 3.52l-3.16.92-3.13-13.81 13.9-4 .86 3-11 3.2 1.62 7.58a7.35 7.35 0 014.68-3.4c5.12-1.49 8.57 1.4 9.88 5.91 1.53 5.26-.49 9.44-5.65 10.93-5 1.46-8.83-.44-10.46-5l3.27-.96z"
                                ></path>
                            </g>
                            <g transform="translate(140 142)">
                                <path
                                    fill={sBlockUnit('block_9', '6').colour}
                                    stroke={sBlockUnit('block_9', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_9', '6').width
                                    }
                                    opacity={sBlockUnit('block_9', '6').opacity}
                                    d="M53.36 39.34L41.8 0.54 0.52 13.75 11.25 52.55 10.88 52.65 57.38 209.83 59.96 217.24 104.95 203.62 104.95 199.08 145.72 187.37 97.66 24.91z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_9', '6')
                                                .floorplanID,
                                            sBlockUnit('block_9', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_9'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M82.78 134.13c-4.28 1.22-7-1.6-8.21-5.74-1.21-4.14-.25-8.05 3.93-9.24a5.54 5.54 0 016.91 3.33l-2 .57a3.43 3.43 0 00-4.38-2.13c-3.07.88-3.24 4-2.4 6.89.84 2.89 2.62 5.43 5.67 4.56a3.41 3.41 0 002.62-4.2l2.06-.59a5.5 5.5 0 01-4.2 6.55z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74.59 108.14c-6.79 2-10.29-3.71-12-9.47-2.37-8.17-1.59-14.49 4.84-16.36 4.66-1.35 7.89.76 9.35 4.19l-3.27.94c-1.09-2-2.65-3-5.31-2.23-4.16 1.21-4.15 5.75-2.81 10.76a7 7 0 015-4.61c5.15-1.49 8.66 1.15 10.06 6 1.4 4.85-.6 9.25-5.86 10.78zm-3.87-13.86c-2.78.8-4.34 3.38-3.38 6.68.87 3 3.19 5.28 6.42 4.34 3.23-.94 4.35-3.73 3.4-7-.86-2.98-2.96-5.03-6.44-4.02z"
                                ></path>
                            </g>
                            <g transform="translate(0 169)">
                                <path
                                    fill={sBlockUnit('block_9', '7').colour}
                                    stroke={sBlockUnit('block_9', '7').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_9', '7').width
                                    }
                                    opacity={sBlockUnit('block_9', '7').opacity}
                                    d="M106.26 38.34L95.12 0.78 51.37 13.16 52.6 18.53 0.18 33.8 20 103.14 47.65 95.3 59.62 136 83.15 129.97 89.75 155.15 132.27 179.09 191.43 162.73 150.88 25.65z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_9', '7')
                                                .floorplanID,
                                            sBlockUnit('block_9', '7')
                                                .unit_elevation.value,
                                            7,
                                            'block_9'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M129.09 118.3l2-.59 9.52 12.83-2.1.6-2.33-3.18-6.1 1.75-.29 3.92-2 .58 1.3-15.91zm6 8.08l-4.37-6-.51 7.38 4.88-1.38z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M116.89 109a244 244 0 013.14-24.34l-12.93 3.75-.88-3 16.56-4.8.81 2.8a214.32 214.32 0 00-3 24.55l-3.7 1.04z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M401.1 281.93l-8.63 2.44-6.58-23.26 8.31-2.34c5.4-1.53 8.75.1 9.78 3.73a5.31 5.31 0 01-1.8 6 5.75 5.75 0 015.37 4.38c1.19 4.21-1.08 7.54-6.45 9.05zm-6.13-20.45l-5.14 1.45 2 7.07 5.43-1.54c2.91-.82 4.3-2.27 3.56-4.89-.74-2.62-2.82-2.95-5.82-2.09h-.03zm3.23 9.69l-5.6 1.58 2.26 8 5.43-1.54c3.31-.93 4.84-2.64 4-5.45-.84-2.81-2.92-3.49-6.09-2.59z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M407.11 255.08L410.35 254.16 416.11 274.54 427.73 271.26 428.55 274.17 413.7 278.37z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M441 271.18c-7 2-11.62-2.62-13.49-9.26-1.87-6.64-.3-13.06 6.63-15 6.93-1.94 11.55 2.6 13.44 9.31 1.89 6.71.54 12.94-6.58 14.95zm-6-21.4c-5.3 1.5-5.47 6.53-4.15 11.21 1.32 4.68 4.12 8.83 9.39 7.34 5.27-1.49 5.42-6.44 4.09-11.15s-4.15-8.87-9.33-7.4z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M464.25 264.61c-6.93 2-11.37-2.61-13.26-9.32-1.89-6.71-.37-13 6.4-15 5.2-1.47 9.25 1.1 11.18 5.43l-3.27.92c-1.33-2.94-3.72-4.42-7.09-3.47-5 1.41-5.26 6.4-3.92 11.14 1.34 4.74 4.22 8.81 9.16 7.41 3.57-1 4.77-3.61 4.26-6.78l3.34-1c.86 4.87-1.53 9.18-6.8 10.67z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M470.72 237.1L473.95 236.18 477.25 247.83 484.65 233.16 488.41 232.09 483.01 243.09 495.85 255.15 492.12 256.15 481.4 246 478.4 251.93 480.53 259.45 477.29 260.37z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M494.8 243.29L504.8 240.47 505.63 243.38 495.63 246.2z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M513.42 244.42c.84 2 2.52 2.79 4.94 2.1 4.25-1.2 3.54-5.59 2.4-9.86a6.59 6.59 0 01-4.56 4.22c-4.71 1.33-8-1.1-9.24-5.55-1.24-4.45.66-8.56 5.47-9.92a6.94 6.94 0 016.73 1.35c2.08 1.71 3.4 4.65 4.25 7.63 1.83 6.48 2.35 12.83-4.35 14.73-4.32 1.22-7.4-.42-8.65-3.85l3.01-.85zm5.17-8.07a5.28 5.28 0 00.45-4.4c-.79-2.67-2.89-4.73-5.87-3.89-2.98.84-4 3.39-3.13 6.4.76 2.72 2.69 4.65 5.89 3.74a4.61 4.61 0 002.66-1.85zM401.1 281.93l-8.63 2.44-6.58-23.26 8.31-2.34c5.4-1.53 8.75.1 9.78 3.73a5.31 5.31 0 01-1.8 6 5.75 5.75 0 015.37 4.38c1.19 4.21-1.08 7.54-6.45 9.05zm-6.13-20.45l-5.14 1.45 2 7.07 5.43-1.54c2.91-.82 4.3-2.27 3.56-4.89-.74-2.62-2.82-2.95-5.82-2.09h-.03zm3.23 9.69l-5.6 1.58 2.26 8 5.43-1.54c3.31-.93 4.84-2.64 4-5.45-.84-2.81-2.92-3.49-6.09-2.59z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M407.11 255.08L410.35 254.16 416.11 274.54 427.73 271.26 428.55 274.17 413.7 278.37z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M441 271.18c-7 2-11.62-2.62-13.49-9.26-1.87-6.64-.3-13.06 6.63-15 6.93-1.94 11.55 2.6 13.44 9.31 1.89 6.71.54 12.94-6.58 14.95zm-6-21.4c-5.3 1.5-5.47 6.53-4.15 11.21 1.32 4.68 4.12 8.83 9.39 7.34 5.27-1.49 5.42-6.44 4.09-11.15s-4.15-8.87-9.33-7.4z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M464.25 264.61c-6.93 2-11.37-2.61-13.26-9.32-1.89-6.71-.37-13 6.4-15 5.2-1.47 9.25 1.1 11.18 5.43l-3.27.92c-1.33-2.94-3.72-4.42-7.09-3.47-5 1.41-5.26 6.4-3.92 11.14 1.34 4.74 4.22 8.81 9.16 7.41 3.57-1 4.77-3.61 4.26-6.78l3.34-1c.86 4.87-1.53 9.18-6.8 10.67z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M470.72 237.1L473.95 236.18 477.25 247.83 484.65 233.16 488.41 232.09 483.01 243.09 495.85 255.15 492.12 256.15 481.4 246 478.4 251.93 480.53 259.45 477.29 260.37z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M494.8 243.29L504.8 240.47 505.63 243.38 495.63 246.2z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M513.42 244.42c.84 2 2.52 2.79 4.94 2.1 4.25-1.2 3.54-5.59 2.4-9.86a6.59 6.59 0 01-4.56 4.22c-4.71 1.33-8-1.1-9.24-5.55-1.24-4.45.66-8.56 5.47-9.92a6.94 6.94 0 016.73 1.35c2.08 1.71 3.4 4.65 4.25 7.63 1.83 6.48 2.35 12.83-4.35 14.73-4.32 1.22-7.4-.42-8.65-3.85l3.01-.85zm5.17-8.07a5.28 5.28 0 00.45-4.4c-.79-2.67-2.89-4.73-5.87-3.89-2.98.84-4 3.39-3.13 6.4.76 2.72 2.69 4.65 5.89 3.74a4.61 4.61 0 002.66-1.85z"
                            ></path>
                        </g>
                        <g
                            id="Block-8"
                            fillRule="nonzero"
                            transform="translate(963 1800)"
                            style={{
                                display: sBlock('block_8').active ? '' : 'none'
                            }}
                        >
                            <g>
                                <path
                                    fill={sBlockUnit('block_8', '1').colour}
                                    stroke={sBlockUnit('block_8', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_8', '1').width
                                    }
                                    opacity={sBlockUnit('block_8', '1').opacity}
                                    d="M199.67 125.13L189.53 66.76 150.38 39.15 124.52 43.69 119.98 19.92 76.29 26.57 71.05 0 0.44 11.89 9.75 66.63 4.29 67.81 12.68 111.5 51.82 105.21 59.86 150.29z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_8', '1')
                                                .floorplanID,
                                            sBlockUnit('block_8', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_8'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M129.91 90.64l-.37-2.09 13.73-8.16.38 2.15-3.4 2 1.11 6.25 3.88.69.37 2.09-15.7-2.93zm8.65-5.17l-6.39 3.74 7.28 1.27-.89-5.01z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M106.51 79.76l-21.28 4c1.68 1.76 3.4 3.51 5.08 5.27l-3.49.65c-1.86-1.88-3.75-3.75-5.6-5.63l-.55-3 25.21-4.68.63 3.39z"
                                ></path>
                            </g>
                            <g transform="translate(21 120)">
                                <path
                                    fill={sBlockUnit('block_8', '2').colour}
                                    stroke={sBlockUnit('block_8', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_8', '2').width
                                    }
                                    opacity={sBlockUnit('block_8', '2').opacity}
                                    d="M215.02 48.47L219.21 48.47 211.92 0.2 0.09 37.78 8.45 80.97 47.25 75.03 55.32 122.56 222.05 92.99z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_8', '2')
                                                .floorplanID,
                                            sBlockUnit('block_8', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_8'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M141.19 61.14l1 5.45-14.7 2.62-.94-5.25c-.6-3.41.6-5.38 2.9-5.78a3.27 3.27 0 013.57 1.47 3.54 3.54 0 013-3c2.66-.5 4.57 1.1 5.17 4.49zm-12.94 2.51l.58 3.25 4.46-.8-.61-3.43c-.33-1.84-1.13-2.78-2.79-2.49-1.66.29-1.98 1.55-1.64 3.47zm6.15-1.39l.63 3.54 5-.9-.61-3.43c-.37-2.09-1.32-3.14-3.1-2.82-1.78.32-2.32 1.61-1.92 3.61z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M105.07 73c-1-.8-9-5.67-10.13-6.55-3-2.27-5.44-3.32-7.88-2.87a4 4 0 00-3.39 5c.56 3 2.42 4.14 5.34 4.05l.62 3.35c-4.08.31-8-1.48-9-6.87-.88-4.73 1.37-8.23 5.66-9 3.24-.6 6.31.53 9.9 3.25.71.58 7.07 4.44 7.82 5.06l-2.13-11.5 3.09-.57 3 16.11-2.9.54z"
                                ></path>
                            </g>
                            <g transform="translate(50 212)">
                                <path
                                    fill={sBlockUnit('block_8', '3').colour}
                                    stroke={sBlockUnit('block_8', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_8', '3').width
                                    }
                                    opacity={sBlockUnit('block_8', '3').opacity}
                                    d="M205.59 42.8L199.65 42.8 193.05 0.99 26.32 30.56 33.98 75.65 0.77 81.94 8.34 124.26 214.24 87.74z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_8', '3')
                                                .floorplanID,
                                            sBlockUnit('block_8', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_8'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M128.38 60.06l1 5.46-14.7 2.61-.94-5.25c-.6-3.41.6-5.37 2.9-5.78a3.28 3.28 0 013.57 1.48 3.55 3.55 0 013-3c2.66-.51 4.56 1.09 5.17 4.48zm-13 2.52l.58 3.24 4.46-.79-.61-3.43c-.33-1.84-1.13-2.79-2.79-2.49-1.66.3-1.93 1.55-1.59 3.47h-.05zm6.15-1.39l.63 3.53 5-.9-.61-3.43c-.37-2.09-1.32-3.13-3.1-2.82-1.78.31-2.23 1.61-1.87 3.62h-.05z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M86.93 64c3-.83 4.47-2.71 3.85-6.06-.56-3-2.52-4.65-5.51-4.09-2.99.56-4.14 2.8-3.58 5.85l.41 2.19-3.06.56-.41-2.25c-.52-2.77-2.25-4.18-5-3.66-2.37.44-3.65 2.44-3.14 5.17.55 3 2.21 4.41 5.16 4.27l.62 3.35c-4 .72-7.89-1.92-8.85-7.09-.9-4.87 1.42-8.39 5.35-9.12a6 6 0 016.44 2.64 6.31 6.31 0 015.39-5.4c4.66-.86 8.27 1.78 9.24 7 1.09 5.89-2.18 9.17-6.26 10.08L86.93 64z"
                                ></path>
                            </g>
                            <g transform="translate(62 299)">
                                <path
                                    fill={sBlockUnit('block_8', '4').colour}
                                    stroke={sBlockUnit('block_8', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_8', '4').width
                                    }
                                    opacity={sBlockUnit('block_8', '4').opacity}
                                    d="M206.52 48.42L211.41 48.42 202.24 0.74 0.77 36.48 8 80.57 37.36 76.03 46.31 122.55 213.66 92.86z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_8', '4')
                                                .floorplanID,
                                            sBlockUnit('block_8', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_8'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M132.24 62.21l1 5.46-14.7 2.61-.93-5.25c-.61-3.41.6-5.37 2.89-5.78a3.27 3.27 0 013.57 1.48 3.56 3.56 0 013-3c2.66-.51 4.57 1.09 5.17 4.48zm-13 2.52l.58 3.24 4.47-.79-.61-3.43c-.33-1.84-1.14-2.79-2.79-2.49-1.65.3-1.89 1.54-1.6 3.47h-.05zm6.15-1.4l.63 3.54 5-.9-.61-3.43c-.37-2.09-1.33-3.13-3.11-2.82-1.78.31-2.21 1.61-1.86 3.61h-.05zM91.64 75.16l-18.58-8.67-.64-3.45 16.08-3-.6-3.2 2.84-.52.59 3.2 6.23-1.16.64 3.49L92 63l2.17 11.68-2.53.48zm-2.49-11.61l-11.32 2.1 12.81 6-1.49-8.1z"
                                ></path>
                            </g>
                            <g transform="translate(87 391)">
                                <path
                                    fill={sBlockUnit('block_8', '5').colour}
                                    stroke={sBlockUnit('block_8', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_8', '5').width
                                    }
                                    opacity={sBlockUnit('block_8', '5').opacity}
                                    d="M200.74 43.45L195.5 43.45 188.66 0.86 21.31 30.55 30.18 76.65 0.82 81.89 8.34 124.12 209.17 88.49z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_8', '5')
                                                .floorplanID,
                                            sBlockUnit('block_8', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_8'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M122.83 57.83l1 5.46-14.7 2.61-.94-5.25c-.6-3.41.6-5.37 2.9-5.78a3.28 3.28 0 013.57 1.48 3.55 3.55 0 013-3c2.66-.51 4.57 1.09 5.17 4.48zm-12.94 2.52l.58 3.24 4.46-.79-.61-3.43c-.33-1.84-1.13-2.79-2.79-2.49-1.66.3-1.98 1.54-1.64 3.47zm6.15-1.4l.63 3.54 5-.9-.61-3.43c-.37-2.09-1.32-3.13-3.1-2.82-1.78.31-2.28 1.66-1.96 3.66l.04-.05z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M84.24 68.23c2.85-.83 4.18-2.81 3.6-5.94-.58-3.13-3-4.52-6.63-3.85-3.16.59-5.11 2.46-4.48 5.84a5.76 5.76 0 003.1 4.35l.6 3.24-14 1.74-2.64-14.22 3.06-.57 2.09 11.28 7.7-.87a7.36 7.36 0 01-2.92-5c-1-5.24 2.25-8.39 6.87-9.25 5.38-1 9.33 1.43 10.31 6.71.98 5.28-1.31 8.74-6 9.91l-.66-3.37z"
                                ></path>
                            </g>
                            <g transform="translate(89 479)">
                                <path
                                    fill={sBlockUnit('block_8', '6').colour}
                                    stroke={sBlockUnit('block_8', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_8', '6').width
                                    }
                                    opacity={sBlockUnit('block_8', '6').opacity}
                                    d="M211.33 48.42L215.87 47.02 207.17 0.49 0.62 37.13 9.31 80.57 42.51 75.68 51.16 121.87 218.73 92.15z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_8', '6')
                                                .floorplanID,
                                            sBlockUnit('block_8', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_8'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M136.73 59.15l1 5.46-14.7 2.61-.93-5.25c-.61-3.41.6-5.37 2.89-5.78a3.27 3.27 0 013.57 1.48 3.56 3.56 0 013-3c2.66-.51 4.57 1.09 5.17 4.48zm-13 2.52l.58 3.24 4.47-.79-.61-3.43c-.33-1.84-1.14-2.79-2.79-2.49-1.65.3-1.94 1.54-1.6 3.47h-.05zm6.15-1.4l.63 3.54 5-.9-.61-3.43c-.38-2.09-1.33-3.13-3.11-2.82-1.78.31-2.21 1.61-1.86 3.61h-.05zM105.34 62.75c1.29 6.95-4.71 9.87-10.61 11-8.36 1.55-14.57.15-15.79-6.44-.89-4.77 1.53-7.77 5.08-8.89l.62 3.35c-2.13.89-3.25 2.34-2.74 5.07.79 4.25 5.31 4.69 10.42 3.86a7.06 7.06 0 01-4.09-5.42c-1-5.27 2-8.5 7-9.42 5-.92 9.11 1.51 10.11 6.89zm-14.18 2.49c.53 2.83 2.94 4.65 6.32 4 3.06-.57 5.57-2.65 5-6-.57-3.35-3.28-4.69-6.63-4.07-3.1.62-5.35 2.5-4.69 6.07z"
                                ></path>
                            </g>
                            <g transform="translate(110 571)">
                                <path
                                    fill={sBlockUnit('block_8', '7').colour}
                                    stroke={sBlockUnit('block_8', '7').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_8', '7').width
                                    }
                                    opacity={sBlockUnit('block_8', '7').opacity}
                                    d="M217.59 88.53L209.55 43.1 205.01 43.1 197.73 0.15 30.16 29.87 38.99 77 0.19 83.29 7.75 125.92z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_8', '7')
                                                .floorplanID,
                                            sBlockUnit('block_8', '7')
                                                .unit_elevation.value,
                                            7,
                                            'block_8'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M131.7 56.89l1 5.46L118 65l-.93-5.25c-.61-3.41.6-5.38 2.89-5.79a3.27 3.27 0 013.57 1.48 3.54 3.54 0 013-3c2.66-.54 4.57 1.06 5.17 4.45zm-13 2.52l.58 3.25 4.47-.8-.61-3.43c-.33-1.84-1.14-2.78-2.79-2.49-1.65.29-1.94 1.55-1.6 3.47h-.05zm6.15-1.39l.63 3.53 5-.89-.61-3.44c-.37-2.08-1.33-3.13-3.11-2.81-1.78.32-2.21 1.59-1.86 3.59l-.05.02z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M101.4 65.71c-6.24-1-18.05-3.8-23.91-5.54l2.46 13.24-3.09.58-3.15-17 2.87-.53a217.1 217.1 0 0024.13 5.46l.69 3.79z"
                                ></path>
                            </g>
                            <g transform="translate(117 661)">
                                <path
                                    fill={sBlockUnit('block_8', '8').colour}
                                    stroke={sBlockUnit('block_8', '8').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_8', '8').width
                                    }
                                    opacity={sBlockUnit('block_8', '8').opacity}
                                    d="M180.18 4.48L201.34 0.34 0.75 35.92 0.88 36.63 17.31 33.49 25.35 80.32 21.33 80.32 29.19 125.06 34.08 125.06 38.63 150.92 95.95 140.09 95.95 144.28 155.02 134.15 149.42 104.09 198.01 95z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_8', '8')
                                                .floorplanID,
                                            sBlockUnit('block_8', '8')
                                                .unit_elevation.value,
                                            8,
                                            'block_8'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M127.48 67.22l-.37-2.09 13.73-8.16.38 2.15-3.4 2 1.11 6.25 3.88.69.37 2.09-15.7-2.93zm8.65-5.17l-6.39 3.74 7.28 1.27-.89-5.01zM95.16 85c-4.26.79-7.61-1.86-8.55-6.91-.94-5.05 1.21-8.7 5.51-9.49a6.25 6.25 0 016.41 2.53 6.78 6.78 0 015.36-5.4c4.69-.87 8.15 2.14 9.14 7.45.99 5.31-1.16 9.36-5.86 10.23a6.74 6.74 0 01-6.9-3.12A6.33 6.33 0 0195.16 85zM98 76c-.59-3.16-2.46-4.55-5.19-4-2.44.45-3.92 2.42-3.32 5.62.6 3.2 2.71 4.5 5.15 4 2.69-.5 3.94-2.5 3.36-5.62zm12.11-2.28c-.64-3.42-2.82-5.05-5.66-4.52-2.84.53-4.21 2.62-3.55 6.23.66 3.61 2.87 5.07 5.56 4.57 2.87-.54 4.29-2.84 3.65-6.29v.01z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M271.3 452.28l1.54 8.84-23.81 4.16-1.48-8.51c-1-5.52 1-8.69 4.71-9.34a5.28 5.28 0 015.77 2.41 5.76 5.76 0 014.91-4.9c4.32-.75 7.4 1.85 8.36 7.34zm-21 4l.92 5.26 7.24-1.26-1-5.56c-.52-3-1.83-4.52-4.51-4.05-2.68.47-3.17 2.5-2.63 5.62l-.02-.01zm10-2.22l1 5.72 8.17-1.42-1-5.56c-.59-3.38-2.13-5.08-5-4.58s-3.75 2.6-3.18 5.85l.01-.01z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M245.2 443.55L244.62 440.24 265.49 436.59 263.41 424.7 266.41 424.18 269.07 439.39z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M264.69 411.47c1.26 7.2-3.79 11.29-10.59 12.48-6.8 1.19-13-1-14.26-8.14-1.26-7.14 3.77-11.22 10.64-12.42 6.87-1.2 12.94.81 14.21 8.08zm-21.9 3.82c1 5.43 5.93 6.11 10.72 5.27 4.79-.84 9.21-3.19 8.27-8.58-.94-5.39-5.86-6.06-10.68-5.21-4.82.85-9.24 3.23-8.31 8.52z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M260.54 387.69c1.24 7.1-3.77 11-10.63 12.24-6.86 1.24-13-1-14.22-7.9-.93-5.33 2-9.09 6.54-10.57l.59 3.35c-3.07 1-4.79 3.25-4.18 6.7.89 5.09 5.82 5.89 10.68 5 4.86-.89 9.19-3.3 8.3-8.35-.63-3.65-3.1-5.12-6.31-4.94l-.6-3.42c4.84-.32 8.89 2.5 9.83 7.89z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M233.83 378.44L233.26 375.12 245.18 373.04 231.34 364.17 230.67 360.32 241.06 366.83 254.36 355.29 255.03 359.11 243.8 368.73 249.4 372.31 257.1 370.96 257.68 374.28z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M242.46 355.12L240.68 344.91 243.68 344.39 245.46 354.6z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M234.18 341.86c-3.92.69-7-1.78-7.79-6.44s1.2-8 5.15-8.66a5.75 5.75 0 015.87 2.39 6.19 6.19 0 015-4.9c4.32-.76 7.46 2 8.32 6.93.86 4.93-1.16 8.59-5.48 9.34a6.19 6.19 0 01-6.31-2.93 5.81 5.81 0 01-4.76 4.27zm2.7-8.27c-.51-2.91-2.22-4.2-4.73-3.76-2.25.39-3.62 2.18-3.11 5.13.51 2.95 2.45 4.16 4.7 3.77 2.47-.43 3.64-2.26 3.14-5.14zm11.14-2c-.55-3.15-2.54-4.66-5.15-4.2-2.61.46-3.9 2.37-3.32 5.68.58 3.31 2.58 4.69 5.06 4.26 2.64-.44 3.97-2.54 3.39-5.72l.02-.02zM271.3 452.28l1.54 8.84-23.81 4.16-1.48-8.51c-1-5.52 1-8.69 4.71-9.34a5.28 5.28 0 015.77 2.41 5.76 5.76 0 014.91-4.9c4.32-.75 7.4 1.85 8.36 7.34zm-21 4l.92 5.26 7.24-1.26-1-5.56c-.52-3-1.83-4.52-4.51-4.05-2.68.47-3.17 2.5-2.63 5.62l-.02-.01zm10-2.22l1 5.72 8.17-1.42-1-5.56c-.59-3.38-2.13-5.08-5-4.58s-3.75 2.6-3.18 5.85l.01-.01z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M245.2 443.55L244.62 440.24 265.49 436.59 263.41 424.7 266.41 424.18 269.07 439.39z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M264.69 411.47c1.26 7.2-3.79 11.29-10.59 12.48-6.8 1.19-13-1-14.26-8.14-1.26-7.14 3.77-11.22 10.64-12.42 6.87-1.2 12.94.81 14.21 8.08zm-21.9 3.82c1 5.43 5.93 6.11 10.72 5.27 4.79-.84 9.21-3.19 8.27-8.58-.94-5.39-5.86-6.06-10.68-5.21-4.82.85-9.24 3.23-8.31 8.52z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M260.54 387.69c1.24 7.1-3.77 11-10.63 12.24-6.86 1.24-13-1-14.22-7.9-.93-5.33 2-9.09 6.54-10.57l.59 3.35c-3.07 1-4.79 3.25-4.18 6.7.89 5.09 5.82 5.89 10.68 5 4.86-.89 9.19-3.3 8.3-8.35-.63-3.65-3.1-5.12-6.31-4.94l-.6-3.42c4.84-.32 8.89 2.5 9.83 7.89z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M233.83 378.44L233.26 375.12 245.18 373.04 231.34 364.17 230.67 360.32 241.06 366.83 254.36 355.29 255.03 359.11 243.8 368.73 249.4 372.31 257.1 370.96 257.68 374.28z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M242.46 355.12L240.68 344.91 243.68 344.39 245.46 354.6z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M234.18 341.86c-3.92.69-7-1.78-7.79-6.44s1.2-8 5.15-8.66a5.75 5.75 0 015.87 2.39 6.19 6.19 0 015-4.9c4.32-.76 7.46 2 8.32 6.93.86 4.93-1.16 8.59-5.48 9.34a6.19 6.19 0 01-6.31-2.93 5.81 5.81 0 01-4.76 4.27zm2.7-8.27c-.51-2.91-2.22-4.2-4.73-3.76-2.25.39-3.62 2.18-3.11 5.13.51 2.95 2.45 4.16 4.7 3.77 2.47-.43 3.64-2.26 3.14-5.14zm11.14-2c-.55-3.15-2.54-4.66-5.15-4.2-2.61.46-3.9 2.37-3.32 5.68.58 3.31 2.58 4.69 5.06 4.26 2.64-.44 3.97-2.54 3.39-5.72l.02-.02z"
                            ></path>
                        </g>
                        <g
                            id="Block-7"
                            fillRule="nonzero"
                            transform="translate(561 2110)"
                            style={{
                                display: sBlock('block_7').active ? '' : 'none'
                            }}
                        >
                            <g>
                                <path
                                    fill={sBlockUnit('block_7', '1').colour}
                                    stroke={sBlockUnit('block_7', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_7', '1').width
                                    }
                                    opacity={sBlockUnit('block_7', '1').opacity}
                                    d="M197.63 0.84L0.97 35.79 8.89 79.13 14.02 79.13 20.54 117.34 15.41 117.34 15.67 118.8 212.46 82.2z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_7', '1')
                                                .floorplanID,
                                            sBlockUnit('block_7', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_7'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M133.76 60.36l1 5.46L120 68.44l-.94-5.25c-.6-3.41.6-5.38 2.9-5.79a3.26 3.26 0 013.56 1.48 3.54 3.54 0 013-3c2.73-.51 4.64 1.12 5.24 4.48zm-12.94 2.52l.58 3.25 4.46-.8-.61-3.43c-.33-1.84-1.14-2.78-2.79-2.49-1.65.29-1.98 1.59-1.64 3.47zm6.15-1.39l.63 3.53 5-.9-.61-3.43c-.37-2.08-1.33-3.13-3.1-2.81-1.77.32-2.28 1.6-1.89 3.61h-.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M98.59 62.32l-21.28 3.95c1.68 1.76 3.4 3.51 5.08 5.27l-3.49.65c-1.86-1.88-3.75-3.75-5.6-5.62l-.56-3L98 58.9l.59 3.42z"
                                ></path>
                            </g>
                            <g transform="translate(15 82)">
                                <path
                                    fill={sBlockUnit('block_7', '2').colour}
                                    stroke={sBlockUnit('block_7', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_7', '2').width
                                    }
                                    opacity={sBlockUnit('block_7', '2').opacity}
                                    d="M197.46 0.2L0.67 36.8 13.46 108.51 23.25 108.51 28.01 134.79 215.76 100.6z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_7', '2')
                                                .floorplanID,
                                            sBlockUnit('block_7', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_7'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M134.94 69.29l1 5.46-14.69 2.61-.94-5.25c-.6-3.41.6-5.37 2.9-5.78a3.27 3.27 0 013.57 1.47 3.55 3.55 0 013-3c2.65-.5 4.56 1.1 5.16 4.49zM122 71.8l.58 3.25 4.46-.79-.61-3.44c-.33-1.83-1.14-2.78-2.79-2.48-1.65.3-1.98 1.54-1.64 3.46zm6.15-1.39l.63 3.54 5-.9-.61-3.43c-.37-2.09-1.32-3.14-3.1-2.82-1.78.32-2.28 1.61-1.92 3.61z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M99.07 79.72c-1-.79-9-5.67-10.14-6.55-3-2.26-5.43-3.32-7.87-2.87a4 4 0 00-3.39 5c.56 3 2.42 4.15 5.34 4.06l.62 3.34c-4.08.31-8-1.48-9-6.86-.87-4.73 1.37-8.24 5.67-9 3.23-.6 6.3.52 9.89 3.24.71.58 7.08 4.44 7.83 5.06l-2.13-11.5 3.09-.57 3 16.11-2.91.54z"
                                ></path>
                            </g>
                            <g transform="translate(38 182)">
                                <path
                                    fill={sBlockUnit('block_7', '3').colour}
                                    stroke={sBlockUnit('block_7', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_7', '3').width
                                    }
                                    opacity={sBlockUnit('block_7', '3').opacity}
                                    d="M192.76 0.6L5.01 34.79 10.04 62.57 0.25 64.89 11.89 125.9 209.06 90z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_7', '3')
                                                .floorplanID,
                                            sBlockUnit('block_7', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_7'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M127.81 58.44l1 5.45-14.7 2.62-.93-5.25c-.61-3.41.6-5.38 2.89-5.78a3.25 3.25 0 013.57 1.47 3.55 3.55 0 013-3c2.66-.5 4.56 1.1 5.17 4.49zM114.86 61l.58 3.25 4.47-.8-.62-3.43c-.32-1.84-1.13-2.78-2.78-2.49-1.65.29-1.99 1.47-1.65 3.47zm6.15-1.39l.63 3.53 5.05-.89-.61-3.43c-.38-2.09-1.33-3.14-3.11-2.82-1.78.32-2.31 1.55-1.97 3.56l.01.05z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M88.86 73.19c3-.83 4.47-2.71 3.84-6.06-.56-3-2.51-4.65-5.5-4.09-2.99.56-4.14 2.8-3.58 5.85l.41 2.19-3.06.56-.42-2.25c-.51-2.77-2.24-4.18-5-3.66-2.37.43-3.65 2.44-3.15 5.17.56 3 2.21 4.41 5.16 4.27l.62 3.35c-4 .71-7.88-1.92-8.84-7.09-.9-4.87 1.42-8.39 5.35-9.12a6 6 0 016.43 2.64 6.32 6.32 0 015.4-5.4c4.65-.87 8.27 1.78 9.23 7 1.1 5.89-2.17 9.17-6.25 10.08l-.64-3.44z"
                                ></path>
                            </g>
                            <g transform="translate(49 272)">
                                <path
                                    fill={sBlockUnit('block_7', '4').colour}
                                    stroke={sBlockUnit('block_7', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_7', '4').width
                                    }
                                    opacity={sBlockUnit('block_7', '4').opacity}
                                    d="M0.89 35.9L0.9 35.94 9.75 35.94 14.88 62.04 5.56 64.37 16.75 127.28 20.08 127.28 203.64 93.86 203.62 93.73 212.19 92.3 214.79 91.83 198.06 0z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_7', '4')
                                                .floorplanID,
                                            sBlockUnit('block_7', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_7'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M132.74 56.17c.78 4.38-2.3 6.83-6.54 7.58s-8-.57-8.8-4.85a5.55 5.55 0 014-6.54l.37 2.07a3.42 3.42 0 00-2.57 4.14c.56 3.14 3.61 3.63 6.61 3.09 3-.54 5.67-2 5.11-5.17a3.4 3.4 0 00-3.91-3l-.37-2.11a5.5 5.5 0 016.1 4.79z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M94.44 80.63L75.86 72l-.64-3.46 16.08-3-.6-3.2 2.84-.53.59 3.2 6.23-1.15.64 3.49-6.22 1.15 2.17 11.68-2.51.45zM92 69l-11.32 2.1 12.81 6L92 69z"
                                ></path>
                            </g>
                            <g transform="translate(69 365)">
                                <path
                                    fill={sBlockUnit('block_7', '5').colour}
                                    stroke={sBlockUnit('block_7', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_7', '5').width
                                    }
                                    opacity={sBlockUnit('block_7', '5').opacity}
                                    d="M2.34 34.28L13.05 93.47 18.18 93.47 22.84 124.22 89.48 112.57 91.81 124.22 131.42 117.7 128.16 104.18 199 92.07 183.64 0.86 0.08 34.28z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_7', '5')
                                                .floorplanID,
                                            sBlockUnit('block_7', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_7'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M113.86 56.58l-.37-2.09 13.73-8.16.38 2.15-3.4 2 1.12 6.24 3.87.7.37 2.09-15.7-2.93zm8.66-5.17l-6.4 3.74 7.29 1.26-.89-5z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M92.42 74.74c2.85-.83 4.18-2.81 3.6-5.94-.58-3.13-3-4.52-6.63-3.85-3.16.59-5.11 2.45-4.48 5.84a5.76 5.76 0 003.1 4.35l.6 3.24-14.05 1.74-2.64-14.22 3.06-.57 2.09 11.28 7.7-.87a7.36 7.36 0 01-2.92-5c-1-5.24 2.25-8.39 6.87-9.25 5.38-1 9.33 1.43 10.31 6.7.98 5.27-1.31 8.75-6 9.92l-.61-3.37z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M235.44 289l1.54 8.84-23.81 4.16-1.49-8.51c-1-5.53 1-8.7 4.72-9.35a5.31 5.31 0 015.77 2.41 5.74 5.74 0 014.91-4.89c4.32-.81 7.4 1.8 8.36 7.34zm-21 4l.92 5.25 7.23-1.26-1-5.56c-.52-3-1.82-4.51-4.5-4-2.68.51-3.17 2.41-2.63 5.57h-.02zm10-2.23l1 5.73 8.17-1.43-1-5.56c-.59-3.38-2.13-5.08-5-4.58s-3.75 2.55-3.18 5.8l.01.04z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M209.34 280.22L208.76 276.9 229.62 273.26 227.55 261.37 230.55 260.85 233.2 276.05z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M228.83 248.13c1.25 7.2-3.79 11.29-10.59 12.48-6.8 1.19-13-1-14.26-8.14-1.26-7.14 3.77-11.22 10.63-12.42 6.86-1.2 12.95.82 14.22 8.08zm-21.9 3.83c.94 5.42 5.93 6.1 10.72 5.27 4.79-.83 9.21-3.2 8.26-8.59-.95-5.39-5.85-6-10.67-5.21s-9.24 3.2-8.31 8.57v-.04z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M224.68 224.35c1.24 7.1-3.77 11.05-10.64 12.25-6.87 1.2-13-1-14.21-7.91-.93-5.32 2-9.09 6.54-10.56l.58 3.35c-3.06 1-4.78 3.25-4.18 6.7.89 5.09 5.83 5.88 10.68 5 4.85-.88 9.2-3.29 8.31-8.35-.63-3.65-3.1-5.12-6.31-4.94l-.6-3.41c4.84-.33 8.88 2.52 9.83 7.87z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M198 215.1L197.42 211.79 209.35 209.71 195.51 200.84 194.84 196.99 205.22 203.49 218.5 192 219.17 195.82 207.94 205.44 213.54 209.02 221.24 207.68 221.82 210.99z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M206.6 191.79L204.82 181.57 207.82 181.05 209.6 191.27z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M215.15 172.76c-5.72-1-16.55-3.67-21.91-5.32l2.13 12.19-2.85.5-2.72-15.61 2.64-.46a197.93 197.93 0 0022.11 5.25l.6 3.45z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M235.44 289l1.54 8.84-23.81 4.16-1.49-8.51c-1-5.53 1-8.7 4.72-9.35a5.31 5.31 0 015.77 2.41 5.74 5.74 0 014.91-4.89c4.32-.81 7.4 1.8 8.36 7.34zm-21 4l.92 5.25 7.23-1.26-1-5.56c-.52-3-1.82-4.51-4.5-4-2.68.51-3.17 2.41-2.63 5.57h-.02zm10-2.23l1 5.73 8.17-1.43-1-5.56c-.59-3.38-2.13-5.08-5-4.58s-3.75 2.55-3.18 5.8l.01.04z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M209.34 280.22L208.76 276.9 229.62 273.26 227.55 261.37 230.55 260.85 233.2 276.05z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M228.83 248.13c1.25 7.2-3.79 11.29-10.59 12.48-6.8 1.19-13-1-14.26-8.14-1.26-7.14 3.77-11.22 10.63-12.42 6.86-1.2 12.95.82 14.22 8.08zm-21.9 3.83c.94 5.42 5.93 6.1 10.72 5.27 4.79-.83 9.21-3.2 8.26-8.59-.95-5.39-5.85-6-10.67-5.21s-9.24 3.2-8.31 8.57v-.04z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M224.68 224.35c1.24 7.1-3.77 11.05-10.64 12.25-6.87 1.2-13-1-14.21-7.91-.93-5.32 2-9.09 6.54-10.56l.58 3.35c-3.06 1-4.78 3.25-4.18 6.7.89 5.09 5.83 5.88 10.68 5 4.85-.88 9.2-3.29 8.31-8.35-.63-3.65-3.1-5.12-6.31-4.94l-.6-3.41c4.84-.33 8.88 2.52 9.83 7.87z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M198 215.1L197.42 211.79 209.35 209.71 195.51 200.84 194.84 196.99 205.22 203.49 218.5 192 219.17 195.82 207.94 205.44 213.54 209.02 221.24 207.68 221.82 210.99z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M206.6 191.79L204.82 181.57 207.82 181.05 209.6 191.27z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M215.15 172.76c-5.72-1-16.55-3.67-21.91-5.32l2.13 12.19-2.85.5-2.72-15.61 2.64-.46a197.93 197.93 0 0022.11 5.25l.6 3.45z"
                            ></path>
                        </g>
                        <g
                            id="Block-6"
                            fillRule="nonzero"
                            transform="translate(493 1559)"
                            style={{
                                display: sBlock('block_6').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(70)">
                                <path
                                    fill={sBlockUnit('block_6', '1').colour}
                                    stroke={sBlockUnit('block_6', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_6', '1').width
                                    }
                                    opacity={sBlockUnit('block_6', '1').opacity}
                                    d="M78.54 120.77L166.3 174.34 215.55 95 184.44 73.93 186.54 70.44 129.22 34.79 114.89 53.66 95.67 40.38 105.1 25 63.16 0.19 35.2 44.92 30.66 43.18 0.36 90.88 70.5 133.7z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_6', '1')
                                                .floorplanID,
                                            sBlockUnit('block_6', '1')
                                                .unit_elevation.value,
                                            6,
                                            'block_6'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M109.66 92.8l1.12-1.81 15.68 3-1.15 1.86-3.86-.78-3.33 5.4 2.43 3.1-1.11 1.81-9.78-12.58zm9.9 1.9l-7.26-1.46 4.6 5.79 2.66-4.33z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M94 91L75.84 79.22c0 2.43 0 4.89.06 7.32l-3-1.93c-.06-2.64-.14-5.3-.19-7.94l1.65-2.54 21.51 14L94 91z"
                                ></path>
                            </g>
                            <g transform="translate(0 90)">
                                <path
                                    fill={sBlockUnit('block_6', '2').colour}
                                    stroke={sBlockUnit('block_6', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_6', '2').width
                                    }
                                    opacity={sBlockUnit('block_6', '2').opacity}
                                    d="M210.4 151.7L205.51 151.7 198.52 103.47 215.65 72.71 235.92 84.94 236.3 84.34 148.54 30.77 140.5 43.7 70.36 0.88 70.25 1.06 76.54 5 64.66 23.78 60.46 22.03 23.76 80.4 27.61 83.2 25.51 87.74 31.45 126.54 0 133.53 9.73 188.43 210.53 152.37z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_6', '2')
                                                .floorplanID,
                                            sBlockUnit('block_6', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_6'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M131.16 104.12l.52-2 15.88-1.73-.54 2.12-3.92.4-1.58 6.14 3.24 2.24-.53 2.06-13.07-9.23zm10-1.1l-7.36.75 6.1 4.17 1.26-4.92z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M111.52 107.7c-.58-1.15-5.68-9-6.33-10.27-1.75-3.35-3.48-5.34-5.87-6a4 4 0 00-5.23 3.08c-.8 3 .41 4.78 3.08 6l-.88 3.29c-3.82-1.49-6.61-4.8-5.19-10.09 1.24-4.65 4.78-6.84 9-5.71 3.19.85 5.47 3.19 7.54 7.19.39.83 4.47 7 4.89 7.93l3-11.29 3 .81-4.24 15.84-2.77-.78z"
                                ></path>
                            </g>
                            <g transform="translate(9 242)">
                                <path
                                    fill={sBlockUnit('block_6', '3').colour}
                                    stroke={sBlockUnit('block_6', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_6', '3').width
                                    }
                                    opacity={sBlockUnit('block_6', '3').opacity}
                                    d="M201.53 0.37L0.73 36.43 0.78 36.75 9.17 36.75 19.66 96.17 32.24 94.07 35.74 120.63 30.14 121.33 30.25 121.96 217.67 89.63z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_6', '3')
                                                .floorplanID,
                                            sBlockUnit('block_6', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_6'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M140.52 60.51c.78 4.39-2.31 6.83-6.54 7.59-4.23.76-8-.58-8.8-4.86a5.55 5.55 0 014-6.53l.37 2.06a3.43 3.43 0 00-2.57 4.15c.56 3.14 3.61 3.62 6.61 3.09 3-.53 5.67-2.06 5.11-5.18a3.4 3.4 0 00-3.91-3l-.38-2.11a5.5 5.5 0 016.11 4.79zM102.57 68.9c3-.83 4.46-2.71 3.84-6.06-.56-3-2.52-4.65-5.5-4.09-2.98.56-4.15 2.8-3.58 5.85l.41 2.19-3.06.56-.42-2.25c-.51-2.77-2.24-4.18-5-3.66-2.36.43-3.65 2.44-3.14 5.17.56 3 2.21 4.41 5.16 4.27l.62 3.35c-4 .71-7.88-1.92-8.84-7.09-.91-4.87 1.41-8.39 5.34-9.12a6 6 0 016.44 2.64 6.31 6.31 0 015.39-5.4c4.66-.87 8.28 1.78 9.24 7 1.1 5.89-2.17 9.17-6.26 10.08l-.64-3.44z"
                                ></path>
                            </g>
                            <g transform="translate(34 331)">
                                <path
                                    fill={sBlockUnit('block_6', '4').colour}
                                    stroke={sBlockUnit('block_6', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_6', '4').width
                                    }
                                    opacity={sBlockUnit('block_6', '4').opacity}
                                    d="M5.25 32.96L10.04 60.99 0.25 64.49 11.31 124.98 208.99 90.87 192.67 0.63z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_6', '4')
                                                .floorplanID,
                                            sBlockUnit('block_6', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_6'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M116.64 64.92l-.37-2.09L130 54.68l.38 2.15-3.4 2 1.11 6.24 3.88.69.37 2.09-15.7-2.93zm8.65-5.16l-6.39 3.74 7.28 1.26-.89-5zM93.48 73.69L74.89 65l-.64-3.46 16.08-3-.59-3.21 2.83-.52.6 3.2 6.22-1.16.65 3.5-6.22 1.15 2.16 11.68-2.5.51zm-2.5-11.62l-11.31 2.1 12.81 6-1.5-8.1z"
                                ></path>
                            </g>
                            <g transform="translate(45 421)">
                                <path
                                    fill={sBlockUnit('block_6', '5').colour}
                                    stroke={sBlockUnit('block_6', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_6', '5').width
                                    }
                                    opacity={sBlockUnit('block_6', '5').opacity}
                                    d="M11.62 96.82L15.81 96.82 21.75 127.57 214.34 91.22 197.99 0.87 0.31 34.98z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_6', '5')
                                                .floorplanID,
                                            sBlockUnit('block_6', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_6'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M121.61 64.67l-.37-2.09 13.73-8.16.38 2.15-3.4 2 1.11 6.24 3.88.7.37 2.09-15.7-2.93zm8.65-5.17l-6.39 3.74 7.28 1.26-.89-5z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M100.24 69.68c2.86-.83 4.18-2.81 3.6-5.94-.58-3.13-3-4.52-6.62-3.85-3.16.58-5.11 2.45-4.49 5.84a5.77 5.77 0 003.11 4.35l.6 3.24-14.05 1.74-2.64-14.23 3-.56 2.1 11.28 7.7-.87a7.33 7.33 0 01-2.92-5c-1-5.24 2.24-8.4 6.86-9.25 5.39-1 9.34 1.43 10.32 6.7.98 5.27-1.31 8.75-6 9.92l-.57-3.37z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M225.43 390.58l1.55 8.84-23.81 4.15-1.49-8.5c-1-5.53 1-8.7 4.72-9.35a5.29 5.29 0 015.77 2.41 5.75 5.75 0 014.91-4.9c4.32-.75 7.4 1.85 8.35 7.35zm-21 4l.92 5.26 7.23-1.26-1-5.56c-.52-3-1.82-4.52-4.5-4-2.68.52-3.16 2.45-2.62 5.56h-.03zm10-2.22l1 5.73 8.17-1.43-1-5.56c-.59-3.38-2.13-5.08-5-4.58s-3.74 2.59-3.17 5.84z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M199.34 381.84L198.76 378.53 219.62 374.88 217.55 363 220.55 362.48 223.2 377.68z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M218.83 349.76c1.25 7.2-3.8 11.29-10.59 12.48-6.79 1.19-13-1-14.26-8.14-1.26-7.14 3.77-11.22 10.63-12.42 6.86-1.2 12.95.81 14.22 8.08zm-21.91 3.82c1 5.43 5.94 6.11 10.73 5.28 4.79-.83 9.2-3.2 8.26-8.59-.94-5.39-5.85-6-10.67-5.21s-9.24 3.2-8.32 8.52z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M214.67 326c1.24 7.1-3.76 11-10.63 12.24-6.87 1.24-13-1-14.22-7.9-.92-5.33 2-9.09 6.55-10.57l.58 3.35c-3.06 1-4.78 3.25-4.18 6.7.89 5.09 5.83 5.89 10.68 5 4.85-.89 9.19-3.29 8.31-8.35-.64-3.65-3.1-5.12-6.31-4.94l-.6-3.41c4.84-.35 8.88 2.47 9.82 7.88z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M188 316.73L187.42 313.42 199.34 311.34 185.51 302.47 184.84 298.61 195.22 305.12 208.53 293.58 209.2 297.4 197.94 307 203.53 310.58 211.24 309.23 211.82 312.55z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M196.6 293.41L194.82 283.2 197.82 282.68 199.6 292.89z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M204.76 269.21c1.12 6.4-4.43 9-9.85 10-7.7 1.34-13.39 0-14.45-6.07-.77-4.39 1.48-7.13 4.76-8.11l.54 3.08a3.72 3.72 0 00-2.58 4.62c.69 3.92 4.84 4.37 9.54 3.65a6.47 6.47 0 01-3.7-5c-.85-4.86 1.92-7.79 6.47-8.59 4.55-.8 8.41 1.46 9.27 6.42zm-13 2.14c.46 2.61 2.65 4.3 5.77 3.76 2.81-.49 5.14-2.38 4.6-5.43s-3-4.35-6-3.81c-2.91.49-4.99 2.2-4.42 5.48h.05zM225.43 390.58l1.55 8.84-23.81 4.15-1.49-8.5c-1-5.53 1-8.7 4.72-9.35a5.29 5.29 0 015.77 2.41 5.75 5.75 0 014.91-4.9c4.32-.75 7.4 1.85 8.35 7.35zm-21 4l.92 5.26 7.23-1.26-1-5.56c-.52-3-1.82-4.52-4.5-4-2.68.52-3.16 2.45-2.62 5.56h-.03zm10-2.22l1 5.73 8.17-1.43-1-5.56c-.59-3.38-2.13-5.08-5-4.58s-3.74 2.59-3.17 5.84z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M199.34 381.84L198.76 378.53 219.62 374.88 217.55 363 220.55 362.48 223.2 377.68z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M218.83 349.76c1.25 7.2-3.8 11.29-10.59 12.48-6.79 1.19-13-1-14.26-8.14-1.26-7.14 3.77-11.22 10.63-12.42 6.86-1.2 12.95.81 14.22 8.08zm-21.91 3.82c1 5.43 5.94 6.11 10.73 5.28 4.79-.83 9.2-3.2 8.26-8.59-.94-5.39-5.85-6-10.67-5.21s-9.24 3.2-8.32 8.52z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M214.67 326c1.24 7.1-3.76 11-10.63 12.24-6.87 1.24-13-1-14.22-7.9-.92-5.33 2-9.09 6.55-10.57l.58 3.35c-3.06 1-4.78 3.25-4.18 6.7.89 5.09 5.83 5.89 10.68 5 4.85-.89 9.19-3.29 8.31-8.35-.64-3.65-3.1-5.12-6.31-4.94l-.6-3.41c4.84-.35 8.88 2.47 9.82 7.88z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M188 316.73L187.42 313.42 199.34 311.34 185.51 302.47 184.84 298.61 195.22 305.12 208.53 293.58 209.2 297.4 197.94 307 203.53 310.58 211.24 309.23 211.82 312.55z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M196.6 293.41L194.82 283.2 197.82 282.68 199.6 292.89z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M204.76 269.21c1.12 6.4-4.43 9-9.85 10-7.7 1.34-13.39 0-14.45-6.07-.77-4.39 1.48-7.13 4.76-8.11l.54 3.08a3.72 3.72 0 00-2.58 4.62c.69 3.92 4.84 4.37 9.54 3.65a6.47 6.47 0 01-3.7-5c-.85-4.86 1.92-7.79 6.47-8.59 4.55-.8 8.41 1.46 9.27 6.42zm-13 2.14c.46 2.61 2.65 4.3 5.77 3.76 2.81-.49 5.14-2.38 4.6-5.43s-3-4.35-6-3.81c-2.91.49-4.99 2.2-4.42 5.48h.05z"
                            ></path>
                        </g>
                        <g
                            id="Block-5"
                            fillRule="nonzero"
                            transform="translate(717 1037)"
                            style={{
                                display: sBlock('block_5').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(547)">
                                <path
                                    fill={sBlockUnit('block_5', '1').colour}
                                    stroke={sBlockUnit('block_5', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_5', '1').width
                                    }
                                    opacity={sBlockUnit('block_5', '1').opacity}
                                    d="M146.04 192.8L88.32 0.22 46.76 13.38 47.95 16.97 0.5 30.7 57.34 218.73z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_5', '1')
                                                .floorplanID,
                                            sBlockUnit('block_5', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_5'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M83.4 142.62l-5.33 1.52-4.1-14.35 5.13-1.46c3.33-1 5.4 0 6.05 2.29a3.28 3.28 0 01-1.11 3.7 3.55 3.55 0 013.33 2.69c.74 2.61-.66 4.66-3.97 5.61zM79.58 130l-3.17.91 1.24 4.36 3.35-1c1.8-.51 2.66-1.41 2.2-3-.46-1.59-1.75-1.81-3.62-1.27zm2 6l-3.45 1 1.4 4.93 3.36-1c2-.58 3-1.64 2.48-3.37-.52-1.73-1.82-2.14-3.78-1.56h-.01z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74 113.57l-5.93-20.82c-1.6 1.84-3.18 3.71-4.77 5.55l-1-3.41c1.69-2 3.37-4.08 5.07-6.1l2.92-.84 7 24.66-3.29.96z"
                                ></path>
                            </g>
                            <g transform="translate(459 26)">
                                <path
                                    fill={sBlockUnit('block_5', '2').colour}
                                    stroke={sBlockUnit('block_5', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_5', '2').width
                                    }
                                    opacity={sBlockUnit('block_5', '2').opacity}
                                    d="M88.5 4.7L88.41 4.72 86.91 0.24 26.51 18.18 28.6 27.15 0.8 35.14 55.9 218.88 145.34 192.73z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_5', '2')
                                                .floorplanID,
                                            sBlockUnit('block_5', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_5'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M82.17 142.12l-5.33 1.52-4.1-14.36 5.13-1.46c3.33-1 5.41 0 6.05 2.29a3.27 3.27 0 01-1.11 3.7 3.55 3.55 0 013.33 2.7c.74 2.6-.66 4.66-3.97 5.61zm-3.82-12.62l-3.17.9 1.24 4.36 3.36-1c1.79-.51 2.65-1.41 2.19-3-.46-1.59-1.74-1.76-3.62-1.26zm2 6l-3.45 1 1.41 4.92 3.35-1c2-.59 3-1.64 2.48-3.38s-1.82-2.13-3.78-1.57l-.01.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M65 112.18c.69-1.08 4.8-9.49 5.56-10.7 2-3.22 2.8-5.73 2.12-8.11a4 4 0 00-5.33-2.91c-3 .85-3.9 2.81-3.53 5.7l-3.28.94c-.69-4 .72-8.13 6-9.64 4.63-1.31 8.33.59 9.52 4.79.91 3.17.08 6.33-2.29 10.16-.52.76-3.77 7.46-4.3 8.27l11.24-3.21.86 3-15.76 4.49-.81-2.78z"
                                ></path>
                            </g>
                            <g transform="translate(373 61)">
                                <path
                                    fill={sBlockUnit('block_5', '3').colour}
                                    stroke={sBlockUnit('block_5', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_5', '3').width
                                    }
                                    opacity={sBlockUnit('block_5', '3').opacity}
                                    d="M86.49 0.23L88.29 7.7 61.37 14.58 59.28 8.3 0.58 26.47 53.62 209.69 141.9 183.88 86.8 0.14z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_5', '3')
                                                .floorplanID,
                                            sBlockUnit('block_5', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_5'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M78.53 132.73l-5.33 1.52-4.1-14.35 5.12-1.47c3.34-1 5.41 0 6 2.29a3.27 3.27 0 01-1.1 3.7 3.56 3.56 0 013.32 2.7c.8 2.6-.6 4.66-3.91 5.61zm-3.83-12.62l-3.17.9 1.25 4.36 3.35-.95c1.8-.52 2.65-1.42 2.19-3-.46-1.58-1.74-1.85-3.62-1.31zm2 6l-3.46 1 1.41 4.93 3.35-1c2-.58 3-1.64 2.49-3.38-.51-1.74-1.82-2.14-3.77-1.58l-.02.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M63.76 97.39c1.11 2.95 3.12 4.19 6.39 3.25 3-.84 4.4-2.94 3.56-5.86-.84-2.92-3.17-3.86-6.16-3l-2.14.61-.85-3 2.21-.63c2.7-.77 3.94-2.63 3.17-5.33-.66-2.31-2.78-3.4-5.45-2.64-2.91.83-4.18 2.61-3.77 5.54l-3.27.93c-1.09-4 1.17-8 6.23-9.47 4.76-1.36 8.48.62 9.58 4.46a6 6 0 01-2 6.66 6.31 6.31 0 015.89 4.86c1.3 4.56-1 8.4-6.08 9.85-5.76 1.65-9.34-1.3-10.62-5.28l3.31-.95z"
                                ></path>
                            </g>
                            <g transform="translate(288 82)">
                                <path
                                    fill={sBlockUnit('block_5', '4').colour}
                                    stroke={sBlockUnit('block_5', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_5', '4').width
                                    }
                                    opacity={sBlockUnit('block_5', '4').opacity}
                                    d="M85.37 5.54L83.28 0.16 24.66 18.1 27.36 30.06 0.44 37.84 51.52 214.15 138.62 188.69 85.58 5.47z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_5', '4')
                                                .floorplanID,
                                            sBlockUnit('block_5', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_5'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74.82 137.07l-4.92 1.41-4.1-14.35 5.15-1.47c4.54-1.3 7.06 1.41 8.19 5.36s.29 7.74-4.32 9.05zm-3.61-12.59l-2.93.84 3.1 10.84 2.72-.78c3.72-1.06 3.82-3.84 3-6.75-.82-2.91-2.32-5.17-5.89-4.15zM56.14 101.25l6.88-19.31 3.38-1 4.49 15.73 3.13-.89.79 2.77-3.13.9 1.73 6.08-3.41 1-1.74-6.09-11.42 3.26-.7-2.45zm11.33-3.57l-3.16-11.07-4.74 13.32 7.9-2.25z"
                                ></path>
                            </g>
                            <g transform="translate(194 111)">
                                <path
                                    fill={sBlockUnit('block_5', '5').colour}
                                    stroke={sBlockUnit('block_5', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_5', '5').width
                                    }
                                    opacity={sBlockUnit('block_5', '5').opacity}
                                    d="M94.44 8.84L91.75 0.76 63.34 9.73 60.65 0.76 0.85 18.67 58.75 210.52 145.52 185.15 94.44 8.84z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_5', '5')
                                                .floorplanID,
                                            sBlockUnit('block_5', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_5'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M82.39 132.77l-5.33 1.52-4.1-14.35 5.13-1.47c3.33-1 5.41 0 6 2.29a3.27 3.27 0 01-1.11 3.7 3.57 3.57 0 013.33 2.7c.79 2.6-.61 4.66-3.92 5.61zm-3.82-12.62l-3.17.9 1.24 4.37 3.36-1c1.79-.52 2.65-1.42 2.19-3-.46-1.58-1.74-1.81-3.62-1.27zm2 6l-3.45 1 1.41 4.93 3.35-1c2-.58 3-1.64 2.48-3.37-.52-1.73-1.82-2.14-3.78-1.59l-.01.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M66.2 98c1.1 2.76 3.19 3.9 6.25 3 3.06-.9 4.22-3.44 3.21-7-.88-3.1-2.92-4.86-6.23-3.92a5.76 5.76 0 00-4 3.5l-3.17.9-3.06-13.82 13.92-4 .85 3-11 3.14 1.58 7.59a7.35 7.35 0 014.7-3.37c5.12-1.46 8.56 1.44 9.85 6 1.5 5.27-.54 9.43-5.7 10.9-5.16 1.47-8.83-.48-10.44-5L66.2 98z"
                                ></path>
                            </g>
                            <g transform="translate(57 129)">
                                <path
                                    fill={sBlockUnit('block_5', '6').colour}
                                    stroke={sBlockUnit('block_5', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_5', '6').width
                                    }
                                    opacity={sBlockUnit('block_5', '6').opacity}
                                    d="M85.81 16.26L94.19 49.15 55.01 60.21 52.32 65.3 47.84 62.9 13.15 120.02 16.74 122.71 4.77 142.15 0.75 140.22 68.47 182.52 60.69 195.97 128.23 236.84 147.12 206.74 195.75 192.52 137.85 0.67z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_5', '6')
                                                .floorplanID,
                                            sBlockUnit('block_5', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_5'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M118.1 160.22l1.49-1.51 14.61 6.47-1.53 1.55-3.6-1.61-4.44 4.52 1.68 3.56-1.49 1.51-6.72-14.49zm9.23 4.07l-6.75-3 3.19 6.67 3.56-3.67zM120 134.43c-5.21 4.78-10.87 1.23-14.92-3.2-5.74-6.27-7.83-12.29-2.9-16.81 3.58-3.27 7.41-2.81 10.24-.38l-2.51 2.3a4.07 4.07 0 00-5.76.34c-3.19 2.93-1.17 7 2.25 10.89a7.05 7.05 0 012.42-6.33c4-3.62 8.27-2.81 11.67.91 3.4 3.72 3.51 8.59-.49 12.28zm-9.61-10.71c-2.13 2-2.39 5-.07 7.48 2.1 2.3 5.2 3.32 7.68 1 2.48-2.32 2.25-5.27 0-7.78-2.16-2.24-4.94-3.15-7.62-.7h.01z"
                                ></path>
                            </g>
                            <g transform="translate(0 267)">
                                <path
                                    fill={sBlockUnit('block_5', '7').colour}
                                    stroke={sBlockUnit('block_5', '7').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_5', '7').width
                                    }
                                    opacity={sBlockUnit('block_5', '7').opacity}
                                    d="M125.47 44.52L57.75 2.22 54.9 0.86 26.19 49 29.18 51.99 0.47 97.75 42.64 124.36 54.9 107.61 73.44 120.47 60.58 141.11 119.19 177.29 122.18 173.1 154.77 192.84 205.61 111.2 184.98 99.24 185.23 98.84 117.69 57.97z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_5', '7')
                                                .floorplanID,
                                            sBlockUnit('block_5', '7')
                                                .unit_elevation.value,
                                            7,
                                            'block_5'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M107.13 112.13l1.12-1.8 15.66 3.13-1.15 1.85-3.86-.79-3.35 5.38L118 123l-1.12 1.8-9.75-12.67zm9.88 2l-7.25-1.5 4.57 5.81 2.68-4.31z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M94.38 105.53c-4-4.88-11-14.81-14.23-20l-6.94 11.54-2.69-1.62 8.88-14.78 2.5 1.51a217.68 217.68 0 0014.44 20.07l-1.96 3.28z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M361.26 277.54l-8.61 2.54-6.83-23.19 8.29-2.44c5.38-1.58 8.75 0 9.82 3.63a5.31 5.31 0 01-1.74 6 5.74 5.74 0 015.41 4.32c1.24 4.22-1 7.6-6.34 9.14zm-6.36-20.38l-5.12 1.5 2.07 7 5.42-1.59c2.9-.86 4.28-2.33 3.51-4.94-.77-2.61-2.85-2.87-5.88-1.97zm3.34 9.65l-5.58 1.64 2.34 8 5.42-1.6c3.29-1 4.8-2.69 4-5.49-.8-2.8-3.02-3.48-6.18-2.55z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M367 250.63L370.23 249.63 376.23 269.94 387.81 266.54 388.66 269.44 373.85 273.8z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M401.06 266.36c-7 2.06-11.65-2.49-13.6-9.11-1.95-6.62-.43-13 6.48-15.09s11.57 2.48 13.54 9.16c1.97 6.68.66 12.96-6.42 15.04zm-6.28-21.33c-5.28 1.56-5.39 6.59-4 11.25 1.39 4.66 4.22 8.79 9.47 7.24 5.25-1.55 5.35-6.5 4-11.19-1.35-4.69-4.25-8.83-9.47-7.33v.03z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M424.22 259.54c-6.92 2-11.4-2.49-13.37-9.17-1.97-6.68-.5-13 6.25-15 5.18-1.53 9.26 1 11.23 5.3l-3.26 1c-1.36-2.93-3.77-4.38-7.13-3.39-5 1.45-5.19 6.45-3.79 11.18 1.4 4.73 4.31 8.76 9.23 7.31 3.56-1 4.74-3.66 4.2-6.83l3.32-1c.91 4.72-1.43 9.06-6.68 10.6z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M430.39 232L433.62 231 437.03 242.61 444.28 227.86 448.03 226.75 442.74 237.81 455.74 249.73 452.02 250.82 441.19 240.75 438.27 246.75 440.48 254.25 437.25 255.25z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M454.53 237.9L464.53 234.97 465.38 237.88 455.44 240.8z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M472.89 237.89c1 2.53 3 3.56 5.76 2.73 2.76-.83 3.85-3.19 2.9-6.42-.84-2.83-2.73-4.44-5.76-3.55a5.34 5.34 0 00-3.69 3.25l-2.9.85-2.92-12.67 12.76-3.76.8 2.74-10.11 3 1.52 7a6.74 6.74 0 014.28-3.14c4.7-1.38 7.89 1.26 9.11 5.4 1.42 4.83-.43 8.67-5.16 10.06-4.73 1.39-8.12-.38-9.63-4.54l3.04-.95z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M361.26 277.54l-8.61 2.54-6.83-23.19 8.29-2.44c5.38-1.58 8.75 0 9.82 3.63a5.31 5.31 0 01-1.74 6 5.74 5.74 0 015.41 4.32c1.24 4.22-1 7.6-6.34 9.14zm-6.36-20.38l-5.12 1.5 2.07 7 5.42-1.59c2.9-.86 4.28-2.33 3.51-4.94-.77-2.61-2.85-2.87-5.88-1.97zm3.34 9.65l-5.58 1.64 2.34 8 5.42-1.6c3.29-1 4.8-2.69 4-5.49-.8-2.8-3.02-3.48-6.18-2.55z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M367 250.63L370.23 249.63 376.23 269.94 387.81 266.54 388.66 269.44 373.85 273.8z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M401.06 266.36c-7 2.06-11.65-2.49-13.6-9.11-1.95-6.62-.43-13 6.48-15.09s11.57 2.48 13.54 9.16c1.97 6.68.66 12.96-6.42 15.04zm-6.28-21.33c-5.28 1.56-5.39 6.59-4 11.25 1.39 4.66 4.22 8.79 9.47 7.24 5.25-1.55 5.35-6.5 4-11.19-1.35-4.69-4.25-8.83-9.47-7.33v.03z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M424.22 259.54c-6.92 2-11.4-2.49-13.37-9.17-1.97-6.68-.5-13 6.25-15 5.18-1.53 9.26 1 11.23 5.3l-3.26 1c-1.36-2.93-3.77-4.38-7.13-3.39-5 1.45-5.19 6.45-3.79 11.18 1.4 4.73 4.31 8.76 9.23 7.31 3.56-1 4.74-3.66 4.2-6.83l3.32-1c.91 4.72-1.43 9.06-6.68 10.6z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M430.39 232L433.62 231 437.03 242.61 444.28 227.86 448.03 226.75 442.74 237.81 455.74 249.73 452.02 250.82 441.19 240.75 438.27 246.75 440.48 254.25 437.25 255.25z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M454.53 237.9L464.53 234.97 465.38 237.88 455.44 240.8z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M472.89 237.89c1 2.53 3 3.56 5.76 2.73 2.76-.83 3.85-3.19 2.9-6.42-.84-2.83-2.73-4.44-5.76-3.55a5.34 5.34 0 00-3.69 3.25l-2.9.85-2.92-12.67 12.76-3.76.8 2.74-10.11 3 1.52 7a6.74 6.74 0 014.28-3.14c4.7-1.38 7.89 1.26 9.11 5.4 1.42 4.83-.43 8.67-5.16 10.06-4.73 1.39-8.12-.38-9.63-4.54l3.04-.95z"
                            ></path>
                        </g>
                        <g
                            id="Block-4"
                            fillRule="nonzero"
                            transform="translate(1390 876)"
                            style={{
                                display: sBlock('block_4').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(438)">
                                <path
                                    fill={sBlockUnit('block_4', '1').colour}
                                    stroke={sBlockUnit('block_4', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_4', '1').width
                                    }
                                    opacity={sBlockUnit('block_4', '1').opacity}
                                    d="M148.3 187.24L94.31 0.04 62.29 8.21 60.49 4.08 0.61 22.02 1.62 25.66 57.9 213.8z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_4', '1')
                                                .floorplanID,
                                            sBlockUnit('block_4', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_4'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M80.08 124.61l2-.58 9.52 12.83-2.1.6-2.33-3.18-6.09 1.74-.3 3.93-2 .58 1.3-15.92zm6 8.09l-4.38-6-.51 7.37 4.89-1.37z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M79 111.11L73.07 90.3c-1.59 1.84-3.18 3.71-4.77 5.55l-1-3.42c1.69-2 3.37-4.08 5.07-6.1l2.92-.83 7 24.66-3.29.95z"
                                ></path>
                            </g>
                            <g transform="translate(353 25)">
                                <path
                                    fill={sBlockUnit('block_4', '2').colour}
                                    stroke={sBlockUnit('block_4', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_4', '2').width
                                    }
                                    opacity={sBlockUnit('block_4', '2').opacity}
                                    d="M86.62 0.66L86.77 1.22 28.05 18.69 29.22 23.12 0.79 31.74 2.89 39.43 2.73 39.48 54.74 214.7 142.9 188.8z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_4', '2')
                                                .floorplanID,
                                            sBlockUnit('block_4', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_4'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M75 125.35l2-.58 9.51 12.83-2.1.6-2.32-3.18-6.1 1.74-.3 3.93-2 .58L75 125.35zm6 8.09l-4.37-6-.52 7.37 4.89-1.37z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M66.85 110.78c.69-1.09 4.8-9.49 5.56-10.71 2-3.22 2.8-5.72 2.12-8.11a4 4 0 00-5.33-2.9c-3 .84-3.9 2.8-3.53 5.7l-3.28.93c-.69-4 .73-8.13 6-9.63 4.63-1.32 8.33.59 9.52 4.79.91 3.17.08 6.33-2.29 10.16-.52.76-3.77 7.46-4.3 8.26l11.24-3.2.86 3-15.76 4.5-.81-2.79z"
                                ></path>
                            </g>
                            <g transform="translate(266 64)">
                                <path
                                    fill={sBlockUnit('block_4', '3').colour}
                                    stroke={sBlockUnit('block_4', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_4', '3').width
                                    }
                                    opacity={sBlockUnit('block_4', '3').opacity}
                                    d="M63.09 8.12L60.53 0.43 2.51 17.44 1.11 12.78 0.91 12.84 56.67 200.69 141.74 175.7 89.73 0.48z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_4', '3')
                                                .floorplanID,
                                            sBlockUnit('block_4', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_4'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M75.17 111.16l2-.58 9.52 12.83-2.1.6-2.33-3.18-6.1 1.74-.3 3.93-2 .58 1.31-15.92zm6 8.09l-4.37-6-.51 7.37 4.88-1.37z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M66.46 92c1.11 3 3.12 4.19 6.39 3.26 3-.84 4.39-3 3.56-5.86-.87-3.07-3.18-3.87-6.17-3l-2.13.61-.85-3 2.2-.63c2.71-.77 4-2.62 3.18-5.33-.66-2.31-2.78-3.4-5.45-2.64-2.92.83-4.18 2.62-3.77 5.54l-3.27.93c-1.09-4 1.17-8 6.22-9.47 4.77-1.36 8.49.62 9.59 4.47a6 6 0 01-2 6.65 6.32 6.32 0 015.89 4.87c1.3 4.55-1 8.4-6.08 9.85-5.77 1.64-9.34-1.3-10.63-5.28l3.32-.97z"
                                ></path>
                            </g>
                            <g transform="translate(181 76)">
                                <path
                                    fill={sBlockUnit('block_4', '4').colour}
                                    stroke={sBlockUnit('block_4', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_4', '4').width
                                    }
                                    opacity={sBlockUnit('block_4', '4').opacity}
                                    d="M25.53 18.96L27.86 29.21 1.76 37.6 0.39 34.3 51.88 215.07 141.67 188.69 85.91 0.84z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_4', '4')
                                                .floorplanID,
                                            sBlockUnit('block_4', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_4'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M77.94 138.53c-4.28 1.22-7-1.59-8.21-5.73-1.21-4.14-.25-8.06 3.93-9.25a5.54 5.54 0 016.91 3.33l-2 .58a3.41 3.41 0 00-4.38-2.13c-3.07.87-3.24 4-2.4 6.88.84 2.88 2.62 5.43 5.67 4.56a3.4 3.4 0 002.62-4.2l2.06-.58a5.49 5.49 0 01-4.2 6.54z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M58.67 105l6.88-19.32 3.38-1 4.48 15.72 3.14-.89.79 2.78-3.14.89 1.74 6.08-3.42 1-1.73-6.09-11.42 3.26-.7-2.43zM70 101.42l-3.16-11.07-4.74 13.32 7.9-2.25z"
                                ></path>
                            </g>
                            <g transform="translate(90 108)">
                                <path
                                    fill={sBlockUnit('block_4', '5').colour}
                                    stroke={sBlockUnit('block_4', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_4', '5').width
                                    }
                                    opacity={sBlockUnit('block_4', '5').opacity}
                                    d="M90.43 0L61.07 7 59.67 3.73 1.42 20.04 0.96 16.32 0.16 16.55 54.95 208.9 142.88 183.07 91.39 2.3z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_4', '5')
                                                .floorplanID,
                                            sBlockUnit('block_4', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_4'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74.42 117.67l2-.59 9.52 12.83-2.1.6-2.33-3.18-6.09 1.74-.3 3.93-2 .58 1.3-15.91zm6 8.08l-4.38-6-.51 7.37 4.89-1.37z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M65.56 98.77c1.09 2.76 3.19 3.9 6.25 3 3.06-.9 4.21-3.44 3.21-7-.88-3.1-2.92-4.86-6.23-3.92a5.8 5.8 0 00-4 3.5l-3.16.91-3.06-13.83 13.91-4 .85 3-11 3.15 1.59 7.59a7.34 7.34 0 014.69-3.38c5.13-1.46 8.57 1.44 9.86 6 1.5 5.27-.54 9.43-5.7 10.9-5.16 1.47-8.84-.48-10.44-5l3.23-.92z"
                                ></path>
                            </g>
                            <g transform="translate(0 124)">
                                <path
                                    fill={sBlockUnit('block_4', '6').colour}
                                    stroke={sBlockUnit('block_4', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_4', '6').width
                                    }
                                    opacity={sBlockUnit('block_4', '6').opacity}
                                    d="M29.44 18.49L29.44 23.15 0.08 31.54 54.54 219.46 144.95 192.9 90.16 0.55z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_4', '6')
                                                .floorplanID,
                                            sBlockUnit('block_4', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_4'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M77.1 132.8l2-.58 9.52 12.83-2.1.6-2.33-3.18-6.1 1.74-.3 3.93-2 .58 1.31-15.92zm6 8.08l-4.37-6-.51 7.37 4.88-1.37zM73.6 114.1c-6.79 1.93-10.27-3.77-11.91-9.54-2.33-8.18-1.52-14.49 4.92-16.33 4.66-1.33 7.89.79 9.33 4.23l-3.28.93a4.06 4.06 0 00-5.3-2.26c-4.16 1.19-4.17 5.74-2.86 10.75a7.05 7.05 0 015-4.58c5.16-1.47 8.65 1.19 10 6 1.35 4.81-.63 9.3-5.9 10.8zm-3.8-13.89c-2.78.79-4.35 3.36-3.41 6.67.85 3 3.16 5.29 6.4 4.37 3.24-.92 4.37-3.71 3.44-7-.86-2.97-2.94-5.03-6.43-4.04z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M259.72 271.7l-8.61 2.54-6.82-23.19 8.28-2.44c5.39-1.58 8.76 0 9.83 3.63a5.32 5.32 0 01-1.74 6 5.74 5.74 0 015.41 4.32c1.24 4.22-1 7.57-6.35 9.14zm-6.35-20.38l-5.12 1.5 2.07 7 5.42-1.59c2.9-.86 4.28-2.33 3.51-4.94-.77-2.61-2.85-2.87-5.88-1.97zm3.34 9.65l-5.58 1.64 2.34 8 5.42-1.6c3.29-1 4.8-2.69 4-5.49-.8-2.8-3.02-3.52-6.18-2.52v-.03z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M265.45 244.79L268.68 243.79 274.68 264.1 286.26 260.7 287.11 263.6 272.3 267.96z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M299.53 260.52c-7 2.06-11.65-2.49-13.6-9.11-1.95-6.62-.43-13.05 6.48-15.09 6.91-2.04 11.57 2.48 13.54 9.16 1.97 6.68.66 12.96-6.42 15.04zm-6.28-21.33c-5.28 1.56-5.39 6.59-4 11.25 1.39 4.66 4.21 8.79 9.47 7.24 5.26-1.55 5.35-6.5 4-11.19-1.35-4.69-4.28-8.82-9.47-7.3z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M322.69 253.71c-6.92 2-11.4-2.5-13.37-9.18-1.97-6.68-.5-13 6.25-15 5.18-1.53 9.26 1 11.23 5.3l-3.26 1c-1.36-2.93-3.77-4.38-7.13-3.39-5 1.45-5.19 6.45-3.8 11.18 1.39 4.73 4.32 8.76 9.24 7.31 3.56-1 4.74-3.66 4.19-6.83l3.33-1c.91 4.72-1.43 9.06-6.68 10.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M328.86 226.13L332.08 225.13 335.5 236.74 342.75 221.99 346.5 220.88 341.21 231.94 354.21 243.86 350.49 244.95 339.66 234.88 336.74 240.88 338.95 248.38 335.72 249.33z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M353 232.06L363 229.13 363.85 232.04 353.91 234.96z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M367.11 231.6l6.17-17.8 3.1-.92 4.24 14.42 2.88-.84.74 2.54-2.87.84 1.64 5.58-3.13.92-1.64-5.58-10.47 3.09-.66-2.25zm10.38-3.38l-3-10.14-4.26 12.27 7.26-2.13zM259.72 271.7l-8.61 2.54-6.82-23.19 8.28-2.44c5.39-1.58 8.76 0 9.83 3.63a5.32 5.32 0 01-1.74 6 5.74 5.74 0 015.41 4.32c1.24 4.22-1 7.57-6.35 9.14zm-6.35-20.38l-5.12 1.5 2.07 7 5.42-1.59c2.9-.86 4.28-2.33 3.51-4.94-.77-2.61-2.85-2.87-5.88-1.97zm3.34 9.65l-5.58 1.64 2.34 8 5.42-1.6c3.29-1 4.8-2.69 4-5.49-.8-2.8-3.02-3.52-6.18-2.52v-.03z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M265.45 244.79L268.68 243.79 274.68 264.1 286.26 260.7 287.11 263.6 272.3 267.96z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M299.53 260.52c-7 2.06-11.65-2.49-13.6-9.11-1.95-6.62-.43-13.05 6.48-15.09 6.91-2.04 11.57 2.48 13.54 9.16 1.97 6.68.66 12.96-6.42 15.04zm-6.28-21.33c-5.28 1.56-5.39 6.59-4 11.25 1.39 4.66 4.21 8.79 9.47 7.24 5.26-1.55 5.35-6.5 4-11.19-1.35-4.69-4.28-8.82-9.47-7.3z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M322.69 253.71c-6.92 2-11.4-2.5-13.37-9.18-1.97-6.68-.5-13 6.25-15 5.18-1.53 9.26 1 11.23 5.3l-3.26 1c-1.36-2.93-3.77-4.38-7.13-3.39-5 1.45-5.19 6.45-3.8 11.18 1.39 4.73 4.32 8.76 9.24 7.31 3.56-1 4.74-3.66 4.19-6.83l3.33-1c.91 4.72-1.43 9.06-6.68 10.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M328.86 226.13L332.08 225.13 335.5 236.74 342.75 221.99 346.5 220.88 341.21 231.94 354.21 243.86 350.49 244.95 339.66 234.88 336.74 240.88 338.95 248.38 335.72 249.33z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M353 232.06L363 229.13 363.85 232.04 353.91 234.96z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M367.11 231.6l6.17-17.8 3.1-.92 4.24 14.42 2.88-.84.74 2.54-2.87.84 1.64 5.58-3.13.92-1.64-5.58-10.47 3.09-.66-2.25zm10.38-3.38l-3-10.14-4.26 12.27 7.26-2.13z"
                            ></path>
                        </g>
                        <g
                            id="Block-3"
                            fillRule="nonzero"
                            transform="translate(1980 698)"
                            style={{
                                display: sBlock('block_3').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(442)">
                                <path
                                    fill={sBlockUnit('block_3', '1').colour}
                                    stroke={sBlockUnit('block_3', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_3', '1').width
                                    }
                                    opacity={sBlockUnit('block_3', '1').opacity}
                                    d="M146.64 192.57L90.26 0.57 48.31 12.45 49.25 16.88 0.8 30.62 53.68 219.59z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_3', '1')
                                                .floorplanID,
                                            sBlockUnit('block_3', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_3'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M84 146.2l-5.33 1.52-4.1-14.35 5.13-1.47c3.33-.95 5.4.05 6.05 2.29a3.27 3.27 0 01-1.11 3.7 3.56 3.56 0 013.33 2.7c.78 2.6-.62 4.66-3.97 5.61zm-3.82-12.62l-3.17.9 1.24 4.36 3.35-1c1.8-.51 2.66-1.41 2.2-3-.46-1.59-1.71-1.84-3.58-1.26h-.04zm2 6l-3.45 1 1.4 4.92 3.36-1c2-.59 3-1.64 2.48-3.38s-1.78-2.12-3.74-1.57l-.05.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74.56 118.46l-6-20.81c-1.59 1.84-3.16 3.72-4.75 5.56l-1-3.42c1.69-2 3.37-4.08 5.06-6.1l2.92-.84 7.06 24.65-3.29.96z"
                                ></path>
                            </g>
                            <g transform="translate(357 26)">
                                <path
                                    fill={sBlockUnit('block_3', '2').colour}
                                    stroke={sBlockUnit('block_3', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_3', '2').width
                                    }
                                    opacity={sBlockUnit('block_3', '2').opacity}
                                    d="M85.8 4.62L85.78 4.63 84.68 0.62 84.68 0.61 24.96 18.84 27.53 28.16 0.03 36.78 52.31 218.7 138.68 193.59z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_3', '2')
                                                .floorplanID,
                                            sBlockUnit('block_3', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_3'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M78.94 145.94l-5.33 1.52-4.1-14.35 5.13-1.47c3.33-.95 5.41.05 6.05 2.29a3.27 3.27 0 01-1.11 3.7 3.56 3.56 0 013.33 2.7c.74 2.6-.65 4.67-3.97 5.61zm-3.82-12.62l-3.17.9 1.25 4.36 3.35-1c1.79-.52 2.65-1.42 2.19-3-.46-1.58-1.74-1.8-3.62-1.26zm2 6l-3.45 1 1.41 4.93 3.35-1c2-.58 3-1.64 2.49-3.38-.51-1.74-1.83-2.14-3.79-1.58l-.01.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M62.82 117.12c.69-1.08 4.79-9.49 5.56-10.71 2-3.22 2.78-5.73 2.1-8.11a4 4 0 00-5.33-2.9c-3 .84-3.89 2.81-3.53 5.7l-3.27.94c-.69-4 .71-8.13 6-9.64 4.62-1.32 8.32.58 9.52 4.78.91 3.16.09 6.33-2.28 10.16-.51.76-3.75 7.46-4.29 8.27l11.24-3.22.87 3-15.76 4.51-.83-2.78z"
                                ></path>
                            </g>
                            <g transform="translate(268 67)">
                                <path
                                    fill={sBlockUnit('block_3', '3').colour}
                                    stroke={sBlockUnit('block_3', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_3', '3').width
                                    }
                                    opacity={sBlockUnit('block_3', '3').opacity}
                                    d="M63.17 8.6L60.61 2.31 0.87 19.59 53.29 203.29 141.31 177.7 90.46 0.74z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_3', '3')
                                                .floorplanID,
                                            sBlockUnit('block_3', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_3'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M81.13 129.74l-5.33 1.53-4.11-14.36 5.13-1.46c3.33-1 5.41 0 6 2.29a3.27 3.27 0 01-1.1 3.7 3.54 3.54 0 013.32 2.69c.8 2.61-.6 4.67-3.91 5.61zm-3.83-12.62l-3.17.91 1.25 4.36 3.35-1c1.8-.51 2.65-1.41 2.19-3-.46-1.59-1.74-1.8-3.62-1.27zm2 6l-3.45 1 1.41 4.93 3.35-1c2-.58 3-1.63 2.49-3.37-.51-1.74-1.83-2.14-3.79-1.58l-.01.02z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M66.33 95.89c1.12 3 3.12 4.18 6.4 3.25 2.95-.85 4.39-2.95 3.55-5.87-.84-2.92-3.18-3.86-6.17-3l-2.13.62-.86-3 2.21-.64c2.7-.77 3.94-2.63 3.16-5.33-.66-2.31-2.78-3.4-5.45-2.63-2.91.83-4.17 2.62-3.76 5.54l-3.27.94c-1.1-4 1.16-8 6.21-9.48 4.77-1.37 8.49.61 9.59 4.45a6 6 0 01-2 6.66 6.33 6.33 0 015.9 4.86c1.3 4.55-1 8.4-6.07 9.85-5.76 1.65-9.34-1.29-10.63-5.27l3.32-.95z"
                                ></path>
                            </g>
                            <g transform="translate(181 82)">
                                <path
                                    fill={sBlockUnit('block_3', '4').colour}
                                    stroke={sBlockUnit('block_3', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_3', '4').width
                                    }
                                    opacity={sBlockUnit('block_3', '4').opacity}
                                    d="M86.48 0.27L27.14 18.3 29.47 28.55 3.14 36.94 1.04 29.48 0.93 29.51 53.35 213.57 140.29 188.29z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_3', '4')
                                                .floorplanID,
                                            sBlockUnit('block_3', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_3'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M79.42 140.09l-4.92 1.41-4.1-14.36 5.14-1.47c4.55-1.3 7.07 1.41 8.2 5.37 1.13 3.96.26 7.73-4.32 9.05zM75.8 127.5l-2.92.83 3.09 10.84 2.73-.77c3.71-1.07 3.81-3.85 3-6.75s-2.32-5.17-5.9-4.15zM61 105.79l6.86-19.32 3.38-1 4.5 15.72 3.14-.89.79 2.77-3.13.9 1.74 6.08-3.41 1-1.75-6.08-11.41 3.26-.71-2.44zm11.33-3.59l-3.17-11.06-4.73 13.32 7.9-2.26z"
                                ></path>
                            </g>
                            <g transform="translate(90 109)">
                                <path
                                    fill={sBlockUnit('block_3', '5').colour}
                                    stroke={sBlockUnit('block_3', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_3', '5').width
                                    }
                                    opacity={sBlockUnit('block_3', '5').opacity}
                                    d="M69.44 9.47L65.71 0.62 0 19.26 1.64 24.62 0.74 24.88 56.14 212.21 144.35 186.57 91.93 2.51z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_3', '5')
                                                .floorplanID,
                                            sBlockUnit('block_3', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_3'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M82.37 138.25l-5.33 1.52-4.1-14.35 5.13-1.47c3.33-1 5.41 0 6 2.29a3.27 3.27 0 01-1.11 3.7 3.57 3.57 0 013.33 2.7c.79 2.6-.61 4.66-3.92 5.61zm-3.82-12.62l-3.17.9 1.24 4.36 3.35-.95c1.8-.52 2.66-1.42 2.2-3-.46-1.58-1.75-1.85-3.62-1.31zm2 6l-3.45 1 1.4 4.93 3.36-1c2-.58 3-1.64 2.48-3.38s-1.82-2.18-3.78-1.58l-.01.03z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M68.3 104.59c1.1 2.77 3.19 3.9 6.25 3 3.06-.9 4.22-3.43 3.21-7-.89-3.09-2.93-4.85-6.24-3.91a5.77 5.77 0 00-4 3.51l-3.17.9-3.07-13.82 13.91-4 .86 3-11 3.15 1.59 7.59a7.37 7.37 0 014.69-3.38c5.13-1.47 8.57 1.43 9.87 5.95 1.5 5.27-.54 9.43-5.7 10.91-5 1.43-8.83-.48-10.44-5l3.24-.9z"
                                ></path>
                            </g>
                            <g transform="translate(0 133)">
                                <path
                                    fill={sBlockUnit('block_3', '6').colour}
                                    stroke={sBlockUnit('block_3', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_3', '6').width
                                    }
                                    opacity={sBlockUnit('block_3', '6').opacity}
                                    d="M44.57 14.83L42.47 9.94 0.53 22.52 56.45 214.29 146.14 188.21 90.74 0.88z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_3', '6')
                                                .floorplanID,
                                            sBlockUnit('block_3', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_3'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M83.42 139.66l-5.33 1.53-4.1-14.36 5.13-1.46c3.33-1 5.41 0 6.05 2.29a3.27 3.27 0 01-1.11 3.7 3.55 3.55 0 013.33 2.69c.74 2.61-.66 4.67-3.97 5.61zM79.6 127l-3.17.91 1.24 4.36 3.35-1c1.8-.51 2.66-1.41 2.2-3-.46-1.59-1.75-1.76-3.62-1.27zm2 6l-3.45 1 1.41 4.93 3.35-1c2-.58 3-1.64 2.48-3.37-.52-1.73-1.82-2.1-3.78-1.56h-.01zM76 112.18c-6.79 1.94-10.27-3.76-11.92-9.52-2.35-8.18-1.54-14.5 4.9-16.34 4.66-1.34 7.89.78 9.33 4.22l-3.27.93a4.06 4.06 0 00-5.3-2.25c-4.17 1.19-4.17 5.74-2.86 10.75a7.07 7.07 0 015-4.59c5.16-1.48 8.66 1.18 10 6 1.34 4.82-.62 9.29-5.88 10.8zM72.18 98.3c-2.77.79-4.35 3.36-3.4 6.67.85 3 3.17 5.29 6.4 4.36 3.23-.93 4.37-3.71 3.43-7-.85-2.97-2.94-5.03-6.43-4.03z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M261.69 275.5l-8.61 2.53-6.82-23.18 8.28-2.44c5.38-1.58 8.76 0 9.83 3.63a5.29 5.29 0 01-1.75 6 5.74 5.74 0 015.42 4.33c1.24 4.2-1.04 7.56-6.35 9.13zm-6.35-20.38l-5.12 1.5 2.07 7 5.42-1.6c2.9-.85 4.28-2.32 3.51-4.93-.77-2.61-2.85-2.87-5.88-1.97zm3.34 9.65l-5.58 1.64 2.34 8 5.41-1.59c3.3-1 4.81-2.69 4-5.49-.81-2.8-3.01-3.5-6.17-2.56z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M267.42 248.58L270.65 247.58 276.65 267.9 288.22 264.49 289.08 267.4 274.27 271.76z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M301.5 264.32c-7 2.06-11.65-2.49-13.6-9.11-1.95-6.62-.44-13.06 6.48-15.09 6.92-2.03 11.57 2.48 13.54 9.16 1.97 6.68.66 12.96-6.42 15.04zM295.22 243c-5.28 1.55-5.4 6.58-4 11.25 1.4 4.67 4.21 8.79 9.46 7.24 5.25-1.55 5.36-6.5 4-11.2-1.36-4.7-4.27-8.83-9.46-7.29z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M324.66 257.5c-6.92 2-11.4-2.49-13.37-9.17-1.97-6.68-.5-13 6.25-15 5.18-1.52 9.25 1 11.23 5.31l-3.26 1c-1.36-2.93-3.77-4.38-7.13-3.4-5 1.46-5.19 6.46-3.8 11.19 1.39 4.73 4.32 8.76 9.24 7.31 3.56-1 4.74-3.67 4.19-6.84l3.33-1c.91 4.72-1.43 9.1-6.68 10.6z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M330.82 229.92L334.05 228.98 337.47 240.58 344.72 225.84 348.47 224.73 343.18 235.79 356.18 247.7 352.46 248.8 341.63 238.73 338.71 244.73 340.92 252.24 337.69 253.19z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M355 235.86L365 232.93 365.85 235.84 355.9 238.76z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M372.82 235.71c1 2.7 2.9 3.82 5.9 2.94 2.71-.8 4-2.74 3.23-5.41s-2.95-3.53-5.69-2.72l-2 .57-.81-2.74 2-.59c2.47-.73 3.6-2.44 2.87-4.92-.62-2.12-2.58-3.11-5-2.39-2.68.79-3.82 2.44-3.42 5.12l-3 .88c-1-3.63 1-7.38 5.64-8.75 4.37-1.28 7.81.51 8.84 4a5.49 5.49 0 01-1.81 6.13 5.81 5.81 0 015.45 4.42c1.23 4.18-.85 7.73-5.51 9.1-5.28 1.56-8.59-1.12-9.8-4.77l3.11-.87z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M261.69 275.5l-8.61 2.53-6.82-23.18 8.28-2.44c5.38-1.58 8.76 0 9.83 3.63a5.29 5.29 0 01-1.75 6 5.74 5.74 0 015.42 4.33c1.24 4.2-1.04 7.56-6.35 9.13zm-6.35-20.38l-5.12 1.5 2.07 7 5.42-1.6c2.9-.85 4.28-2.32 3.51-4.93-.77-2.61-2.85-2.87-5.88-1.97zm3.34 9.65l-5.58 1.64 2.34 8 5.41-1.59c3.3-1 4.81-2.69 4-5.49-.81-2.8-3.01-3.5-6.17-2.56z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M267.42 248.58L270.65 247.58 276.65 267.9 288.22 264.49 289.08 267.4 274.27 271.76z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M301.5 264.32c-7 2.06-11.65-2.49-13.6-9.11-1.95-6.62-.44-13.06 6.48-15.09 6.92-2.03 11.57 2.48 13.54 9.16 1.97 6.68.66 12.96-6.42 15.04zM295.22 243c-5.28 1.55-5.4 6.58-4 11.25 1.4 4.67 4.21 8.79 9.46 7.24 5.25-1.55 5.36-6.5 4-11.2-1.36-4.7-4.27-8.83-9.46-7.29z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M324.66 257.5c-6.92 2-11.4-2.49-13.37-9.17-1.97-6.68-.5-13 6.25-15 5.18-1.52 9.25 1 11.23 5.31l-3.26 1c-1.36-2.93-3.77-4.38-7.13-3.4-5 1.46-5.19 6.46-3.8 11.19 1.39 4.73 4.32 8.76 9.24 7.31 3.56-1 4.74-3.67 4.19-6.84l3.33-1c.91 4.72-1.43 9.1-6.68 10.6z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M330.82 229.92L334.05 228.98 337.47 240.58 344.72 225.84 348.47 224.73 343.18 235.79 356.18 247.7 352.46 248.8 341.63 238.73 338.71 244.73 340.92 252.24 337.69 253.19z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M355 235.86L365 232.93 365.85 235.84 355.9 238.76z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M372.82 235.71c1 2.7 2.9 3.82 5.9 2.94 2.71-.8 4-2.74 3.23-5.41s-2.95-3.53-5.69-2.72l-2 .57-.81-2.74 2-.59c2.47-.73 3.6-2.44 2.87-4.92-.62-2.12-2.58-3.11-5-2.39-2.68.79-3.82 2.44-3.42 5.12l-3 .88c-1-3.63 1-7.38 5.64-8.75 4.37-1.28 7.81.51 8.84 4a5.49 5.49 0 01-1.81 6.13 5.81 5.81 0 015.45 4.42c1.23 4.18-.85 7.73-5.51 9.1-5.28 1.56-8.59-1.12-9.8-4.77l3.11-.87z"
                            ></path>
                        </g>
                        <g
                            id="Block-2"
                            fillRule="nonzero"
                            transform="translate(2548 535)"
                            style={{
                                display: sBlock('block_2').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(440)">
                                <path
                                    fill={sBlockUnit('block_2', '1').colour}
                                    stroke={sBlockUnit('block_2', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_2', '1').width
                                    }
                                    opacity={sBlockUnit('block_2', '1').opacity}
                                    d="M147.32 189.43L91.4 0.69 62.04 10.48 60.18 4.89 0.28 23.78 56.88 216.08z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_2', '1')
                                                .floorplanID,
                                            sBlockUnit('block_2', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_2'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M76.49 132.1l2-.57L88 144.4l-2.1.59-2.31-3.19-6.11 1.72-.31 3.92-2 .58 1.32-15.92zm6 8.11l-4.35-6-.55 7.38 4.9-1.38z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M76.22 116.19L70.53 95.3c-1.61 1.82-3.21 3.68-4.83 5.5l-.93-3.43c1.72-2 3.43-4 5.14-6l2.93-.8 6.74 24.74-3.36.88z"
                                ></path>
                            </g>
                            <g transform="translate(356 28)">
                                <path
                                    fill={sBlockUnit('block_2', '2').colour}
                                    stroke={sBlockUnit('block_2', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_2', '2').width
                                    }
                                    opacity={sBlockUnit('block_2', '2').opacity}
                                    d="M85.73 0.73L26.27 16.97 27.67 22.09 0.37 30.28 54.33 213.58 140.88 188.08z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_2', '2')
                                                .floorplanID,
                                            sBlockUnit('block_2', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_2'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M72.72 128.76l2-.58 9.46 12.87-2.1.59-2.31-3.18-6.11 1.71-.31 3.93-2 .57 1.37-15.91zm6 8.11l-4.35-6-.55 7.37 4.9-1.37z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M62.93 111.64c.7-1.07 4.91-9.43 5.69-10.64 2-3.19 2.86-5.69 2.21-8.08a4 4 0 00-5.29-3c-3 .81-3.93 2.76-3.6 5.66l-3.28.9c-.64-4 .81-8.13 6.1-9.57 4.64-1.26 8.32.69 9.46 4.91.87 3.17 0 6.32-2.42 10.13-.52.75-3.85 7.41-4.39 8.21l11.28-3.07.82 3-15.81 4.3-.77-2.75z"
                                ></path>
                            </g>
                            <g transform="translate(268 58)">
                                <path
                                    fill={sBlockUnit('block_2', '3').colour}
                                    stroke={sBlockUnit('block_2', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_2', '3').width
                                    }
                                    opacity={sBlockUnit('block_2', '3').opacity}
                                    d="M87.71 0.48L89.57 6.54 62.55 14 59.28 7.94 0.65 25.15 54.86 209.35 142.33 183.58 88.37 0.28z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_2', '3')
                                                .floorplanID,
                                            sBlockUnit('block_2', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_2'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M73 122.44l2.05-.57 9.46 12.87-2.11.59-2.31-3.19-6.1 1.72-.32 3.92-2 .58L73 122.44zm6 8.11l-4.35-6-.54 7.38 4.89-1.38z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M63.48 100.8c1.07 3 3.07 4.23 6.35 3.34 3-.81 4.43-2.89 3.63-5.82-.8-2.93-3.13-3.9-6.13-3.09l-2.14.58-.82-3 2.21-.61c2.72-.74 4-2.58 3.24-5.29-.63-2.32-2.74-3.43-5.41-2.7-2.93.79-4.21 2.56-3.84 5.49l-3.28.89c-1.05-4 1.27-8 6.34-9.39 4.78-1.31 8.48.72 9.53 4.57a6 6 0 01-2.11 6.64 6.3 6.3 0 015.83 4.93c1.24 4.57-1.09 8.39-6.2 9.78-5.78 1.57-9.32-1.42-10.56-5.41l3.36-.91z"
                                ></path>
                            </g>
                            <g transform="translate(184 78)">
                                <path
                                    fill={sBlockUnit('block_2', '4').colour}
                                    stroke={sBlockUnit('block_2', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_2', '4').width
                                    }
                                    opacity={sBlockUnit('block_2', '4').opacity}
                                    d="M84.57 5.18L83.43 1.01 83.15 0.06 23.52 18.23 25.85 29.41 0.19 37.2 52.46 214.81 138.86 189.35 84.65 5.15z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_2', '4')
                                                .floorplanID,
                                            sBlockUnit('block_2', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_2'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74.69 142.37c-4.29 1.21-7-1.62-8.19-5.77-1.19-4.15-.21-8.05 4-9.23a5.54 5.54 0 016.89 3.37l-2 .56a3.42 3.42 0 00-4.37-2.15c-3.08.87-3.26 4-2.44 6.88.82 2.88 2.6 5.44 5.65 4.58a3.38 3.38 0 002.64-4.18l2.07-.58a5.5 5.5 0 01-4.25 6.52z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M54.56 104.56l7.12-19.23 3.39-.92 4.3 15.78 3.14-.86.76 2.79-3.15.85 1.67 6.11-3.43.93-1.66-6.1L55.24 107l-.68-2.44zm11.38-3.44l-3-11.1L58 103.28l7.94-2.16z"
                                ></path>
                            </g>
                            <g transform="translate(90 109)">
                                <path
                                    fill={sBlockUnit('block_2', '5').colour}
                                    stroke={sBlockUnit('block_2', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_2', '5').width
                                    }
                                    opacity={sBlockUnit('block_2', '5').opacity}
                                    d="M93.75 6.34L91.42 0.28 62.99 8.66 60.2 3.07 1.94 20.32 1.01 16.12 0.69 16.22 57.72 209.96 146.46 183.81 94.19 6.2z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_2', '5')
                                                .floorplanID,
                                            sBlockUnit('block_2', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_2'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M75.4 121.72l2-.57 9.46 12.87-2.11.59-2.31-3.19-6.11 1.71-.31 3.93-2 .57 1.38-15.91zm6 8.11l-4.35-6-.54 7.38 4.89-1.38z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M64.33 98.33c1.06 2.78 3.14 3.94 6.21 3.11 3.07-.83 4.26-3.39 3.29-6.92-.84-3.11-2.86-4.9-6.18-4a5.75 5.75 0 00-4.09 3.45l-3.18.86L57.49 81l14-3.8.82 3-11.07 3 1.5 7.61a7.34 7.34 0 014.73-3.32c5.14-1.4 8.55 1.54 9.79 6.08 1.43 5.28-.66 9.42-5.84 10.83-5.18 1.41-8.83-.59-10.37-5.15l3.28-.92z"
                                ></path>
                            </g>
                            <g transform="translate(0 125)">
                                <path
                                    fill={sBlockUnit('block_2', '6').colour}
                                    stroke={sBlockUnit('block_2', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_2', '6').width
                                    }
                                    opacity={sBlockUnit('block_2', '6').opacity}
                                    d="M29.96 18.76L30.9 23.89 0.61 31.81 57.46 220.55 147.72 193.96 90.69 0.22z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_2', '6')
                                                .floorplanID,
                                            sBlockUnit('block_2', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_2'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M77.63 130.38l2-.58 9.46 12.87-2.1.59-2.31-3.19-6.11 1.72-.31 3.93-2 .57 1.37-15.91zm6 8.11l-4.35-6-.54 7.37 4.89-1.37zM72.56 112.53c-6.82 1.86-10.22-3.89-11.8-9.67-2.23-8.21-1.34-14.52 5.12-16.28 4.68-1.27 7.87.89 9.28 4.34l-3.29.9c-1.06-2.05-2.59-3.05-5.27-2.32-4.18 1.13-4.25 5.68-3 10.7a7.06 7.06 0 015.06-4.52c5.17-1.41 8.63 1.29 9.95 6.15 1.32 4.86-.76 9.26-6.05 10.7zM68.92 98.6c-2.78.76-4.39 3.31-3.49 6.63.82 3 3.11 5.33 6.35 4.44 3.24-.89 4.42-3.65 3.52-6.94-.81-2.99-2.88-5.08-6.38-4.13z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M261.94 273.53l-8.61 2.53-6.83-23.19 8.29-2.43c5.38-1.59 8.75 0 9.82 3.63a5.29 5.29 0 01-1.74 6 5.75 5.75 0 015.42 4.33c1.23 4.2-1 7.6-6.35 9.13zm-6.36-20.39l-5.12 1.51 2.08 7 5.41-1.59c2.9-.85 4.28-2.32 3.51-4.93-.77-2.61-2.84-2.88-5.88-1.99zm3.34 9.65l-5.58 1.64 2.34 8 5.42-1.59c3.29-1 4.81-2.69 4-5.5-.81-2.81-3.02-3.48-6.18-2.55z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M267.66 246.61L270.89 245.61 276.89 265.93 288.47 262.52 289.32 265.43 274.52 269.78z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M301.74 262.35c-7 2.06-11.65-2.49-13.59-9.11-1.94-6.62-.44-13.06 6.47-15.09 6.91-2.03 11.57 2.47 13.54 9.16 1.97 6.69.66 12.95-6.42 15.04zM295.47 241c-5.29 1.55-5.4 6.58-4 11.25 1.4 4.67 4.22 8.78 9.47 7.24 5.25-1.54 5.35-6.5 4-11.2-1.35-4.7-4.29-8.8-9.47-7.29z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M324.9 255.53c-6.91 2-11.4-2.49-13.37-9.18-1.97-6.69-.5-13 6.25-15 5.18-1.52 9.26 1 11.24 5.31l-3.27 1c-1.35-2.93-3.77-4.39-7.13-3.4-4.95 1.46-5.18 6.45-3.79 11.18 1.39 4.73 4.31 8.76 9.24 7.31 3.55-1 4.73-3.66 4.19-6.83l3.32-1c.91 4.73-1.43 9.08-6.68 10.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M331.07 228L334.3 227 337.71 238.61 344.96 223.86 348.71 222.76 343.42 233.76 356.42 245.68 352.71 246.78 341.88 236.71 338.95 242.71 341.16 250.21 337.93 251.16z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M355.21 233.89L365.16 230.96 366.02 233.86 356.07 236.79z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M371.88 238.38c.63-1 4.33-8.76 5-9.88 1.79-3 2.52-5.28 1.88-7.47a3.67 3.67 0 00-4.92-2.63c-2.71.8-3.56 2.61-3.2 5.27l-3 .88c-.66-3.7.6-7.47 5.43-8.89 4.24-1.25 7.65.47 8.78 4.32.86 2.9.12 5.81-2 9.35-.47.71-3.4 6.88-3.89 7.63l10.31-3 .82 2.77-14.45 4.27-.76-2.62z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M261.94 273.53l-8.61 2.53-6.83-23.19 8.29-2.43c5.38-1.59 8.75 0 9.82 3.63a5.29 5.29 0 01-1.74 6 5.75 5.75 0 015.42 4.33c1.23 4.2-1 7.6-6.35 9.13zm-6.36-20.39l-5.12 1.51 2.08 7 5.41-1.59c2.9-.85 4.28-2.32 3.51-4.93-.77-2.61-2.84-2.88-5.88-1.99zm3.34 9.65l-5.58 1.64 2.34 8 5.42-1.59c3.29-1 4.81-2.69 4-5.5-.81-2.81-3.02-3.48-6.18-2.55z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M267.66 246.61L270.89 245.61 276.89 265.93 288.47 262.52 289.32 265.43 274.52 269.78z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M301.74 262.35c-7 2.06-11.65-2.49-13.59-9.11-1.94-6.62-.44-13.06 6.47-15.09 6.91-2.03 11.57 2.47 13.54 9.16 1.97 6.69.66 12.95-6.42 15.04zM295.47 241c-5.29 1.55-5.4 6.58-4 11.25 1.4 4.67 4.22 8.78 9.47 7.24 5.25-1.54 5.35-6.5 4-11.2-1.35-4.7-4.29-8.8-9.47-7.29z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M324.9 255.53c-6.91 2-11.4-2.49-13.37-9.18-1.97-6.69-.5-13 6.25-15 5.18-1.52 9.26 1 11.24 5.31l-3.27 1c-1.35-2.93-3.77-4.39-7.13-3.4-4.95 1.46-5.18 6.45-3.79 11.18 1.39 4.73 4.31 8.76 9.24 7.31 3.55-1 4.73-3.66 4.19-6.83l3.32-1c.91 4.73-1.43 9.08-6.68 10.61z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M331.07 228L334.3 227 337.71 238.61 344.96 223.86 348.71 222.76 343.42 233.76 356.42 245.68 352.71 246.78 341.88 236.71 338.95 242.71 341.16 250.21 337.93 251.16z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M355.21 233.89L365.16 230.96 366.02 233.86 356.07 236.79z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M371.88 238.38c.63-1 4.33-8.76 5-9.88 1.79-3 2.52-5.28 1.88-7.47a3.67 3.67 0 00-4.92-2.63c-2.71.8-3.56 2.61-3.2 5.27l-3 .88c-.66-3.7.6-7.47 5.43-8.89 4.24-1.25 7.65.47 8.78 4.32.86 2.9.12 5.81-2 9.35-.47.71-3.4 6.88-3.89 7.63l10.31-3 .82 2.77-14.45 4.27-.76-2.62z"
                            ></path>
                        </g>
                        <g
                            id="Block-1"
                            fillRule="nonzero"
                            transform="translate(3138 362)"
                            style={{
                                display: sBlock('block_1').active ? '' : 'none'
                            }}
                        >
                            <g transform="translate(440)">
                                <path
                                    fill={sBlockUnit('block_1', '1').colour}
                                    stroke={sBlockUnit('block_1', '1').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_1', '1').width
                                    }
                                    opacity={sBlockUnit('block_1', '1').opacity}
                                    d="M53.61 200.49L144 173.69 93.84 0.6 68.77 8.96 65.39 3.57 0 23.09z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_1', '1')
                                                .floorplanID,
                                            sBlockUnit('block_1', '1')
                                                .unit_elevation.value,
                                            1,
                                            'block_1'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M76.88 112.68L71 91.83c-1.6 1.84-3.19 3.71-4.79 5.54l-1-3.42c1.7-2 3.39-4.07 5.09-6.08l2.93-.82 6.93 24.68-3.28.95z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M79.62 126.51l2.05-.58 9.46 12.88-2.11.59-2.31-3.19-6.1 1.71-.32 3.93-2 .57 1.33-15.91zm6 8.11l-4.35-6-.54 7.37 4.89-1.37z"
                                ></path>
                            </g>
                            <g transform="translate(357 23)">
                                <path
                                    fill={sBlockUnit('block_1', '2').colour}
                                    stroke={sBlockUnit('block_1', '2').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_1', '2').width
                                    }
                                    opacity={sBlockUnit('block_1', '2').opacity}
                                    d="M83 0.09L25.33 17.3 29.51 27.75 0.19 35.98 54.03 217.68 141.15 192.48z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_1', '2')
                                                .floorplanID,
                                            sBlockUnit('block_1', '2')
                                                .unit_elevation.value,
                                            2,
                                            'block_1'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M62.78 113.91c.7-1.08 4.84-9.46 5.61-10.68 2-3.21 2.82-5.71 2.15-8.1a4 4 0 00-5.32-2.92c-3 .83-3.91 2.78-3.55 5.68l-3.28.92c-.68-4 .75-8.13 6-9.61 4.63-1.3 8.33.63 9.51 4.83.89 3.17 0 6.33-2.34 10.15-.52.76-3.79 7.44-4.33 8.25l11.25-3.16.86 3-15.79 4.43-.77-2.79zM79.49 142.72c-4.29 1.2-7-1.63-8.19-5.77-1.19-4.14-.21-8.06 4-9.23a5.53 5.53 0 016.89 3.36l-2 .57a3.42 3.42 0 00-4.37-2.15c-3.08.86-3.26 3.94-2.44 6.87.82 2.93 2.6 5.45 5.65 4.59a3.4 3.4 0 002.64-4.19l2.07-.58a5.51 5.51 0 01-4.25 6.53z"
                                ></path>
                            </g>
                            <g transform="translate(267 58)">
                                <path
                                    fill={sBlockUnit('block_1', '3').colour}
                                    stroke={sBlockUnit('block_1', '3').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_1', '3').width
                                    }
                                    opacity={sBlockUnit('block_1', '3').opacity}
                                    d="M89.73 1.11L91.82 6.86 64.65 13.65 60.99 4.25 0.07 21.96 55.34 208.82 144.03 182.68 90.19 0.98z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_1', '3')
                                                .floorplanID,
                                            sBlockUnit('block_1', '3')
                                                .unit_elevation.value,
                                            3,
                                            'block_1'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M65.49 97.78c1.1 3 3.1 4.2 6.38 3.28 3-.83 4.4-2.92 3.58-5.85-.82-2.93-3.16-3.87-6.15-3l-2.14.6-.84-3 2.21-.62c2.7-.76 3.95-2.61 3.19-5.32-.65-2.32-2.76-3.41-5.43-2.66-2.93.82-4.19 2.6-3.8 5.52l-3.27.92c-1.08-4 1.2-8 6.26-9.45 4.77-1.34 8.49.66 9.57 4.5a6 6 0 01-2.06 6.65 6.32 6.32 0 015.87 4.89c1.28 4.56-1 8.4-6.11 9.83-5.78 1.62-9.34-1.34-10.61-5.33l3.35-.96z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M82.45 136.06l-7.12 2L70 118.89l6.85-1.92c4.44-1.25 7.21.09 8 3.09a4.34 4.34 0 01-1.5 4.92 4.74 4.74 0 014.42 3.62c.98 3.48-.9 6.22-5.32 7.46zm-5-16.86l-4.23 1.19 1.64 5.83 4.47-1.26c2.4-.68 3.55-1.87 2.94-4-.61-2.13-2.34-2.46-4.85-1.76h.03zm2.65 8l-4.61 1.29 1.85 6.58 4.48-1.26c2.72-.76 4-2.17 3.33-4.48-.67-2.31-2.46-2.88-5.08-2.14l.03.01z"
                                ></path>
                            </g>
                            <g transform="translate(183 79)">
                                <path
                                    fill={sBlockUnit('block_1', '4').colour}
                                    stroke={sBlockUnit('block_1', '4').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_1', '4').width
                                    }
                                    opacity={sBlockUnit('block_1', '4').opacity}
                                    d="M26.39 17.73L30.05 29.23 1.83 37.06 0.43 33.13 52.47 212.61 139.34 187.82 84.07 0.96z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_1', '4')
                                                .floorplanID,
                                            sBlockUnit('block_1', '4')
                                                .unit_elevation.value,
                                            4,
                                            'block_1'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M56.77 102.29l7-19.29 3.38-1 4.43 15.75 3.13-.88.78 2.77-3.13.88 1.71 6.1-3.42 1-1.71-6.09-11.44 3.21-.73-2.45zm11.35-3.53L65 87.68l-4.79 13.3 7.91-2.22zM75.37 136.42l-4.93 1.39-4-14.37 5.15-1.45c4.55-1.28 7.06 1.44 8.17 5.4 1.11 3.96.24 7.74-4.39 9.03zm-3.56-12.61l-2.93.83 3 10.85 2.73-.76c3.72-1.05 3.84-3.83 3-6.74-.84-2.91-2.23-5.18-5.8-4.18z"
                                ></path>
                            </g>
                            <g transform="translate(91 108)">
                                <path
                                    fill={sBlockUnit('block_1', '5').colour}
                                    stroke={sBlockUnit('block_1', '5').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_1', '5').width
                                    }
                                    opacity={sBlockUnit('block_1', '5').opacity}
                                    d="M91.22 0.75L62.49 8.06 58.31 0.23 0.53 17.4 54.91 210.81 144.68 184.35 92.43 4.13z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_1', '5')
                                                .floorplanID,
                                            sBlockUnit('block_1', '5')
                                                .unit_elevation.value,
                                            5,
                                            'block_1'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M64.74 98.74c1.08 2.77 3.17 3.91 6.23 3 3.06-.91 4.23-3.41 3.24-6.94-.87-3.1-2.9-4.87-6.22-3.94a5.75 5.75 0 00-4 3.48l-3.17.89-3-13.84 13.93-3.91.84 3-11 3.1 1.55 7.6a7.35 7.35 0 014.71-3.36c5.13-1.44 8.56 1.48 9.84 6 1.48 5.27-.59 9.43-5.75 10.88-5.16 1.45-8.83-.52-10.42-5.07l3.22-.89z"
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M79.62 132.07l-5.34 1.5-4-14.37 5.13-1.45c3.33-.93 5.41.08 6 2.32a3.29 3.29 0 01-1.12 3.7 3.54 3.54 0 013.31 2.71c.74 2.61-.67 4.66-3.98 5.59zm-3.77-12.64l-3.17.89 1.22 4.37 3.36-.94c1.8-.51 2.66-1.4 2.2-3-.46-1.6-1.73-1.85-3.61-1.32zm2 6l-3.46 1 1.39 4.93 3.35-.94c2-.58 3-1.63 2.5-3.37-.5-1.74-1.83-2.18-3.79-1.63l.01.01z"
                                ></path>
                            </g>
                            <g transform="translate(0 125)">
                                <path
                                    fill={sBlockUnit('block_1', '6').colour}
                                    stroke={sBlockUnit('block_1', '6').stroke}
                                    strokeWidth={
                                        sBlockUnit('block_1', '6').width
                                    }
                                    opacity={sBlockUnit('block_1', '6').opacity}
                                    d="M91.31 0.47L92.36 4.65 45.34 19.27 42.72 14.05 0.93 27.63 57.35 219.9 145.91 193.81 91.53 0.4z"
                                    onClick={() =>
                                        unit_clicked(
                                            sBlockUnit('block_1', '6')
                                                .floorplanID,
                                            sBlockUnit('block_1', '6')
                                                .unit_elevation.value,
                                            6,
                                            'block_1'
                                        )
                                    }
                                ></path>
                                <path
                                    fill="#FFF"
                                    d="M74.22 112.59c-6.81 1.92-10.26-3.8-11.88-9.57-2.3-8.2-1.46-14.51 5-16.32 4.67-1.31 7.88.83 9.31 4.27l-3.28.92a4.06 4.06 0 00-5.29-2.28c-4.17 1.17-4.2 5.71-2.91 10.73a7.08 7.08 0 015-4.56c5.17-1.45 8.65 1.22 10 6.07 1.35 4.85-.68 9.26-5.95 10.74zM70.47 98.7c-2.78.78-4.37 3.34-3.44 6.65.84 3 3.14 5.31 6.39 4.39 3.25-.92 4.38-3.69 3.46-7-.88-2.96-2.88-5.02-6.41-4.04zM82 140l-5.34 1.5-4-14.37 5.13-1.44c3.34-.94 5.41.07 6 2.31a3.29 3.29 0 01-1.12 3.7 3.54 3.54 0 013.31 2.71C86.69 137 85.29 139 82 140zm-3.77-12.64l-3.17.9 1.23 4.36 3.35-.94c1.8-.51 2.66-1.4 2.21-3-.45-1.6-1.77-1.87-3.65-1.35l.03.03zm2 6l-3.46 1 1.39 4.93 3.35-.94c2.05-.57 3-1.63 2.5-3.36s-1.86-2.22-3.82-1.67l.04.04z"
                                ></path>
                            </g>
                            <path
                                fill="#FFF"
                                d="M261.26 271.61l-8.59 2.58-7-23.15 8.27-2.48c5.38-1.62 8.76 0 9.85 3.57a5.31 5.31 0 01-1.71 6 5.73 5.73 0 015.44 4.29c1.3 4.22-.92 7.58-6.26 9.19zm-6.46-20.35l-5.12 1.54 2.12 7 5.4-1.62c2.9-.87 4.27-2.35 3.48-5-.79-2.65-2.86-2.83-5.88-1.92zm3.38 9.63l-5.56 1.67 2.38 8 5.41-1.62c3.29-1 4.79-2.72 4-5.52-.79-2.8-3.07-3.48-6.23-2.53z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M266.84 244.66L270.06 243.66 276.15 263.95 287.71 260.48 288.58 263.38 273.8 267.82z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M301 260.21c-7 2.1-11.66-2.43-13.64-9-1.98-6.57-.51-13 6.39-15.12 6.9-2.12 11.59 2.41 13.59 9.09s.73 12.91-6.34 15.03zm-6.39-21.3c-5.27 1.59-5.36 6.62-4 11.28 1.36 4.66 4.26 8.76 9.5 7.19 5.24-1.57 5.32-6.53 3.91-11.22-1.41-4.69-4.23-8.8-9.41-7.25z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M324.12 253.27c-6.9 2.07-11.41-2.43-13.41-9.11s-.58-13 6.16-15c5.18-1.56 9.26.94 11.26 5.24l-3.25 1c-1.38-2.93-3.8-4.37-7.15-3.36-4.95 1.49-5.15 6.48-3.73 11.2 1.42 4.72 4.36 8.74 9.27 7.26 3.55-1.06 4.72-3.68 4.16-6.85l3.32-1c.93 4.69-1.38 9.04-6.63 10.62z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M330.14 225.65L333.36 224.65 336.84 236.24 344.01 221.45 347.75 220.32 342.53 231.41 355.53 243.25 351.82 244.37 340.94 234.37 338.05 240.37 340.3 247.86 337.07 248.86z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M354.32 231.46L364.25 228.46 365.12 231.36 355.19 234.36z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M378.78 236.32l-5.72-19.05c-1.44 1.71-2.87 3.45-4.3 5.16l-.94-3.12c1.53-1.88 3-3.79 4.58-5.67l2.67-.8 6.77 22.56-3.06.92z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M261.26 271.61l-8.59 2.58-7-23.15 8.27-2.48c5.38-1.62 8.76 0 9.85 3.57a5.31 5.31 0 01-1.71 6 5.73 5.73 0 015.44 4.29c1.3 4.22-.92 7.58-6.26 9.19zm-6.46-20.35l-5.12 1.54 2.12 7 5.4-1.62c2.9-.87 4.27-2.35 3.48-5-.79-2.65-2.86-2.83-5.88-1.92zm3.38 9.63l-5.56 1.67 2.38 8 5.41-1.62c3.29-1 4.79-2.72 4-5.52-.79-2.8-3.07-3.48-6.23-2.53z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M266.84 244.66L270.06 243.66 276.15 263.95 287.71 260.48 288.58 263.38 273.8 267.82z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M301 260.21c-7 2.1-11.66-2.43-13.64-9-1.98-6.57-.51-13 6.39-15.12 6.9-2.12 11.59 2.41 13.59 9.09s.73 12.91-6.34 15.03zm-6.39-21.3c-5.27 1.59-5.36 6.62-4 11.28 1.36 4.66 4.26 8.76 9.5 7.19 5.24-1.57 5.32-6.53 3.91-11.22-1.41-4.69-4.23-8.8-9.41-7.25z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M324.12 253.27c-6.9 2.07-11.41-2.43-13.41-9.11s-.58-13 6.16-15c5.18-1.56 9.26.94 11.26 5.24l-3.25 1c-1.38-2.93-3.8-4.37-7.15-3.36-4.95 1.49-5.15 6.48-3.73 11.2 1.42 4.72 4.36 8.74 9.27 7.26 3.55-1.06 4.72-3.68 4.16-6.85l3.32-1c.93 4.69-1.38 9.04-6.63 10.62z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M330.14 225.65L333.36 224.65 336.84 236.24 344.01 221.45 347.75 220.32 342.53 231.41 355.53 243.25 351.82 244.37 340.94 234.37 338.05 240.37 340.3 247.86 337.07 248.86z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M354.32 231.46L364.25 228.46 365.12 231.36 355.19 234.36z"
                            ></path>
                            <path
                                fill="#FFF"
                                d="M378.78 236.32l-5.72-19.05c-1.44 1.71-2.87 3.45-4.3 5.16l-.94-3.12c1.53-1.88 3-3.79 4.58-5.67l2.67-.8 6.77 22.56-3.06.92z"
                            ></path>
                        </g>
                    </g>
                </g>
            </svg>
        );
    } else {
        return <div></div>;
    }
}

export default SitePlanSvg;
