import React from 'react';
import './Submit.scss';

const Submit = (props) => {
    return (
        <div className="submit">
            <div
                className="submit-button"
                onClick={props.handleSubmit}
            >
                {props.buttonText}
            </div>
        </div>
    );
};

export default Submit;
