import React, { useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import "react-simple-keyboard/build/css/index.css";
import "./VirtualKeyboard.scss";

function VirtualKeyboard(props) {
    const [layout, setLayout] = useState("default");

    let classes = "virtual-keyboard";
    if(props.open) classes += ' open';

    function handleShift() {
        const name = layout === "default" ? "shift" : "default";
        setLayout(name);
    }

    function keyPress(button) {
        if(button === "{shift}" || button === "{lock}") handleShift();
    }
    
    return (
        <div className={classes}>
            <Keyboard
                keyboardRef={r => (props.keyboard.current = r)}
                layoutName={layout}
                onChange={props.onChange}
                onKeyPress={keyPress}
            />
        </div>
    )
}

export default VirtualKeyboard;