import { SITEPLAN_QUERY, SITEPLAN_UPDATE, SITEPLAN_UPDATE_UNIT_OUTLINE } from '../actions/actionTypes';

const initalState = {
};

function showAll(ID, blocks) {
    //this is where its happening. I need to make sure if its mini
    //it doesn't set off this all condition

    var bkeys = Object.keys(blocks);

    for(var i = 0; i < bkeys.length; i++) {
        var ukeys = Object.keys(blocks[bkeys[i]].units);

        for(var j = 0; j < ukeys.length; j++) {
            if(blocks[bkeys[i]].units[ukeys[j]].floorplanID === ID)
                return blocks[bkeys[i]].units[ukeys[j]].outline;
        }
    }

    return false;
}

function siteplan(state = initalState, action) {
    switch(action.type) {
        case SITEPLAN_QUERY:
            return state;
        case SITEPLAN_UPDATE:
            return Object.assign({}, state, action.siteplan);
        case SITEPLAN_UPDATE_UNIT_OUTLINE:
            var blocks = Object.assign({}, state);
            var bkeys = Object.keys(blocks);
            var all = false;
            var eleMatch = false;

            if(action.ele !== -1)
            eleMatch = true;
            
            if(!action.mini)
            all = showAll(action.ID, blocks);

            if(action.mini) eleMatch = true;
            if(action.ID === 'all') all = true;

            for(var i = 0; i < bkeys.length; i++) {
                var ukeys = Object.keys(blocks[bkeys[i]].units);

                //find a unit and check it 
                for(var j = 0; j < ukeys.length; j++) {
                    if(all) {
                        blocks[bkeys[i]].units[ukeys[j]].outline = false;
                        blocks[bkeys[i]].units[ukeys[j]].fade = false;
                    }
                    else {
                        if(blocks[bkeys[i]].units[ukeys[j]].floorplanID === action.ID) {
                            if(eleMatch === true) {
                                if(blocks[bkeys[i]].units[ukeys[j]].unit_elevation.value == action.ele) {
                                    blocks[bkeys[i]].units[ukeys[j]].outline = true;
                                    blocks[bkeys[i]].units[ukeys[j]].fade = false;
                                }
                                else {
                                    blocks[bkeys[i]].units[ukeys[j]].outline = false;
                                    blocks[bkeys[i]].units[ukeys[j]].fade = true;
                                }
                            }
                            else {
                                blocks[bkeys[i]].units[ukeys[j]].outline = true;
                                blocks[bkeys[i]].units[ukeys[j]].fade = false;
                            }
                        }
                        else {
                            blocks[bkeys[i]].units[ukeys[j]].outline = false;
                            blocks[bkeys[i]].units[ukeys[j]].fade = true;
                        }
                    }
                }
            }

            return Object.assign({}, state, blocks);
        default:
            return state;
    }
}

export default siteplan; 