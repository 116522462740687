import React from 'react';
import './DownloadIcon.scss';

function DownloadIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            className="download-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-103 -442)">
                    <g transform="translate(-1)">
                        <g transform="translate(0 80)">
                            <g transform="translate(1)">
                                <g transform="translate(0 104)">
                                    <g transform="translate(79 240)">
                                        <g transform="translate(24 18)">
                                            <g
                                                fill="#FFF"
                                                transform="translate(0 13)"
                                            >
                                                <path d="M0 0H1V7H0z"></path>
                                                <path d="M19 0H20V7H19z"></path>
                                                <path d="M0 6H20V7H0z"></path>
                                            </g>
                                            <g transform="rotate(90 7 7.5)">
                                                <path
                                                    fill="#FFF"
                                                    d="M0 4H15V5H0z"
                                                ></path>
                                                <path
                                                    stroke="#FFF"
                                                    d="M10 1.27897692e-13L15 4.5 10 9"
                                                    className="stroke"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default DownloadIcon;
