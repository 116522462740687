import React from 'react';
import './FloorplansList.scss';
import FloorplansTable from '../FloorplansTable/FloorplansTable';
import Cover from '../Cover/Cover';

var renderingImageUrl = {
    backgroundImage: "url('/img-temp/rendering.jpg')"
};

var flowerImageUrl = {
    backgroundImage: "url('/img-temp/flowers.jpg')"
};

function FloorplansList() {
    return (
        <div className="floorplans-list">
            <Cover
                background={{
                    background: '#fff',
                    transition: 'all 0s !important'
                }}
                svgColor={{ stroke: '#9b3465' }}
                // noTransition
            />
            <div className="top-bar-bg"></div>
            <img
                src="/img-temp/flowers.jpg"
                className="list-flowers-left"
                alt="Flowers"
            />
            <img
                src="/img-temp/flowers-2.jpg"
                className="list-flowers-right"
                alt="Flowers"
            />
            <FloorplansTable />
        </div>
    );
}

export default FloorplansList;
