import React from 'react';
import TubIcon from '../TubIcon/TubIcon';
import ToiletIcon from '../ToiletIcon/ToiletIcon';
import BedroomsIcon from '../BedroomsIcon/BedroomsIcon';
import SquareFootIcon from '../SquareFootIcon/SquareFootIcon';
import './FloorplanDetailsStats.scss';

function FloorplanDetailsStats(props) {
    return (
        <div className="floorplan-details-stats">
            <div className="floorplan-details-name">
                <span className="name-height-spacer">{props.name}</span>
                {/* <div className="floorplan-details-star-container">
                    <img src="/img-temp/star.svg" alt="Star" />
                </div> */}
            </div>
            <div className="floorplan-details-product-type">
                {props.description} {props.productName}
            </div>
            <div className="floorplan-details-info-container">
                <div className="floorplan-details-info">
                    <SquareFootIcon />
                    {props.squarefeet} Sq. Ft.
                </div>
                <div className="floorplan-details-info">
                    <BedroomsIcon />
                    {props.bedrooms.first}
                    {props.bedrooms.second && `/${props.bedrooms.second}`}
                </div>
                <div className="floorplan-details-info">
                    <TubIcon />
                    {props.bathrooms.first}
                    {props.bathrooms.second && `/${props.bathrooms.second}`}
                </div>
                <div className="floorplan-details-info">
                    <ToiletIcon />
                    {props.half_bathrooms.first}
                    {props.half_bathrooms.second && `/${props.half_bathrooms.second}`}
                </div>
            </div>
        </div>
    );
}

export default FloorplanDetailsStats;
