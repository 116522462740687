import React from 'react';
import RadioIcon from '../RadioIcon/RadioIcon';
import "./RadioButton.scss";

function RadioButton(props) {
    let classes = "radio-button";
    if(props.selected) classes += " radio-active";

    return (
        <div className={classes} role="button" onClick={props.callback}>
            <RadioIcon />
            <label>{props.label}</label>
        </div>
    );
}

export default RadioButton;