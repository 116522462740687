import React, { useEffect } from 'react';
import './FeaturesAndFinishes.scss';
import FeaturesAndFinishesHero from '../FeaturesAndFinishesHero/FeaturesAndFinishesHero';
import FeaturesAndFinishesList from '../FeaturesAndFinishesList/FeaturesAndFinishesList';
import FeaturesAndFinishesRenderings from '../FeaturesAndFinishesRenderings/FeaturesAndFinishesRenderings';
import Cover from '../Cover/Cover';
import { useScrollBoost } from 'react-scrollbooster';
import { useSelector } from 'react-redux';
import MobileFooter from '../MobileFooter/MobileFooter';

function hideDragInfo() {
    if (
        document.querySelector('.features-and-finishes-viewport').scrollLeft >
        40
    ) {
        document.querySelector('.drag-info').style.opacity = '0';
    } else {
        document.querySelector('.drag-info').style.opacity = '1';
    }
}

function FeaturesAndFinishes() {
    const content = useSelector(
        (state) => state.content.pages.features_and_finishes
    );

    const [viewport] = useScrollBoost({
        direction: 'all',
        friction: 0.05,
        scrollMode: 'native',
        onClick: (state, event) => {
            if (state.isDragging) {
                event.preventDefault();
            }
        }
    });

    useEffect(() => {
        var item = document.querySelector('.features-and-finishes-viewport');

        window.addEventListener('wheel', function (e) {
            if (e.deltaY > 0) {
                if (item) item.scrollLeft += 100;
            } else if (e.deltaY < 0) {
                if (item) item.scrollLeft -= 100;
            }
        });
    });

    if (typeof content !== 'undefined') {
        return (
            <div
                ref={viewport}
                className="features-and-finishes-viewport"
                onScroll={hideDragInfo}
            >
                <div className="features-and-finishes">
                    <Cover />
                    <FeaturesAndFinishesHero
                        content={content.hero}
                        download={content.download}
                    />
                    <FeaturesAndFinishesRenderings
                        content={content.renderings}
                    />
                    <FeaturesAndFinishesList
                        content={content.exterior}
                        download={content.download}
                    />
                    <MobileFooter showBelow={1300} />
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default FeaturesAndFinishes;
