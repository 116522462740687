import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './GalleryVideos.scss';
import PlayButtonIcon from '../PlayButtonIcon/PlayButtonIcon';
import Cover from '../Cover/Cover';
import MobileFooter from '../MobileFooter/MobileFooter';

function GalleryVideos() {
    const content = useSelector((state) => state.content.pages);
    const video = useRef();
    const play = useRef();
    const [cover, setCover] = useState(true);

    let [isPlaying, setIsPlaying] = useState(false);
    const coverDisplay = {
        display: cover ? '' : 'none'
    };

    function toggleVideo() {
        setCover(false);
        if (video.current.paused) playVideo();
        else pauseVideo();
    }

    function resetVideo() {
        setIsPlaying(false);
        video.current.currentTime = 0;
    }

    function playVideo() {
        video.current.play();
        setIsPlaying(true);
    }

    function pauseVideo() {
        video.current.pause();
        setIsPlaying(false);
    }

    useEffect(() => {
        if (typeof content.gallery !== 'undefined') {
            video.current.addEventListener('ended', resetVideo);

            return () => {
                video.current.removeEventListener('ended', resetVideo);
            };
        }
    });

    if (typeof content.gallery !== 'undefined') {
        const renderings = content.gallery;

        return (
            <div className="gallery-videos">
                <Cover />
                <div className="video-container" onClick={toggleVideo}>
                    <video id="video" src={renderings.video.url} ref={video} />
                    <img
                        src={renderings.video_cover.url}
                        alt={renderings.video_cover.alt}
                        // style={coverDisplay}
                        className="faux-thumb"
                    />
                    <div
                        className={`button-container${
                            isPlaying ? ' playing' : ''
                        }`}
                        // onClick={toggleVideo}
                    >
                        <PlayButtonIcon />
                        Play Video
                    </div>
                </div>
                <MobileFooter showBelow={1301} />
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default GalleryVideos;
