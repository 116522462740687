import React from 'react';
import './StarIcon.scss';

function StarIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="22"
            viewBox="0 0 24 22"
            className={`star-icon${props.saved ? ' active-star' : ''}`}
        >
            <g
                fill="none"
                fillOpacity="0"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
            >
                <g
                    fill="#D8D8D8"
                    stroke="#FFF"
                    transform="translate(-1796 -27)"
                >
                    <g transform="translate(224)">
                        <g transform="translate(1521)">
                            <path d="M63 28.194l3.049 6.61 7.228.857-5.344 4.942 1.419 7.14L63 44.186l-6.352 3.555 1.419-7.14-5.344-4.941 7.228-.857L63 28.194z"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default StarIcon;
