import React, { useRef, useState } from 'react';
import './TabbedLayout.scss';
import Tab from '../Tab/Tab';

function TabbedLayout(props) {
    //const [index, setIndex] = useState(props.index || 0);

    function active(key) {
        return props.index === key;
    }

    function container_class(key) {
        let classes = 'tabbed-container';
        if (active(key)) classes += ' active';
        if (props.colCont) classes += ' col-cont';
        return classes;
    }

    function tab_clicked(key) {
        //change the displayed tab to the selected tab
        //lets support passing in a function to run
        if (typeof props.callback === 'function') props.callback(key);
        //setIndex(key);
    }

    function indexOutOfScope() {
        var outOfScope = true;

        props.titles.map((title, key) => {
            if (key === props.index) outOfScope = false;
        });

        //if (outOfScope) setIndex(parseInt(Object.keys(props.titles)[0]));
    }

    if (props.titles.length !== 0) indexOutOfScope();
    
    return (
        <div className="tabbed-layout">
            <div className="tabbed-titles">
                {props.titles &&
                    props.titles.map((title, key) => (
                        <Tab
                            text={title.label}
                            key={key}
                            active={active(key)}
                            callback={(e) => {
                                e.stopPropagation();
                                tab_clicked(key);
                            }}
                        />
                    ))}
            </div>
            <div
                className={`tabbed-content${props.details ? ' details' : ''}${
                    props.compare ? ' details compare' : ''
                }${props.grid ? ' grid' : ''}`}
            >
                {props.content &&
                    props.content.map((con, key) => (
                        <div className={container_class(key)} key={key}>
                            {con}
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default TabbedLayout;
