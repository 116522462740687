import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    filterFloorplans,
    updateFilterValue,
    updateFilterActive,
    clearSearchFilter,
    updateFilterCompareValue
} from '../../redux/actions/floorplanActions';
import RadioButton from '../RadioButton/RadioButton';
import "./RadioFilter.scss";

function RadioFilter(props) {
    const [selected, setSelected] = useState(props.index);
    const dispatch = useDispatch();
    
    function changeSelected(id) {
        setSelected(id);
        dispatch(updateFilterActive(props.filterID, true));
        dispatch(updateFilterValue(props.filterID, id, id));
        
        dispatch(updateFilterCompareValue('left', props.buttons[id].value));
        dispatch(updateFilterCompareValue('right', props.buttons[id].value));

        //clear the search filter when we update the filter value
        props.clear();
        dispatch(clearSearchFilter());

        //refilter the floorplans
        dispatch(filterFloorplans());
    }

    function is_selected(id) {
        return selected === id;
    }

    return(
        <div className="radio-filter">
            {props.buttons && props.buttons.length > 0 && props.buttons.map((button, key) => 
                <RadioButton
                    key={key}
                    value={button.value}
                    label={button.label}
                    selected={is_selected(key)}
                    callback={() => changeSelected(key)}
                />
            )}
        </div>
    );
}

export default RadioFilter;