import React, { useRef, useEffect } from 'react';
import SubheaderInPageLinks from '../SubheaderInPageLinks/SubheaderInPageLinks';
import Area from '../Area/Area';
import './AreaPage.scss';

const AreaPage = () => {
    const areaLinkContainer = useRef();
    const areaViewport = useRef();

    useEffect(() => {
        const areaLinks = areaLinkContainer.current.querySelectorAll('a');
        for (const areaLink of areaLinks) {
            areaLink.addEventListener('click', areaSubmenuClick);
        }

        function areaSubmenuClick(e) {
            e.preventDefault();
            const href = this.getAttribute('href');
            const offsetLeft = document.querySelector(href).offsetLeft;

            areaViewport.current.scroll({
                left: offsetLeft,
                behavior: 'smooth'
            });
        }
    });

    useEffect(() => {
        document.querySelector('#root').scrollTo(0, 0);
    }, []);

    return (
        <div className="area-page">
            <SubheaderInPageLinks
                areaLinkContainer={areaLinkContainer}
            ></SubheaderInPageLinks>
            <Area areaViewport={areaViewport} />
        </div>
    );
};

export default AreaPage;
