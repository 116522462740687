import React from 'react';
import './DragInfo.scss';
import DragIcon from '../DragIcon/DragIcon';

function hideDragInfo() {
    if (document.querySelector('.home-viewport').scrollLeft > 40) {
        document.querySelector('.drag-info').style.opacity = '0';
    } else {
        document.querySelector('.drag-info').style.opacity = '1';
    }
}

function DragInfo() {
    return (
        <div className="drag-info">
            <span>
                Scroll / Drag
                <DragIcon />
            </span>
        </div>
    );
}

export default DragInfo;
