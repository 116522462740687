import React from 'react';
import './LoadingIcon.scss';

function LoadingIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="38"
            stroke="#fff"
            viewBox="0 0 38 38"
            className="loading-icon"
            style={props.svgColor}
        >
            <g
                fill="none"
                fillRule="evenodd"
                strokeWidth="2"
                transform="translate(1 1)"
            >
                <circle cx="18" cy="18" r="18" strokeOpacity="0.5"></circle>
                <path d="M36 18c0-9.94-8.06-18-18-18"></path>
            </g>
        </svg>
    );
}

export default LoadingIcon;
