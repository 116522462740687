import React, { useEffect } from 'react';
import './App.scss';
import Router from './components/Router/Router';
import { useDispatch } from 'react-redux';
import { queryContent } from './redux/actions/contentActions';
import { queryFloorplans } from './redux/actions/floorplanActions';
import { querySiteplan } from './redux/actions/siteplanActions';
import {
    getCookie,
    updateCookie,
    checkIfTouchscreen
} from './redux/actions/utilitiesActions';

function App() {
    const dispatch = useDispatch();

    //this one only fires once use it to init stuff
    useEffect(() => {
        dispatch(checkIfTouchscreen());
        dispatch(queryContent());
        dispatch(queryFloorplans());
        dispatch(querySiteplan());
        //dispatch(getCookie());
        //dispatch(updateCookie({test: "value"}));
    }, []);

    // useEffect(() => {
    //     let rootElement = document.querySelector('#root');
    //     const setRootHeight = () => {
    //         let windowHeight = window.innerHeight;
    //         rootElement.style.height = windowHeight + 'px';
    //     };
    //     setRootHeight();
    //     window.addEventListener('resize', setRootHeight);
    //     return () => {
    //         window.removeEventListener('resize', setRootHeight);
    //     };
    // });

    return <Router></Router>;
}

export default App;
