import React from 'react';
import './Tab.scss';

function Tab(props) {
    return (
        <div
            className={`floorplan-details-tab${
                props.active ? ' active-tab' : ''
            }`}
            onClick={props.callback}
        >
            {props.text}
        </div>
    );
}

export default Tab;
