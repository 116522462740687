import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './FeaturesAndFinishesHero.scss';
import DragInfo from '../DragInfo/DragInfo';
import MainButton from '../MainButton/MainButton';
import DownloadIcon from '../DownloadIcon/DownloadIcon';
import utilities from '../../redux/reducers/utilitiesReducer';

function FeaturesAndFinishesHero(props) {
    // useEffect(() => {
    //     const sizeImageContainers = () => {
    //         let heroImage = document.querySelector('.features-hero-image');
    //         let heroImageWidth = heroImage.naturalWidth / 2;
    //         heroImage.style.width = heroImageWidth + 'px';
    //     };

    //     // sizeImageContainers();
    // });

    const isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);

    return (
        <div className="features-and-finishes-hero">
            <DragInfo />
            <div className="copy">
                <h1>{props.content.heading}</h1>
                <h2>{props.content.sub_heading}</h2>
                <div dangerouslySetInnerHTML={{ __html: props.content.copy }} />
                {!isTouchScreen && (
                    <MainButton
                        text={props.download.label}
                        svg={<DownloadIcon />}
                        link={props.download.file}
                        download
                        blank
                    />
                )}
            </div>
            <div className="hero-image-container">
                <img
                    src={props.content.image.url}
                    className="features-hero-image"
                    alt={props.content.image.alt}
                    // style={{ width: '1392px' }}
                />
            </div>
        </div>
    );
}

export default FeaturesAndFinishesHero;
