import React from 'react';
// import './CheckboxIcon.scss';

function CheckboxIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={`checkbox-icon${props.active ? ' checkbox-active' : ''}`}
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-1009 -176)">
                    <g transform="translate(0 80)">
                        <g transform="translate(961 64)">
                            <g transform="translate(48 32)">
                                <path
                                    fill="transparent"
                                    stroke="#F1F7F2"
                                    d="M0.5 0.5H23.5V23.5H0.5z"
                                    className="box"
                                ></path>
                                <path
                                    fill="#F1F7F2"
                                    fillRule="nonzero"
                                    d="M9.808 16.068l8.198-8.198a.328.328 0 000-.465l-.309-.309a.328.328 0 00-.464 0l-7.657 7.657-2.707-2.707a.328.328 0 00-.464 0l-.309.31a.328.328 0 000 .463l3.248 3.249a.328.328 0 00.464 0z"
                                    className="checkmark"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CheckboxIcon;
