import React from 'react';
import './FormRadio.scss';
import RadioIcon from '../RadioIcon/RadioIcon';
import RequiredStar from '../RequiredStar/RequiredStar';

const FormRadio = (props) => {
    const activateRadio = (e) => {
        let allButtons = document.querySelectorAll('.form-radio-single');
        for (var i = 0; i < allButtons.length; i++) {
            allButtons[i].classList.remove('active');
        }
        e.target.closest('.form-radio-single').classList.add('active');
        props.setFormValue({
            ...props.formValue,
            [e.target.closest('.form-radio-single').querySelector('input')
                .name]: e.target.closest('.form-radio-single').textContent
        });
    };

    const radioButtons = props.buttons;
    const radioButtonsList = radioButtons.map((radioButton, key) => (
        <div className="form-radio-single" onClick={activateRadio} key={key}>
            <input type="radio" name={props.name} value={radioButton.value} />
            <RadioIcon />
            <label htmlFor={radioButton.value}>{radioButton.text}</label>
        </div>
    ));

    return (
        <div className="form-radio">
            <label className="radio-label">
                {props.label}
                {props.required && <RequiredStar />}
            </label>
            <div className="radio-buttons-list">{radioButtonsList}</div>
        </div>
    );
};

export default FormRadio;
