import React, { useState } from 'react';
import CloseIcon from '../CloseIcon/CloseIcon';
import FloorplanDetailsFloorplanPanel from '../FloorplanDetailsFloorplanPanel/FloorplanDetailsFloorplanPanel';
import FloorplanDetailsInfoPanel from '../FloorplanDetailsInfoPanel/FloorplanDetailsInfoPanel';
import FloorplanDetailsSendEmailModal from '../FloorplanDetailsSendEmailModal/FloorplanDetailsSendEmailModal';

function SiteplanPanel(props) {
    const [modalOpen, setModalOpen] = useState(false);

    function toggleModal() {
        setModalOpen(!modalOpen);
    }

    function closeModal() {
        setModalOpen(false);
    }

    if(typeof props.floorplan !== 'undefined' && typeof props.floorplan.elevation !== 'undefined') {
        const eKeys = Object.keys(props.floorplan.elevation);

        return(
            <div
                className={`site-plan-floorplan-details${
                    props.open
                        ? ' site-plan-floorplan-details-open'
                        : ''
                }`}
            >
                <FloorplanDetailsSendEmailModal
                    open={modalOpen}
                    close={closeModal}
                    label={`${props.floorplan.realName} ${
                        props.floorplan.elevation[eKeys[props.floorplan.ele]]
                            .label_elevation.letter_label
                    }`}
                    floorplans={[
                        {
                            ID: props.floorplan.ID,
                            ele: eKeys[props.floorplan.ele]
                        }
                    ]}
                />
                <FloorplanDetailsInfoPanel
                    sitePlan={true}
                    floorplan={props.floorplan}
                    toggleModal={toggleModal}
                />
                <FloorplanDetailsFloorplanPanel
                    sitePlan={true}
                    floorplan={props.floorplan}
                />
                <button
                    className="site-plan-close-floorplan"
                    onClick={() => props.callback()}
                >
                    <CloseIcon />
                </button>
            </div>
        )
    }
    else {
        return <div/>
    }
}

export default SiteplanPanel;