import React, { useEffect } from 'react';
import './SubheaderInPageLinks.scss';

function SubheaderInPageLinks(props) {
    return (
        <div
            className="subheader-in-page-links area-subheader"
            ref={props.areaLinkContainer}
        >
            <a href="#overview">Overview</a>
            <a href="#trails">Trails</a>
            <a href="#area">Area</a>
            <a href="#amenities">Amenities</a>
        </div>
    );
}

export default SubheaderInPageLinks;
