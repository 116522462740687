import React, { useState, useEffect } from 'react';
import './FeaturesAndFinishesRenderings.scss';

function FeaturesAndFinishesRenderings(props) {
    const [index, setIndex] = useState(0);

    const sizeImageContainers = () => {
        let finishRendering = document.querySelector('.finish-rendering');
        let finishRenderingWidth = finishRendering.naturalWidth;
        let finishRenderingHeight = finishRendering.naturalHeight;
        let finishRenderingAspectRatio =
            finishRenderingWidth / finishRenderingHeight;
        let finishRenderingContainer = document.querySelector(
            '.finish-rendering-container'
        );
        let finishRenderingContainerHeight =
            finishRenderingContainer.offsetHeight;
        let finishRenderingContainerWidth =
            finishRenderingContainerHeight * finishRenderingAspectRatio;
        finishRenderingContainer.style.width =
            Math.floor(finishRenderingContainerWidth) + 'px';

        let finishImage = document.querySelector('.finish-image');
        let finishImageWidth = finishImage.naturalWidth;
        let finishImageHeight = finishImage.naturalHeight;
        let finishImageAspectRatio = finishImageWidth / finishImageHeight;
        let finishImageContainer = document.querySelector(
            '.finish-image-container'
        );
        let finishImageContainerHeight = finishImageContainer.offsetHeight;
        let finishImageContainerWidth =
            finishImageContainerHeight * finishImageAspectRatio;
        finishImageContainer.style.width =
            Math.floor(finishImageContainerWidth) + 'px';
    };

    useEffect(() => {
        window.addEventListener('resize', sizeImageContainers);

        return () => {
            window.removeEventListener('resize', sizeImageContainers);
        };
    }, []);

    function updateIndex(i) {
        setIndex(i);
    }

    function thumb_classes(i) {
        let classes = 'thumb';
        if (i === index) classes += ' active';

        return classes;
    }

    return (
        <div className="features-and-finishes-renderings">
            <div className="slider-left">
                <img
                    src="/img-temp/features-renderings-flowers-left.jpg"
                    className="features-renderings-flowers-left"
                    alt="Flowers"
                />
                <div className="slider-copy">
                    <div className="big-number">
                        {props.content.finishes_slider[index].big_number}
                    </div>
                    {props.content.finishes_slider[index].heading}
                </div>
                <div className="slider-thumbs-title">Select Finishes</div>
                <div className="slider-thumbs">
                    {props.content.finishes_slider.map((slide, key) => (
                        <button
                            key={key}
                            className={thumb_classes(key)}
                            onClick={() => updateIndex(key)}
                        >
                            <img
                                src={slide.thumbnail.url}
                                alt={slide.thumbnail.alt}
                            />
                        </button>
                    ))}
                </div>
                <div className="finish-info">
                    <div className="title">
                        {props.content.finishes_slider[index].label}
                    </div>
                    <div className="finish-copy">
                        {props.content.finishes_slider[index].copy}
                    </div>
                </div>
            </div>
            <div className="slider-right">
                <div className="finish-image-container">
                    <img
                        src={
                            props.content.finishes_slider[index].vertical_image
                                .url
                        }
                        className="finish-image"
                        alt=""
                        onLoad={sizeImageContainers}
                    />
                </div>
                <div className="finish-rendering-container">
                    <img
                        src={
                            props.content.finishes_slider[index]
                                .horizontal_image.url
                        }
                        className="finish-rendering"
                        alt=""
                        onLoad={sizeImageContainers}
                    />
                </div>
                <img
                    src="/img-temp/features-renderings-flowers-right.jpg"
                    className="features-renderings-flowers-right"
                    alt="Flowers"
                />
            </div>
        </div>
    );
}

export default FeaturesAndFinishesRenderings;
