import React from 'react';
import './Subheader.scss';
import { NavLink } from 'react-router-dom';

function Subheader(props) {
    return (
        <div
            className={`subheader${props.isAreaPage ? ' area-subheader' : ''}`}
        >
            <NavLink to="/gallery/videos" activeClassName="active">
                Videos
            </NavLink>
            <NavLink to="/gallery/renderings" activeClassName="active">
                Renderings
            </NavLink>
            <NavLink to="/gallery/area" activeClassName="active">
                Area
            </NavLink>
        </div>
    );
}

export default Subheader;
