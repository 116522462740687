import React from 'react';
import { useDispatch } from 'react-redux';
import './FormTextInput.scss';
import RequiredStar from '../RequiredStar/RequiredStar';
import { removeFormFieldError } from '../../redux/actions/contentActions';

const FormTextInput = (props) => {
    const dispatch = useDispatch();

    let inputSectionClass = props.inputSectionChild
        ? ' input-section-child'
        : '';
    let radioSectionClass = props.radioSectionChild
        ? ' radio-section-child'
        : '';
    let brokerageActiveClass = ' active';
    
    if(props.conditional) {
        brokerageActiveClass = '';
        if(props.cond_value === 'working with' || props.cond_value === 'realtor') {
            brokerageActiveClass = ' active';
        }
    }
    let inputClasses =
        'form-text-input' +
        inputSectionClass +
        radioSectionClass +
        brokerageActiveClass;

    const onTextInputChange = (e) => {
        const value = e.target.value;
        props.setFormValue({ ...props.formValue, [e.target.name]: value });
        if (props.error) {
            dispatch(removeFormFieldError(props.index));
        }
    };

    return (
        <div className={inputClasses}>
            <label htmlFor={props.id}>
                {props.label}
                {props.required && <RequiredStar />}
            </label>
            <input
                type={props.email ? 'email' : 'text'}
                placeholder={props.placeholder}
                onChange={onTextInputChange}
                name={props.name}
                className={props.error ? 'text-input-error' : ''}
            />
        </div>
    );
};

export default FormTextInput;
