import {
    FLOORPLAN_QUERY,
    FLOORPLAN_UPDATE,
    FLOORPLAN_UPDATE_ACTIVE,
    FLOORPLAN_UPDATE_ACTIVE_FROM_SLUG,
    FLOORPLAN_UPDATE_ACTIVE_ELEV,
    FLOORPLAN_UPDATE_ACTIVE_PLAN,
    FLOORPLAN_UPDATE_DISPLAYED_PLAN,
    FLOORPLAN_FILTER_FLOORPLANS,
    FLOORPLAN_UPDATE_FILTER_ACTIVE,
    FLOORPLAN_UPDATE_FILTER_VALUE,
    FLOORPLAN_ACTIVE_TO_COMPARE,
    FLOORPLAN_COMPARE_TO_ACTIVE,
    FLOORPLAN_UPDATE_FILTERS,
    FLOORPLAN_UPDATE_PRODUCTS,
    FLOORPLAN_UPDATE_PRODUCT_FILTER_ID,
    FLOORPLAN_UPDATE_SEARCH_FILTER_ID,
    FLOORPLAN_UPDATE_ACTIVE_COMPARE_FLOORPLAN,
    FLOORPLAN_UPDATE_COMPARE_FILTER_VALUE,
    FLOORPLAN_FILTER_COMPARE_FLOORPLANS,
    FLOORPLAN_SETUP_COMPARE_FILTERS,
    FLOORPLAN_UPDATE_SITEPLAN_ACTIVE_FLOORPLAN,
    FLOORPLAN_TOGGLE_FILTER_DROPDOWN,
    FLOORPLAN_CLEAR_SEARCH_FILTER,
    FLOORPLAN_CLEAR_NORMAL_FILTERS,
    FLOORPLAN_SEND_EMAIL,
    FLOORPLAN_EMAIL_STATUS,
    FLOORPLAN_UPDATE_EMAIL_ERROR,
    FLOORPLAN_CLEAR_COMPARE,
    FLOORPLAN_CLEAR_RIGHT_COMPARE,
    FLOORPLAN_CLOSE_ALL_FILTER_DROPDOWNS,
    FLOORPLAN_UPDATE_COMPARE_DISPLAYED_PLAN,
    FLOORPLAN_EMAIL_CLEAR_SUBMISSION,
    FLOORPLAN_SETUP_SITEPLAN_LIST,
    FLOORPLAN_CLEAR_COMPARE_HIDDEN
} from './actionTypes';
import apiService from '../../services/apiService';

/**
 * Function: floorplan_notify
 * this function is simply here to notify the system that floorplans started loading
 * because it happens async we know the action is done because the next funciton in the
 * chain will get called
*/
function floorplan_notify() {
    return {
        type: FLOORPLAN_QUERY
    }
}

/**
 * Function: updateFloorplans.
 * This method updates the redux store with the new floorplans that have been returned
 * from our query
 * 
 * @param {*} floorplans
*/
function updateFloorplans(floorplans) {
    for(var i = 0; i < floorplans.length; i++) {
        var newElevation = {};

        for(var j = 0; j < floorplans[i].elevation.length; j++) {
            newElevation[floorplans[i].elevation[j].label_elevation.value] = floorplans[i].elevation[j];
        }

        floorplans[i].elevation = newElevation;
    }
    
    return {
        type: FLOORPLAN_UPDATE,
        floorplans
    }
}

export function clearCompareFloorplans() {
    return {
        type: FLOORPLAN_CLEAR_COMPARE
    }
}

/**
 * Funtion: updatefilters.
 * This method updates the filters in the redux store. Before it does this it setups
 * the filters to be properly used by the system
 * 
 * @param {*} filters 
*/
export function updateFilters(filters) {
    var newFilters = [];

    for(var i = 0; i < filters.length; i++) {
        var filterFields = filterTypeByName(filters[i].filter_field);
        var active = false;
        var value = filterFields.value;
    
        if(filters[i].filter_field === "product") {
            active = false;
            value = filters[i].values[0];
        }

        newFilters.push({
            name: filters[i].filter_label,
            active: active,
            type: filterFields.type,
            value: value,
            values: filters[i].values,
            index: -1,
            open: false
        });
    }

    return {
        type: FLOORPLAN_UPDATE_FILTERS,
        newFilters
    }
}

/**
 * Function: filterTypeByName
 * 
 * this function returns the type and a default value
 * based on the type of filter it is
 * 
 * @param {*} name 
 * @returns {
 *   type: string
 *   value: string|number
 * } type
 * 
 * 
*/
function filterTypeByName(name) {
    var type = {};
    switch(name) {
        case "bedroom":
            type = {
                type: "number",
                value: -1
            };
        break;
        case "sqft":
            type = {
                type: "number",
                value: -1
            };
        break;
        case "block":
            type = {
                type: "id",
                value: -1
            };
        break;
        case "product":
            type = {
                type: "product",
                value: -1
            }
        break;
        case "search":
            type = {
                type: "search",
                value: ""
            };
        break;
        default:
            type = {
                type: "number",
                value: -1
            };
        break;
    }

    return type;
}

/**
 * function updateFilterValue
 * updates the value of the specified filter in the redux store
 * 
 * @param {*} id 
 * @param {*} value 
*/
export function updateFilterValue(id, value, index) {
    return {
        type: FLOORPLAN_UPDATE_FILTER_VALUE,
        id: id,
        value: value,
        index: index
    }
}

/**
 * Function: updateFilterCompareVaule
 * 
 * Updates the value of the filter for the compare lists
 * 
 * @param {*} side The side to switch
 * @param {*} term The id of the term
*/
export function updateFilterCompareValue(side, term) {
    return {
        type: FLOORPLAN_UPDATE_COMPARE_FILTER_VALUE,
        side,
        term
    }
}

/**
 * function updateFilterActive
 * updates the active status of the specificed filter in the redux store
 * 
 * @param {*} id 
 * @param {*} active 
*/
export function updateFilterActive(id, active) {
    return {
        type: FLOORPLAN_UPDATE_FILTER_ACTIVE,
        id: id,
        active: active
    }
}

/**
 * function toggleFilterDropdown
 * 
 * @param {*} index the index of the Dropdown
 */
export function toggleFilterDropdown(index, open) {
    return {
        type: FLOORPLAN_TOGGLE_FILTER_DROPDOWN,
        index: index,
        open: open
    }
}

/**
 * Function: closeAllFilterDropdowns
 * 
 * Closes all of the filter dropdowns
 */
export function closeAllFilterDropdowns() {
    return {
        type: FLOORPLAN_CLOSE_ALL_FILTER_DROPDOWNS
    }
}

/**
 * function clearSearchFilter
 * 
 * Clears out all values in the search filter and then turns it off
 */
export function clearSearchFilter() {
    return {
        type: FLOORPLAN_CLEAR_SEARCH_FILTER
    }
}

/**
 * function clearNormalFilters
 * 
 * Clears out all values in the normal filters and then turns it off
 */
export function clearNormalFilters() {
    return {
        type: FLOORPLAN_CLEAR_NORMAL_FILTERS
    }
}

/**
 * Function: updateActiveFloorplan
 * 
 * updates the actively used floorplan in the redux store.
 * This floorplan will show up on the floorplan page, will be use to
 * populare the url bar, and will be one of the two floorplans used for
 * comparing two floorplans
 * 
 * @param {*} key 
*/
export function updateActiveFloorplan(key) {
    return {
        type: FLOORPLAN_UPDATE_ACTIVE,
        key: key
    }
}

/**
 * Updates the actively used floorplan in the redux store.
 * This will be done using the slug generated on the floorplan
 * details page.
 * 
 * @param {*} slug 
 */
export function activeFloorplanFromSlug(slug) {
    return {
        type: FLOORPLAN_UPDATE_ACTIVE_FROM_SLUG,
        slug: slug
    }
}

/**
 * Function: updateSiteplanToFloorplan
 * 
 * updates the actively used floorplan in the redux store.
 * This floorplan will show up in the floorplan popout on the siteplan.
 * It will also show up on the floorplan details page. I felt that was wise
 * 
 * @param {*} ID The wordpress ID of the floorplan. Need to search for the actual object
 * @param {*} ele The elevation to start the floorplan on
 * @param {*} unit The unit Id to be set as active
 * @param {*} bID The key for the block this unit is on
*/
export function updateSiteplanToFloorplan(ID, ele, unit, bID) {
    return {
        type: FLOORPLAN_UPDATE_SITEPLAN_ACTIVE_FLOORPLAN,
        ID,
        ele,
        unit,
        bID
    }
}

/**
 * Function: updateActiveCompareFloorplan
 * 
 * updates the active used compare floorplans in the redux store.
 * This floorlan will show up on the compare flooplans page, on
 * the side passed in
 * 
 * @param {*} key The id of the floorplan
 * @param {*} side The side the compare is
*/
export function updateActiveCompareFloorplan(key, side) {
    return {
        type: FLOORPLAN_UPDATE_ACTIVE_COMPARE_FLOORPLAN,
        key,
        side
    }
}

/**
 * Function: activeFloorplanToCompare
 * 
 * updates the active use floorplan on the compare floorplans page
 * with the floorplan passed in from the floorpan details page
 * IT will always go on the left side
 * 
 * @param {*} floorplan 
*/
export function activeFloorplanToCompare(floorplan) {
    return {
        type: FLOORPLAN_ACTIVE_TO_COMPARE,
        floorplan
    };
}

/**
 * Function: compareFloorplanToActive
 * 
 * updates the active used floorplan on the floorplan details page
 * with the floorplan passed in from the compare floorplans
 * this will also take the side and move the product that one has
 * over to the active side so the lists match up
 * 
 * @param {*} floorplan  The floorplan to be displayed
 * @param {*} side The side to take the product filter from
*/
export function compareFloorplanToActive(floorplan, side) {
    return {
        type: FLOORPLAN_COMPARE_TO_ACTIVE,
        floorplan,
        side
    }
}

/**
 * Function: updateActiveElevation
 * 
 * Updates the elevation of the actively used floorplan in the redux store.
 * @param {*} ele 
*/
export function updateActiveElevation(ele) {
    return {
        type: FLOORPLAN_UPDATE_ACTIVE_ELEV,
        ele
    }
}

/**
 * Function: updateActivePlan
 * 
 * Updates the foorplan index of the active floorplan in the redex store. It also
 * updates the display floorplans to keep the grid page in sync 
 * 
 * @param {*} plan 
*/
export function updateActivePlan(plan) {
    return {
        type: FLOORPLAN_UPDATE_ACTIVE_PLAN,
        plan
    }
}

/**
 * Function: updateDisplayedFloorplan
 * 
 * Updates the floorplan index of the active floorplan in the redux store of the floorplan 
 * for display on the grid floorplans page and the floorplan details page
 * 
 * @param {*} floorplanID 
 * @param {*} plan 
*/
export function updateDisplayedFloorplan(floorplanID, plan){
    return {
        type: FLOORPLAN_UPDATE_DISPLAYED_PLAN,
        floorplanID,
        plan
    }
}

/**
 * Function: updateActiveCompareDisplayedFloorplan
 * 
 * Updates the floorplan index of the active compoare floorplan in the redux store
 * 
 * @param {*} side Side of the compare page
 * @param {*} plan Index of the floorplan
 */
export function updateActiveCompareDisplayedFloorplan(side, plan) {
    return {
        type: FLOORPLAN_UPDATE_COMPARE_DISPLAYED_PLAN,
        side,
        plan
    }
}

/**
 * Function: filterFloorplans
 * 
 * applies the currently active filters to the floorplan list in the redux store.
 * Generating the list of floorplans that will be used for display on the
 * floorplans page.
 * 
*/
export function filterFloorplans() {
    return {
        type: FLOORPLAN_FILTER_FLOORPLANS
    }
}

/**
 * Function: filterCompareFloorplans
 * 
 * applies the product filter to the floorplans list in the redux store.
 * Generating the list of floorplans that will be used for displayed on
 * one side of the Compare Floorplans page.
 * 
 * @param {*} side 
*/
export function filterCompareFloorplans(side) {
    return {
        type: FLOORPLAN_FILTER_COMPARE_FLOORPLANS,
        side
    }
}

export function clearCompareHidden(side) {
    return {
        type: FLOORPLAN_CLEAR_COMPARE_HIDDEN,
        side
    }
}

/**
 * Function: setupCompareFilters
 * 
 * setups inital values for the compare filters so we can have an inital value
 * load on the display table
 * 
*/
export function setupCompareFilters() {
    return {
        type: FLOORPLAN_SETUP_COMPARE_FILTERS
    }
}

/**
 * Function: updateProductFilterID
 * 
 * Updates the productFilterID so components can find it
*/
export function updateProductFilterID() {
    return {
        type: FLOORPLAN_UPDATE_PRODUCT_FILTER_ID
    }
}

/**
 * Function: updateSearchFilterID
 * 
 * Updates the searchFilterID so components can find it
*/
export function updateSearchFilterID() {
    return {
        type: FLOORPLAN_UPDATE_SEARCH_FILTER_ID
    }
}

/**
 * Function: updateProducts
 * 
 * adds the products to the redux store
 * 
 * @param {*} prods 
*/
export function updateProducts(prods) {
    var products = [];

    for(var i = 0; i < prods.length; i++) {
        products.push({
            name: prods[i].name,
            id: prods[i].term_id
        });
    }
    return {
        type: FLOORPLAN_UPDATE_PRODUCTS,
        products
    }
}

/**
 * Function: emailFloorplans
 * 
 * This function sends an email to the provided email address
 * with all of the downloadable versions of the floorplan attached
 * 

 
export function emailFloorplans(email, floorplans) {
    return {
        type: FLOORPLAN_SEND_EMAIL,
        email,
        floorplans
    }
}
*/

function setupSitePlanFloorplans() {
    return {
        type: FLOORPLAN_SETUP_SITEPLAN_LIST
    }
}

function emailFloorplans_notify() {
    return {
        type: FLOORPLAN_SEND_EMAIL,
    }
}

function updateEmailSendingStatus(data) {
    return {
        type: FLOORPLAN_EMAIL_STATUS,
        success: data.success
    }
}

export function updateEmailError(err) {
    return {
        type: FLOORPLAN_UPDATE_EMAIL_ERROR,
        err
    }
}

export function clearEmailSubmission() {
    return {
        type: FLOORPLAN_EMAIL_CLEAR_SUBMISSION
    }
}

export function clearRightComparePlan() {
    return {
        type: FLOORPLAN_CLEAR_RIGHT_COMPARE
    }
}

export function emailFloorplans(email, floorplans) {
    return function(dispatch) {
        dispatch(emailFloorplans_notify());
        return apiService.sendFloorplanEmail(email, floorplans)
        .then(response => {
            dispatch(updateEmailSendingStatus(response.data));
        });
    }
}

/**
 * Function: Query Floorplans
 * 
 * This is basically the init method for the floor plans section of the site.
 * This should only really be called once. it queries wordpress for the floor
 * plan data then sets up the filters and everything with that data
*/
export function queryFloorplans() {
    return function(dispatch) {
        dispatch(floorplan_notify);
        return apiService.getFloorplans()
        .then(response => {
            dispatch(updateFloorplans(response.data.results.floorplans));
            dispatch(updateFilters(response.data.results.filters));
            dispatch(updateProducts(response.data.results.products))
            dispatch(updateProductFilterID());
            dispatch(updateSearchFilterID());
            dispatch(filterFloorplans());
            dispatch(setupSitePlanFloorplans());
            dispatch(updateActiveFloorplan(0));
            dispatch(setupCompareFilters());
            dispatch(filterCompareFloorplans('left'));
            dispatch(filterCompareFloorplans('right'));
        }).catch(err => {
            console.error(err);
        });
    }
}