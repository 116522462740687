import React from 'react';
import './FloorplansTableTopRow.scss';

function FloorplansTableTopRow(props) {
    return (
        <React.Fragment>
            {/* {props.compare ? '' : <div className="top-row"></div>} */}
            <div className="top-row">Name</div>
            <div className="top-row">Sq. Ft.</div>
            {/* <div className="top-row">Block</div> */}
            <div className="top-row">Bedrooms</div>
            <div className="top-row">Full Baths</div>
            {/* <div className="top-row"></div> */}
            <div className="top-row"></div>
        </React.Fragment>
    );
}

export default FloorplansTableTopRow;
