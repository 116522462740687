import React from 'react';
import { useSelector } from 'react-redux';
import './GalleryRenderings.scss';
import GallerySlider from '../GallerySlider/GallerySlider';
import Cover from '../Cover/Cover';
import MobileFooter from '../MobileFooter/MobileFooter';

function GalleryRenderings() {
    const content = useSelector((state) => state.content.pages);

    if (typeof content.gallery !== 'undefined') {
        const renderings = content.gallery.renderings;

        return (
            <div className="gallery-renderings">
                <Cover
                    background={{ background: '#fff' }}
                    svgColor={{ stroke: '#9b3465' }}
                />
                <GallerySlider renderings={renderings} />
                <MobileFooter showBelow={1301} />
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default GalleryRenderings;
