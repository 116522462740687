import React from 'react';

function PlayButtonIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 80 80"
            version="1.1"
            viewBox="0 0 80 80"
            xmlSpace="preserve"
            className="play-button-icon"
        >
            <g>
                <g transform="translate(-867 -413)">
                    <g transform="translate(0 80)">
                        <g transform="translate(0 64)">
                            <g transform="translate(861 323)">
                                <g transform="translate(60)">
                                    <circle
                                        cx="-14"
                                        cy="-14"
                                        r="40"
                                        className="st0"
                                    ></circle>
                                    <path
                                        d="M-8.5 -14L-20.5 -6 -20.5 -22z"
                                        className="st1"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PlayButtonIcon;
