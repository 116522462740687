import React, { useEffect, useState } from 'react';
import FloorplansList from '../FloorplansList/FloorplansList';
import FloorplansGrid from '../FloorplansGrid/FloorplansGrid';

function FloorplanListContainer() {
    const [winWidth, setWinWidth] = useState(window.innerWidth);

    function winResized() {
        setWinWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', winResized);

        return () => {
            window.removeEventListener('resize', winResized);
        }
    });

    return (
        <React.Fragment>
            {winWidth > 768 &&
                <FloorplansList />
            }
            {winWidth <= 768 &&
                <FloorplansGrid />
            }
        </React.Fragment>
    )
}

export default FloorplanListContainer;