import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './CompareFloorplansSide.scss';
import CompareFloorplansListContainer from '../CompareFloorplansListContainer/CompareFloorplansListContainer';
import CompareFloorplansSelectFloorplan from '../CompareFloorplansSelectFloorplan/CompareFloorplansSelectFloorplan';
import CompareFloorplansSingleFloorplan from '../CompareFloorplansSingleFloorplan/CompareFloorplansSingleFloorplan';

function CompareFloorplansSide(props) {
    const compare = useSelector(state => state.floorplan.activeCompareFloorplans);
    let compareplans = useSelector(state => state.floorplan.compareFloorplans);
    const [visible, setVisible] = useState(typeof compare[props.side] !== "undefined" ? true : false);  

    let comp;
    if(typeof props.side !== "undefined")
    comp = compare[props.side];

    console.log(compareplans);

    return (
        <div
            className={
                `compare-floorplans-side` +
                `${props.side === 'left' ? ' compare-floorplans-left' : ''}` +
                `${props.side === 'right' ? ' compare-floorplans-right' : ''}`
            }
        >
            <CompareFloorplansSelectFloorplan side={props.side} />
            <CompareFloorplansListContainer
                side={props.side}
                update={setVisible}
                visible={!visible}
                comparePlans={compareplans[props.side]}
            />
            <CompareFloorplansSingleFloorplan
                side={props.side}
                floorplan={comp}
                visible={visible}
                update={setVisible}
            />
        </div>
    );
}

export default CompareFloorplansSide;
