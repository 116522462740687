import {
    UTILITIES_GATHER_COOKIES,
    UTILITIES_UPDATE_COOKIES,
    UTILITIES_CHECK_IF_TOUCHSCREEN
} from '../actions/actionTypes';
import cookieService from '../../services/cookieService';

const initialState = {
    cookie: {},
    isTouchScreen: false
};

function utilities(state = initialState, action) {
    switch(action.type) {
        case UTILITIES_CHECK_IF_TOUCHSCREEN:
            return Object.assign({}, state, {
                isTouchScreen: action.isTouch
            });
        case UTILITIES_GATHER_COOKIES:
            return Object.assign({}, state, {
                cookie: action.cookie
            });
        case UTILITIES_UPDATE_COOKIES:
            var cookie = state.cookie;

            var keys = Object.keys(action.cookie);
            keys.map((key) => {
                cookie[key] = action.cookie[key];
            });

            cookieService.updateCookie(cookie);

            return Object.assign([], state, {
                cookie: cookie
            });
        default:
            return state;
    }
}

export default utilities;