import {
    SITEPLAN_UPDATE,
    SITEPLAN_QUERY,
    SITEPLAN_UPDATE_UNIT_OUTLINE
} from './actionTypes';
import apiService from '../../services/apiService';

function siteplan_notify() {
    return {
        type: SITEPLAN_QUERY
    }
}

function updateSiteplan(siteplan) {
    var bkeys = Object.keys(siteplan);

    for(var i = 0; i < bkeys.length; i++) {
        var ukeys = Object.keys(siteplan[bkeys[i]].units);

        for(var j = 0; j < ukeys.length; j++) {
            siteplan[bkeys[i]].units[ukeys[j]].outline = false;
            siteplan[bkeys[i]].units[ukeys[j]].fade = false;
        }
    }

    return {
        type: SITEPLAN_UPDATE,
        siteplan
    }
}

export function updateUnitOutline(ID, mini = false, ele = -1) {
    return {
        type: SITEPLAN_UPDATE_UNIT_OUTLINE,
        ID,
        mini,
        ele
    }
}

export function querySiteplan() {
    return function(dispatch) {
        dispatch(siteplan_notify);
        return apiService.get('get-siteplan-blocks')
        .then(response => {
            dispatch(updateSiteplan(response.data.blocks));
        }).catch(err => {
            console.error(err);
        });
    }
}