import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DownArrowIcon from '../DownArrowIcon/DownArrowIcon';
import CheckboxButton from '../CheckboxButton/CheckboxButton';
import {
    updateFilterValue,
    updateFilterActive,
    filterFloorplans,
    toggleFilterDropdown,
    clearSearchFilter
} from '../../redux/actions/floorplanActions';
import './DropdownFilter.scss';

function DropdownFilter(props) {
    const dispatch = useDispatch();

    let output = '';
    if (props.index === -1) output = props.text;
    else output = props.options[props.index].label;

    let classes = 'search-header-dropdown-container';
    if (props.open) classes += ' active-dropdown';

    const itemNode = useRef();

    const [dropdownHeight, setDropdownHeight] = useState(0);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    let itemsListHeight = 0;

    function getHeightDropdown() {
        const items = itemNode.current.querySelectorAll('.dropdown li');
        const itemHeight = items[0].offsetHeight + 16;
        const numberOfItems = items.length;
        const itemsListHeight = numberOfItems * itemHeight + 48;
        return itemsListHeight;
    }

    function toggleDropdown() {
        // This is a hot mess and I hate it, good luck figuring all this out future me
        let listHeight = getHeightDropdown();
        setDropdownIsOpen(!dropdownIsOpen);
        setDropdownHeight(listHeight);
        if (!props.open) {
            props.setDropdownHeight(listHeight);
        } else {
            props.setDropdownHeight(-listHeight);
        }
        //do the thing here instead
        dispatch(toggleFilterDropdown(props.filterID, !props.open));
    }

    function is_active(key) {
        return props.index === key;
    }

    function upFilterValue(value, id) {
        if (value === 'reset:all') {
            //turn this filter off and set view all to active
            //setViewAll(true);
            dispatch(updateFilterActive(props.filterID, false));
            dispatch(updateFilterValue(props.filterID, -1, id));
        } else {
            dispatch(updateFilterActive(props.filterID, true));
            dispatch(updateFilterValue(props.filterID, value, id));
        }

        //clear out the search filter when we update any of these filters
        props.clear();
        dispatch(clearSearchFilter());
        dispatch(filterFloorplans());
    }

    return (
        <div className={classes}>
            <div className="button" onClick={() => toggleDropdown()}>
                <div className="dropdown-left">
                    {props.svg}
                    {output}
                </div>
                <DownArrowIcon />
            </div>
            {props.options && (
                <ul
                    className="dropdown"
                    ref={itemNode}
                    style={
                        !dropdownIsOpen
                            ? { height: '0' }
                            : { height: `${dropdownHeight}px` }
                    }
                >
                    <CheckboxButton
                        text="View All"
                        active={is_active(-1)}
                        callback={() => upFilterValue('reset:all', -1)}
                    />

                    {props.options.map((option, key) => (
                        <CheckboxButton
                            key={key}
                            text={option.label}
                            active={is_active(key)}
                            callback={() => upFilterValue(option.value, key)}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
}

export default DropdownFilter;
