/**
 * function get
 * 
 * returns the cookie from the users computer
 */
function getCookie() {
    var cookie = '';
    cookie = document.cookie;

    //parse the cookie into an object
    var cookieObject = parseCookie(cookie);

    return cookieObject;
}

function updateCookie(cookie) {
    var string = cookieToString(cookie);
    document.cookie = string;
}

function parseCookie(cookie) {
    var obj = {};

    if(cookie !== '') {
        var split = cookie.split(";");

        for(var i = 0; i < split.length; i++) {
            var param = split[i].split("=");
            obj[decodeURI(param[0])] = decodeURI(param[1]);
        }
    }

    return obj;
}

function cookieToString(cookie) {
    var keys = Object.keys(cookie);
    var cookieStr = "";

    for(var i = 0; i < keys.length; i++) {
        cookieStr += `${keys[i]}=${cookie[keys[i]]}${i === keys.length +1 ? "" : ";"}`;
    }

    return cookieStr;
}

export default {
    getCookie,
    updateCookie
};