import React, { useEffect } from 'react';
import './FloorplansGrid.scss';
import GridFloorplan from '../GridFloorplan/GridFloorplan';
import Cover from '../Cover/Cover';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useScrollBoost } from 'react-scrollbooster';

function FloorplansGrid() {
    useEffect(() => {
        const equalizeHeight = (target) => {
            let elementHeight = 0;
            const elements = document.querySelectorAll(target);

            for (var i = 0; i < elements.length; i++) {
                elements[i].style.height = 'auto';
                if (elements[i].clientHeight > elementHeight) {
                    elementHeight = elements[i].clientHeight;
                }
                if (i === elements.length - 1) {
                    for (var ii = 0; ii < elements.length; ii++) {
                        elements[ii].style.height = elementHeight + 'px';
                    }
                }
            }
        };

        const resizeFunctions = () => {
            equalizeHeight('.floorplan-details-name');
        };

        equalizeHeight('.floorplan-details-name');

        window.addEventListener('resize', resizeFunctions);

        return () => {
            window.removeEventListener('resize', resizeFunctions);
        };
    });

    const valid = useSelector((state) => state.floorplan.productFilterID);
    const floorplans = useSelector(
        (state) => state.floorplan.displayFloorplans
    );
    const history = useHistory();

    const [viewport] = useScrollBoost({
        direction: 'vertical',
        friction: 0.05,
        scrollMode: 'native',
        onClick: (state, event) => {
            if (state.isDragging) {
                event.preventDefault();
            }
        }
    });

    if (valid !== -1) {
        return (
            <div ref={viewport} className="grid-viewport">
                <div className="floorplans-grid">
                    {floorplans.map((floorplan, key) => (
                        <GridFloorplan
                            id={key}
                            key={key}
                            floorplan={floorplan}
                        />
                    ))}
                </div>
                <Cover
                    background={{
                        background: '#fff'
                    }}
                    svgColor={{ stroke: '#9b3465' }}
                    // noTransition
                />
            </div>
        );
    } else {
        // history.push('/floorplans/select-products');

        return <div></div>;
    }
}

export default FloorplansGrid;
