import React from 'react';

function ToiletIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="21"
            viewBox="0 0 18 21"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#032"
                    fillRule="nonzero"
                    transform="translate(-404 -244)"
                >
                    <g transform="translate(1)">
                        <g transform="translate(0 104)">
                            <g transform="translate(0 72)">
                                <g transform="translate(49 23)">
                                    <g transform="translate(304 2)">
                                        <path d="M66.993 44.75l.007.163v9.064c.15.335.032.814-.35 1.323-.247.329-.617.725-1.112 1.19l-.22.204-.428.383-.115.1-.117.102-.502.424-.608.497.12.63.1.575.083.536.018.128.018.126.058.479.011.113.011.111.031.422c.074 1.333-.16 2.04-.788 2.161l-.109.015L63 63.5h-8c-.749 0-.874-.628-.734-1.565.074-.489.23-1.103.452-1.814l.1-.311.137-.414c.088-.257.178-.51.268-.757l.134-.362.014-.038-.211-.11-.587-.315c-.871-.475-1.68-.95-2.35-1.398-.919-.612-1.507-1.117-1.733-1.567-.297-.596-.07-1.003.493-1.204-.652-.755-.983-1.37-.983-1.895 0-.87.76-1.25 2-1.25l5.66-.02 4.14-.002.199.002.001-5.567c0-2.495 4.785-2.55 4.993-.163zm-1.005 9.75l-14.128.002a3.522 3.522 0 00-.237.014l-.095.011-.052.008.051.06c.176.195.483.451.895.744l.183.128.456.302c.584.375 1.264.77 1.99 1.167l.149.08.145.078.55.29.74.375-.17.427-.303.797-.13.363-.13.369-.135.407-.178.572c-.163.547-.278 1.02-.334 1.389a3.44 3.44 0 00-.03.272l-.005.11v.035h7.69l.023-.064c.028-.098.048-.234.059-.404l.006-.135.002-.147c0-.17-.007-.36-.022-.569l-.018-.216-.05-.47-.07-.522-.09-.574-.026-.151-.026-.155-.12-.65-.128-.643.52-.418.137-.111.134-.11.505-.42.119-.1.116-.1.433-.378.384-.35.337-.322c.311-.307.55-.571.715-.791l.057-.079.048-.07.033-.051zm-5.004-3.026h-1.31L52 51.5c-.054 0-.106 0-.155.002l-.264.013c-.432.037-.581.133-.581.235 0 .214.198.581.599 1.072l.265.309.15.165.195.204h9.79v-2.02l-1.015-.006zM63 46.5l-.001 4.498L63 51v2h3l-.001-6.479-.006.123A1.5 1.5 0 0163 46.5zm1.5-.5a.5.5 0 100 1 .5.5 0 000-1zm-1.49-1.175l-.01.122V46.5a1.5 1.5 0 013-.021v-1.532c0-1.22-2.803-1.261-2.99-.122z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ToiletIcon;
