import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BedroomsIcon from '../BedroomsIcon/BedroomsIcon';
import SquareFootIcon from '../SquareFootIcon/SquareFootIcon';
import BlockIcon from '../BlockIcon/BlockIcon';
import SearchIcon from '../SearchIcon/SearchIcon';
import CompareFloorplansIcon from '../CompareFloorplansIcon/CompareFloorplansIcon';
import MainButton from '../MainButton/MainButton';
import DropdownFilter from '../DropdownFilter/DropdownFilter';
import RadioFilter from '../RadioFilter/RadioFilter';
import {
    filterFloorplans,
    updateFilterValue,
    clearNormalFilters,
    clearCompareFloorplans,
    closeAllFilterDropdowns
} from '../../redux/actions/floorplanActions';
import './SearchHeader.scss';
import VirtualKeyboard from '../VirtualKeyboard/VirtualKeyboard';
import { useHistory } from 'react-router-dom';
import DownArrowIcon from '../DownArrowIcon/DownArrowIcon';

function SearchHeader() {
    const history = useHistory();
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.floorplan.filters);
    const products = useSelector((state) => state.floorplan.products);
    const filterID = useSelector((state) => state.floorplan.productFilterID);
    const searchID = useSelector((state) => state.floorplan.searchFilterID);
    const isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);

    const searchInput = useRef();
    const keyboard = useRef();
    const searchHeaderSizer = useRef();
    const mobileDropdownContainer = useRef();
    const [input, setInput] = useState('');
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterHeight, setFilterHeight] = useState(0);

    const dropFilters = [];
    const radioProducts = [];
    let dropID = 0;

    useEffect(() => {
        document.addEventListener('click', close);
        return () => {
            document.removeEventListener('click', close);
        };
    });

    useEffect(() => {
        //i think im just going to do the search bar here. So it works with the touch keyboard as well.
        if(filters.length > 0)
        searchInput.current.value = filters[searchID].value || "";

        return () => {
            //close all dropdowns
            dispatch(closeAllFilterDropdowns());
        }
    }, []);
    
    // This for sure isn't gonna make sense to me later
    // diff is the height of the dropdown in the child component, so we can calculate the container size before it animates
    // You gotta put in a zero if you're just using it regular style here on the main dropdown
    // I'm sorry, this is awful
    const setDropdownHeight = (diff) => {
        setFilterHeight(searchHeaderSizer.current.clientHeight + diff);
    };

    const toggleFilter = () => {
        setDropdownHeight(0);
        setFilterOpen(!filterOpen);
    };

    function close(e) {
        if (
            !e.target.closest('#floorplan-search') &&
            !e.target.closest('.virtual-keyboard')
        ) {
            setKeyboardOpen(false);
        }
    }

    function svgByName(name) {
        switch (name) {
            case 'Bedrooms':
                return <BedroomsIcon />;
            case 'SQ.FT.':
                return <SquareFootIcon />;
            case 'Block':
            default:
                return <BlockIcon />;
        }
    }

    function runQuickSearch(value) {
        dispatch(updateFilterValue(searchID, value));
        //need to clear the other dropdowns now
        dispatch(clearNormalFilters());
        dispatch(filterFloorplans());
    }

    function clearSearchInput() {
        searchInput.current.value = '';
    }

    function onChange(input) {
        setInput(input);
        runQuickSearch(input);
    }

    function openKeyboard() {
        setKeyboardOpen(true);
    }

    function onInputChange(e) {
        const i = e.target.value;
        setInput(i);
        keyboard.current.setInput(i);
        runQuickSearch(i);
    }

    function navToCompare() {
        //clear the left and right side of the compare floorplans
        dispatch(clearCompareFloorplans());
        history.push('/floorplans/compare');
    }

    if (filters.length > 0 && products.length !== 0 && filterID !== -1) {
        for (var i = 0; i < filters.length; i++) {
            var filter = filters[i];

            if (filter.type !== 'product' && filter.type !== 'search') {
                dropFilters.push({
                    filterID: i,
                    label: filter.name,
                    values: filter.values,
                    open: filter.open,
                    index: filter.index
                });
            }
        }

        if (typeof filters[filterID] !== 'undefined')
            dropID = filters[filterID].values.indexOf(filters[filterID].value);

        for (var i = 0; i < products.length; i++) {
            var product = products[i];

            radioProducts.push({
                label: product.name,
                value: product.id
            });
        }
        return (
            <div className="search-header">
                <div
                    className={
                        filterOpen
                            ? 'search-header-dropdown-container mobile-dropdown-filter active-dropdown'
                            : 'search-header-dropdown-container mobile-dropdown-filter'
                    }
                >
                    <div className="button" onClick={toggleFilter}>
                        <div className="dropdown-left">Filter</div>
                        <DownArrowIcon />
                    </div>
                </div>
                <div
                    className="mobile-dropdown-container"
                    style={
                        !filterOpen
                            ? { height: '0' }
                            : { height: `${filterHeight}px` }
                    }
                    ref={mobileDropdownContainer}
                >
                    <div
                        className="search-header-sizer"
                        ref={searchHeaderSizer}
                    >
                        <div className="input-search-container">
                            {isTouchScreen && (
                                <input
                                    type="text"
                                    id="floorplan-search"
                                    placeholder="Search All Floorplans"
                                    onChange={onInputChange}
                                    value={input}
                                    ref={searchInput}
                                    onClick={openKeyboard}
                                />
                            )}
                            {isTouchScreen && (
                                <VirtualKeyboard
                                    keyboard={keyboard}
                                    onChange={onChange}
                                    open={keyboardOpen}
                                />
                            )}
                            {!isTouchScreen && (
                                <input
                                    type="text"
                                    id="floorplan-search"
                                    placeholder="Search All Floorplans"
                                    onChange={(e) =>
                                        runQuickSearch(e.target.value)
                                    }
                                    ref={searchInput}
                                />
                            )}
                            <div className="search-icon-container">
                                <SearchIcon />
                            </div>
                        </div>
                        <div className="search-header-radio-container">
                            <RadioFilter
                                buttons={radioProducts}
                                filterID={filterID}
                                index={filters[filterID].index}
                                clear={clearSearchInput}
                            />
                        </div>
                        {dropFilters.length > 0 &&
                            dropFilters.map((filter, key) => (
                                <DropdownFilter
                                    key={key}
                                    text={filter.label}
                                    options={filter.values}
                                    svg={svgByName(filter.label)}
                                    filterID={filter.filterID}
                                    open={filter.open}
                                    index={filter.index}
                                    clear={clearSearchInput}
                                    setDropdownHeight={setDropdownHeight}
                                    filterHeight={filterHeight}
                                />
                            ))}
                        <div className="search-header-compare-floorplans">
                            <MainButton
                                svg={<CompareFloorplansIcon />}
                                text="Compare Floorplans"
                                onclick={() => navToCompare}
                                style={{ padding: '0px 40px', height: '100%' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default SearchHeader;
