import React, { useRef } from 'react';
import './AccordionItem.scss';
import DownArrowIcon from '../DownArrowIcon/DownArrowIcon';

const AccordionItem = (props) => {
    const container = useRef();
    const handleAccordionClick = () => {
        if(props.active)
            props.update(-1);
        else
            props.update(props.id);
    };

    let containerHeight = 0;

    if(typeof container.current !== 'undefined')
    if(props.active) containerHeight = container.current.scrollHeight;

    const containerStyles = {
        height: containerHeight
    }

    return (
        <li className={`accordion-item${props.active ? ' active' : ''}`}>
            <div className="item-container" onClick={handleAccordionClick}>
                {props.item.label}
                <DownArrowIcon />
            </div>
            <div className="accordion-drop-down-container" style={containerStyles} ref={container}>
                <ul className="accordion-drop-down">
                    {props.item.locations.map((label, key) => 
                        <li className="accordion-dropdown-item" key={key}>
                            <span className="number" style={{backgroundColor: props.colour}}>{label.number}</span>
                            {label.label}
                        </li>
                    )}
                </ul>
            </div>
        </li>
    );
};

export default AccordionItem;
