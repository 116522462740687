import React from 'react';
import './FeaturesAndFinishesWarranty.scss';
import FeaturesAndFinishesListItem from '../FeaturesAndFinishesListItem/FeaturesAndFinishesListItem';

const FeaturesAndFinishesWarranty = (props) => {
    return (
        <div className="features-and-finishes-warranty">
            <div className="warranty-column">
                <div className="warranty-subheader">
                    {props.content.heading}
                </div>
                <div className="warranty-section-header">
                    {props.content['2_year'].label}
                </div>
                {props.content['2_year'].protections.map((prot, key) => 
                    <FeaturesAndFinishesListItem
                        key={key}
                        text={prot.text}
                        number={key+1}
                    />
                )}
            </div>
            <div className="warranty-column">
                <div className="warranty-section-header">
                    {props.content['7_year'].label}
                </div>
                {props.content['7_year'].protections.map((prot, key) =>
                    <FeaturesAndFinishesListItem
                        key={key}
                        text={prot.text}
                        number={key+1}
                    />
                )}
                <div className="sublist">
                    {props.content.highlighted.map((high, key) => 
                        <FeaturesAndFinishesListItem
                            key={key}
                            text={high.text}
                            number={key+1}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FeaturesAndFinishesWarranty;
