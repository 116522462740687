import React from 'react';
import './EmailIcon.scss';

function EmailIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="14"
            viewBox="0 0 20 14"
            className="email-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#9B3465"
                    fillRule="nonzero"
                    transform="translate(-104 -509)"
                >
                    <g transform="translate(-1)">
                        <g transform="translate(0 80)">
                            <g transform="translate(1)">
                                <g transform="translate(0 104)">
                                    <g transform="translate(79 240)">
                                        <g transform="translate(0 64)">
                                            <g transform="translate(25 19)">
                                                <g transform="translate(0 2)">
                                                    <path d="M20 0v14H0V0h20zM7.906 7.529l-6.153 5.47h16.493L12.24 7.66l-2.092 1.861L7.906 7.53zM19 1.652l-6.007 5.339L19 12.331V1.652zM1 1.39v10.94l6.154-5.47L1 1.39zM18.229.999H2.065l8.083 7.183L18.229 1z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default EmailIcon;
