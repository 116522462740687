import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DownloadIcon from '../DownloadIcon/DownloadIcon';
import MainButton from '../MainButton/MainButton';
import './FeaturesAndFinishesList.scss';
import FeaturesAndFinishesListButton from '../FeaturesAndFinishesListButton/FeaturesAndFinishesListButton';
import FeaturesAndFinishesListItem from '../FeaturesAndFinishesListItem/FeaturesAndFinishesListItem';
import FeaturesAndFinishesWarranty from '../FeaturesAndFinishesWarranty/FeaturesAndFinishesWarranty';

function FeaturesAndFinishesList(props) {
    const [index, setIndex] = useState(0);
    let isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);
    const mobileSelect = useRef();

    function is_active(i) {
        return index === i;
    }

    function updateIndex(i) {
        setIndex(i);
    }

    useEffect(() => {
        mobileSelect.current.selectedIndex = index;

        mobileSelect.current.addEventListener('change', function () {
            //if its the last one we need to set the index to -1 ez

            if (
                mobileSelect.current.length ===
                mobileSelect.current.selectedIndex + 1
            )
                updateIndex(-1);
            else updateIndex(mobileSelect.current.selectedIndex);
        });
    });

    return (
        <div className="features-and-finishes-list">
            <ul className="list-buttons">
                {props.content.features.map((feature, key) => (
                    <FeaturesAndFinishesListButton
                        key={key}
                        text={feature.label}
                        active={is_active(key)}
                        callback={() => updateIndex(key)}
                    />
                ))}
                <FeaturesAndFinishesListButton
                    text={props.content.warranty.label}
                    callback={() => updateIndex(-1)}
                    active={is_active(-1)}
                />
            </ul>
            <select className="mobile-list-select" ref={mobileSelect}>
                {props.content.features.map((feature, key) => (
                    <option key={key}>{feature.label}</option>
                ))}

                <option>{props.content.warranty.label}</option>
            </select>
            <div className="list-items">
                <div className="list-items-header">
                    <h2 className="list-items-title">
                        {index > -1 && props.content.features[index].label}
                        {index === -1 && props.content.warranty.label}
                    </h2>
                    {!isTouchScreen && (
                        <MainButton
                            ghost={true}
                            text="Download Features & Finishes"
                            link={props.download.file}
                            text={props.download.label}
                            download
                            blank
                            svg={<DownloadIcon />}
                            style={{
                                border: 'none',
                                padding: '16px 40px'
                            }}
                        />
                    )}
                </div>
                <ul className="list">
                    {index > -1 &&
                        props.content.features[index].items.map((item, key) => (
                            <FeaturesAndFinishesListItem
                                key={key}
                                text={item.text}
                                number={key + 1}
                            />
                        ))}
                    {index === -1 && (
                        <FeaturesAndFinishesWarranty
                            content={props.content.warranty}
                        />
                    )}
                </ul>
            </div>
        </div>
    );
}

export default FeaturesAndFinishesList;
