import React from 'react';

function SearchIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="25"
            viewBox="0 0 26 25"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g stroke="#FFF" transform="translate(-15 -15)">
                    <path d="M25.6 35.2a9.6 9.6 0 100-19.2 9.6 9.6 0 000 19.2zm7.2-2.4L40 40"></path>
                </g>
            </g>
        </svg>
    );
}

export default SearchIcon;
