import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './SitePlan.scss';
import ScrollBooster from 'scrollbooster';
import ZoomIcon from '../ZoomIcon/ZoomIcon';
import ZoomOutIcon from '../ZoomOutIcon/ZoomOutIcon';
import SitePlanSvg from '../SitePlanSvg/SitePlanSvg';
import MainButton from '../MainButton/MainButton';
import DownloadIcon from '../DownloadIcon/DownloadIcon';
import SiteplanPanel from '../SiteplanPanel/SiteplanPanel';
import { updateUnitOutline } from '../../redux/actions/siteplanActions';
import Cover from '../Cover/Cover';
import MobileFooter from '../MobileFooter/MobileFooter';

class SitePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUnit: -1,
            panelOpen: false,
            legendSelected: -1
        };
    }

    componentDidMount() {
        //check this
        document.querySelector('#root').scrollTo(0, 0);
        if (this.props.history.location.state === null) {
            this.props.updateUnitOutline('all');
        }

        this.viewport = document.querySelector('.site-plan-viewport');
        this.imageContainer = document.querySelector(
            '.site-plan-image-container'
        );
        this.image = document.querySelector('.site-plan-jpg');
        // this.imageLarge = document.querySelector('.site-plan-jpg-large');
        this.content = document.querySelector('.translate-container');
        let viewportWidth = this.viewport.offsetWidth;
        let viewportHeight = this.viewport.offsetHeight;
        let viewportAspectRatio = viewportHeight / viewportWidth;

        this.sb = new ScrollBooster({
            viewport: this.viewport,
            content: this.content,
            direction: 'all',
            friction: 0.05,
            scrollMode: 'transform',
            emulateScroll: true
        });

        const setPositionAndStyling = () => {
            viewportWidth = this.viewport.offsetWidth;
            viewportHeight = this.viewport.offsetHeight;
            viewportAspectRatio = viewportHeight / viewportWidth;
            const imageWidth = this.image.offsetWidth;
            const imageHeight = this.image.offsetHeight;
            const imageAspectRatio = imageHeight / imageWidth;
            if (imageAspectRatio < viewportAspectRatio) {
                if (window.innerWidth > 1300) {
                    this.imageContainer.style.height = 'calc((100vh - 80px)';
                    this.imageContainer.style.width = `calc((100vh - 80px) / ${imageAspectRatio})`;
                } else {
                    this.imageContainer.style.height = 'calc((100vh - 56px)';
                    this.imageContainer.style.width = `calc((100vh - 56px) / ${imageAspectRatio})`;
                }
            } else {
                this.imageContainer.style.height = `calc((100vw - 175px) * ${imageAspectRatio})`;
                this.imageContainer.style.width = 'calc((100vw - 175px)';
            }
            this.offsetX = this.image.offsetWidth - this.viewport.offsetWidth;
            this.offsetY = this.image.offsetHeight - this.viewport.offsetHeight;
            this.sb.setPosition({
                x: this.offsetX / 2,
                y: this.offsetY / 2
            });
        };

        this.image.addEventListener('load', () => {
            setPositionAndStyling();
        });

        window.addEventListener('resize', setPositionAndStyling);

        const zoomOnWheel = (e) => {
            if (e.metaKey) {
                this.sb.updateOptions({
                    emulateScroll: false
                });
                if (e.deltaY > 0) {
                    this.zoom(1.1);
                } else if (e.deltaY < 0) {
                    this.zoom(1 / 1.1);
                }
            } else {
                this.sb.updateOptions({
                    emulateScroll: true
                });
            }
        };

        window.addEventListener('wheel', zoomOnWheel);
    }

    zoom = (zoomLevel) => {
        if (
            // check if image is between viewport size and min size for retina
            this.image.offsetWidth < this.image.naturalWidth / zoomLevel &&
            this.image.offsetWidth > this.viewport.offsetWidth / zoomLevel &&
            this.image.offsetHeight < this.image.naturalHeight / zoomLevel &&
            this.image.offsetHeight > this.viewport.offsetHeight / zoomLevel
        ) {
            this.initialHeight = this.imageContainer.offsetHeight;
            this.imageContainer.style.height =
                this.imageContainer.offsetHeight * zoomLevel + 'px';
            this.newHeight = this.imageContainer.offsetHeight;
            this.zoomVerticalOffset = (this.initialHeight - this.newHeight) / 2;
            this.initialWidth = this.imageContainer.offsetWidth;
            this.imageContainer.style.width =
                this.imageContainer.offsetWidth * zoomLevel + 'px';
            this.newWidth = this.imageContainer.offsetWidth;
            this.zoomHorizontalOffset = (this.initialWidth - this.newWidth) / 2;
            // check if new values are within viewport bounds and reset if necessary
            if (-this.sb.position.x - this.zoomHorizontalOffset <= 0) {
                this.xOffset = 0;
            } else if (
                -this.sb.position.x - this.zoomHorizontalOffset >=
                this.imageContainer.offsetWidth - this.viewport.offsetWidth
            ) {
                this.xOffset =
                    this.imageContainer.offsetWidth - this.viewport.offsetWidth;
            } else {
                this.xOffset = -this.sb.position.x - this.zoomHorizontalOffset;
            }
            if (-this.sb.position.y - this.zoomVerticalOffset <= 0) {
                this.yOffset = 0;
            } else if (
                -this.sb.position.y - this.zoomVerticalOffset >=
                this.imageContainer.offsetHeight - this.viewport.offsetHeight
            ) {
                this.yOffset =
                    this.imageContainer.offsetHeight -
                    this.viewport.offsetHeight;
            } else {
                this.yOffset = -this.sb.position.y - this.zoomVerticalOffset;
            }
            // set new position
            this.sb.setPosition({
                x: this.xOffset,
                y: this.yOffset
            });
            this.sb.updateMetrics();
        }
    };

    openPanel() {
        this.setState({
            panelOpen: true
        });
    }

    closePanel() {
        this.setState({
            panelOpen: false
        });
    }

    highLightUnits(ID) {
        //update the units in the redux store here
        this.props.updateUnitOutline(ID);
    }

    toggleLegendHighlight(ID) {
        if (this.state.legendSelected === ID) {
            this.setState({
                legendSelected: -1
            });
        } else {
            this.setState({
                legendSelected: ID
            });
        }
    }

    floorplanByID(ID) {
        for (var i = 0; i < this.props.floorplans.length; i++) {
            if (this.props.floorplans[i].ID === ID)
                return this.props.floorplans[i];
        }
    }

    floorplanHasUnits(ID) {
        var units = false;
        var floorplan = this.floorplanByID(ID);

        for (var j = 0; j < floorplan.units.length; j++) {
            if (
                floorplan.units[j].avaliblity &&
                floorplan.units[j].block.active
            )
                units = true;
        }

        return units;
    }

    render() {
        const legend = [];

        if (this.props.floorplans.length > 0) {

            this.props.products.map((prod) => {
                const p = {
                    label: prod.name,
                    term: prod.id,
                    plans: []
                };

                this.props.floorplans.map((plan) => {
                    if (p.term === plan.product.term_id) {
                        const obj = {
                            colour: plan.floorplan_colour,
                            label: plan.post_title,
                            floorplanID: plan.ID,
                            highlighted: plan.ID === this.state.legendSelected
                        };

                        if (this.floorplanHasUnits(plan.ID)) p.plans.push(obj);
                    }
                });

                legend.push(p);
            });
        }

        let downloadURL = "";
        if(typeof this.props.download !== 'undefined') {
            downloadURL = this.props.download.downloadable.url;
        }

        return (
            <div className="site-plan">
                <Cover />
                <SiteplanPanel
                    open={this.state.panelOpen}
                    floorplan={this.props.floorplan}
                    callback={() => this.closePanel()}
                />
                <div className="responsive-container">
                    <div className="side-bar">
                        {!this.props.isTouchScreen && (
                            <MainButton
                                text="Download Site Plan"
                                svg={<DownloadIcon />}
                                link={downloadURL}
                                external
                                blank
                                style={{
                                    padding: '24px 44px',
                                    flexDirection: 'column'
                                }}
                            />
                        )}
                        <div className="legend">
                            {legend.map((prod, key) => (
                                <React.Fragment key={key}>
                                    <h2>{prod.label}</h2>
                                    <ul>
                                        {prod.plans.map((plan, key) => (
                                            <li
                                                key={key}
                                                onClick={() => {
                                                    this.toggleLegendHighlight(
                                                        plan.floorplanID
                                                    );
                                                    this.highLightUnits(
                                                        plan.floorplanID
                                                    );
                                                }}
                                                style={{
                                                    fontFamily: plan.highlighted
                                                        ? '"Avenir-Heavy", sans-serif'
                                                        : ''
                                                }}
                                            >
                                                <span
                                                    className="color-box"
                                                    style={{
                                                        backgroundColor:
                                                            plan.colour
                                                    }}
                                                />
                                                {plan.label}
                                            </li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className="site-plan-viewport">
                        <div className="translate-container">
                            <div className="site-plan-image-container">
                                <img
                                    src="/img-temp/archetto-site-plan-update.jpg"
                                    alt="Site Plan Background"
                                    className="site-plan-jpg"
                                />
                                <SitePlanSvg
                                    open={() => this.openPanel()}
                                    panelOpen={this.state.panelOpen}
                                    floorplan={this.props.floorplan}
                                />
                            </div>
                        </div>
                        <button
                            className="zoom-container zoom-in-container"
                            onClick={() => this.zoom(1.1)}
                        >
                            <ZoomIcon />
                        </button>
                        <button
                            className="zoom-container"
                            onClick={() => this.zoom(1 / 1.1)}
                        >
                            <ZoomOutIcon />
                        </button>
                    </div>
                    <div className="mobile-site-plan-image-container">
                        <img
                            src="/img-temp/archetto-site-plan.jpg"
                            alt="Site Plan Background"
                            className="site-plan-jpg"
                        />
                        <SitePlanSvg
                            open={() => this.openPanel()}
                            panelOpen={this.state.panelOpen}
                            floorplan={this.props.floorplan}
                        />
                    </div>
                </div>
                <MobileFooter showBelow={1301} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        floorplan: state.floorplan.activeFloorplan,
        floorplans: state.floorplan.floorplans,
        products: state.floorplan.products,
        isTouchScreen: state.utilities.isTouchScreen,
        download: state.content.pages.siteplan
    };
};

const mapDispatchToProps = {
    updateUnitOutline
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SitePlan));
