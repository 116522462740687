import React from 'react';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <div className="pp-container">
                <h2>Privacy Policy</h2>
                <p>
                    Marlin Spring respects your right to privacy and security.
                    Any personal information that you disclose during your visit
                    to our website will be treated as confidential.
                </p>
                <p>
                    Marlin Spring does not sell or release any of your personal
                    information. Your name, address, and IP (Internet Protocol)
                    address will not be added to any mailing lists, forwarded to
                    any third party vendors, or used in conjunction with any
                    website other than our own without your consent.
                </p>
                <p>
                    You may be contacted by one of our Sales Representatives
                    based on your request for information.
                </p>
                <p>
                    All of the images depicted on this website are actual Marlin
                    Spring homes and/or artist’s concept only. Please contact a
                    Marlin Spring representative for more information.
                </p>
                <p>
                    What does Marlin Spring use your personal information for?
                    To offer you a more personal online experience and superior
                    customer service;
                </p>
                <ul>
                    <li>
                        To offer you a more personal online experience and
                        superior customer service;
                    </li>
                    <li>
                        To respond to any inquiries that you may have about our
                        properties or communities, and to provide you with any
                        additional information that you request;
                    </li>
                    <li>
                        To inform you about any promotions, new offers or any
                        other developments that are being marketed;
                    </li>
                    <li>
                        To learn more about you, as a purchaser, so that we can
                        better anticipate and satisfy your needs;
                    </li>
                    <li>
                        To obtain information that will help us determine how
                        visitors use our website, and ultimately guide us in
                        making future enhancements and developments to the site.
                    </li>
                </ul>
                <h2>Terms of Use</h2>
                <h3>Disclaimer:</h3>
                <p>
                    Marlin Spring has provided this site as a starting point for
                    purchasers, so they can understand what we offer, including
                    but not limited to promotions, neighbourhood information,
                    floor plans, features, additional options, and appointment
                    booking. We strive to offer the same service excellence
                    online that we provide in person.
                </p>
                <p>
                    However, the Internet is not a fully secure medium, nor is
                    it always possible for information to be immediately updated
                    and accurate. Therefore, Marlin Spring is not responsible
                    for any inaccurate information contained on this site. In
                    the event that there is conflicting information such as
                    promotion effective dates, offers and inclusions, the
                    written information provided by our Sales Representative on
                    marketing materials including price sheets, bonuses etc.
                    will be deemed accurate and non-debatable. Oral
                    representations are not to be deemed accurate.
                </p>
                <h3>Limitation of Liability:</h3>
                <p>
                    Under no circumstances shall Marlin Spring be liable for any
                    direct, indirect, punitive, incidental, special, or
                    consequential damages that result from the use of, or
                    inability to use, this site. The limitation applies whether
                    the alleged liability is based on contract, tort,
                    negligence, strict liability, or any other basis, even if
                    Marlin Spring has been advised of the possibility of such
                    damage.
                </p>
                <p>
                    Marlin Spring has created this privacy policy to demonstrate
                    our integrity and firm commitment to you. If you have any
                    questions or concerns about any of the above, please contact
                    us.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
