import React from 'react';
import {
    updateActiveCompareDisplayedFloorplan,
    updateActivePlan
} from '../../redux/actions/floorplanActions';
import { useDispatch } from 'react-redux';
import './FloorplanDetailsFloorplanPanel.scss';
import TabbedLayout from '../TabbedLayout/TabbedLayout';
import ZoomIcon from '../ZoomIcon/ZoomIcon';

function FloorplanDetailsFloorplanPanel(props) {
    const dispatch = useDispatch();
    const planTitles = [];
    const planContent = [];

    function compareupdatePlan(key) {
        dispatch(updateActiveCompareDisplayedFloorplan(props.side, key));
    }

    function updatePlan(key) {
        //i need to update teh siteplan displayed floorplan
        //in a way that will have ti reflect on the floorplan
        //details page
        dispatch(updateActivePlan(key));
    }

    let onclick = updatePlan;
    if(props.compare) onclick = compareupdatePlan;

    if (typeof props.floorplan.elevation !== 'undefined') {
        var eKeys = Object.keys(props.floorplan.elevation);

        props.floorplan.elevation[eKeys[props.floorplan.ele]].floorplans.map(
            (plan, key) => {
                planTitles.push({
                    label: plan.label,
                    active: key === 0 ? true : false
                });

                planContent.push(
                    <div className="grid-floorplan-image-container">
                        <img
                            src={plan.content.main.image}
                            className="grid-floorplan-image"
                            alt="Floorplan"
                        />
                    </div>
                );
            }
        );
    }
    return (
        <div
            className={`floorplan-details-floorplan-panel${
                props.compare ? ' floorplan-panel-compare' : ''
            }${props.sitePlan ? ' floorplan-panel-site-plan' : ''}`}
        >
            <div className="floorplan-details-floorplans">
                <TabbedLayout
                    titles={planTitles}
                    content={planContent}
                    index={props.floorplan.disp}
                    colCont
                    callback={onclick}
                    
                />
            </div>
            {!props.sitePlan && (
                <button className="zoom-container" onClick={props.callback}>
                    <ZoomIcon />
                </button>
            )}
        </div>
    );
}

export default FloorplanDetailsFloorplanPanel;
