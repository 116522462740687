import React, { useEffect } from 'react';
import './GridFloorplan.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FloorplanDetailsStats from '../FloorplanDetailsStats/FloorplanDetailsStats';
import {
    updateActiveFloorplan,
    updateDisplayedFloorplan
} from '../../redux/actions/floorplanActions';
import TabbedLayout from '../TabbedLayout/TabbedLayout';

function GridFloorplan(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        let grid = document.querySelector('.floorplans-grid');
        grid.addEventListener('mousedown', mousedown);
        grid.addEventListener('mouseup', mouseup);
    });

    let horizontalDown = 0;
    let verticalDown = 0;
    let horizontalUp = 0;
    let verticalUp = 0;

    function mousedown(event) {
        horizontalDown = event.clientX;
        verticalDown = event.clientY;
    }

    function mouseup(event) {
        horizontalUp = event.clientX;
        verticalUp = event.clientY;
    }

    function floorplanClicked() {
        if (
            Math.abs(horizontalDown - horizontalUp) < 5 &&
            Math.abs(verticalDown - verticalUp) < 5
        ) {
            dispatch(updateActiveFloorplan(props.id));
            history.push('/floorplans/details');
        }
    }

    function gridClicked(plan) {
        if (
            Math.abs(horizontalDown - horizontalUp) < 5 &&
            Math.abs(verticalDown - verticalUp) < 5
        ) {
            dispatch(updateDisplayedFloorplan(props.id, plan));
        }
    }

    const callback = () => floorplanClicked();
    const tabbedTitles = [];
    const tabbedContent = [];
    const eKeys = Object.keys(props.floorplan.elevation);

    if (typeof props.floorplan.elevation !== 'undefined') {
        props.floorplan.elevation[eKeys[props.floorplan.ele]].floorplans.map(
            (plan, key) => {
                tabbedTitles.push({
                    label: plan.label,
                    active: key === 0 ? true : false
                });

                tabbedContent.push(
                    <div className="grid-floorplan-image-container">
                        <img
                            src={plan.content.main.image}
                            className="grid-floorplan-image"
                            alt="Floorplan"
                        />
                    </div>
                );
            }
        );
    }

    return (
        <div onClick={callback} className="grid-floorplan">
            <FloorplanDetailsStats
                name={props.floorplan.post_title}
                description={props.floorplan.description}
                productName={props.floorplan.product.name}
                squarefeet={
                    props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                        .square_feet
                }
                bedrooms={
                    props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                        .bedrooms
                }
                bathrooms={
                    props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                        .bathrooms
                }
                half_bathrooms={
                    props.floorplan.elevation[eKeys[props.floorplan.ele]].info
                        .half_bathrooms
                }
            />
            <TabbedLayout
                titles={tabbedTitles}
                content={tabbedContent}
                callback={gridClicked}
                index={props.floorplan.disp}
                colCont
                grid
            />
            {/*
                {props.floorplan.elevation[eKeys[props.floorplan.ele]]
                    .starting_from && (
                    <div className="grid-floorplan-caption">
                        {
                            props.floorplan.elevation[eKeys[props.floorplan.ele]]
                                .starting_from
                        }
                    </div>
                )}
            */}
        </div>
    );
}

export default GridFloorplan;
