import React from 'react';

function BackIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="13"
            viewBox="0 0 33 13"
            className="back-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-135 -863)">
                    <g transform="translate(-1)">
                        <g transform="translate(0 80)">
                            <g transform="translate(1)">
                                <g transform="translate(0 104)">
                                    <g transform="translate(0 676)">
                                        <g transform="translate(136)">
                                            <g transform="translate(0 4)">
                                                <path
                                                    stroke="#7A7367"
                                                    d="M2.71001444e-10 11L5 5.5 2.73133072e-10 -2.20934382e-14"
                                                    transform="rotate(-180 2.5 5.5)"
                                                    className="arrow"
                                                ></path>
                                                <path
                                                    fill="#7A7367"
                                                    d="M0 5H32V6H0z"
                                                    className="line"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default BackIcon;
