import React, { useState } from 'react';
import './Amenities.scss';
import Accordion from '../Accordion/Accordion';

const Amenities = (props) => {
    const [selected, setSelected] = useState(-1);

    let mapImage = props.blank;
    if (selected !== -1) mapImage = props.map[selected].image;

    return (
        <div className="amenities" id="amenities">
            <Accordion
                items={props.map}
                update={setSelected}
                active={selected}
            />
            <div className="map-container">
                <img src={mapImage} alt="Amenities map" />
                {/* <div className="overlay"></div> */}
            </div>
        </div>
    );
};

export default Amenities;
