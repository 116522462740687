import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import "./SurveyPopUp.scss";

function SurveyPopup() {
    const shouldShow = false;
    const [show, setShow] = useState(false);
    const filters = useSelector((state) => state.floorplan.filters);
    const isTouchScreen = useSelector((state) => state.utilities.isTouchScreen);
    const popup = useSelector((state) => state.content.options.popup);
    
    function click() {
        setShow(false);
    }

    function renderPopupRepeaterItem(item) {
        const styles = {
            backgroundColor: `#${item.colour}`
        };

        if(item.is_it_a_link) {
            return (
                <a className="detail-box" href={item.link} target="_blank" style={styles}>
                    <img
                        className="icon"
                        src={item.icon.url}
                        alt={item.icon.alt}
                    />
                    <div dangerouslySetInnerHTML={{__html: item.inner_area}}/>
                </a>
            );
        }
        return(
            <div className="detail-box" style={styles}>
                <img
                    className="icon"
                    src={item.icon.url}
                    alt={item.icon.alt}
                />
                <div dangerouslySetInnerHTML={{__html: item.inner_area}}/>
            </div>
        );
    }

    useEffect(() => {
        setTimeout(()=> {
            setShow(shouldShow);
        }, 2000);
    }, []);

    if(show && filters.length > 0 && !isTouchScreen && popup && popup.is_msd_html_popup_active) {
        return (
            <div className="survey-popup" onClick={click}>
                <div className="popup-interior">
                    <span id="Pclose" className="close-button" onClick={click}>
                        <img src="/img-temp/close-btn.svg"></img>
                    </span>
                    <div className="MSD-HTML-Interior">
                        <img
                            className="desktop-only"
                            src={popup.html_popup_top_image_section_desktop.url}
                            alt={popup.html_popup_top_image_section_desktop.alt}
                        />
                        <img
                            className="mobile-only"
                            src={popup.html_popup_top_image_section_mobile.url}
                            alt={popup.html_popup_top_image_section_mobile.alt}
                        />
                        <p className="sub-header">
                            {popup.html_popup_top_subheader_section}
                        </p>
                        <div className="grid-section">
                            <div className="left-top top">
                                <img
                                    className="logo"
                                    src={popup.html_popup_middle_left_image_logo.url}
                                    alt={popup.html_popup_middle_left_image_logo.alt}
                                />
                                <div dangerouslySetInnerHTML={{__html: popup.html_popup_middle_left_text}} />
                            </div>
                            <div className="right-top top">
                                <img
                                    className="logo"
                                    src={popup.html_popup_middle_right_image_logo.url}
                                    alt={popup.html_popup_middle_right_image_logo.alt}
                                />
                                <div dangerouslySetInnerHTML={{__html: popup.html_popup_middle_right_text}} />
                            </div>
                            <div className="bottom">
                                <div className="detail-wrapper">
                                    {popup.html_popup_bottom_repeater.map((item, key) => (
                                        renderPopupRepeaterItem(item)
                                    ))}
                                </div>
                                <a className="button" href={popup.html_popup_bottom_button_link}>
                                    {popup.html_popup_bottom_button_text}
                                </a>    
                            </div>
                        </div>
                    </div>
                    <p className="disclaimer" dangerouslySetInnerHTML={{__html: popup.html_popup_bottom_terms}}/>
                </div>
            </div>
        );
    }
    else {
        return (
            <div></div>
        );
    }
}

export default SurveyPopup;