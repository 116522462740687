import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './CompareFloorplansListContainer.scss';
import FloorplansTable from '../FloorplansTable/FloorplansTable';
import CompareFloorplanListContainerButton from '../CompareFloorplanListContainerButton/CompareFloorplanListContainerButton';
import { updateFilterCompareValue, filterCompareFloorplans } from '../../redux/actions/floorplanActions';

function CompareFloorplansListContainer(props) {
    const product = useSelector(state => state.floorplan.compareFilters[props.side]);
    //const compareplans = useSelector(state => state.floorplan.compareFloorplans[props.side]);
    const filterID = useSelector(state => state.floorplan.productFilterID);
    const productFilters = useSelector(state => state.floorplan.filters[filterID]);
    const [active, setActive] = useState(
        termToIndex(product) !== -1 ? termToIndex(product) : 0
    );
    const dispatch = useDispatch();
    
    function changeProduct(term) {
        dispatch(updateFilterCompareValue(props.side, term));
        dispatch(filterCompareFloorplans(props.side));
        setActive(termToIndex(term));
    }

    function termToIndex(term) {
        if(typeof productFilters !== 'undefined')
            return productFilters.values.indexOf(term);
        else
            return -1;
    }

    let styles = {
        display: props.visible ? "block" : "none"
    };

    return (
        <div className="compare-floorplans-list-container" style={styles}>
            <div className="compare-floorplans-list-header-container">
                <div className="compare-floorplans-list-header">
                    Select Floorplan
                </div>
                {productFilters && 
                    <React.Fragment>
                        <CompareFloorplanListContainerButton
                            text="Front Loaded"
                            term={productFilters.values[0]}
                            active={active === 0}
                            callback={changeProduct}
                        />
                        <CompareFloorplanListContainerButton
                            text="Rear Lane"
                            term={productFilters.values[1]}
                            active={active === 1}
                            callback={changeProduct}
                        />
                    </React.Fragment>
                }
            </div>
            <div className="top-bar-bg"></div>
            <FloorplansTable
                compare={true}
                side={props.side}
                update={props.update}
                compareplans={props.compareplans}
            />
        </div>
    );
}

export default CompareFloorplansListContainer;
