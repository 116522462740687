import React from 'react';

function ListIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            className="list-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#032"
                    fillRule="nonzero"
                    transform="translate(-1718 -159)"
                >
                    <g transform="translate(-24)">
                        <g transform="translate(24 144)">
                            <g transform="translate(1718)">
                                <g transform="translate(0 15)">
                                    <path d="M5 14v5H0v-5h5zm-1 1H1v3h3v-3zm16 1v1H8v-1h12zM5 7v5H0V7h5zM4 8H1v3h3V8zm16 1v1H8V9h12zM5 0v5H0V0h5zM4 1H1v3h3V1zm16 1v1H8V2h12z"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ListIcon;
