import axios from 'axios';

let baseUrl = 'https://data.archettotowns.com/wp-json/52archettoapi/v1/';

if(window.location.host === "archetto-app.52beta.ca" || window.location.host === "localhost:3000") {
    baseUrl = 'https://archetto-api.52beta.ca/wp-json/52archettoapi/v1/';
}

function get(url, data = {}) {
    return axios({
        headers: {
            'Content-type': 'application/json'
        },
        method: 'get',
        url: baseUrl+url,
        params: data
    });
}

function post(url, data = {}) {
    return axios({
        headers: {
            'Content-type': 'application/json'
        },
        method: 'post',
        url: baseUrl+url,
        data: data
    });
}

function getContent() {
    return get('get-all-content');
}

function getPosts(type) {
    return get('getposts', { "type": type });
}

function getFloorplans() {
    return get('getfloorplans');
}

function submitForm(data) {
    return post('submit-form', data);
}

function sendFloorplanEmail(email, floorplans) {
    var data = {
        floorplans: floorplans,
        email: email
    }
    return post('emailfloorplans', data);
}

export default {
    get,
    post,
    getPosts,
    submitForm,
    getFloorplans,
    getContent,
    sendFloorplanEmail
};