import React from 'react';

function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            className="close-icon"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#FFF"
                    transform="translate(-1823 -13)"
                    className="close-fill"
                >
                    <g transform="translate(0 -1)">
                        <g transform="translate(1805)">
                            <g transform="rotate(45 -2.228 43.556)">
                                <path d="M8 0H9V16H8z"></path>
                                <path
                                    d="M8 0H9V16H8z"
                                    transform="rotate(90 8.5 8)"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CloseIcon;
