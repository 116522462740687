import React from 'react';

function DownArrowIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="5"
            viewBox="0 0 10 5"
            className="down-arrow"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    stroke="#FFF"
                    transform="translate(-1157 -109)"
                    className="down-arrow-path"
                >
                    <g transform="translate(-24)">
                        <g transform="translate(24 80)">
                            <g transform="translate(960)">
                                <g transform="translate(47 23)">
                                    <path
                                        d="M153 13L157 8.5 153 4"
                                        transform="rotate(90 155 8.5)"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default DownArrowIcon;
