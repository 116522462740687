import React from 'react';
import './FeaturesAndFinishesListItem.scss';

function FeaturesAndFinishesListItem(props) {
    return (
        <li className="list-item">
            <div className="number">{props.number}</div>
            <div dangerouslySetInnerHTML={{__html: props.text}} />
        </li>
    );
}

export default FeaturesAndFinishesListItem;
