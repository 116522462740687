import React from 'react';
import './Product.scss';
import MainButton from '../MainButton/MainButton';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    filterFloorplans,
    filterCompareFloorplans,
    updateFilterValue,
    updateFilterActive
} from '../../redux/actions/floorplanActions';

function Product(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    function productSelected() {
        //a product type has been selected we just need to update the product filter which
        //i am not figurign out how it will work

        dispatch(updateFilterActive(props.filterid, true));
        dispatch(updateFilterValue(props.filterid, props.productid, props.productid));
        dispatch(filterFloorplans());
        dispatch(filterCompareFloorplans('left'));
        dispatch(filterCompareFloorplans('right'));

        history.push('/floorplans/list');
    }

    return (
        <div className="product">
            <div className="product-image">
                <img src={props.rendering} alt="" />
            </div>
            <div className="product-info">
                <div className="product-info-left">
                    <h2>{props.title}</h2>
                    <p>{props.copy}</p>
                    <MainButton
                        text="View Floorplans"
                        onclick={() => productSelected}
                        style={{ marginTop: '16px' }}
                    />
                </div>
                <div className="product-info-right">
                    <img src={props.flowers} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Product;
