import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './MobileFooter.scss';
import LinkedInIcon from '../LinkedInIcon/LinkedInIcon';
import InstagramIcon from '../InstagramIcon/InstagramIcon';
import FacebookIcon from '../FacbookIcon/FacebookIcon';
import TwitterIcon from '../TwitterIcon/TwitterIcon';

const MobileFooter = (props) => {
    const content = useSelector((state) => state.content.pages.contact);
    
    const [mobileFooterStatus, setMobileFooterStatus] = useState(
        window.innerWidth < props.showBelow
    );

    const showMobileFooter = () => {
        setMobileFooterStatus(window.innerWidth < props.showBelow);
    };

    useEffect(() => {
        window.addEventListener('resize', showMobileFooter);

        return () => {
            window.removeEventListener('resize', showMobileFooter);
        };
    });

    if(typeof content !== 'undefined') {
        return (
            <div
                className={
                    `mobile-footer` +
                    `${mobileFooterStatus ? ' mobile-footer-show' : ''}`
                }
            >
                <div className="mobile-footer-container">
                    <div className="corporate-logos">
                        <a href="https://marlinspring.com/" target="_blank">
                            <img
                                src={content.form_contact_info.marlin_logo.url}
                                alt={content.form_contact_info.marlin_logo.alt}
                            />
                        </a>
                        <span className="separator"></span>
                        <a href="https://realty.greybrook.com/" target="_blank">
                            <img
                                src={content.form_contact_info.greybrook_logo.url}
                                alt={content.form_contact_info.greybrook_logo.alt}
                            />
                        </a>
                    </div>
                    <ul className="contact-social">
                        <li>
                            <a
                                href="https://www.linkedin.com/company/marlinspringinvestments/"
                                target="_blank"
                            >
                                <LinkedInIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/marlinspring/"
                                target="_blank"
                            >
                                <InstagramIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.facebook.com/MarlinSpring"
                                target="_blank"
                            >
                                <FacebookIcon />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/marlinspring"
                                target="_blank"
                            >
                                <TwitterIcon />
                            </a>
                        </li>
                    </ul>
                    <div className="mobile-copyright">
                        &copy;2020 Marlin Spring. All Rights Reserved.
                    </div>
                    <div className="mobile-footer-links">
                        <span>
                            <a href="/privacy-policy" target="_blank">
                                Privacy Policy
                            </a>
                        </span>
                        <span>
                            By{' '}
                            <a href="https://www.52pick-up.com/" target="_blank">
                                52 Pick-up Inc.
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default MobileFooter;
