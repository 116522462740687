import React from 'react';

function TubIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            viewBox="0 0 22 21"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#032"
                    fillRule="nonzero"
                    transform="translate(-372 -244)"
                >
                    <g transform="translate(1)">
                        <g transform="translate(0 104)">
                            <g transform="translate(0 72)">
                                <g transform="translate(49 23)">
                                    <g transform="translate(304 2)">
                                        <path d="M35.996 43.421c.803.2 1.427.89 1.497 1.68l.007.149v9.25h2.077l-.139.95c-.73 4.615-2.488 7.328-5.317 8.035L34 63.5H24l-.158-.026c-2.204-.734-3.948-3.525-5.323-8.337l-.182-.637H36.5l.001-9.25c0-.374-.323-.753-.746-.859-.405-.1-.809.06-1.138.543.662 1.13.546 2.561-.373 3.48-1.105 1.105-5.348-3.138-4.243-4.242 1.056-1.056 2.789-1.053 3.963-.027.552-.655 1.29-.91 2.033-.724zm2.42 12.079H19.669l.05.163c1.207 3.892 2.655 6.151 4.276 6.803l.095.034h9.84l.155-.042c2.174-.653 3.62-2.884 4.301-6.779l.03-.179zm-5.479-6.081l.08.042.069.055a.5.5 0 01.116.558l-.781 1.841a.5.5 0 01-.656.265l-.08-.042-.068-.055a.5.5 0 01-.117-.558l.782-1.841a.5.5 0 01.655-.265zm-1.978-1.666l.075.05a.5.5 0 01.074.703l-1.259 1.555a.5.5 0 01-.628.124l-.075-.05a.5.5 0 01-.074-.704l1.259-1.554a.5.5 0 01.628-.124zm-2.18-1.706l.076.042a.499.499 0 01.003.822l-1.639 1.147a.5.5 0 01-.495.045l-.075-.043a.499.499 0 01-.003-.821l1.638-1.147a.5.5 0 01.496-.045zm2.142-1.35l-.082.064c.014.05.033.104.06.164l.042.095c.2.41.586.92 1.059 1.394.473.474.984.86 1.395 1.059.066.032.126.058.18.077l.078.025.064-.082c.482-.68.42-1.667-.195-2.377L33.414 45c-.715-.715-1.775-.812-2.493-.302z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default TubIcon;
