import React from 'react';

function GridIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            className="grid-icon"
        >
            <g
                fill="none"
                fillOpacity="0"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
            >
                <g
                    fill="#D8D8D8"
                    stroke="#7A7367"
                    transform="translate(-1812 -159)"
                >
                    <g transform="translate(-24)">
                        <g transform="translate(24 145)">
                            <g transform="translate(1718)">
                                <g transform="translate(94 14)">
                                    <path d="M8.5 11.5v8h-8v-8h8zm11 0v8h-8v-8h8zM8.5.5v8h-8v-8h8zm11 0v8h-8v-8h8z"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default GridIcon;
