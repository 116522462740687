import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';

function Tracker() {
    const history = useHistory();

    function locationChanged(location) {
        ReactGA.initialize('GTM-KJR8KSF');
        ReactGA.set({page: location.pathname});
        ReactGA.pageview(location.pathname);
    }
    
    useEffect(() => {
        history.listen(locationChanged);
    });

    return(
        <div style={{display: 'none'}} />
    )
}

export default Tracker;