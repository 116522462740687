import React, { useEffect } from 'react';
import './Cover.scss';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

/* This component shows a loading screen until all images are rendered
(or a selection of images inside a div with #image-load-target if page has a ton of images) */

const Cover = (props) => {
    useEffect(() => {
        let images = null;
        let totalImages = null;
        let imageLoadTarget = null;
        if (document.querySelector('#image-load-target')) {
            imageLoadTarget = document.querySelector('#image-load-target');
            images = imageLoadTarget.querySelectorAll('img');
            totalImages = imageLoadTarget.querySelectorAll('img').length;
        } else {
            images = document.querySelectorAll('img');
            totalImages = document.querySelectorAll('img').length;
        }
        let imagesLoaded = 0;
        let renderedImages = 0;
        let cover = document.querySelector('.cover');

        if (props.noTransition) {
            cover.style.transition = 'all 0s';
        }
        const hideCover = () => {
            cover.style.opacity = '0';

            setTimeout(function () {
                if (cover) {
                    cover.style.display = 'none';
                }
            }, 500);
        };

        if (props.fadeImmediately) {
            setTimeout(function () {
                hideCover();
            }, 1000);
        }

        setTimeout(function () {
            let svg = document.querySelector('.cover svg');
            if (svg) {
                svg.style.opacity = '1';
            }
            if (cover && !props.noTransition) {
                cover.style.transition = 'all 0.5s';
            }
        }, 250);

        setTimeout(function () {
            let svg = document.querySelector('.cover svg');
            if (svg) {
                svg.style.opacity = '1';
            }
        }, 500);

        const allImagesRendered = () => {
            hideCover();
        };

        const allImagesLoaded = () => {
            Array.prototype.forEach.call(images, function (image) {
                const interval = setInterval(() => {
                    if (image.naturalWidth > 0 && image.naturalHeight > 0) {
                        renderedImages++;
                        clearInterval(interval);
                        if (renderedImages == totalImages) {
                            allImagesRendered();
                        }
                    }
                }, 20);
            });
        };

        const dummyImageHandler = () => {
            imagesLoaded++;
            if (imagesLoaded == totalImages) {
                allImagesLoaded();
            }
        };

        Array.prototype.forEach.call(images, function (image) {
            let dummyImage = document.createElement('img');
            dummyImage.addEventListener('load', dummyImageHandler);
            let src = image.getAttribute('src');
            dummyImage.setAttribute('src', src);
        });
    });

    return (
        <div className="cover" style={props.background}>
            <LoadingIcon svgColor={props.svgColor} />
        </div>
    );
};

export default Cover;
