import React from 'react';

function SquareFootIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 20.6 18.6"
            version="1.1"
            viewBox="0 0 20.6 18.6"
            xmlSpace="preserve"
        >
            <g>
                <g transform="translate(-49 -360)">
                    <g transform="translate(0 192)">
                        <g transform="translate(0 144)">
                            <g transform="translate(48 23)">
                                <g transform="translate(.584 1)">
                                    <g transform="translate(.416)">
                                        <path
                                            d="M10.3 0l10.3 7.2-.6.8-1.2-.8v8.9c0 1.3-1 2.4-2.3 2.5H4.3c-1.3 0-2.4-1-2.5-2.3V7.2L.6 8 0 7.2 10.3 0zm0 1.2L2.8 6.5v9.6c0 .8.6 1.4 1.4 1.5h12.1c.8 0 1.4-.6 1.5-1.4V6.5l-7.5-5.3zm2.9 7.4l3.5 3.5-3.5 3.5-.7-.7 2.3-2.3H5.7L8 15l-.7.7-3.5-3.5 3.5-3.5.7.6-2.3 2.3h9.1l-2.3-2.3.7-.7z"
                                            className="st0"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SquareFootIcon;
