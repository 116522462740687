import React from 'react';
import './CheckboxButton.scss';
import CheckboxIcon from '../CheckboxIcon/CheckboxIcon';

function CheckboxButton(props) {
    return (
        <li>
            <span onClick={props.callback}>
                <CheckboxIcon active={props.active} />
                <label>{props.text}</label>
            </span>
        </li>
    );
}

export default CheckboxButton;
